import background from "../images/background.png";
import backgroundGreen from "../images/background-green.png";
import banner from "../images/banner.png";
import bucket from "../images/bucket.png";
import cardsImg from "../images/cards-img.png";
import cardsImgOld from "../images/cards-img-old.png";
import eraser from "../images/eraser.png";
import frame from "../images/frame.png";
import muted from "../images/muted.png";
import painter from "../images/painter.png";
import pen from "../images/pen.png";
import potato from "../images/potato.png";
import scrawlFullscreen from "../images/scrawl_fullscreen.png";
import scrawlHelp from "../images/scrawl_help.png";
import scrawlMuted from "../images/scrawl_muted.png";
import scrawlUnmuted from "../images/scrawl_unmuted.png";
import scrawlLogo from "../images/scrawl-logo.png";
import scrawlLogo17 from "../images/scrawl-logo-17.png";
import undo from "../images/undo.png";
import unmuted from "../images/unmuted.png";
import familyIcon from "images/Family Icon.png"
import adultsIcon from "images/Adults Icon.png"


const images = [
    background,
    banner,
    bucket,
    cardsImg,
    cardsImgOld,
    eraser,
    frame,
    muted,
    painter,
    pen,
    potato,
    scrawlFullscreen,
    scrawlHelp,
    scrawlMuted,
    scrawlUnmuted,
    scrawlLogo,
    undo,
    unmuted,
    familyIcon,
    adultsIcon,
    backgroundGreen,
    scrawlLogo17
];

const fonts = [
    { font: "Gagalin", path: `https://${window.location.host}/Assets/gagalin.ttf` },
    { font: "Helenita", path: `https://${window.location.host}/Assets/HelenitaBookRough.otf` },
    { font: "Interstate", path: `https://${window.location.host}/Assets/Interstate-Regular.otf` },
    { font: "MyriadPro", path: `https://${window.location.host}/Assets/MyriadPro-Regular.otf` },
];


const WSErrorCodes = {
    1001: "Browser Tab Closing",
    1002: "Malformed framce received",
    1003: "Unsupported frame received",
    1006: "GS ungraceful shutdown / connection refused",
    1011: "Internal Error",
    1012: "Server Restarting",
    1013: "Try again later, server capacity full",
    1014: "Bad Gateway",
    4000: "Gameserver graceful shutdown",
    4050: "Player removed"
};

const isBot = process.env.REACT_APP_IS_BOT === "true";
const botDrawingData = [[
    {
        "drawMode": true,
        "strokeColor": "#000000",
        "strokeWidth": 4,
        "paths": [
            {
                "x": 190,
                "y": 52.5
            },
            {
                "x": 190,
                "y": 52.5
            },
            {
                "x": 190,
                "y": 54.5
            },
            {
                "x": 190,
                "y": 57.5
            },
            {
                "x": 190,
                "y": 60.5
            },
            {
                "x": 190,
                "y": 64.5
            },
            {
                "x": 190,
                "y": 69.5
            },
            {
                "x": 190,
                "y": 76.5
            },
            {
                "x": 190,
                "y": 83.5
            },
            {
                "x": 189,
                "y": 89.5
            },
            {
                "x": 188,
                "y": 96.5
            },
            {
                "x": 187,
                "y": 103.5
            },
            {
                "x": 187,
                "y": 109.5
            },
            {
                "x": 187,
                "y": 115.5
            },
            {
                "x": 186,
                "y": 121.5
            },
            {
                "x": 186,
                "y": 126.5
            },
            {
                "x": 186,
                "y": 132.5
            },
            {
                "x": 187,
                "y": 138.5
            },
            {
                "x": 189,
                "y": 145.5
            },
            {
                "x": 191,
                "y": 150.5
            },
            {
                "x": 193,
                "y": 156.5
            },
            {
                "x": 195,
                "y": 161.5
            },
            {
                "x": 197,
                "y": 164.5
            },
            {
                "x": 198,
                "y": 167.5
            },
            {
                "x": 200,
                "y": 170.5
            },
            {
                "x": 201,
                "y": 172.5
            },
            {
                "x": 203,
                "y": 174.5
            },
            {
                "x": 205,
                "y": 175.5
            },
            {
                "x": 207,
                "y": 178.5
            },
            {
                "x": 212,
                "y": 182.5
            },
            {
                "x": 217,
                "y": 185.5
            },
            {
                "x": 223,
                "y": 189.5
            },
            {
                "x": 230,
                "y": 194.5
            },
            {
                "x": 236,
                "y": 197.5
            },
            {
                "x": 239,
                "y": 198.5
            },
            {
                "x": 243,
                "y": 200.5
            },
            {
                "x": 245,
                "y": 201.5
            },
            {
                "x": 246,
                "y": 201.5
            },
            {
                "x": 247,
                "y": 202.5
            },
            {
                "x": 248,
                "y": 202.5
            },
            {
                "x": 249,
                "y": 203.5
            },
            {
                "x": 250,
                "y": 204.5
            },
            {
                "x": 251,
                "y": 204.5
            },
            {
                "x": 254,
                "y": 206.5
            },
            {
                "x": 256,
                "y": 207.5
            },
            {
                "x": 259,
                "y": 208.5
            },
            {
                "x": 262,
                "y": 209.5
            },
            {
                "x": 266,
                "y": 209.5
            },
            {
                "x": 272,
                "y": 208.5
            },
            {
                "x": 278,
                "y": 204.5
            },
            {
                "x": 284,
                "y": 198.5
            },
            {
                "x": 292,
                "y": 189.5
            },
            {
                "x": 299,
                "y": 179.5
            },
            {
                "x": 307,
                "y": 164.5
            },
            {
                "x": 314,
                "y": 149.5
            },
            {
                "x": 320,
                "y": 133.5
            },
            {
                "x": 323,
                "y": 119.5
            },
            {
                "x": 324,
                "y": 106.5
            },
            {
                "x": 324,
                "y": 95.5
            },
            {
                "x": 323,
                "y": 84.5
            },
            {
                "x": 317,
                "y": 76.5
            },
            {
                "x": 309,
                "y": 68.5
            },
            {
                "x": 292,
                "y": 60.5
            },
            {
                "x": 266,
                "y": 54.5
            },
            {
                "x": 231,
                "y": 49.5
            },
            {
                "x": 188,
                "y": 47.5
            },
            {
                "x": 136,
                "y": 47.5
            },
            {
                "x": 87,
                "y": 48.5
            },
            {
                "x": 45,
                "y": 54.5
            },
            {
                "x": 13,
                "y": 62.5
            },
            {
                "x": 2,
                "y": 107.5
            },
            {
                "x": 32,
                "y": 107.5
            },
            {
                "x": 68,
                "y": 107.5
            },
            {
                "x": 103,
                "y": 107.5
            },
            {
                "x": 139,
                "y": 107.5
            },
            {
                "x": 166,
                "y": 107.5
            },
            {
                "x": 181,
                "y": 107.5
            },
            {
                "x": 190,
                "y": 107.5
            },
            {
                "x": 193,
                "y": 107.5
            },
            {
                "x": 186,
                "y": 107.5
            },
            {
                "x": 174,
                "y": 108.5
            },
            {
                "x": 156,
                "y": 108.5
            },
            {
                "x": 132,
                "y": 108.5
            },
            {
                "x": 106,
                "y": 108.5
            },
            {
                "x": 86,
                "y": 105.5
            },
            {
                "x": 72,
                "y": 102.5
            },
            {
                "x": 65,
                "y": 100.5
            },
            {
                "x": 62,
                "y": 98.5
            },
            {
                "x": 62,
                "y": 96.5
            },
            {
                "x": 68,
                "y": 94.5
            },
            {
                "x": 79,
                "y": 89.5
            },
            {
                "x": 97,
                "y": 83.5
            },
            {
                "x": 124,
                "y": 75.5
            },
            {
                "x": 154,
                "y": 67.5
            },
            {
                "x": 183,
                "y": 60.5
            },
            {
                "x": 202,
                "y": 55.5
            },
            {
                "x": 213,
                "y": 51.5
            },
            {
                "x": 219,
                "y": 49.5
            },
            {
                "x": 220,
                "y": 49.5
            },
            {
                "x": 216,
                "y": 49.5
            },
            {
                "x": 204,
                "y": 49.5
            },
            {
                "x": 187,
                "y": 53.5
            },
            {
                "x": 165,
                "y": 58.5
            },
            {
                "x": 143,
                "y": 67.5
            },
            {
                "x": 126,
                "y": 80.5
            },
            {
                "x": 116,
                "y": 92.5
            },
            {
                "x": 112,
                "y": 106.5
            },
            {
                "x": 113,
                "y": 121.5
            },
            {
                "x": 125,
                "y": 140.5
            },
            {
                "x": 143,
                "y": 158.5
            },
            {
                "x": 172,
                "y": 176.5
            },
            {
                "x": 208,
                "y": 188.5
            },
            {
                "x": 248,
                "y": 195.5
            },
            {
                "x": 287,
                "y": 199.5
            },
            {
                "x": 325,
                "y": 201.5
            },
            {
                "x": 360,
                "y": 201.5
            },
            {
                "x": 391,
                "y": 200.5
            },
            {
                "x": 411,
                "y": 197.5
            },
            {
                "x": 425,
                "y": 195.5
            },
            {
                "x": 431,
                "y": 195.5
            },
            {
                "x": 434,
                "y": 195.5
            },
            {
                "x": 429,
                "y": 195.5
            },
            {
                "x": 412,
                "y": 195.5
            },
            {
                "x": 376,
                "y": 198.5
            },
            {
                "x": 329,
                "y": 203.5
            },
            {
                "x": 281,
                "y": 211.5
            },
            {
                "x": 233,
                "y": 224.5
            },
            {
                "x": 194,
                "y": 237.5
            },
            {
                "x": 171,
                "y": 250.5
            },
            {
                "x": 161,
                "y": 259.5
            },
            {
                "x": 157,
                "y": 268.5
            },
            {
                "x": 160,
                "y": 278.5
            },
            {
                "x": 172,
                "y": 290.5
            },
            {
                "x": 201,
                "y": 307.5
            },
            {
                "x": 246,
                "y": 331.5
            },
            {
                "x": 304,
                "y": 354.5
            },
            {
                "x": 369,
                "y": 375.5
            },
            {
                "x": 426,
                "y": 391.5
            },
            {
                "x": 490,
                "y": 407.5
            },
            {
                "x": 487,
                "y": 425.5
            },
            {
                "x": 425,
                "y": 425.5
            },
            {
                "x": 365,
                "y": 431.5
            },
            {
                "x": 317,
                "y": 441.5
            },
            {
                "x": 283,
                "y": 455.5
            },
            {
                "x": 262,
                "y": 469.5
            },
            {
                "x": 253,
                "y": 478.5
            },
            {
                "x": 250,
                "y": 486.5
            },
            {
                "x": 250,
                "y": 491.5
            },
            {
                "x": 257,
                "y": 494.5
            },
            {
                "x": 270,
                "y": 494.5
            },
            {
                "x": 291,
                "y": 489.5
            },
            {
                "x": 326,
                "y": 481.5
            },
            {
                "x": 369,
                "y": 469.5
            },
            {
                "x": 415,
                "y": 458.5
            },
            {
                "x": 453,
                "y": 447.5
            },
            {
                "x": 483,
                "y": 437.5
            },
            {
                "x": 493,
                "y": 425.5
            },
            {
                "x": 469,
                "y": 425.5
            },
            {
                "x": 425,
                "y": 426.5
            },
            {
                "x": 375,
                "y": 430.5
            },
            {
                "x": 325,
                "y": 435.5
            },
            {
                "x": 281,
                "y": 439.5
            },
            {
                "x": 248,
                "y": 441.5
            },
            {
                "x": 226,
                "y": 441.5
            },
            {
                "x": 214,
                "y": 441.5
            },
            {
                "x": 207,
                "y": 438.5
            },
            {
                "x": 204,
                "y": 433.5
            },
            {
                "x": 204,
                "y": 425.5
            },
            {
                "x": 208,
                "y": 415.5
            },
            {
                "x": 216,
                "y": 402.5
            },
            {
                "x": 233,
                "y": 382.5
            },
            {
                "x": 256,
                "y": 358.5
            },
            {
                "x": 287,
                "y": 333.5
            },
            {
                "x": 320,
                "y": 309.5
            },
            {
                "x": 350,
                "y": 286.5
            },
            {
                "x": 378,
                "y": 263.5
            },
            {
                "x": 398,
                "y": 241.5
            },
            {
                "x": 411,
                "y": 221.5
            },
            {
                "x": 417,
                "y": 209.5
            },
            {
                "x": 419,
                "y": 202.5
            },
            {
                "x": 419,
                "y": 199.5
            },
            {
                "x": 415,
                "y": 197.5
            },
            {
                "x": 406,
                "y": 198.5
            },
            {
                "x": 395,
                "y": 205.5
            },
            {
                "x": 381,
                "y": 217.5
            },
            {
                "x": 362,
                "y": 238.5
            },
            {
                "x": 343,
                "y": 268.5
            },
            {
                "x": 327,
                "y": 299.5
            },
            {
                "x": 311,
                "y": 336.5
            },
            {
                "x": 296,
                "y": 379.5
            },
            {
                "x": 278,
                "y": 424.5
            },
            {
                "x": 261,
                "y": 465.5
            },
            {
                "x": 245,
                "y": 499.5
            },
            {
                "x": 270,
                "y": 490.5
            },
            {
                "x": 269,
                "y": 477.5
            },
            {
                "x": 264,
                "y": 461.5
            },
            {
                "x": 252,
                "y": 442.5
            },
            {
                "x": 237,
                "y": 420.5
            },
            {
                "x": 213,
                "y": 398.5
            },
            {
                "x": 183,
                "y": 375.5
            },
            {
                "x": 148,
                "y": 353.5
            },
            {
                "x": 109,
                "y": 334.5
            },
            {
                "x": 75,
                "y": 322.5
            },
            {
                "x": 54,
                "y": 316.5
            },
            {
                "x": 42,
                "y": 315.5
            },
            {
                "x": 36,
                "y": 315.5
            },
            {
                "x": 35,
                "y": 317.5
            },
            {
                "x": 35,
                "y": 323.5
            },
            {
                "x": 42,
                "y": 330.5
            },
            {
                "x": 54,
                "y": 341.5
            },
            {
                "x": 73,
                "y": 356.5
            },
            {
                "x": 100,
                "y": 375.5
            },
            {
                "x": 132,
                "y": 392.5
            },
            {
                "x": 169,
                "y": 409.5
            },
            {
                "x": 205,
                "y": 421.5
            },
            {
                "x": 234,
                "y": 430.5
            },
            {
                "x": 261,
                "y": 434.5
            },
            {
                "x": 284,
                "y": 437.5
            },
            {
                "x": 304,
                "y": 438.5
            },
            {
                "x": 317,
                "y": 438.5
            },
            {
                "x": 326,
                "y": 438.5
            },
            {
                "x": 331,
                "y": 437.5
            },
            {
                "x": 333,
                "y": 434.5
            },
            {
                "x": 333,
                "y": 429.5
            },
            {
                "x": 328,
                "y": 421.5
            },
            {
                "x": 316,
                "y": 411.5
            },
            {
                "x": 294,
                "y": 395.5
            },
            {
                "x": 260,
                "y": 377.5
            },
            {
                "x": 215,
                "y": 357.5
            },
            {
                "x": 158,
                "y": 339.5
            },
            {
                "x": 96,
                "y": 323.5
            },
            {
                "x": 38,
                "y": 313.5
            },
            {
                "x": 17,
                "y": 334.5
            },
            {
                "x": 63,
                "y": 334.5
            },
            {
                "x": 110,
                "y": 329.5
            },
            {
                "x": 158,
                "y": 317.5
            },
            {
                "x": 199,
                "y": 304.5
            },
            {
                "x": 238,
                "y": 290.5
            },
            {
                "x": 269,
                "y": 274.5
            },
            {
                "x": 293,
                "y": 259.5
            },
            {
                "x": 310,
                "y": 248.5
            },
            {
                "x": 317,
                "y": 240.5
            },
            {
                "x": 321,
                "y": 232.5
            },
            {
                "x": 321,
                "y": 227.5
            },
            {
                "x": 320,
                "y": 222.5
            },
            {
                "x": 315,
                "y": 220.5
            },
            {
                "x": 309,
                "y": 218.5
            },
            {
                "x": 302,
                "y": 217.5
            },
            {
                "x": 294,
                "y": 217.5
            },
            {
                "x": 286,
                "y": 217.5
            },
            {
                "x": 278,
                "y": 215.5
            },
            {
                "x": 270,
                "y": 213.5
            },
            {
                "x": 261,
                "y": 210.5
            },
            {
                "x": 255,
                "y": 209.5
            },
            {
                "x": 249,
                "y": 209.5
            },
            {
                "x": 247,
                "y": 209.5
            },
            {
                "x": 246,
                "y": 210.5
            },
            {
                "x": 246,
                "y": 213.5
            },
            {
                "x": 246,
                "y": 220.5
            },
            {
                "x": 254,
                "y": 235.5
            },
            {
                "x": 270,
                "y": 259.5
            },
            {
                "x": 298,
                "y": 293.5
            },
            {
                "x": 334,
                "y": 334.5
            },
            {
                "x": 374,
                "y": 376.5
            },
            {
                "x": 413,
                "y": 416.5
            },
            {
                "x": 447,
                "y": 448.5
            },
            {
                "x": 470,
                "y": 466.5
            },
            {
                "x": 483,
                "y": 475.5
            },
            {
                "x": 492,
                "y": 479.5
            },
            {
                "x": 496,
                "y": 481.5
            },
            {
                "x": 499,
                "y": 481.5
            },
            {
                "x": 495,
                "y": 479.5
            },
            {
                "x": 484,
                "y": 477.5
            },
            {
                "x": 465,
                "y": 475.5
            },
            {
                "x": 437,
                "y": 471.5
            },
            {
                "x": 400,
                "y": 468.5
            },
            {
                "x": 361,
                "y": 464.5
            },
            {
                "x": 322,
                "y": 459.5
            },
            {
                "x": 285,
                "y": 452.5
            },
            {
                "x": 252,
                "y": 443.5
            },
            {
                "x": 226,
                "y": 430.5
            },
            {
                "x": 204,
                "y": 415.5
            },
            {
                "x": 188,
                "y": 397.5
            },
            {
                "x": 174,
                "y": 375.5
            },
            {
                "x": 165,
                "y": 355.5
            },
            {
                "x": 161,
                "y": 334.5
            },
            {
                "x": 162,
                "y": 314.5
            },
            {
                "x": 171,
                "y": 291.5
            },
            {
                "x": 185,
                "y": 270.5
            },
            {
                "x": 205,
                "y": 249.5
            },
            {
                "x": 231,
                "y": 229.5
            },
            {
                "x": 263,
                "y": 209.5
            },
            {
                "x": 295,
                "y": 191.5
            },
            {
                "x": 326,
                "y": 176.5
            },
            {
                "x": 352,
                "y": 163.5
            },
            {
                "x": 372,
                "y": 154.5
            },
            {
                "x": 387,
                "y": 148.5
            },
            {
                "x": 398,
                "y": 146.5
            },
            {
                "x": 404,
                "y": 145.5
            },
            {
                "x": 406,
                "y": 145.5
            },
            {
                "x": 406,
                "y": 147.5
            },
            {
                "x": 405,
                "y": 153.5
            },
            {
                "x": 398,
                "y": 164.5
            },
            {
                "x": 388,
                "y": 181.5
            },
            {
                "x": 372,
                "y": 210.5
            },
            {
                "x": 354,
                "y": 245.5
            },
            {
                "x": 335,
                "y": 284.5
            },
            {
                "x": 316,
                "y": 326.5
            },
            {
                "x": 297,
                "y": 368.5
            },
            {
                "x": 282,
                "y": 409.5
            },
            {
                "x": 270,
                "y": 445.5
            },
            {
                "x": 260,
                "y": 473.5
            },
            {
                "x": 253,
                "y": 495.5
            },
            {
                "x": 165,
                "y": 487.5
            },
            {
                "x": 143,
                "y": 452.5
            },
            {
                "x": 121,
                "y": 417.5
            },
            {
                "x": 103,
                "y": 382.5
            },
            {
                "x": 91,
                "y": 352.5
            },
            {
                "x": 84,
                "y": 325.5
            },
            {
                "x": 82,
                "y": 304.5
            },
            {
                "x": 83,
                "y": 284.5
            },
            {
                "x": 90,
                "y": 264.5
            },
            {
                "x": 100,
                "y": 248.5
            },
            {
                "x": 116,
                "y": 231.5
            },
            {
                "x": 136,
                "y": 215.5
            },
            {
                "x": 157,
                "y": 203.5
            },
            {
                "x": 181,
                "y": 196.5
            },
            {
                "x": 208,
                "y": 191.5
            },
            {
                "x": 233,
                "y": 190.5
            },
            {
                "x": 250,
                "y": 190.5
            },
            {
                "x": 266,
                "y": 193.5
            },
            {
                "x": 277,
                "y": 198.5
            },
            {
                "x": 283,
                "y": 202.5
            },
            {
                "x": 286,
                "y": 208.5
            },
            {
                "x": 286,
                "y": 213.5
            },
            {
                "x": 281,
                "y": 221.5
            },
            {
                "x": 270,
                "y": 229.5
            },
            {
                "x": 255,
                "y": 237.5
            },
            {
                "x": 230,
                "y": 245.5
            },
            {
                "x": 199,
                "y": 250.5
            },
            {
                "x": 166,
                "y": 252.5
            },
            {
                "x": 133,
                "y": 252.5
            },
            {
                "x": 95,
                "y": 252.5
            },
            {
                "x": 62,
                "y": 251.5
            },
            {
                "x": 18,
                "y": 249.5
            },
            {
                "x": 22,
                "y": 333.5
            },
            {
                "x": 93,
                "y": 363.5
            },
            {
                "x": 179,
                "y": 390.5
            },
            {
                "x": 263,
                "y": 409.5
            },
            {
                "x": 340,
                "y": 420.5
            },
            {
                "x": 408,
                "y": 424.5
            },
            {
                "x": 462,
                "y": 424.5
            },
            {
                "x": 498,
                "y": 362.5
            },
            {
                "x": 459,
                "y": 347.5
            },
            {
                "x": 409,
                "y": 327.5
            },
            {
                "x": 354,
                "y": 301.5
            },
            {
                "x": 296,
                "y": 271.5
            },
            {
                "x": 247,
                "y": 239.5
            },
            {
                "x": 208,
                "y": 208.5
            },
            {
                "x": 179,
                "y": 179.5
            },
            {
                "x": 163,
                "y": 152.5
            },
            {
                "x": 155,
                "y": 130.5
            },
            {
                "x": 155,
                "y": 116.5
            },
            {
                "x": 163,
                "y": 101.5
            },
            {
                "x": 180,
                "y": 85.5
            },
            {
                "x": 209,
                "y": 69.5
            },
            {
                "x": 248,
                "y": 53.5
            },
            {
                "x": 311,
                "y": 34.5
            },
            {
                "x": 382,
                "y": 23.5
            },
            {
                "x": 448,
                "y": 19.5
            },
            {
                "x": 498,
                "y": 14.5
            },
            {
                "x": 441,
                "y": 14.5
            },
            {
                "x": 382,
                "y": 14.5
            },
            {
                "x": 322,
                "y": 14.5
            },
            {
                "x": 265,
                "y": 17.5
            },
            {
                "x": 215,
                "y": 23.5
            },
            {
                "x": 173,
                "y": 28.5
            },
            {
                "x": 144,
                "y": 33.5
            },
            {
                "x": 131,
                "y": 37.5
            },
            {
                "x": 122,
                "y": 40.5
            },
            {
                "x": 121,
                "y": 40.5
            },
            {
                "x": 123,
                "y": 40.5
            },
            {
                "x": 132,
                "y": 40.5
            },
            {
                "x": 155,
                "y": 40.5
            },
            {
                "x": 193,
                "y": 40.5
            },
            {
                "x": 233,
                "y": 40.5
            },
            {
                "x": 274,
                "y": 40.5
            },
            {
                "x": 312,
                "y": 40.5
            },
            {
                "x": 346,
                "y": 42.5
            },
            {
                "x": 367,
                "y": 47.5
            },
            {
                "x": 379,
                "y": 52.5
            },
            {
                "x": 384,
                "y": 58.5
            },
            {
                "x": 384,
                "y": 65.5
            },
            {
                "x": 374,
                "y": 73.5
            },
            {
                "x": 350,
                "y": 83.5
            },
            {
                "x": 305,
                "y": 90.5
            },
            {
                "x": 242,
                "y": 95.5
            },
            {
                "x": 166,
                "y": 97.5
            },
            {
                "x": 88,
                "y": 99.5
            },
            {
                "x": 18,
                "y": 99.5
            },
            {
                "x": 53,
                "y": 84.5
            },
            {
                "x": 133,
                "y": 76.5
            },
            {
                "x": 215,
                "y": 67.5
            },
            {
                "x": 291,
                "y": 61.5
            },
            {
                "x": 355,
                "y": 53.5
            },
            {
                "x": 408,
                "y": 43.5
            },
            {
                "x": 443,
                "y": 34.5
            },
            {
                "x": 465,
                "y": 27.5
            },
            {
                "x": 476,
                "y": 21.5
            },
            {
                "x": 480,
                "y": 18.5
            },
            {
                "x": 479,
                "y": 15.5
            },
            {
                "x": 472,
                "y": 13.5
            },
            {
                "x": 453,
                "y": 12.5
            },
            {
                "x": 415,
                "y": 12.5
            },
            {
                "x": 366,
                "y": 15.5
            },
            {
                "x": 318,
                "y": 23.5
            },
            {
                "x": 272,
                "y": 34.5
            },
            {
                "x": 239,
                "y": 48.5
            },
            {
                "x": 217,
                "y": 64.5
            },
            {
                "x": 207,
                "y": 76.5
            },
            {
                "x": 203,
                "y": 87.5
            },
            {
                "x": 205,
                "y": 96.5
            },
            {
                "x": 213,
                "y": 103.5
            },
            {
                "x": 226,
                "y": 106.5
            },
            {
                "x": 250,
                "y": 108.5
            },
            {
                "x": 291,
                "y": 108.5
            },
            {
                "x": 337,
                "y": 108.5
            },
            {
                "x": 384,
                "y": 105.5
            },
            {
                "x": 423,
                "y": 101.5
            },
            {
                "x": 451,
                "y": 98.5
            },
            {
                "x": 465,
                "y": 95.5
            },
            {
                "x": 471,
                "y": 93.5
            },
            {
                "x": 471,
                "y": 91.5
            },
            {
                "x": 466,
                "y": 91.5
            },
            {
                "x": 446,
                "y": 91.5
            },
            {
                "x": 402,
                "y": 92.5
            },
            {
                "x": 334,
                "y": 96.5
            },
            {
                "x": 252,
                "y": 105.5
            },
            {
                "x": 172,
                "y": 118.5
            },
            {
                "x": 108,
                "y": 133.5
            },
            {
                "x": 59,
                "y": 148.5
            },
            {
                "x": 30,
                "y": 162.5
            },
            {
                "x": 19,
                "y": 170.5
            },
            {
                "x": 16,
                "y": 175.5
            },
            {
                "x": 18,
                "y": 179.5
            },
            {
                "x": 29,
                "y": 181.5
            },
            {
                "x": 55,
                "y": 181.5
            },
            {
                "x": 102,
                "y": 178.5
            },
            {
                "x": 164,
                "y": 174.5
            },
            {
                "x": 235,
                "y": 170.5
            },
            {
                "x": 301,
                "y": 166.5
            },
            {
                "x": 356,
                "y": 160.5
            },
            {
                "x": 395,
                "y": 155.5
            },
            {
                "x": 414,
                "y": 151.5
            },
            {
                "x": 421,
                "y": 150.5
            },
            {
                "x": 421,
                "y": 148.5
            },
            {
                "x": 417,
                "y": 148.5
            },
            {
                "x": 397,
                "y": 148.5
            },
            {
                "x": 354,
                "y": 148.5
            },
            {
                "x": 289,
                "y": 148.5
            },
            {
                "x": 205,
                "y": 148.5
            },
            {
                "x": 119,
                "y": 148.5
            },
            {
                "x": 43,
                "y": 148.5
            },
            {
                "x": 21,
                "y": 186.5
            },
            {
                "x": 92,
                "y": 188.5
            },
            {
                "x": 178,
                "y": 188.5
            },
            {
                "x": 270,
                "y": 185.5
            },
            {
                "x": 358,
                "y": 178.5
            },
            {
                "x": 434,
                "y": 170.5
            },
            {
                "x": 496,
                "y": 158.5
            },
            {
                "x": 489,
                "y": 96.5
            },
            {
                "x": 412,
                "y": 92.5
            },
            {
                "x": 315,
                "y": 92.5
            },
            {
                "x": 213,
                "y": 92.5
            },
            {
                "x": 120,
                "y": 99.5
            },
            {
                "x": 43,
                "y": 112.5
            },
            {
                "x": 36,
                "y": 144.5
            },
            {
                "x": 98,
                "y": 127.5
            },
            {
                "x": 170,
                "y": 108.5
            },
            {
                "x": 243,
                "y": 89.5
            },
            {
                "x": 313,
                "y": 69.5
            },
            {
                "x": 370,
                "y": 53.5
            },
            {
                "x": 411,
                "y": 40.5
            },
            {
                "x": 428,
                "y": 34.5
            },
            {
                "x": 434,
                "y": 31.5
            },
            {
                "x": 433,
                "y": 29.5
            },
            {
                "x": 420,
                "y": 28.5
            },
            {
                "x": 386,
                "y": 28.5
            },
            {
                "x": 318,
                "y": 31.5
            },
            {
                "x": 233,
                "y": 40.5
            },
            {
                "x": 145,
                "y": 53.5
            },
            {
                "x": 60,
                "y": 66.5
            },
            {
                "x": 39,
                "y": 124.5
            },
            {
                "x": 129,
                "y": 118.5
            },
            {
                "x": 227,
                "y": 107.5
            },
            {
                "x": 325,
                "y": 98.5
            },
            {
                "x": 413,
                "y": 89.5
            },
            {
                "x": 486,
                "y": 85.5
            },
            {
                "x": 456,
                "y": 81.5
            },
            {
                "x": 362,
                "y": 86.5
            },
            {
                "x": 256,
                "y": 90.5
            },
            {
                "x": 147,
                "y": 99.5
            },
            {
                "x": 51,
                "y": 113.5
            },
            {
                "x": 57,
                "y": 153.5
            },
            {
                "x": 152,
                "y": 146.5
            },
            {
                "x": 253,
                "y": 140.5
            },
            {
                "x": 348,
                "y": 133.5
            },
            {
                "x": 430,
                "y": 127.5
            },
            {
                "x": 493,
                "y": 123.5
            },
            {
                "x": 451,
                "y": 119.5
            },
            {
                "x": 359,
                "y": 123.5
            },
            {
                "x": 250,
                "y": 128.5
            },
            {
                "x": 139,
                "y": 135.5
            },
            {
                "x": 41,
                "y": 146.5
            },
            {
                "x": 10,
                "y": 205.5
            },
            {
                "x": 100,
                "y": 199.5
            },
            {
                "x": 201,
                "y": 192.5
            },
            {
                "x": 301,
                "y": 186.5
            },
            {
                "x": 391,
                "y": 179.5
            },
            {
                "x": 467,
                "y": 173.5
            },
            {
                "x": 452,
                "y": 164.5
            },
            {
                "x": 366,
                "y": 164.5
            },
            {
                "x": 268,
                "y": 168.5
            },
            {
                "x": 176,
                "y": 172.5
            },
            {
                "x": 100,
                "y": 178.5
            },
            {
                "x": 44,
                "y": 188.5
            },
            {
                "x": 11,
                "y": 197.5
            },
            {
                "x": 11,
                "y": 210.5
            },
            {
                "x": 41,
                "y": 210.5
            },
            {
                "x": 101,
                "y": 209.5
            },
            {
                "x": 183,
                "y": 202.5
            },
            {
                "x": 275,
                "y": 196.5
            },
            {
                "x": 368,
                "y": 189.5
            },
            {
                "x": 447,
                "y": 185.5
            },
            {
                "x": 467,
                "y": 176.5
            },
            {
                "x": 381,
                "y": 176.5
            },
            {
                "x": 284,
                "y": 177.5
            },
            {
                "x": 186,
                "y": 184.5
            },
            {
                "x": 96,
                "y": 195.5
            },
            {
                "x": 26,
                "y": 207.5
            },
            {
                "x": 28,
                "y": 253.5
            },
            {
                "x": 98,
                "y": 253.5
            },
            {
                "x": 179,
                "y": 252.5
            },
            {
                "x": 266,
                "y": 252.5
            },
            {
                "x": 349,
                "y": 252.5
            },
            {
                "x": 419,
                "y": 252.5
            },
            {
                "x": 471,
                "y": 250.5
            },
            {
                "x": 499,
                "y": 250.5
            },
            {
                "x": 478,
                "y": 251.5
            },
            {
                "x": 424,
                "y": 251.5
            },
            {
                "x": 348,
                "y": 253.5
            },
            {
                "x": 258,
                "y": 259.5
            },
            {
                "x": 163,
                "y": 268.5
            },
            {
                "x": 78,
                "y": 277.5
            },
            {
                "x": 11,
                "y": 289.5
            },
            {
                "x": 15,
                "y": 356.5
            },
            {
                "x": 83,
                "y": 358.5
            },
            {
                "x": 169,
                "y": 358.5
            },
            {
                "x": 262,
                "y": 355.5
            },
            {
                "x": 353,
                "y": 353.5
            },
            {
                "x": 437,
                "y": 353.5
            },
            {
                "x": 459,
                "y": 353.5
            },
            {
                "x": 375,
                "y": 353.5
            },
            {
                "x": 278,
                "y": 353.5
            },
            {
                "x": 178,
                "y": 354.5
            },
            {
                "x": 88,
                "y": 358.5
            },
            {
                "x": 22,
                "y": 366.5
            },
            {
                "x": 17,
                "y": 396.5
            },
            {
                "x": 67,
                "y": 402.5
            },
            {
                "x": 132,
                "y": 404.5
            },
            {
                "x": 203,
                "y": 406.5
            },
            {
                "x": 273,
                "y": 406.5
            },
            {
                "x": 338,
                "y": 406.5
            },
            {
                "x": 389,
                "y": 406.5
            },
            {
                "x": 422,
                "y": 406.5
            },
            {
                "x": 434,
                "y": 406.5
            },
            {
                "x": 437,
                "y": 406.5
            },
            {
                "x": 434,
                "y": 406.5
            },
            {
                "x": 418,
                "y": 406.5
            },
            {
                "x": 374,
                "y": 406.5
            },
            {
                "x": 307,
                "y": 406.5
            },
            {
                "x": 218,
                "y": 406.5
            },
            {
                "x": 121,
                "y": 406.5
            },
            {
                "x": 29,
                "y": 408.5
            },
            {
                "x": 77,
                "y": 447.5
            },
            {
                "x": 172,
                "y": 446.5
            },
            {
                "x": 264,
                "y": 442.5
            },
            {
                "x": 342,
                "y": 440.5
            },
            {
                "x": 408,
                "y": 436.5
            },
            {
                "x": 450,
                "y": 430.5
            },
            {
                "x": 468,
                "y": 428.5
            },
            {
                "x": 475,
                "y": 426.5
            },
            {
                "x": 475,
                "y": 425.5
            },
            {
                "x": 468,
                "y": 424.5
            },
            {
                "x": 448,
                "y": 423.5
            },
            {
                "x": 404,
                "y": 419.5
            },
            {
                "x": 341,
                "y": 417.5
            },
            {
                "x": 271,
                "y": 417.5
            },
            {
                "x": 203,
                "y": 417.5
            },
            {
                "x": 144,
                "y": 418.5
            },
            {
                "x": 96,
                "y": 424.5
            },
            {
                "x": 67,
                "y": 430.5
            },
            {
                "x": 56,
                "y": 435.5
            },
            {
                "x": 53,
                "y": 440.5
            },
            {
                "x": 55,
                "y": 443.5
            },
            {
                "x": 66,
                "y": 446.5
            },
            {
                "x": 90,
                "y": 448.5
            },
            {
                "x": 134,
                "y": 449.5
            },
            {
                "x": 191,
                "y": 449.5
            },
            {
                "x": 258,
                "y": 449.5
            },
            {
                "x": 326,
                "y": 449.5
            },
            {
                "x": 391,
                "y": 449.5
            },
            {
                "x": 448,
                "y": 449.5
            },
            {
                "x": 494,
                "y": 449.5
            },
            {
                "x": 472,
                "y": 449.5
            },
            {
                "x": 418,
                "y": 449.5
            },
            {
                "x": 359,
                "y": 449.5
            },
            {
                "x": 301,
                "y": 451.5
            },
            {
                "x": 249,
                "y": 455.5
            },
            {
                "x": 210,
                "y": 459.5
            },
            {
                "x": 185,
                "y": 462.5
            },
            {
                "x": 175,
                "y": 464.5
            },
            {
                "x": 171,
                "y": 466.5
            },
            {
                "x": 176,
                "y": 467.5
            },
            {
                "x": 192,
                "y": 468.5
            },
            {
                "x": 222,
                "y": 468.5
            },
            {
                "x": 258,
                "y": 470.5
            },
            {
                "x": 294,
                "y": 472.5
            },
            {
                "x": 327,
                "y": 473.5
            },
            {
                "x": 351,
                "y": 473.5
            }
        ]
    },
    {
        "drawMode": true,
        "strokeColor": "#000000",
        "strokeWidth": 4,
        "paths": [
            {
                "x": 220,
                "y": 331.5
            },
            {
                "x": 220,
                "y": 331.5
            }
        ]
    },
    {
        "drawMode": true,
        "strokeColor": "#000000",
        "strokeWidth": 4,
        "paths": [
            {
                "x": 162,
                "y": 354.5
            },
            {
                "x": 177,
                "y": 357.5
            },
            {
                "x": 192,
                "y": 360.5
            },
            {
                "x": 207,
                "y": 361.5
            },
            {
                "x": 222,
                "y": 361.5
            },
            {
                "x": 234,
                "y": 360.5
            },
            {
                "x": 243,
                "y": 355.5
            },
            {
                "x": 252,
                "y": 351.5
            },
            {
                "x": 258,
                "y": 346.5
            },
            {
                "x": 261,
                "y": 341.5
            },
            {
                "x": 263,
                "y": 337.5
            },
            {
                "x": 263,
                "y": 333.5
            },
            {
                "x": 261,
                "y": 329.5
            },
            {
                "x": 256,
                "y": 326.5
            },
            {
                "x": 248,
                "y": 324.5
            },
            {
                "x": 234,
                "y": 323.5
            },
            {
                "x": 211,
                "y": 323.5
            },
            {
                "x": 181,
                "y": 323.5
            },
            {
                "x": 148,
                "y": 325.5
            },
            {
                "x": 122,
                "y": 330.5
            },
            {
                "x": 108,
                "y": 333.5
            },
            {
                "x": 100,
                "y": 338.5
            },
            {
                "x": 96,
                "y": 342.5
            },
            {
                "x": 96,
                "y": 345.5
            },
            {
                "x": 97,
                "y": 348.5
            },
            {
                "x": 103,
                "y": 350.5
            },
            {
                "x": 114,
                "y": 351.5
            },
            {
                "x": 125,
                "y": 351.5
            },
            {
                "x": 139,
                "y": 348.5
            },
            {
                "x": 157,
                "y": 343.5
            },
            {
                "x": 179,
                "y": 336.5
            },
            {
                "x": 199,
                "y": 327.5
            },
            {
                "x": 214,
                "y": 320.5
            },
            {
                "x": 224,
                "y": 313.5
            },
            {
                "x": 230,
                "y": 307.5
            },
            {
                "x": 234,
                "y": 302.5
            },
            {
                "x": 236,
                "y": 299.5
            },
            {
                "x": 235,
                "y": 295.5
            },
            {
                "x": 231,
                "y": 293.5
            },
            {
                "x": 222,
                "y": 291.5
            },
            {
                "x": 209,
                "y": 287.5
            },
            {
                "x": 188,
                "y": 283.5
            },
            {
                "x": 158,
                "y": 277.5
            },
            {
                "x": 129,
                "y": 270.5
            },
            {
                "x": 104,
                "y": 262.5
            },
            {
                "x": 90,
                "y": 258.5
            },
            {
                "x": 83,
                "y": 255.5
            },
            {
                "x": 80,
                "y": 254.5
            },
            {
                "x": 84,
                "y": 254.5
            },
            {
                "x": 94,
                "y": 257.5
            },
            {
                "x": 110,
                "y": 262.5
            },
            {
                "x": 134,
                "y": 269.5
            },
            {
                "x": 162,
                "y": 279.5
            },
            {
                "x": 188,
                "y": 290.5
            },
            {
                "x": 209,
                "y": 301.5
            },
            {
                "x": 221,
                "y": 308.5
            },
            {
                "x": 230,
                "y": 313.5
            },
            {
                "x": 235,
                "y": 318.5
            },
            {
                "x": 237,
                "y": 321.5
            },
            {
                "x": 238,
                "y": 323.5
            },
            {
                "x": 238,
                "y": 325.5
            },
            {
                "x": 235,
                "y": 326.5
            },
            {
                "x": 228,
                "y": 328.5
            },
            {
                "x": 220,
                "y": 330.5
            },
            {
                "x": 210,
                "y": 331.5
            },
            {
                "x": 203,
                "y": 332.5
            },
            {
                "x": 199,
                "y": 332.5
            },
            {
                "x": 197,
                "y": 332.5
            },
            {
                "x": 196,
                "y": 332.5
            },
            {
                "x": 197,
                "y": 331.5
            },
            {
                "x": 200,
                "y": 328.5
            },
            {
                "x": 205,
                "y": 324.5
            },
            {
                "x": 212,
                "y": 320.5
            },
            {
                "x": 221,
                "y": 317.5
            },
            {
                "x": 230,
                "y": 315.5
            },
            {
                "x": 240,
                "y": 313.5
            },
            {
                "x": 246,
                "y": 313.5
            },
            {
                "x": 252,
                "y": 313.5
            },
            {
                "x": 255,
                "y": 313.5
            },
            {
                "x": 256,
                "y": 314.5
            },
            {
                "x": 256,
                "y": 316.5
            },
            {
                "x": 256,
                "y": 319.5
            }
        ]
    }
],
    [
        {
            "drawMode": true,
            "strokeColor": "#c68642",
            "strokeWidth": 20,
            "paths": [
                {
                    "x": 148,
                    "y": 182.5
                },
                {
                    "x": 111,
                    "y": 177.5
                },
                {
                    "x": 72,
                    "y": 173.5
                },
                {
                    "x": 37,
                    "y": 173.5
                },
                {
                    "x": 11,
                    "y": 175.5
                },
                {
                    "x": 9,
                    "y": 190.5
                },
                {
                    "x": 20,
                    "y": 184.5
                },
                {
                    "x": 34,
                    "y": 174.5
                },
                {
                    "x": 45,
                    "y": 164.5
                },
                {
                    "x": 58,
                    "y": 153.5
                },
                {
                    "x": 73,
                    "y": 140.5
                },
                {
                    "x": 85,
                    "y": 130.5
                },
                {
                    "x": 93,
                    "y": 124.5
                },
                {
                    "x": 97,
                    "y": 120.5
                },
                {
                    "x": 98,
                    "y": 119.5
                },
                {
                    "x": 99,
                    "y": 117.5
                },
                {
                    "x": 99,
                    "y": 116.5
                },
                {
                    "x": 98,
                    "y": 116.5
                },
                {
                    "x": 93,
                    "y": 116.5
                },
                {
                    "x": 83,
                    "y": 116.5
                },
                {
                    "x": 73,
                    "y": 117.5
                },
                {
                    "x": 60,
                    "y": 121.5
                },
                {
                    "x": 48,
                    "y": 124.5
                },
                {
                    "x": 38,
                    "y": 129.5
                },
                {
                    "x": 32,
                    "y": 135.5
                },
                {
                    "x": 29,
                    "y": 139.5
                },
                {
                    "x": 29,
                    "y": 145.5
                },
                {
                    "x": 33,
                    "y": 150.5
                },
                {
                    "x": 43,
                    "y": 155.5
                },
                {
                    "x": 59,
                    "y": 159.5
                },
                {
                    "x": 84,
                    "y": 162.5
                },
                {
                    "x": 115,
                    "y": 161.5
                },
                {
                    "x": 144,
                    "y": 154.5
                },
                {
                    "x": 170,
                    "y": 143.5
                },
                {
                    "x": 189,
                    "y": 130.5
                },
                {
                    "x": 200,
                    "y": 113.5
                },
                {
                    "x": 207,
                    "y": 94.5
                },
                {
                    "x": 208,
                    "y": 74.5
                },
                {
                    "x": 204,
                    "y": 55.5
                },
                {
                    "x": 191,
                    "y": 36.5
                },
                {
                    "x": 173,
                    "y": 19.5
                },
                {
                    "x": 147,
                    "y": 8.5
                },
                {
                    "x": 121,
                    "y": 3.5
                },
                {
                    "x": 96,
                    "y": 4.5
                },
                {
                    "x": 75,
                    "y": 13.5
                },
                {
                    "x": 61,
                    "y": 30.5
                },
                {
                    "x": 51,
                    "y": 58.5
                },
                {
                    "x": 52,
                    "y": 96.5
                },
                {
                    "x": 65,
                    "y": 142.5
                },
                {
                    "x": 92,
                    "y": 193.5
                },
                {
                    "x": 125,
                    "y": 236.5
                },
                {
                    "x": 157,
                    "y": 268.5
                },
                {
                    "x": 187,
                    "y": 287.5
                },
                {
                    "x": 210,
                    "y": 298.5
                },
                {
                    "x": 224,
                    "y": 303.5
                },
                {
                    "x": 232,
                    "y": 305.5
                },
                {
                    "x": 235,
                    "y": 305.5
                },
                {
                    "x": 237,
                    "y": 305.5
                },
                {
                    "x": 236,
                    "y": 302.5
                },
                {
                    "x": 228,
                    "y": 298.5
                },
                {
                    "x": 208,
                    "y": 292.5
                },
                {
                    "x": 174,
                    "y": 286.5
                },
                {
                    "x": 132,
                    "y": 282.5
                },
                {
                    "x": 94,
                    "y": 282.5
                },
                {
                    "x": 65,
                    "y": 288.5
                },
                {
                    "x": 41,
                    "y": 300.5
                },
                {
                    "x": 26,
                    "y": 314.5
                },
                {
                    "x": 17,
                    "y": 327.5
                },
                {
                    "x": 13,
                    "y": 338.5
                },
                {
                    "x": 11,
                    "y": 348.5
                },
                {
                    "x": 11,
                    "y": 353.5
                },
                {
                    "x": 12,
                    "y": 357.5
                },
                {
                    "x": 18,
                    "y": 359.5
                },
                {
                    "x": 29,
                    "y": 359.5
                },
                {
                    "x": 46,
                    "y": 355.5
                },
                {
                    "x": 74,
                    "y": 345.5
                },
                {
                    "x": 105,
                    "y": 333.5
                },
                {
                    "x": 139,
                    "y": 315.5
                },
                {
                    "x": 174,
                    "y": 293.5
                },
                {
                    "x": 209,
                    "y": 269.5
                },
                {
                    "x": 238,
                    "y": 244.5
                },
                {
                    "x": 259,
                    "y": 221.5
                },
                {
                    "x": 273,
                    "y": 199.5
                },
                {
                    "x": 280,
                    "y": 182.5
                },
                {
                    "x": 281,
                    "y": 167.5
                },
                {
                    "x": 280,
                    "y": 152.5
                },
                {
                    "x": 271,
                    "y": 135.5
                },
                {
                    "x": 259,
                    "y": 120.5
                },
                {
                    "x": 244,
                    "y": 106.5
                },
                {
                    "x": 231,
                    "y": 99.5
                },
                {
                    "x": 223,
                    "y": 96.5
                },
                {
                    "x": 218,
                    "y": 97.5
                },
                {
                    "x": 215,
                    "y": 105.5
                },
                {
                    "x": 218,
                    "y": 123.5
                },
                {
                    "x": 233,
                    "y": 164.5
                },
                {
                    "x": 260,
                    "y": 222.5
                },
                {
                    "x": 290,
                    "y": 293.5
                },
                {
                    "x": 317,
                    "y": 375.5
                },
                {
                    "x": 336,
                    "y": 455.5
                },
                {
                    "x": 396,
                    "y": 467.5
                },
                {
                    "x": 421,
                    "y": 430.5
                },
                {
                    "x": 446,
                    "y": 387.5
                },
                {
                    "x": 472,
                    "y": 342.5
                },
                {
                    "x": 493,
                    "y": 297.5
                },
                {
                    "x": 493,
                    "y": 210.5
                },
                {
                    "x": 470,
                    "y": 248.5
                },
                {
                    "x": 451,
                    "y": 292.5
                },
                {
                    "x": 436,
                    "y": 338.5
                },
                {
                    "x": 426,
                    "y": 381.5
                },
                {
                    "x": 423,
                    "y": 415.5
                },
                {
                    "x": 423,
                    "y": 437.5
                },
                {
                    "x": 424,
                    "y": 450.5
                },
                {
                    "x": 428,
                    "y": 457.5
                },
                {
                    "x": 432,
                    "y": 461.5
                },
                {
                    "x": 435,
                    "y": 461.5
                },
                {
                    "x": 439,
                    "y": 455.5
                },
                {
                    "x": 442,
                    "y": 445.5
                },
                {
                    "x": 442,
                    "y": 432.5
                },
                {
                    "x": 433,
                    "y": 414.5
                },
                {
                    "x": 411,
                    "y": 388.5
                },
                {
                    "x": 372,
                    "y": 359.5
                },
                {
                    "x": 321,
                    "y": 329.5
                },
                {
                    "x": 255,
                    "y": 297.5
                },
                {
                    "x": 192,
                    "y": 271.5
                },
                {
                    "x": 141,
                    "y": 256.5
                },
                {
                    "x": 111,
                    "y": 247.5
                },
                {
                    "x": 101,
                    "y": 244.5
                },
                {
                    "x": 108,
                    "y": 243.5
                },
                {
                    "x": 139,
                    "y": 240.5
                },
                {
                    "x": 202,
                    "y": 236.5
                },
                {
                    "x": 294,
                    "y": 231.5
                },
                {
                    "x": 411,
                    "y": 220.5
                },
                {
                    "x": 493,
                    "y": 285.5
                },
                {
                    "x": 410,
                    "y": 305.5
                },
                {
                    "x": 343,
                    "y": 319.5
                },
                {
                    "x": 294,
                    "y": 331.5
                },
                {
                    "x": 268,
                    "y": 335.5
                },
                {
                    "x": 259,
                    "y": 337.5
                },
                {
                    "x": 256,
                    "y": 337.5
                },
                {
                    "x": 257,
                    "y": 334.5
                },
                {
                    "x": 267,
                    "y": 329.5
                },
                {
                    "x": 287,
                    "y": 324.5
                },
                {
                    "x": 318,
                    "y": 319.5
                },
                {
                    "x": 349,
                    "y": 315.5
                },
                {
                    "x": 368,
                    "y": 315.5
                },
                {
                    "x": 380,
                    "y": 317.5
                },
                {
                    "x": 386,
                    "y": 321.5
                },
                {
                    "x": 388,
                    "y": 328.5
                },
                {
                    "x": 385,
                    "y": 337.5
                },
                {
                    "x": 372,
                    "y": 351.5
                },
                {
                    "x": 341,
                    "y": 369.5
                },
                {
                    "x": 288,
                    "y": 391.5
                },
                {
                    "x": 220,
                    "y": 412.5
                },
                {
                    "x": 148,
                    "y": 431.5
                },
                {
                    "x": 80,
                    "y": 450.5
                },
                {
                    "x": 28,
                    "y": 468.5
                },
                {
                    "x": 355,
                    "y": 485.5
                },
                {
                    "x": 410,
                    "y": 462.5
                },
                {
                    "x": 450,
                    "y": 441.5
                },
                {
                    "x": 467,
                    "y": 429.5
                },
                {
                    "x": 471,
                    "y": 422.5
                },
                {
                    "x": 468,
                    "y": 417.5
                },
                {
                    "x": 451,
                    "y": 413.5
                },
                {
                    "x": 412,
                    "y": 411.5
                },
                {
                    "x": 355,
                    "y": 412.5
                },
                {
                    "x": 292,
                    "y": 420.5
                },
                {
                    "x": 232,
                    "y": 435.5
                },
                {
                    "x": 181,
                    "y": 453.5
                },
                {
                    "x": 137,
                    "y": 470.5
                },
                {
                    "x": 111,
                    "y": 481.5
                },
                {
                    "x": 101,
                    "y": 485.5
                },
                {
                    "x": 98,
                    "y": 487.5
                },
                {
                    "x": 98,
                    "y": 488.5
                },
                {
                    "x": 102,
                    "y": 487.5
                },
                {
                    "x": 113,
                    "y": 483.5
                },
                {
                    "x": 131,
                    "y": 475.5
                },
                {
                    "x": 157,
                    "y": 464.5
                },
                {
                    "x": 185,
                    "y": 452.5
                },
                {
                    "x": 213,
                    "y": 441.5
                },
                {
                    "x": 236,
                    "y": 431.5
                },
                {
                    "x": 251,
                    "y": 425.5
                },
                {
                    "x": 257,
                    "y": 422.5
                },
                {
                    "x": 258,
                    "y": 421.5
                },
                {
                    "x": 255,
                    "y": 421.5
                },
                {
                    "x": 240,
                    "y": 421.5
                },
                {
                    "x": 208,
                    "y": 421.5
                },
                {
                    "x": 154,
                    "y": 421.5
                },
                {
                    "x": 89,
                    "y": 421.5
                },
                {
                    "x": 25,
                    "y": 421.5
                },
                {
                    "x": 33,
                    "y": 393.5
                },
                {
                    "x": 121,
                    "y": 377.5
                },
                {
                    "x": 215,
                    "y": 359.5
                },
                {
                    "x": 303,
                    "y": 342.5
                },
                {
                    "x": 380,
                    "y": 329.5
                },
                {
                    "x": 439,
                    "y": 319.5
                },
                {
                    "x": 465,
                    "y": 314.5
                },
                {
                    "x": 472,
                    "y": 312.5
                },
                {
                    "x": 470,
                    "y": 312.5
                },
                {
                    "x": 454,
                    "y": 312.5
                },
                {
                    "x": 406,
                    "y": 312.5
                },
                {
                    "x": 330,
                    "y": 312.5
                },
                {
                    "x": 244,
                    "y": 312.5
                },
                {
                    "x": 155,
                    "y": 312.5
                },
                {
                    "x": 74,
                    "y": 312.5
                },
                {
                    "x": 14,
                    "y": 313.5
                },
                {
                    "x": 59,
                    "y": 304.5
                },
                {
                    "x": 144,
                    "y": 293.5
                },
                {
                    "x": 247,
                    "y": 284.5
                },
                {
                    "x": 353,
                    "y": 272.5
                },
                {
                    "x": 452,
                    "y": 255.5
                },
                {
                    "x": 423,
                    "y": 137.5
                },
                {
                    "x": 328,
                    "y": 133.5
                },
                {
                    "x": 244,
                    "y": 131.5
                },
                {
                    "x": 182,
                    "y": 131.5
                },
                {
                    "x": 149,
                    "y": 131.5
                },
                {
                    "x": 137,
                    "y": 131.5
                },
                {
                    "x": 136,
                    "y": 131.5
                },
                {
                    "x": 139,
                    "y": 131.5
                },
                {
                    "x": 160,
                    "y": 127.5
                },
                {
                    "x": 211,
                    "y": 119.5
                },
                {
                    "x": 283,
                    "y": 106.5
                },
                {
                    "x": 368,
                    "y": 93.5
                },
                {
                    "x": 461,
                    "y": 78.5
                },
                {
                    "x": 390,
                    "y": 90.5
                },
                {
                    "x": 270,
                    "y": 104.5
                },
                {
                    "x": 161,
                    "y": 118.5
                },
                {
                    "x": 78,
                    "y": 133.5
                },
                {
                    "x": 20,
                    "y": 143.5
                },
                {
                    "x": 41,
                    "y": 142.5
                },
                {
                    "x": 110,
                    "y": 134.5
                },
                {
                    "x": 187,
                    "y": 123.5
                },
                {
                    "x": 266,
                    "y": 112.5
                },
                {
                    "x": 338,
                    "y": 104.5
                },
                {
                    "x": 399,
                    "y": 96.5
                },
                {
                    "x": 444,
                    "y": 88.5
                },
                {
                    "x": 467,
                    "y": 84.5
                },
                {
                    "x": 475,
                    "y": 83.5
                },
                {
                    "x": 475,
                    "y": 82.5
                },
                {
                    "x": 470,
                    "y": 82.5
                },
                {
                    "x": 448,
                    "y": 82.5
                },
                {
                    "x": 405,
                    "y": 82.5
                },
                {
                    "x": 340,
                    "y": 82.5
                },
                {
                    "x": 269,
                    "y": 82.5
                },
                {
                    "x": 202,
                    "y": 82.5
                },
                {
                    "x": 153,
                    "y": 82.5
                },
                {
                    "x": 118,
                    "y": 82.5
                },
                {
                    "x": 106,
                    "y": 82.5
                },
                {
                    "x": 104,
                    "y": 82.5
                },
                {
                    "x": 108,
                    "y": 82.5
                },
                {
                    "x": 123,
                    "y": 80.5
                },
                {
                    "x": 160,
                    "y": 75.5
                },
                {
                    "x": 215,
                    "y": 69.5
                },
                {
                    "x": 278,
                    "y": 63.5
                },
                {
                    "x": 341,
                    "y": 56.5
                },
                {
                    "x": 402,
                    "y": 50.5
                },
                {
                    "x": 451,
                    "y": 45.5
                },
                {
                    "x": 491,
                    "y": 39.5
                },
                {
                    "x": 485,
                    "y": 37.5
                },
                {
                    "x": 460,
                    "y": 37.5
                },
                {
                    "x": 433,
                    "y": 37.5
                },
                {
                    "x": 408,
                    "y": 37.5
                },
                {
                    "x": 394,
                    "y": 38.5
                },
                {
                    "x": 384,
                    "y": 39.5
                },
                {
                    "x": 381,
                    "y": 40.5
                },
                {
                    "x": 380,
                    "y": 41.5
                },
                {
                    "x": 380,
                    "y": 42.5
                },
                {
                    "x": 385,
                    "y": 44.5
                },
                {
                    "x": 396,
                    "y": 47.5
                },
                {
                    "x": 410,
                    "y": 52.5
                },
                {
                    "x": 426,
                    "y": 61.5
                },
                {
                    "x": 442,
                    "y": 72.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#9c27b0",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 274,
                    "y": 197.5
                },
                {
                    "x": 273,
                    "y": 197.5
                },
                {
                    "x": 272,
                    "y": 200.5
                },
                {
                    "x": 271,
                    "y": 206.5
                },
                {
                    "x": 271,
                    "y": 215.5
                },
                {
                    "x": 271,
                    "y": 227.5
                },
                {
                    "x": 271,
                    "y": 243.5
                },
                {
                    "x": 271,
                    "y": 268.5
                },
                {
                    "x": 273,
                    "y": 299.5
                },
                {
                    "x": 278,
                    "y": 327.5
                },
                {
                    "x": 282,
                    "y": 351.5
                },
                {
                    "x": 286,
                    "y": 367.5
                },
                {
                    "x": 288,
                    "y": 377.5
                },
                {
                    "x": 289,
                    "y": 382.5
                },
                {
                    "x": 290,
                    "y": 384.5
                },
                {
                    "x": 290,
                    "y": 385.5
                },
                {
                    "x": 291,
                    "y": 385.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#9c27b0",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 416,
                    "y": 143.5
                },
                {
                    "x": 414,
                    "y": 148.5
                },
                {
                    "x": 412,
                    "y": 157.5
                },
                {
                    "x": 410,
                    "y": 169.5
                },
                {
                    "x": 407,
                    "y": 186.5
                },
                {
                    "x": 403,
                    "y": 209.5
                },
                {
                    "x": 395,
                    "y": 234.5
                },
                {
                    "x": 384,
                    "y": 260.5
                },
                {
                    "x": 367,
                    "y": 280.5
                },
                {
                    "x": 347,
                    "y": 295.5
                },
                {
                    "x": 322,
                    "y": 303.5
                },
                {
                    "x": 291,
                    "y": 308.5
                },
                {
                    "x": 258,
                    "y": 309.5
                },
                {
                    "x": 218,
                    "y": 305.5
                },
                {
                    "x": 173,
                    "y": 295.5
                },
                {
                    "x": 124,
                    "y": 282.5
                },
                {
                    "x": 75,
                    "y": 264.5
                },
                {
                    "x": 28,
                    "y": 242.5
                },
                {
                    "x": 0,
                    "y": 123.5
                },
                {
                    "x": 42,
                    "y": 113.5
                },
                {
                    "x": 90,
                    "y": 108.5
                },
                {
                    "x": 133,
                    "y": 106.5
                },
                {
                    "x": 172,
                    "y": 107.5
                },
                {
                    "x": 209,
                    "y": 114.5
                },
                {
                    "x": 239,
                    "y": 124.5
                },
                {
                    "x": 266,
                    "y": 139.5
                },
                {
                    "x": 289,
                    "y": 158.5
                },
                {
                    "x": 310,
                    "y": 181.5
                },
                {
                    "x": 331,
                    "y": 209.5
                },
                {
                    "x": 346,
                    "y": 236.5
                },
                {
                    "x": 356,
                    "y": 256.5
                },
                {
                    "x": 362,
                    "y": 266.5
                },
                {
                    "x": 364,
                    "y": 270.5
                },
                {
                    "x": 366,
                    "y": 269.5
                },
                {
                    "x": 367,
                    "y": 261.5
                },
                {
                    "x": 367,
                    "y": 245.5
                },
                {
                    "x": 365,
                    "y": 219.5
                },
                {
                    "x": 358,
                    "y": 184.5
                },
                {
                    "x": 349,
                    "y": 144.5
                },
                {
                    "x": 335,
                    "y": 98.5
                },
                {
                    "x": 318,
                    "y": 52.5
                },
                {
                    "x": 299,
                    "y": 10.5
                },
                {
                    "x": 117,
                    "y": 0.5
                },
                {
                    "x": 131,
                    "y": 15.5
                },
                {
                    "x": 150,
                    "y": 28.5
                },
                {
                    "x": 173,
                    "y": 38.5
                },
                {
                    "x": 200,
                    "y": 44.5
                },
                {
                    "x": 234,
                    "y": 49.5
                },
                {
                    "x": 273,
                    "y": 53.5
                },
                {
                    "x": 316,
                    "y": 54.5
                },
                {
                    "x": 354,
                    "y": 54.5
                },
                {
                    "x": 382,
                    "y": 54.5
                },
                {
                    "x": 395,
                    "y": 54.5
                },
                {
                    "x": 402,
                    "y": 54.5
                },
                {
                    "x": 403,
                    "y": 54.5
                },
                {
                    "x": 393,
                    "y": 56.5
                },
                {
                    "x": 366,
                    "y": 59.5
                },
                {
                    "x": 315,
                    "y": 61.5
                },
                {
                    "x": 241,
                    "y": 63.5
                },
                {
                    "x": 155,
                    "y": 63.5
                },
                {
                    "x": 63,
                    "y": 63.5
                },
                {
                    "x": 53,
                    "y": 102.5
                },
                {
                    "x": 180,
                    "y": 107.5
                },
                {
                    "x": 323,
                    "y": 109.5
                },
                {
                    "x": 463,
                    "y": 114.5
                },
                {
                    "x": 497,
                    "y": 80.5
                },
                {
                    "x": 368,
                    "y": 80.5
                },
                {
                    "x": 244,
                    "y": 84.5
                },
                {
                    "x": 140,
                    "y": 91.5
                },
                {
                    "x": 66,
                    "y": 99.5
                },
                {
                    "x": 27,
                    "y": 106.5
                },
                {
                    "x": 16,
                    "y": 110.5
                },
                {
                    "x": 16,
                    "y": 112.5
                },
                {
                    "x": 24,
                    "y": 113.5
                },
                {
                    "x": 55,
                    "y": 114.5
                },
                {
                    "x": 111,
                    "y": 114.5
                },
                {
                    "x": 174,
                    "y": 114.5
                },
                {
                    "x": 228,
                    "y": 114.5
                },
                {
                    "x": 266,
                    "y": 114.5
                },
                {
                    "x": 282,
                    "y": 114.5
                },
                {
                    "x": 288,
                    "y": 114.5
                },
                {
                    "x": 288,
                    "y": 115.5
                },
                {
                    "x": 283,
                    "y": 117.5
                },
                {
                    "x": 267,
                    "y": 123.5
                },
                {
                    "x": 236,
                    "y": 135.5
                },
                {
                    "x": 207,
                    "y": 153.5
                },
                {
                    "x": 180,
                    "y": 171.5
                },
                {
                    "x": 161,
                    "y": 192.5
                },
                {
                    "x": 150,
                    "y": 213.5
                },
                {
                    "x": 146,
                    "y": 231.5
                },
                {
                    "x": 149,
                    "y": 247.5
                },
                {
                    "x": 160,
                    "y": 264.5
                },
                {
                    "x": 175,
                    "y": 279.5
                },
                {
                    "x": 195,
                    "y": 292.5
                },
                {
                    "x": 211,
                    "y": 303.5
                },
                {
                    "x": 221,
                    "y": 311.5
                },
                {
                    "x": 226,
                    "y": 318.5
                },
                {
                    "x": 227,
                    "y": 324.5
                },
                {
                    "x": 223,
                    "y": 331.5
                },
                {
                    "x": 210,
                    "y": 338.5
                },
                {
                    "x": 183,
                    "y": 346.5
                },
                {
                    "x": 146,
                    "y": 352.5
                },
                {
                    "x": 113,
                    "y": 353.5
                },
                {
                    "x": 91,
                    "y": 353.5
                },
                {
                    "x": 79,
                    "y": 352.5
                },
                {
                    "x": 74,
                    "y": 348.5
                },
                {
                    "x": 74,
                    "y": 340.5
                },
                {
                    "x": 85,
                    "y": 325.5
                },
                {
                    "x": 116,
                    "y": 295.5
                },
                {
                    "x": 165,
                    "y": 256.5
                },
                {
                    "x": 225,
                    "y": 216.5
                },
                {
                    "x": 297,
                    "y": 172.5
                },
                {
                    "x": 372,
                    "y": 130.5
                },
                {
                    "x": 439,
                    "y": 91.5
                },
                {
                    "x": 495,
                    "y": 62.5
                },
                {
                    "x": 484,
                    "y": 69.5
                },
                {
                    "x": 446,
                    "y": 97.5
                },
                {
                    "x": 412,
                    "y": 130.5
                },
                {
                    "x": 384,
                    "y": 163.5
                },
                {
                    "x": 364,
                    "y": 198.5
                },
                {
                    "x": 352,
                    "y": 228.5
                },
                {
                    "x": 347,
                    "y": 255.5
                },
                {
                    "x": 347,
                    "y": 277.5
                },
                {
                    "x": 350,
                    "y": 293.5
                },
                {
                    "x": 362,
                    "y": 314.5
                },
                {
                    "x": 374,
                    "y": 331.5
                },
                {
                    "x": 390,
                    "y": 344.5
                },
                {
                    "x": 401,
                    "y": 354.5
                },
                {
                    "x": 409,
                    "y": 359.5
                },
                {
                    "x": 412,
                    "y": 362.5
                },
                {
                    "x": 413,
                    "y": 362.5
                },
                {
                    "x": 413,
                    "y": 361.5
                },
                {
                    "x": 411,
                    "y": 357.5
                },
                {
                    "x": 403,
                    "y": 348.5
                },
                {
                    "x": 386,
                    "y": 333.5
                },
                {
                    "x": 358,
                    "y": 310.5
                },
                {
                    "x": 320,
                    "y": 284.5
                },
                {
                    "x": 274,
                    "y": 254.5
                },
                {
                    "x": 226,
                    "y": 228.5
                },
                {
                    "x": 181,
                    "y": 204.5
                },
                {
                    "x": 144,
                    "y": 188.5
                },
                {
                    "x": 125,
                    "y": 181.5
                },
                {
                    "x": 115,
                    "y": 178.5
                },
                {
                    "x": 112,
                    "y": 178.5
                },
                {
                    "x": 113,
                    "y": 180.5
                },
                {
                    "x": 123,
                    "y": 188.5
                },
                {
                    "x": 143,
                    "y": 200.5
                },
                {
                    "x": 183,
                    "y": 219.5
                },
                {
                    "x": 235,
                    "y": 239.5
                },
                {
                    "x": 291,
                    "y": 253.5
                },
                {
                    "x": 347,
                    "y": 261.5
                },
                {
                    "x": 397,
                    "y": 267.5
                },
                {
                    "x": 438,
                    "y": 269.5
                },
                {
                    "x": 465,
                    "y": 269.5
                },
                {
                    "x": 479,
                    "y": 267.5
                },
                {
                    "x": 484,
                    "y": 265.5
                },
                {
                    "x": 486,
                    "y": 264.5
                },
                {
                    "x": 485,
                    "y": 263.5
                },
                {
                    "x": 479,
                    "y": 263.5
                },
                {
                    "x": 463,
                    "y": 263.5
                },
                {
                    "x": 430,
                    "y": 263.5
                },
                {
                    "x": 388,
                    "y": 271.5
                },
                {
                    "x": 342,
                    "y": 282.5
                },
                {
                    "x": 300,
                    "y": 299.5
                },
                {
                    "x": 263,
                    "y": 319.5
                },
                {
                    "x": 234,
                    "y": 337.5
                },
                {
                    "x": 215,
                    "y": 348.5
                },
                {
                    "x": 206,
                    "y": 354.5
                },
                {
                    "x": 203,
                    "y": 356.5
                },
                {
                    "x": 205,
                    "y": 353.5
                },
                {
                    "x": 213,
                    "y": 344.5
                },
                {
                    "x": 231,
                    "y": 330.5
                },
                {
                    "x": 263,
                    "y": 311.5
                },
                {
                    "x": 300,
                    "y": 290.5
                },
                {
                    "x": 343,
                    "y": 267.5
                },
                {
                    "x": 388,
                    "y": 246.5
                },
                {
                    "x": 433,
                    "y": 222.5
                },
                {
                    "x": 470,
                    "y": 202.5
                },
                {
                    "x": 494,
                    "y": 185.5
                },
                {
                    "x": 490,
                    "y": 175.5
                },
                {
                    "x": 466,
                    "y": 187.5
                },
                {
                    "x": 439,
                    "y": 204.5
                },
                {
                    "x": 411,
                    "y": 227.5
                },
                {
                    "x": 390,
                    "y": 250.5
                },
                {
                    "x": 371,
                    "y": 275.5
                },
                {
                    "x": 359,
                    "y": 296.5
                },
                {
                    "x": 354,
                    "y": 313.5
                },
                {
                    "x": 352,
                    "y": 325.5
                },
                {
                    "x": 352,
                    "y": 336.5
                },
                {
                    "x": 358,
                    "y": 346.5
                },
                {
                    "x": 366,
                    "y": 354.5
                },
                {
                    "x": 376,
                    "y": 362.5
                },
                {
                    "x": 387,
                    "y": 368.5
                },
                {
                    "x": 398,
                    "y": 372.5
                },
                {
                    "x": 408,
                    "y": 374.5
                },
                {
                    "x": 414,
                    "y": 376.5
                },
                {
                    "x": 415,
                    "y": 377.5
                },
                {
                    "x": 412,
                    "y": 377.5
                },
                {
                    "x": 403,
                    "y": 377.5
                },
                {
                    "x": 388,
                    "y": 376.5
                },
                {
                    "x": 368,
                    "y": 374.5
                },
                {
                    "x": 343,
                    "y": 374.5
                },
                {
                    "x": 316,
                    "y": 374.5
                },
                {
                    "x": 292,
                    "y": 374.5
                },
                {
                    "x": 270,
                    "y": 374.5
                },
                {
                    "x": 257,
                    "y": 374.5
                },
                {
                    "x": 247,
                    "y": 374.5
                },
                {
                    "x": 241,
                    "y": 375.5
                },
                {
                    "x": 239,
                    "y": 375.5
                },
                {
                    "x": 238,
                    "y": 376.5
                },
                {
                    "x": 237,
                    "y": 376.5
                },
                {
                    "x": 237,
                    "y": 377.5
                },
                {
                    "x": 237,
                    "y": 378.5
                },
                {
                    "x": 237,
                    "y": 380.5
                },
                {
                    "x": 239,
                    "y": 380.5
                },
                {
                    "x": 242,
                    "y": 381.5
                },
                {
                    "x": 245,
                    "y": 382.5
                },
                {
                    "x": 248,
                    "y": 383.5
                },
                {
                    "x": 250,
                    "y": 384.5
                },
                {
                    "x": 251,
                    "y": 385.5
                },
                {
                    "x": 251,
                    "y": 388.5
                },
                {
                    "x": 251,
                    "y": 391.5
                },
                {
                    "x": 250,
                    "y": 396.5
                },
                {
                    "x": 246,
                    "y": 402.5
                },
                {
                    "x": 239,
                    "y": 408.5
                },
                {
                    "x": 232,
                    "y": 415.5
                },
                {
                    "x": 222,
                    "y": 420.5
                },
                {
                    "x": 212,
                    "y": 424.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#00bcd4",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 292,
                    "y": 254.5
                },
                {
                    "x": 283,
                    "y": 253.5
                },
                {
                    "x": 263,
                    "y": 253.5
                },
                {
                    "x": 223,
                    "y": 253.5
                },
                {
                    "x": 165,
                    "y": 254.5
                },
                {
                    "x": 95,
                    "y": 258.5
                },
                {
                    "x": 18,
                    "y": 264.5
                },
                {
                    "x": 48,
                    "y": 338.5
                },
                {
                    "x": 124,
                    "y": 342.5
                },
                {
                    "x": 192,
                    "y": 348.5
                },
                {
                    "x": 245,
                    "y": 354.5
                },
                {
                    "x": 274,
                    "y": 359.5
                },
                {
                    "x": 285,
                    "y": 362.5
                },
                {
                    "x": 285,
                    "y": 365.5
                },
                {
                    "x": 276,
                    "y": 368.5
                },
                {
                    "x": 250,
                    "y": 375.5
                },
                {
                    "x": 205,
                    "y": 387.5
                },
                {
                    "x": 151,
                    "y": 401.5
                },
                {
                    "x": 99,
                    "y": 417.5
                },
                {
                    "x": 61,
                    "y": 429.5
                },
                {
                    "x": 38,
                    "y": 439.5
                },
                {
                    "x": 27,
                    "y": 442.5
                },
                {
                    "x": 24,
                    "y": 443.5
                },
                {
                    "x": 25,
                    "y": 444.5
                },
                {
                    "x": 37,
                    "y": 445.5
                },
                {
                    "x": 63,
                    "y": 445.5
                },
                {
                    "x": 101,
                    "y": 445.5
                },
                {
                    "x": 139,
                    "y": 445.5
                },
                {
                    "x": 166,
                    "y": 445.5
                },
                {
                    "x": 179,
                    "y": 445.5
                },
                {
                    "x": 185,
                    "y": 445.5
                },
                {
                    "x": 181,
                    "y": 445.5
                },
                {
                    "x": 169,
                    "y": 445.5
                },
                {
                    "x": 150,
                    "y": 444.5
                },
                {
                    "x": 128,
                    "y": 444.5
                },
                {
                    "x": 114,
                    "y": 443.5
                },
                {
                    "x": 104,
                    "y": 443.5
                },
                {
                    "x": 100,
                    "y": 442.5
                },
                {
                    "x": 100,
                    "y": 441.5
                },
                {
                    "x": 104,
                    "y": 438.5
                },
                {
                    "x": 118,
                    "y": 433.5
                },
                {
                    "x": 150,
                    "y": 428.5
                },
                {
                    "x": 189,
                    "y": 425.5
                },
                {
                    "x": 224,
                    "y": 423.5
                },
                {
                    "x": 250,
                    "y": 422.5
                },
                {
                    "x": 261,
                    "y": 422.5
                },
                {
                    "x": 266,
                    "y": 422.5
                },
                {
                    "x": 263,
                    "y": 422.5
                },
                {
                    "x": 251,
                    "y": 422.5
                },
                {
                    "x": 231,
                    "y": 422.5
                },
                {
                    "x": 203,
                    "y": 422.5
                },
                {
                    "x": 179,
                    "y": 422.5
                },
                {
                    "x": 162,
                    "y": 422.5
                },
                {
                    "x": 151,
                    "y": 423.5
                },
                {
                    "x": 147,
                    "y": 424.5
                },
                {
                    "x": 145,
                    "y": 425.5
                },
                {
                    "x": 146,
                    "y": 425.5
                },
                {
                    "x": 151,
                    "y": 427.5
                },
                {
                    "x": 160,
                    "y": 430.5
                },
                {
                    "x": 173,
                    "y": 435.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#00bcd4",
            "strokeWidth": 4,
            "paths": [
                {
                    "x": 365,
                    "y": 174.5
                },
                {
                    "x": 354,
                    "y": 172.5
                },
                {
                    "x": 336,
                    "y": 172.5
                },
                {
                    "x": 312,
                    "y": 176.5
                },
                {
                    "x": 282,
                    "y": 186.5
                },
                {
                    "x": 248,
                    "y": 202.5
                },
                {
                    "x": 213,
                    "y": 224.5
                },
                {
                    "x": 182,
                    "y": 252.5
                },
                {
                    "x": 156,
                    "y": 282.5
                },
                {
                    "x": 139,
                    "y": 312.5
                },
                {
                    "x": 131,
                    "y": 336.5
                },
                {
                    "x": 131,
                    "y": 351.5
                },
                {
                    "x": 133,
                    "y": 360.5
                },
                {
                    "x": 139,
                    "y": 367.5
                },
                {
                    "x": 147,
                    "y": 371.5
                },
                {
                    "x": 159,
                    "y": 373.5
                },
                {
                    "x": 173,
                    "y": 374.5
                },
                {
                    "x": 185,
                    "y": 374.5
                },
                {
                    "x": 194,
                    "y": 374.5
                },
                {
                    "x": 198,
                    "y": 373.5
                },
                {
                    "x": 202,
                    "y": 373.5
                },
                {
                    "x": 203,
                    "y": 372.5
                },
                {
                    "x": 203,
                    "y": 371.5
                },
                {
                    "x": 202,
                    "y": 370.5
                },
                {
                    "x": 198,
                    "y": 368.5
                },
                {
                    "x": 193,
                    "y": 365.5
                },
                {
                    "x": 188,
                    "y": 360.5
                },
                {
                    "x": 184,
                    "y": 352.5
                },
                {
                    "x": 180,
                    "y": 341.5
                },
                {
                    "x": 180,
                    "y": 325.5
                },
                {
                    "x": 187,
                    "y": 298.5
                },
                {
                    "x": 202,
                    "y": 259.5
                },
                {
                    "x": 228,
                    "y": 214.5
                },
                {
                    "x": 260,
                    "y": 167.5
                },
                {
                    "x": 294,
                    "y": 121.5
                },
                {
                    "x": 327,
                    "y": 82.5
                },
                {
                    "x": 354,
                    "y": 54.5
                },
                {
                    "x": 370,
                    "y": 38.5
                },
                {
                    "x": 377,
                    "y": 31.5
                },
                {
                    "x": 379,
                    "y": 28.5
                },
                {
                    "x": 375,
                    "y": 33.5
                },
                {
                    "x": 362,
                    "y": 46.5
                },
                {
                    "x": 340,
                    "y": 72.5
                },
                {
                    "x": 314,
                    "y": 108.5
                },
                {
                    "x": 291,
                    "y": 145.5
                },
                {
                    "x": 277,
                    "y": 181.5
                },
                {
                    "x": 272,
                    "y": 210.5
                },
                {
                    "x": 273,
                    "y": 235.5
                },
                {
                    "x": 281,
                    "y": 256.5
                },
                {
                    "x": 298,
                    "y": 273.5
                },
                {
                    "x": 324,
                    "y": 288.5
                },
                {
                    "x": 350,
                    "y": 299.5
                },
                {
                    "x": 375,
                    "y": 309.5
                },
                {
                    "x": 392,
                    "y": 314.5
                },
                {
                    "x": 400,
                    "y": 316.5
                },
                {
                    "x": 403,
                    "y": 317.5
                },
                {
                    "x": 401,
                    "y": 318.5
                },
                {
                    "x": 389,
                    "y": 318.5
                },
                {
                    "x": 368,
                    "y": 315.5
                },
                {
                    "x": 331,
                    "y": 306.5
                },
                {
                    "x": 285,
                    "y": 295.5
                },
                {
                    "x": 239,
                    "y": 281.5
                },
                {
                    "x": 201,
                    "y": 269.5
                },
                {
                    "x": 180,
                    "y": 258.5
                },
                {
                    "x": 171,
                    "y": 252.5
                },
                {
                    "x": 169,
                    "y": 245.5
                },
                {
                    "x": 174,
                    "y": 235.5
                },
                {
                    "x": 189,
                    "y": 222.5
                },
                {
                    "x": 219,
                    "y": 205.5
                },
                {
                    "x": 265,
                    "y": 187.5
                },
                {
                    "x": 320,
                    "y": 169.5
                },
                {
                    "x": 377,
                    "y": 153.5
                },
                {
                    "x": 428,
                    "y": 141.5
                },
                {
                    "x": 463,
                    "y": 130.5
                },
                {
                    "x": 483,
                    "y": 123.5
                },
                {
                    "x": 489,
                    "y": 118.5
                },
                {
                    "x": 488,
                    "y": 112.5
                },
                {
                    "x": 477,
                    "y": 106.5
                },
                {
                    "x": 447,
                    "y": 95.5
                },
                {
                    "x": 388,
                    "y": 81.5
                },
                {
                    "x": 321,
                    "y": 71.5
                },
                {
                    "x": 245,
                    "y": 62.5
                },
                {
                    "x": 173,
                    "y": 54.5
                },
                {
                    "x": 116,
                    "y": 50.5
                },
                {
                    "x": 83,
                    "y": 48.5
                },
                {
                    "x": 71,
                    "y": 48.5
                },
                {
                    "x": 77,
                    "y": 49.5
                },
                {
                    "x": 108,
                    "y": 54.5
                },
                {
                    "x": 168,
                    "y": 60.5
                },
                {
                    "x": 250,
                    "y": 66.5
                },
                {
                    "x": 342,
                    "y": 71.5
                },
                {
                    "x": 427,
                    "y": 75.5
                },
                {
                    "x": 489,
                    "y": 77.5
                },
                {
                    "x": 462,
                    "y": 69.5
                },
                {
                    "x": 397,
                    "y": 69.5
                },
                {
                    "x": 321,
                    "y": 69.5
                },
                {
                    "x": 246,
                    "y": 69.5
                },
                {
                    "x": 183,
                    "y": 72.5
                },
                {
                    "x": 138,
                    "y": 82.5
                },
                {
                    "x": 117,
                    "y": 91.5
                },
                {
                    "x": 109,
                    "y": 97.5
                },
                {
                    "x": 109,
                    "y": 102.5
                },
                {
                    "x": 115,
                    "y": 109.5
                },
                {
                    "x": 131,
                    "y": 114.5
                },
                {
                    "x": 165,
                    "y": 117.5
                },
                {
                    "x": 208,
                    "y": 119.5
                },
                {
                    "x": 257,
                    "y": 119.5
                },
                {
                    "x": 298,
                    "y": 119.5
                },
                {
                    "x": 328,
                    "y": 119.5
                },
                {
                    "x": 341,
                    "y": 119.5
                },
                {
                    "x": 347,
                    "y": 120.5
                },
                {
                    "x": 347,
                    "y": 121.5
                },
                {
                    "x": 343,
                    "y": 124.5
                },
                {
                    "x": 327,
                    "y": 129.5
                },
                {
                    "x": 288,
                    "y": 134.5
                },
                {
                    "x": 236,
                    "y": 138.5
                },
                {
                    "x": 170,
                    "y": 137.5
                },
                {
                    "x": 104,
                    "y": 129.5
                },
                {
                    "x": 46,
                    "y": 119.5
                },
                {
                    "x": 8,
                    "y": 110.5
                },
                {
                    "x": 10,
                    "y": 85.5
                },
                {
                    "x": 48,
                    "y": 74.5
                },
                {
                    "x": 106,
                    "y": 68.5
                },
                {
                    "x": 177,
                    "y": 64.5
                },
                {
                    "x": 250,
                    "y": 64.5
                },
                {
                    "x": 323,
                    "y": 69.5
                },
                {
                    "x": 387,
                    "y": 80.5
                },
                {
                    "x": 436,
                    "y": 93.5
                },
                {
                    "x": 467,
                    "y": 109.5
                },
                {
                    "x": 479,
                    "y": 120.5
                },
                {
                    "x": 483,
                    "y": 131.5
                },
                {
                    "x": 478,
                    "y": 142.5
                },
                {
                    "x": 463,
                    "y": 155.5
                },
                {
                    "x": 431,
                    "y": 169.5
                },
                {
                    "x": 388,
                    "y": 182.5
                },
                {
                    "x": 335,
                    "y": 192.5
                },
                {
                    "x": 279,
                    "y": 202.5
                },
                {
                    "x": 226,
                    "y": 212.5
                },
                {
                    "x": 182,
                    "y": 225.5
                },
                {
                    "x": 151,
                    "y": 241.5
                },
                {
                    "x": 133,
                    "y": 255.5
                },
                {
                    "x": 123,
                    "y": 269.5
                },
                {
                    "x": 119,
                    "y": 282.5
                },
                {
                    "x": 119,
                    "y": 292.5
                },
                {
                    "x": 124,
                    "y": 301.5
                },
                {
                    "x": 135,
                    "y": 307.5
                },
                {
                    "x": 152,
                    "y": 309.5
                },
                {
                    "x": 178,
                    "y": 307.5
                },
                {
                    "x": 206,
                    "y": 295.5
                },
                {
                    "x": 238,
                    "y": 276.5
                },
                {
                    "x": 271,
                    "y": 250.5
                },
                {
                    "x": 300,
                    "y": 221.5
                },
                {
                    "x": 326,
                    "y": 188.5
                },
                {
                    "x": 345,
                    "y": 158.5
                },
                {
                    "x": 358,
                    "y": 132.5
                },
                {
                    "x": 364,
                    "y": 113.5
                },
                {
                    "x": 365,
                    "y": 100.5
                },
                {
                    "x": 364,
                    "y": 89.5
                },
                {
                    "x": 355,
                    "y": 80.5
                },
                {
                    "x": 339,
                    "y": 74.5
                },
                {
                    "x": 308,
                    "y": 69.5
                },
                {
                    "x": 264,
                    "y": 67.5
                },
                {
                    "x": 215,
                    "y": 68.5
                },
                {
                    "x": 166,
                    "y": 79.5
                },
                {
                    "x": 127,
                    "y": 100.5
                },
                {
                    "x": 98,
                    "y": 125.5
                },
                {
                    "x": 81,
                    "y": 152.5
                },
                {
                    "x": 77,
                    "y": 170.5
                },
                {
                    "x": 82,
                    "y": 183.5
                },
                {
                    "x": 93,
                    "y": 191.5
                },
                {
                    "x": 111,
                    "y": 194.5
                },
                {
                    "x": 138,
                    "y": 196.5
                },
                {
                    "x": 172,
                    "y": 194.5
                },
                {
                    "x": 215,
                    "y": 184.5
                },
                {
                    "x": 260,
                    "y": 175.5
                },
                {
                    "x": 303,
                    "y": 165.5
                },
                {
                    "x": 341,
                    "y": 157.5
                },
                {
                    "x": 370,
                    "y": 150.5
                },
                {
                    "x": 385,
                    "y": 148.5
                },
                {
                    "x": 395,
                    "y": 148.5
                },
                {
                    "x": 399,
                    "y": 148.5
                },
                {
                    "x": 399,
                    "y": 153.5
                },
                {
                    "x": 395,
                    "y": 160.5
                },
                {
                    "x": 384,
                    "y": 172.5
                },
                {
                    "x": 364,
                    "y": 189.5
                },
                {
                    "x": 334,
                    "y": 210.5
                },
                {
                    "x": 297,
                    "y": 230.5
                },
                {
                    "x": 258,
                    "y": 247.5
                },
                {
                    "x": 219,
                    "y": 262.5
                },
                {
                    "x": 192,
                    "y": 270.5
                },
                {
                    "x": 178,
                    "y": 273.5
                },
                {
                    "x": 171,
                    "y": 274.5
                },
                {
                    "x": 170,
                    "y": 275.5
                },
                {
                    "x": 179,
                    "y": 275.5
                },
                {
                    "x": 197,
                    "y": 275.5
                },
                {
                    "x": 233,
                    "y": 274.5
                },
                {
                    "x": 279,
                    "y": 271.5
                },
                {
                    "x": 330,
                    "y": 263.5
                },
                {
                    "x": 372,
                    "y": 254.5
                },
                {
                    "x": 408,
                    "y": 241.5
                },
                {
                    "x": 434,
                    "y": 230.5
                },
                {
                    "x": 447,
                    "y": 221.5
                },
                {
                    "x": 454,
                    "y": 215.5
                },
                {
                    "x": 456,
                    "y": 213.5
                },
                {
                    "x": 456,
                    "y": 211.5
                },
                {
                    "x": 449,
                    "y": 210.5
                },
                {
                    "x": 436,
                    "y": 210.5
                },
                {
                    "x": 415,
                    "y": 214.5
                },
                {
                    "x": 385,
                    "y": 224.5
                },
                {
                    "x": 356,
                    "y": 240.5
                },
                {
                    "x": 331,
                    "y": 256.5
                },
                {
                    "x": 316,
                    "y": 272.5
                },
                {
                    "x": 307,
                    "y": 285.5
                },
                {
                    "x": 304,
                    "y": 297.5
                },
                {
                    "x": 304,
                    "y": 307.5
                },
                {
                    "x": 306,
                    "y": 315.5
                },
                {
                    "x": 311,
                    "y": 322.5
                },
                {
                    "x": 317,
                    "y": 330.5
                },
                {
                    "x": 324,
                    "y": 336.5
                },
                {
                    "x": 330,
                    "y": 344.5
                }
            ]
        },
        {
            "drawMode": false,
            "strokeColor": "#000000",
            "strokeWidth": 4,
            "paths": [
                {
                    "x": 199,
                    "y": 59.5
                },
                {
                    "x": 200,
                    "y": 66.5
                },
                {
                    "x": 202,
                    "y": 74.5
                },
                {
                    "x": 204,
                    "y": 84.5
                },
                {
                    "x": 205,
                    "y": 96.5
                },
                {
                    "x": 207,
                    "y": 110.5
                },
                {
                    "x": 208,
                    "y": 127.5
                },
                {
                    "x": 208,
                    "y": 141.5
                },
                {
                    "x": 208,
                    "y": 155.5
                },
                {
                    "x": 208,
                    "y": 168.5
                },
                {
                    "x": 208,
                    "y": 180.5
                },
                {
                    "x": 209,
                    "y": 192.5
                },
                {
                    "x": 211,
                    "y": 204.5
                },
                {
                    "x": 214,
                    "y": 216.5
                },
                {
                    "x": 217,
                    "y": 226.5
                },
                {
                    "x": 219,
                    "y": 234.5
                },
                {
                    "x": 220,
                    "y": 240.5
                },
                {
                    "x": 220,
                    "y": 243.5
                },
                {
                    "x": 220,
                    "y": 245.5
                }
            ]
        },
        {
            "drawMode": false,
            "strokeColor": "#000000",
            "strokeWidth": 4,
            "paths": [
                {
                    "x": 322,
                    "y": 33.5
                },
                {
                    "x": 322,
                    "y": 36.5
                },
                {
                    "x": 322,
                    "y": 40.5
                },
                {
                    "x": 322,
                    "y": 44.5
                },
                {
                    "x": 322,
                    "y": 49.5
                },
                {
                    "x": 322,
                    "y": 54.5
                },
                {
                    "x": 321,
                    "y": 61.5
                },
                {
                    "x": 321,
                    "y": 70.5
                },
                {
                    "x": 320,
                    "y": 81.5
                },
                {
                    "x": 320,
                    "y": 95.5
                },
                {
                    "x": 320,
                    "y": 112.5
                },
                {
                    "x": 320,
                    "y": 134.5
                },
                {
                    "x": 320,
                    "y": 156.5
                },
                {
                    "x": 322,
                    "y": 181.5
                },
                {
                    "x": 323,
                    "y": 204.5
                },
                {
                    "x": 324,
                    "y": 226.5
                },
                {
                    "x": 326,
                    "y": 241.5
                },
                {
                    "x": 327,
                    "y": 252.5
                },
                {
                    "x": 328,
                    "y": 258.5
                },
                {
                    "x": 328,
                    "y": 261.5
                },
                {
                    "x": 328,
                    "y": 263.5
                },
                {
                    "x": 327,
                    "y": 264.5
                }
            ]
        },
        {
            "drawMode": false,
            "strokeColor": "#000000",
            "strokeWidth": 4,
            "paths": [
                {
                    "x": 160,
                    "y": 237.5
                },
                {
                    "x": 159,
                    "y": 238.5
                },
                {
                    "x": 158,
                    "y": 240.5
                },
                {
                    "x": 157,
                    "y": 243.5
                },
                {
                    "x": 157,
                    "y": 247.5
                },
                {
                    "x": 157,
                    "y": 254.5
                },
                {
                    "x": 157,
                    "y": 262.5
                },
                {
                    "x": 160,
                    "y": 272.5
                },
                {
                    "x": 166,
                    "y": 283.5
                },
                {
                    "x": 172,
                    "y": 293.5
                },
                {
                    "x": 181,
                    "y": 301.5
                },
                {
                    "x": 191,
                    "y": 310.5
                },
                {
                    "x": 200,
                    "y": 318.5
                },
                {
                    "x": 210,
                    "y": 325.5
                },
                {
                    "x": 221,
                    "y": 330.5
                },
                {
                    "x": 232,
                    "y": 336.5
                },
                {
                    "x": 244,
                    "y": 341.5
                },
                {
                    "x": 258,
                    "y": 346.5
                },
                {
                    "x": 270,
                    "y": 349.5
                },
                {
                    "x": 284,
                    "y": 350.5
                },
                {
                    "x": 297,
                    "y": 350.5
                },
                {
                    "x": 310,
                    "y": 348.5
                },
                {
                    "x": 323,
                    "y": 344.5
                },
                {
                    "x": 334,
                    "y": 336.5
                },
                {
                    "x": 344,
                    "y": 327.5
                },
                {
                    "x": 353,
                    "y": 317.5
                },
                {
                    "x": 362,
                    "y": 305.5
                },
                {
                    "x": 369,
                    "y": 294.5
                },
                {
                    "x": 375,
                    "y": 284.5
                },
                {
                    "x": 379,
                    "y": 274.5
                },
                {
                    "x": 381,
                    "y": 264.5
                },
                {
                    "x": 381,
                    "y": 253.5
                },
                {
                    "x": 380,
                    "y": 243.5
                },
                {
                    "x": 376,
                    "y": 239.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 12,
            "paths": [
                {
                    "x": 96,
                    "y": 402.5
                },
                {
                    "x": 96,
                    "y": 404.5
                },
                {
                    "x": 96,
                    "y": 405.5
                },
                {
                    "x": 98,
                    "y": 406.5
                },
                {
                    "x": 102,
                    "y": 406.5
                },
                {
                    "x": 109,
                    "y": 407.5
                },
                {
                    "x": 120,
                    "y": 409.5
                },
                {
                    "x": 133,
                    "y": 412.5
                },
                {
                    "x": 151,
                    "y": 417.5
                },
                {
                    "x": 170,
                    "y": 423.5
                },
                {
                    "x": 191,
                    "y": 427.5
                },
                {
                    "x": 210,
                    "y": 431.5
                },
                {
                    "x": 224,
                    "y": 432.5
                },
                {
                    "x": 239,
                    "y": 433.5
                },
                {
                    "x": 253,
                    "y": 433.5
                },
                {
                    "x": 268,
                    "y": 433.5
                },
                {
                    "x": 283,
                    "y": 432.5
                },
                {
                    "x": 297,
                    "y": 431.5
                },
                {
                    "x": 311,
                    "y": 429.5
                },
                {
                    "x": 324,
                    "y": 428.5
                },
                {
                    "x": 337,
                    "y": 428.5
                },
                {
                    "x": 348,
                    "y": 428.5
                },
                {
                    "x": 356,
                    "y": 428.5
                },
                {
                    "x": 361,
                    "y": 428.5
                },
                {
                    "x": 364,
                    "y": 428.5
                },
                {
                    "x": 360,
                    "y": 428.5
                },
                {
                    "x": 352,
                    "y": 428.5
                },
                {
                    "x": 340,
                    "y": 428.5
                },
                {
                    "x": 325,
                    "y": 428.5
                },
                {
                    "x": 307,
                    "y": 428.5
                },
                {
                    "x": 283,
                    "y": 428.5
                },
                {
                    "x": 255,
                    "y": 428.5
                },
                {
                    "x": 231,
                    "y": 428.5
                },
                {
                    "x": 209,
                    "y": 428.5
                },
                {
                    "x": 193,
                    "y": 428.5
                },
                {
                    "x": 181,
                    "y": 428.5
                },
                {
                    "x": 174,
                    "y": 429.5
                },
                {
                    "x": 172,
                    "y": 429.5
                },
                {
                    "x": 173,
                    "y": 429.5
                },
                {
                    "x": 179,
                    "y": 429.5
                },
                {
                    "x": 194,
                    "y": 427.5
                },
                {
                    "x": 214,
                    "y": 425.5
                },
                {
                    "x": 240,
                    "y": 421.5
                },
                {
                    "x": 263,
                    "y": 419.5
                },
                {
                    "x": 282,
                    "y": 415.5
                },
                {
                    "x": 294,
                    "y": 411.5
                },
                {
                    "x": 302,
                    "y": 408.5
                },
                {
                    "x": 306,
                    "y": 406.5
                },
                {
                    "x": 307,
                    "y": 406.5
                },
                {
                    "x": 302,
                    "y": 406.5
                },
                {
                    "x": 291,
                    "y": 405.5
                },
                {
                    "x": 272,
                    "y": 402.5
                },
                {
                    "x": 244,
                    "y": 400.5
                },
                {
                    "x": 208,
                    "y": 397.5
                },
                {
                    "x": 172,
                    "y": 394.5
                },
                {
                    "x": 141,
                    "y": 392.5
                },
                {
                    "x": 122,
                    "y": 392.5
                },
                {
                    "x": 111,
                    "y": 392.5
                },
                {
                    "x": 107,
                    "y": 392.5
                },
                {
                    "x": 112,
                    "y": 392.5
                },
                {
                    "x": 126,
                    "y": 392.5
                },
                {
                    "x": 154,
                    "y": 392.5
                },
                {
                    "x": 200,
                    "y": 392.5
                },
                {
                    "x": 252,
                    "y": 392.5
                },
                {
                    "x": 303,
                    "y": 392.5
                },
                {
                    "x": 344,
                    "y": 392.5
                },
                {
                    "x": 372,
                    "y": 393.5
                },
                {
                    "x": 382,
                    "y": 393.5
                },
                {
                    "x": 385,
                    "y": 393.5
                },
                {
                    "x": 383,
                    "y": 393.5
                },
                {
                    "x": 372,
                    "y": 393.5
                },
                {
                    "x": 342,
                    "y": 393.5
                },
                {
                    "x": 290,
                    "y": 393.5
                },
                {
                    "x": 225,
                    "y": 393.5
                },
                {
                    "x": 158,
                    "y": 393.5
                },
                {
                    "x": 98,
                    "y": 393.5
                },
                {
                    "x": 52,
                    "y": 393.5
                },
                {
                    "x": 25,
                    "y": 393.5
                },
                {
                    "x": 13,
                    "y": 393.5
                },
                {
                    "x": 8,
                    "y": 393.5
                },
                {
                    "x": 8,
                    "y": 392.5
                },
                {
                    "x": 10,
                    "y": 390.5
                },
                {
                    "x": 21,
                    "y": 384.5
                },
                {
                    "x": 42,
                    "y": 379.5
                },
                {
                    "x": 72,
                    "y": 372.5
                },
                {
                    "x": 98,
                    "y": 368.5
                },
                {
                    "x": 117,
                    "y": 362.5
                },
                {
                    "x": 128,
                    "y": 358.5
                },
                {
                    "x": 136,
                    "y": 353.5
                },
                {
                    "x": 141,
                    "y": 346.5
                },
                {
                    "x": 144,
                    "y": 338.5
                },
                {
                    "x": 144,
                    "y": 326.5
                },
                {
                    "x": 141,
                    "y": 309.5
                },
                {
                    "x": 134,
                    "y": 287.5
                },
                {
                    "x": 121,
                    "y": 263.5
                },
                {
                    "x": 107,
                    "y": 241.5
                },
                {
                    "x": 93,
                    "y": 220.5
                },
                {
                    "x": 81,
                    "y": 203.5
                },
                {
                    "x": 73,
                    "y": 192.5
                },
                {
                    "x": 67,
                    "y": 183.5
                },
                {
                    "x": 64,
                    "y": 176.5
                },
                {
                    "x": 62,
                    "y": 170.5
                },
                {
                    "x": 62,
                    "y": 166.5
                },
                {
                    "x": 62,
                    "y": 164.5
                },
                {
                    "x": 62,
                    "y": 162.5
                },
                {
                    "x": 63,
                    "y": 161.5
                },
                {
                    "x": 65,
                    "y": 161.5
                },
                {
                    "x": 67,
                    "y": 162.5
                },
                {
                    "x": 69,
                    "y": 165.5
                },
                {
                    "x": 74,
                    "y": 173.5
                },
                {
                    "x": 79,
                    "y": 188.5
                },
                {
                    "x": 85,
                    "y": 207.5
                },
                {
                    "x": 91,
                    "y": 234.5
                },
                {
                    "x": 96,
                    "y": 262.5
                },
                {
                    "x": 99,
                    "y": 296.5
                },
                {
                    "x": 101,
                    "y": 332.5
                },
                {
                    "x": 103,
                    "y": 365.5
                },
                {
                    "x": 103,
                    "y": 390.5
                },
                {
                    "x": 103,
                    "y": 403.5
                },
                {
                    "x": 103,
                    "y": 408.5
                },
                {
                    "x": 103,
                    "y": 410.5
                },
                {
                    "x": 103,
                    "y": 411.5
                },
                {
                    "x": 103,
                    "y": 410.5
                },
                {
                    "x": 103,
                    "y": 403.5
                },
                {
                    "x": 103,
                    "y": 390.5
                },
                {
                    "x": 103,
                    "y": 367.5
                },
                {
                    "x": 103,
                    "y": 332.5
                },
                {
                    "x": 103,
                    "y": 291.5
                },
                {
                    "x": 103,
                    "y": 248.5
                },
                {
                    "x": 101,
                    "y": 202.5
                },
                {
                    "x": 101,
                    "y": 161.5
                },
                {
                    "x": 101,
                    "y": 126.5
                },
                {
                    "x": 101,
                    "y": 104.5
                },
                {
                    "x": 101,
                    "y": 91.5
                },
                {
                    "x": 101,
                    "y": 85.5
                },
                {
                    "x": 101,
                    "y": 83.5
                },
                {
                    "x": 101,
                    "y": 84.5
                },
                {
                    "x": 99,
                    "y": 93.5
                },
                {
                    "x": 97,
                    "y": 111.5
                },
                {
                    "x": 95,
                    "y": 147.5
                },
                {
                    "x": 89,
                    "y": 194.5
                },
                {
                    "x": 84,
                    "y": 244.5
                },
                {
                    "x": 80,
                    "y": 291.5
                },
                {
                    "x": 76,
                    "y": 327.5
                },
                {
                    "x": 75,
                    "y": 342.5
                },
                {
                    "x": 75,
                    "y": 348.5
                },
                {
                    "x": 75,
                    "y": 347.5
                },
                {
                    "x": 75,
                    "y": 338.5
                },
                {
                    "x": 79,
                    "y": 315.5
                },
                {
                    "x": 85,
                    "y": 275.5
                },
                {
                    "x": 90,
                    "y": 231.5
                },
                {
                    "x": 94,
                    "y": 184.5
                },
                {
                    "x": 98,
                    "y": 142.5
                },
                {
                    "x": 103,
                    "y": 106.5
                },
                {
                    "x": 106,
                    "y": 77.5
                },
                {
                    "x": 107,
                    "y": 57.5
                },
                {
                    "x": 107,
                    "y": 45.5
                },
                {
                    "x": 106,
                    "y": 38.5
                },
                {
                    "x": 104,
                    "y": 35.5
                },
                {
                    "x": 99,
                    "y": 33.5
                },
                {
                    "x": 94,
                    "y": 33.5
                },
                {
                    "x": 89,
                    "y": 33.5
                },
                {
                    "x": 87,
                    "y": 35.5
                },
                {
                    "x": 87,
                    "y": 37.5
                },
                {
                    "x": 87,
                    "y": 39.5
                },
                {
                    "x": 92,
                    "y": 42.5
                },
                {
                    "x": 105,
                    "y": 47.5
                },
                {
                    "x": 122,
                    "y": 51.5
                },
                {
                    "x": 151,
                    "y": 55.5
                },
                {
                    "x": 184,
                    "y": 57.5
                },
                {
                    "x": 225,
                    "y": 59.5
                },
                {
                    "x": 268,
                    "y": 59.5
                },
                {
                    "x": 312,
                    "y": 59.5
                },
                {
                    "x": 353,
                    "y": 58.5
                },
                {
                    "x": 387,
                    "y": 55.5
                },
                {
                    "x": 413,
                    "y": 50.5
                },
                {
                    "x": 429,
                    "y": 46.5
                },
                {
                    "x": 440,
                    "y": 44.5
                },
                {
                    "x": 445,
                    "y": 42.5
                },
                {
                    "x": 447,
                    "y": 42.5
                },
                {
                    "x": 447,
                    "y": 42.5
                },
                {
                    "x": 446,
                    "y": 42.5
                },
                {
                    "x": 436,
                    "y": 45.5
                },
                {
                    "x": 419,
                    "y": 49.5
                },
                {
                    "x": 392,
                    "y": 55.5
                },
                {
                    "x": 358,
                    "y": 60.5
                },
                {
                    "x": 313,
                    "y": 66.5
                },
                {
                    "x": 274,
                    "y": 71.5
                },
                {
                    "x": 242,
                    "y": 76.5
                },
                {
                    "x": 222,
                    "y": 79.5
                },
                {
                    "x": 211,
                    "y": 80.5
                },
                {
                    "x": 208,
                    "y": 80.5
                },
                {
                    "x": 214,
                    "y": 77.5
                },
                {
                    "x": 231,
                    "y": 73.5
                },
                {
                    "x": 260,
                    "y": 70.5
                },
                {
                    "x": 299,
                    "y": 67.5
                },
                {
                    "x": 338,
                    "y": 63.5
                },
                {
                    "x": 373,
                    "y": 61.5
                },
                {
                    "x": 401,
                    "y": 60.5
                },
                {
                    "x": 422,
                    "y": 57.5
                },
                {
                    "x": 433,
                    "y": 56.5
                },
                {
                    "x": 436,
                    "y": 56.5
                },
                {
                    "x": 434,
                    "y": 56.5
                },
                {
                    "x": 426,
                    "y": 56.5
                },
                {
                    "x": 413,
                    "y": 56.5
                },
                {
                    "x": 395,
                    "y": 56.5
                },
                {
                    "x": 373,
                    "y": 56.5
                },
                {
                    "x": 356,
                    "y": 56.5
                },
                {
                    "x": 343,
                    "y": 56.5
                },
                {
                    "x": 334,
                    "y": 56.5
                },
                {
                    "x": 330,
                    "y": 56.5
                },
                {
                    "x": 328,
                    "y": 56.5
                },
                {
                    "x": 329,
                    "y": 56.5
                },
                {
                    "x": 334,
                    "y": 56.5
                },
                {
                    "x": 344,
                    "y": 56.5
                },
                {
                    "x": 363,
                    "y": 56.5
                },
                {
                    "x": 387,
                    "y": 56.5
                },
                {
                    "x": 414,
                    "y": 56.5
                },
                {
                    "x": 442,
                    "y": 56.5
                },
                {
                    "x": 466,
                    "y": 56.5
                },
                {
                    "x": 485,
                    "y": 56.5
                },
                {
                    "x": 499,
                    "y": 56.5
                },
                {
                    "x": 493,
                    "y": 56.5
                },
                {
                    "x": 479,
                    "y": 56.5
                },
                {
                    "x": 463,
                    "y": 56.5
                },
                {
                    "x": 448,
                    "y": 56.5
                },
                {
                    "x": 438,
                    "y": 56.5
                },
                {
                    "x": 431,
                    "y": 57.5
                },
                {
                    "x": 427,
                    "y": 58.5
                },
                {
                    "x": 427,
                    "y": 61.5
                },
                {
                    "x": 427,
                    "y": 64.5
                },
                {
                    "x": 432,
                    "y": 69.5
                },
                {
                    "x": 440,
                    "y": 75.5
                },
                {
                    "x": 450,
                    "y": 83.5
                },
                {
                    "x": 460,
                    "y": 93.5
                },
                {
                    "x": 469,
                    "y": 105.5
                },
                {
                    "x": 477,
                    "y": 116.5
                },
                {
                    "x": 483,
                    "y": 127.5
                },
                {
                    "x": 488,
                    "y": 139.5
                },
                {
                    "x": 490,
                    "y": 151.5
                },
                {
                    "x": 492,
                    "y": 165.5
                },
                {
                    "x": 493,
                    "y": 179.5
                },
                {
                    "x": 493,
                    "y": 193.5
                },
                {
                    "x": 492,
                    "y": 205.5
                },
                {
                    "x": 489,
                    "y": 214.5
                },
                {
                    "x": 486,
                    "y": 222.5
                },
                {
                    "x": 482,
                    "y": 225.5
                },
                {
                    "x": 481,
                    "y": 227.5
                },
                {
                    "x": 480,
                    "y": 227.5
                },
                {
                    "x": 478,
                    "y": 221.5
                },
                {
                    "x": 478,
                    "y": 210.5
                },
                {
                    "x": 478,
                    "y": 196.5
                },
                {
                    "x": 479,
                    "y": 175.5
                },
                {
                    "x": 486,
                    "y": 148.5
                },
                {
                    "x": 494,
                    "y": 121.5
                },
                {
                    "x": 497,
                    "y": 315.5
                },
                {
                    "x": 493,
                    "y": 306.5
                },
                {
                    "x": 488,
                    "y": 297.5
                },
                {
                    "x": 484,
                    "y": 290.5
                },
                {
                    "x": 480,
                    "y": 285.5
                },
                {
                    "x": 478,
                    "y": 284.5
                },
                {
                    "x": 477,
                    "y": 284.5
                },
                {
                    "x": 474,
                    "y": 285.5
                },
                {
                    "x": 472,
                    "y": 291.5
                },
                {
                    "x": 468,
                    "y": 299.5
                },
                {
                    "x": 464,
                    "y": 312.5
                },
                {
                    "x": 459,
                    "y": 329.5
                },
                {
                    "x": 452,
                    "y": 349.5
                },
                {
                    "x": 445,
                    "y": 369.5
                },
                {
                    "x": 438,
                    "y": 382.5
                },
                {
                    "x": 432,
                    "y": 390.5
                },
                {
                    "x": 428,
                    "y": 394.5
                },
                {
                    "x": 424,
                    "y": 396.5
                },
                {
                    "x": 423,
                    "y": 396.5
                },
                {
                    "x": 421,
                    "y": 396.5
                },
                {
                    "x": 421,
                    "y": 392.5
                },
                {
                    "x": 421,
                    "y": 385.5
                },
                {
                    "x": 422,
                    "y": 376.5
                },
                {
                    "x": 425,
                    "y": 366.5
                },
                {
                    "x": 430,
                    "y": 355.5
                },
                {
                    "x": 437,
                    "y": 344.5
                },
                {
                    "x": 442,
                    "y": 335.5
                },
                {
                    "x": 447,
                    "y": 329.5
                },
                {
                    "x": 450,
                    "y": 326.5
                },
                {
                    "x": 451,
                    "y": 324.5
                },
                {
                    "x": 451,
                    "y": 325.5
                },
                {
                    "x": 448,
                    "y": 330.5
                },
                {
                    "x": 444,
                    "y": 337.5
                },
                {
                    "x": 441,
                    "y": 345.5
                },
                {
                    "x": 438,
                    "y": 355.5
                },
                {
                    "x": 436,
                    "y": 366.5
                },
                {
                    "x": 434,
                    "y": 375.5
                },
                {
                    "x": 434,
                    "y": 383.5
                },
                {
                    "x": 434,
                    "y": 388.5
                },
                {
                    "x": 434,
                    "y": 389.5
                },
                {
                    "x": 434,
                    "y": 387.5
                },
                {
                    "x": 433,
                    "y": 383.5
                },
                {
                    "x": 431,
                    "y": 377.5
                },
                {
                    "x": 427,
                    "y": 371.5
                },
                {
                    "x": 422,
                    "y": 365.5
                },
                {
                    "x": 416,
                    "y": 360.5
                },
                {
                    "x": 408,
                    "y": 356.5
                },
                {
                    "x": 401,
                    "y": 353.5
                },
                {
                    "x": 395,
                    "y": 351.5
                },
                {
                    "x": 391,
                    "y": 351.5
                },
                {
                    "x": 387,
                    "y": 351.5
                },
                {
                    "x": 382,
                    "y": 353.5
                },
                {
                    "x": 379,
                    "y": 356.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 12,
            "paths": [
                {
                    "x": 419,
                    "y": 375.5
                },
                {
                    "x": 405,
                    "y": 374.5
                },
                {
                    "x": 389,
                    "y": 373.5
                },
                {
                    "x": 364,
                    "y": 373.5
                }
            ]
        }
    ],
    [
        {
            "drawMode": true,
            "strokeColor": "#000000",
            "strokeWidth": 4,
            "paths": [
                {
                    "x": 66,
                    "y": 46.5
                },
                {
                    "x": 56,
                    "y": 50.5
                },
                {
                    "x": 51,
                    "y": 53.5
                },
                {
                    "x": 47,
                    "y": 59.5
                },
                {
                    "x": 45,
                    "y": 66.5
                },
                {
                    "x": 45,
                    "y": 74.5
                },
                {
                    "x": 45,
                    "y": 84.5
                },
                {
                    "x": 49,
                    "y": 95.5
                },
                {
                    "x": 58,
                    "y": 108.5
                },
                {
                    "x": 68,
                    "y": 122.5
                },
                {
                    "x": 82,
                    "y": 137.5
                },
                {
                    "x": 97,
                    "y": 153.5
                },
                {
                    "x": 114,
                    "y": 167.5
                },
                {
                    "x": 131,
                    "y": 178.5
                },
                {
                    "x": 152,
                    "y": 190.5
                },
                {
                    "x": 178,
                    "y": 203.5
                },
                {
                    "x": 211,
                    "y": 215.5
                },
                {
                    "x": 246,
                    "y": 224.5
                },
                {
                    "x": 280,
                    "y": 229.5
                },
                {
                    "x": 314,
                    "y": 233.5
                },
                {
                    "x": 348,
                    "y": 230.5
                },
                {
                    "x": 380,
                    "y": 222.5
                },
                {
                    "x": 411,
                    "y": 210.5
                },
                {
                    "x": 435,
                    "y": 195.5
                },
                {
                    "x": 452,
                    "y": 181.5
                },
                {
                    "x": 462,
                    "y": 167.5
                },
                {
                    "x": 467,
                    "y": 153.5
                },
                {
                    "x": 469,
                    "y": 141.5
                },
                {
                    "x": 469,
                    "y": 130.5
                },
                {
                    "x": 464,
                    "y": 120.5
                },
                {
                    "x": 455,
                    "y": 110.5
                },
                {
                    "x": 441,
                    "y": 99.5
                },
                {
                    "x": 415,
                    "y": 86.5
                },
                {
                    "x": 382,
                    "y": 73.5
                },
                {
                    "x": 342,
                    "y": 64.5
                },
                {
                    "x": 294,
                    "y": 57.5
                },
                {
                    "x": 242,
                    "y": 53.5
                },
                {
                    "x": 190,
                    "y": 51.5
                },
                {
                    "x": 144,
                    "y": 52.5
                },
                {
                    "x": 114,
                    "y": 58.5
                },
                {
                    "x": 101,
                    "y": 67.5
                },
                {
                    "x": 95,
                    "y": 77.5
                },
                {
                    "x": 98,
                    "y": 89.5
                },
                {
                    "x": 109,
                    "y": 104.5
                },
                {
                    "x": 132,
                    "y": 122.5
                },
                {
                    "x": 161,
                    "y": 139.5
                },
                {
                    "x": 194,
                    "y": 150.5
                },
                {
                    "x": 225,
                    "y": 155.5
                },
                {
                    "x": 256,
                    "y": 158.5
                },
                {
                    "x": 287,
                    "y": 157.5
                },
                {
                    "x": 313,
                    "y": 153.5
                },
                {
                    "x": 333,
                    "y": 146.5
                },
                {
                    "x": 346,
                    "y": 139.5
                },
                {
                    "x": 352,
                    "y": 131.5
                },
                {
                    "x": 355,
                    "y": 124.5
                },
                {
                    "x": 353,
                    "y": 117.5
                },
                {
                    "x": 344,
                    "y": 110.5
                },
                {
                    "x": 328,
                    "y": 104.5
                },
                {
                    "x": 299,
                    "y": 98.5
                },
                {
                    "x": 263,
                    "y": 94.5
                },
                {
                    "x": 224,
                    "y": 92.5
                },
                {
                    "x": 192,
                    "y": 92.5
                },
                {
                    "x": 168,
                    "y": 96.5
                },
                {
                    "x": 153,
                    "y": 102.5
                },
                {
                    "x": 144,
                    "y": 110.5
                },
                {
                    "x": 139,
                    "y": 121.5
                },
                {
                    "x": 135,
                    "y": 134.5
                },
                {
                    "x": 135,
                    "y": 149.5
                },
                {
                    "x": 137,
                    "y": 168.5
                },
                {
                    "x": 146,
                    "y": 190.5
                },
                {
                    "x": 160,
                    "y": 214.5
                },
                {
                    "x": 179,
                    "y": 235.5
                },
                {
                    "x": 199,
                    "y": 250.5
                },
                {
                    "x": 222,
                    "y": 259.5
                },
                {
                    "x": 241,
                    "y": 265.5
                },
                {
                    "x": 256,
                    "y": 266.5
                },
                {
                    "x": 269,
                    "y": 266.5
                },
                {
                    "x": 278,
                    "y": 263.5
                },
                {
                    "x": 284,
                    "y": 261.5
                },
                {
                    "x": 287,
                    "y": 258.5
                },
                {
                    "x": 287,
                    "y": 257.5
                },
                {
                    "x": 284,
                    "y": 256.5
                },
                {
                    "x": 274,
                    "y": 256.5
                },
                {
                    "x": 260,
                    "y": 256.5
                },
                {
                    "x": 238,
                    "y": 260.5
                },
                {
                    "x": 206,
                    "y": 271.5
                },
                {
                    "x": 174,
                    "y": 286.5
                },
                {
                    "x": 147,
                    "y": 307.5
                },
                {
                    "x": 129,
                    "y": 329.5
                },
                {
                    "x": 118,
                    "y": 355.5
                },
                {
                    "x": 116,
                    "y": 378.5
                },
                {
                    "x": 120,
                    "y": 394.5
                },
                {
                    "x": 128,
                    "y": 402.5
                },
                {
                    "x": 138,
                    "y": 406.5
                },
                {
                    "x": 147,
                    "y": 406.5
                },
                {
                    "x": 158,
                    "y": 399.5
                },
                {
                    "x": 172,
                    "y": 387.5
                },
                {
                    "x": 190,
                    "y": 369.5
                },
                {
                    "x": 210,
                    "y": 346.5
                },
                {
                    "x": 228,
                    "y": 321.5
                },
                {
                    "x": 241,
                    "y": 297.5
                },
                {
                    "x": 248,
                    "y": 278.5
                },
                {
                    "x": 249,
                    "y": 264.5
                },
                {
                    "x": 247,
                    "y": 256.5
                },
                {
                    "x": 241,
                    "y": 251.5
                },
                {
                    "x": 231,
                    "y": 248.5
                },
                {
                    "x": 219,
                    "y": 249.5
                },
                {
                    "x": 206,
                    "y": 257.5
                },
                {
                    "x": 193,
                    "y": 274.5
                },
                {
                    "x": 183,
                    "y": 304.5
                },
                {
                    "x": 178,
                    "y": 341.5
                },
                {
                    "x": 180,
                    "y": 382.5
                },
                {
                    "x": 193,
                    "y": 423.5
                },
                {
                    "x": 211,
                    "y": 455.5
                },
                {
                    "x": 228,
                    "y": 475.5
                },
                {
                    "x": 239,
                    "y": 483.5
                },
                {
                    "x": 249,
                    "y": 485.5
                },
                {
                    "x": 256,
                    "y": 484.5
                },
                {
                    "x": 265,
                    "y": 478.5
                },
                {
                    "x": 272,
                    "y": 468.5
                },
                {
                    "x": 280,
                    "y": 453.5
                },
                {
                    "x": 286,
                    "y": 436.5
                },
                {
                    "x": 289,
                    "y": 418.5
                },
                {
                    "x": 290,
                    "y": 404.5
                },
                {
                    "x": 288,
                    "y": 390.5
                },
                {
                    "x": 282,
                    "y": 379.5
                },
                {
                    "x": 273,
                    "y": 371.5
                },
                {
                    "x": 261,
                    "y": 366.5
                },
                {
                    "x": 248,
                    "y": 365.5
                },
                {
                    "x": 235,
                    "y": 366.5
                },
                {
                    "x": 227,
                    "y": 373.5
                },
                {
                    "x": 221,
                    "y": 386.5
                },
                {
                    "x": 221,
                    "y": 402.5
                },
                {
                    "x": 234,
                    "y": 428.5
                },
                {
                    "x": 258,
                    "y": 454.5
                },
                {
                    "x": 293,
                    "y": 474.5
                },
                {
                    "x": 329,
                    "y": 487.5
                },
                {
                    "x": 365,
                    "y": 490.5
                },
                {
                    "x": 402,
                    "y": 486.5
                },
                {
                    "x": 438,
                    "y": 470.5
                },
                {
                    "x": 471,
                    "y": 446.5
                },
                {
                    "x": 496,
                    "y": 418.5
                },
                {
                    "x": 497,
                    "y": 298.5
                },
                {
                    "x": 478,
                    "y": 286.5
                },
                {
                    "x": 461,
                    "y": 281.5
                },
                {
                    "x": 447,
                    "y": 281.5
                },
                {
                    "x": 434,
                    "y": 288.5
                },
                {
                    "x": 425,
                    "y": 300.5
                },
                {
                    "x": 419,
                    "y": 319.5
                },
                {
                    "x": 420,
                    "y": 342.5
                },
                {
                    "x": 429,
                    "y": 367.5
                },
                {
                    "x": 443,
                    "y": 388.5
                },
                {
                    "x": 454,
                    "y": 401.5
                },
                {
                    "x": 465,
                    "y": 407.5
                },
                {
                    "x": 477,
                    "y": 410.5
                },
                {
                    "x": 488,
                    "y": 410.5
                },
                {
                    "x": 498,
                    "y": 407.5
                },
                {
                    "x": 491,
                    "y": 365.5
                },
                {
                    "x": 471,
                    "y": 358.5
                },
                {
                    "x": 445,
                    "y": 353.5
                },
                {
                    "x": 420,
                    "y": 351.5
                },
                {
                    "x": 403,
                    "y": 352.5
                },
                {
                    "x": 392,
                    "y": 356.5
                },
                {
                    "x": 387,
                    "y": 361.5
                },
                {
                    "x": 387,
                    "y": 368.5
                },
                {
                    "x": 393,
                    "y": 375.5
                },
                {
                    "x": 406,
                    "y": 381.5
                },
                {
                    "x": 424,
                    "y": 383.5
                },
                {
                    "x": 452,
                    "y": 383.5
                },
                {
                    "x": 483,
                    "y": 379.5
                },
                {
                    "x": 473,
                    "y": 194.5
                },
                {
                    "x": 424,
                    "y": 190.5
                },
                {
                    "x": 383,
                    "y": 190.5
                },
                {
                    "x": 354,
                    "y": 194.5
                },
                {
                    "x": 339,
                    "y": 200.5
                },
                {
                    "x": 334,
                    "y": 206.5
                },
                {
                    "x": 334,
                    "y": 213.5
                },
                {
                    "x": 337,
                    "y": 218.5
                },
                {
                    "x": 345,
                    "y": 221.5
                },
                {
                    "x": 357,
                    "y": 221.5
                },
                {
                    "x": 375,
                    "y": 217.5
                },
                {
                    "x": 401,
                    "y": 204.5
                },
                {
                    "x": 428,
                    "y": 187.5
                },
                {
                    "x": 451,
                    "y": 166.5
                },
                {
                    "x": 469,
                    "y": 141.5
                },
                {
                    "x": 479,
                    "y": 118.5
                },
                {
                    "x": 480,
                    "y": 98.5
                },
                {
                    "x": 477,
                    "y": 82.5
                },
                {
                    "x": 467,
                    "y": 68.5
                },
                {
                    "x": 453,
                    "y": 59.5
                },
                {
                    "x": 432,
                    "y": 54.5
                },
                {
                    "x": 398,
                    "y": 50.5
                },
                {
                    "x": 357,
                    "y": 51.5
                },
                {
                    "x": 314,
                    "y": 60.5
                },
                {
                    "x": 278,
                    "y": 77.5
                },
                {
                    "x": 247,
                    "y": 100.5
                },
                {
                    "x": 229,
                    "y": 125.5
                },
                {
                    "x": 221,
                    "y": 150.5
                },
                {
                    "x": 222,
                    "y": 167.5
                },
                {
                    "x": 226,
                    "y": 179.5
                },
                {
                    "x": 233,
                    "y": 185.5
                },
                {
                    "x": 242,
                    "y": 186.5
                },
                {
                    "x": 253,
                    "y": 183.5
                },
                {
                    "x": 266,
                    "y": 173.5
                },
                {
                    "x": 286,
                    "y": 158.5
                },
                {
                    "x": 305,
                    "y": 138.5
                },
                {
                    "x": 321,
                    "y": 113.5
                },
                {
                    "x": 332,
                    "y": 90.5
                },
                {
                    "x": 337,
                    "y": 71.5
                },
                {
                    "x": 337,
                    "y": 56.5
                },
                {
                    "x": 333,
                    "y": 45.5
                },
                {
                    "x": 323,
                    "y": 38.5
                },
                {
                    "x": 309,
                    "y": 34.5
                },
                {
                    "x": 282,
                    "y": 31.5
                },
                {
                    "x": 239,
                    "y": 32.5
                },
                {
                    "x": 183,
                    "y": 38.5
                },
                {
                    "x": 127,
                    "y": 52.5
                },
                {
                    "x": 80,
                    "y": 72.5
                },
                {
                    "x": 49,
                    "y": 97.5
                },
                {
                    "x": 33,
                    "y": 122.5
                },
                {
                    "x": 26,
                    "y": 144.5
                },
                {
                    "x": 26,
                    "y": 158.5
                },
                {
                    "x": 28,
                    "y": 168.5
                },
                {
                    "x": 33,
                    "y": 173.5
                },
                {
                    "x": 38,
                    "y": 174.5
                },
                {
                    "x": 48,
                    "y": 172.5
                },
                {
                    "x": 59,
                    "y": 166.5
                },
                {
                    "x": 72,
                    "y": 158.5
                },
                {
                    "x": 82,
                    "y": 149.5
                },
                {
                    "x": 90,
                    "y": 139.5
                },
                {
                    "x": 94,
                    "y": 130.5
                },
                {
                    "x": 94,
                    "y": 125.5
                },
                {
                    "x": 94,
                    "y": 122.5
                },
                {
                    "x": 90,
                    "y": 120.5
                },
                {
                    "x": 83,
                    "y": 121.5
                },
                {
                    "x": 73,
                    "y": 126.5
                },
                {
                    "x": 60,
                    "y": 136.5
                },
                {
                    "x": 44,
                    "y": 154.5
                },
                {
                    "x": 30,
                    "y": 176.5
                },
                {
                    "x": 21,
                    "y": 196.5
                },
                {
                    "x": 17,
                    "y": 214.5
                },
                {
                    "x": 16,
                    "y": 229.5
                },
                {
                    "x": 16,
                    "y": 241.5
                },
                {
                    "x": 16,
                    "y": 250.5
                },
                {
                    "x": 17,
                    "y": 257.5
                },
                {
                    "x": 19,
                    "y": 262.5
                },
                {
                    "x": 23,
                    "y": 265.5
                },
                {
                    "x": 29,
                    "y": 269.5
                },
                {
                    "x": 37,
                    "y": 275.5
                },
                {
                    "x": 47,
                    "y": 281.5
                },
                {
                    "x": 57,
                    "y": 287.5
                },
                {
                    "x": 65,
                    "y": 292.5
                },
                {
                    "x": 71,
                    "y": 296.5
                },
                {
                    "x": 75,
                    "y": 299.5
                },
                {
                    "x": 76,
                    "y": 300.5
                },
                {
                    "x": 76,
                    "y": 301.5
                },
                {
                    "x": 76,
                    "y": 304.5
                },
                {
                    "x": 76,
                    "y": 306.5
                },
                {
                    "x": 74,
                    "y": 308.5
                },
                {
                    "x": 71,
                    "y": 310.5
                },
                {
                    "x": 69,
                    "y": 314.5
                },
                {
                    "x": 67,
                    "y": 318.5
                },
                {
                    "x": 67,
                    "y": 323.5
                },
                {
                    "x": 67,
                    "y": 327.5
                },
                {
                    "x": 69,
                    "y": 334.5
                },
                {
                    "x": 74,
                    "y": 342.5
                },
                {
                    "x": 81,
                    "y": 352.5
                },
                {
                    "x": 88,
                    "y": 362.5
                },
                {
                    "x": 93,
                    "y": 372.5
                },
                {
                    "x": 98,
                    "y": 381.5
                },
                {
                    "x": 101,
                    "y": 387.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#9c27b0",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 142,
                    "y": 480.5
                },
                {
                    "x": 140,
                    "y": 480.5
                },
                {
                    "x": 138,
                    "y": 480.5
                },
                {
                    "x": 137,
                    "y": 480.5
                },
                {
                    "x": 135,
                    "y": 480.5
                },
                {
                    "x": 136,
                    "y": 480.5
                },
                {
                    "x": 142,
                    "y": 480.5
                },
                {
                    "x": 155,
                    "y": 480.5
                },
                {
                    "x": 180,
                    "y": 480.5
                },
                {
                    "x": 220,
                    "y": 480.5
                },
                {
                    "x": 274,
                    "y": 480.5
                },
                {
                    "x": 337,
                    "y": 480.5
                },
                {
                    "x": 407,
                    "y": 480.5
                },
                {
                    "x": 477,
                    "y": 479.5
                },
                {
                    "x": 490,
                    "y": 444.5
                },
                {
                    "x": 425,
                    "y": 444.5
                },
                {
                    "x": 358,
                    "y": 444.5
                },
                {
                    "x": 293,
                    "y": 444.5
                },
                {
                    "x": 233,
                    "y": 445.5
                },
                {
                    "x": 187,
                    "y": 445.5
                },
                {
                    "x": 152,
                    "y": 445.5
                },
                {
                    "x": 135,
                    "y": 445.5
                },
                {
                    "x": 127,
                    "y": 445.5
                },
                {
                    "x": 125,
                    "y": 445.5
                },
                {
                    "x": 126,
                    "y": 444.5
                },
                {
                    "x": 135,
                    "y": 443.5
                },
                {
                    "x": 156,
                    "y": 439.5
                },
                {
                    "x": 192,
                    "y": 435.5
                },
                {
                    "x": 239,
                    "y": 431.5
                },
                {
                    "x": 291,
                    "y": 430.5
                },
                {
                    "x": 340,
                    "y": 430.5
                },
                {
                    "x": 383,
                    "y": 428.5
                },
                {
                    "x": 413,
                    "y": 428.5
                },
                {
                    "x": 428,
                    "y": 428.5
                },
                {
                    "x": 433,
                    "y": 428.5
                },
                {
                    "x": 426,
                    "y": 428.5
                },
                {
                    "x": 408,
                    "y": 429.5
                },
                {
                    "x": 369,
                    "y": 432.5
                },
                {
                    "x": 311,
                    "y": 434.5
                },
                {
                    "x": 244,
                    "y": 436.5
                },
                {
                    "x": 168,
                    "y": 439.5
                },
                {
                    "x": 92,
                    "y": 441.5
                },
                {
                    "x": 24,
                    "y": 443.5
                },
                {
                    "x": 14,
                    "y": 451.5
                },
                {
                    "x": 95,
                    "y": 444.5
                },
                {
                    "x": 199,
                    "y": 435.5
                },
                {
                    "x": 310,
                    "y": 426.5
                },
                {
                    "x": 421,
                    "y": 417.5
                },
                {
                    "x": 411,
                    "y": 376.5
                },
                {
                    "x": 316,
                    "y": 379.5
                },
                {
                    "x": 221,
                    "y": 386.5
                },
                {
                    "x": 132,
                    "y": 390.5
                },
                {
                    "x": 58,
                    "y": 395.5
                },
                {
                    "x": 6,
                    "y": 398.5
                },
                {
                    "x": 21,
                    "y": 399.5
                },
                {
                    "x": 89,
                    "y": 393.5
                },
                {
                    "x": 179,
                    "y": 384.5
                },
                {
                    "x": 280,
                    "y": 377.5
                },
                {
                    "x": 383,
                    "y": 368.5
                },
                {
                    "x": 479,
                    "y": 357.5
                },
                {
                    "x": 391,
                    "y": 353.5
                },
                {
                    "x": 267,
                    "y": 360.5
                },
                {
                    "x": 139,
                    "y": 370.5
                },
                {
                    "x": 25,
                    "y": 379.5
                },
                {
                    "x": 65,
                    "y": 385.5
                },
                {
                    "x": 168,
                    "y": 373.5
                },
                {
                    "x": 269,
                    "y": 362.5
                },
                {
                    "x": 357,
                    "y": 353.5
                },
                {
                    "x": 430,
                    "y": 347.5
                },
                {
                    "x": 483,
                    "y": 343.5
                },
                {
                    "x": 481,
                    "y": 339.5
                },
                {
                    "x": 425,
                    "y": 339.5
                },
                {
                    "x": 346,
                    "y": 340.5
                },
                {
                    "x": 249,
                    "y": 342.5
                },
                {
                    "x": 148,
                    "y": 347.5
                },
                {
                    "x": 54,
                    "y": 351.5
                },
                {
                    "x": 18,
                    "y": 352.5
                },
                {
                    "x": 94,
                    "y": 348.5
                },
                {
                    "x": 171,
                    "y": 341.5
                },
                {
                    "x": 237,
                    "y": 335.5
                },
                {
                    "x": 292,
                    "y": 327.5
                },
                {
                    "x": 329,
                    "y": 320.5
                },
                {
                    "x": 348,
                    "y": 315.5
                },
                {
                    "x": 356,
                    "y": 312.5
                },
                {
                    "x": 356,
                    "y": 310.5
                },
                {
                    "x": 352,
                    "y": 309.5
                },
                {
                    "x": 339,
                    "y": 309.5
                },
                {
                    "x": 311,
                    "y": 309.5
                },
                {
                    "x": 262,
                    "y": 307.5
                },
                {
                    "x": 202,
                    "y": 307.5
                },
                {
                    "x": 143,
                    "y": 307.5
                },
                {
                    "x": 88,
                    "y": 307.5
                },
                {
                    "x": 45,
                    "y": 308.5
                },
                {
                    "x": 16,
                    "y": 311.5
                },
                {
                    "x": 4,
                    "y": 313.5
                },
                {
                    "x": 0,
                    "y": 313.5
                },
                {
                    "x": 0,
                    "y": 314.5
                },
                {
                    "x": 5,
                    "y": 314.5
                },
                {
                    "x": 23,
                    "y": 314.5
                },
                {
                    "x": 65,
                    "y": 310.5
                },
                {
                    "x": 120,
                    "y": 306.5
                },
                {
                    "x": 183,
                    "y": 300.5
                },
                {
                    "x": 251,
                    "y": 296.5
                },
                {
                    "x": 317,
                    "y": 290.5
                },
                {
                    "x": 370,
                    "y": 282.5
                },
                {
                    "x": 410,
                    "y": 275.5
                },
                {
                    "x": 431,
                    "y": 271.5
                },
                {
                    "x": 438,
                    "y": 269.5
                },
                {
                    "x": 432,
                    "y": 269.5
                },
                {
                    "x": 414,
                    "y": 269.5
                },
                {
                    "x": 370,
                    "y": 269.5
                },
                {
                    "x": 311,
                    "y": 269.5
                },
                {
                    "x": 241,
                    "y": 269.5
                },
                {
                    "x": 168,
                    "y": 269.5
                },
                {
                    "x": 103,
                    "y": 269.5
                },
                {
                    "x": 49,
                    "y": 269.5
                },
                {
                    "x": 14,
                    "y": 269.5
                },
                {
                    "x": 22,
                    "y": 264.5
                },
                {
                    "x": 75,
                    "y": 258.5
                },
                {
                    "x": 149,
                    "y": 252.5
                },
                {
                    "x": 236,
                    "y": 243.5
                },
                {
                    "x": 329,
                    "y": 234.5
                },
                {
                    "x": 413,
                    "y": 228.5
                },
                {
                    "x": 484,
                    "y": 221.5
                },
                {
                    "x": 459,
                    "y": 214.5
                },
                {
                    "x": 378,
                    "y": 214.5
                },
                {
                    "x": 289,
                    "y": 214.5
                },
                {
                    "x": 200,
                    "y": 214.5
                },
                {
                    "x": 124,
                    "y": 214.5
                },
                {
                    "x": 68,
                    "y": 214.5
                },
                {
                    "x": 32,
                    "y": 214.5
                },
                {
                    "x": 20,
                    "y": 214.5
                },
                {
                    "x": 18,
                    "y": 214.5
                },
                {
                    "x": 22,
                    "y": 212.5
                },
                {
                    "x": 40,
                    "y": 208.5
                },
                {
                    "x": 82,
                    "y": 203.5
                },
                {
                    "x": 142,
                    "y": 197.5
                },
                {
                    "x": 219,
                    "y": 188.5
                },
                {
                    "x": 301,
                    "y": 180.5
                },
                {
                    "x": 377,
                    "y": 173.5
                },
                {
                    "x": 443,
                    "y": 167.5
                },
                {
                    "x": 493,
                    "y": 161.5
                },
                {
                    "x": 499,
                    "y": 156.5
                },
                {
                    "x": 447,
                    "y": 156.5
                },
                {
                    "x": 380,
                    "y": 156.5
                },
                {
                    "x": 307,
                    "y": 156.5
                },
                {
                    "x": 239,
                    "y": 156.5
                },
                {
                    "x": 180,
                    "y": 156.5
                },
                {
                    "x": 139,
                    "y": 156.5
                },
                {
                    "x": 122,
                    "y": 156.5
                },
                {
                    "x": 116,
                    "y": 156.5
                },
                {
                    "x": 123,
                    "y": 153.5
                },
                {
                    "x": 145,
                    "y": 148.5
                },
                {
                    "x": 187,
                    "y": 140.5
                },
                {
                    "x": 245,
                    "y": 134.5
                },
                {
                    "x": 313,
                    "y": 128.5
                },
                {
                    "x": 381,
                    "y": 124.5
                },
                {
                    "x": 445,
                    "y": 118.5
                },
                {
                    "x": 491,
                    "y": 114.5
                },
                {
                    "x": 492,
                    "y": 104.5
                },
                {
                    "x": 452,
                    "y": 104.5
                },
                {
                    "x": 402,
                    "y": 102.5
                },
                {
                    "x": 350,
                    "y": 98.5
                },
                {
                    "x": 298,
                    "y": 94.5
                },
                {
                    "x": 257,
                    "y": 94.5
                },
                {
                    "x": 230,
                    "y": 93.5
                },
                {
                    "x": 218,
                    "y": 93.5
                },
                {
                    "x": 212,
                    "y": 93.5
                },
                {
                    "x": 212,
                    "y": 90.5
                },
                {
                    "x": 216,
                    "y": 88.5
                },
                {
                    "x": 228,
                    "y": 85.5
                },
                {
                    "x": 251,
                    "y": 80.5
                },
                {
                    "x": 288,
                    "y": 75.5
                },
                {
                    "x": 332,
                    "y": 71.5
                },
                {
                    "x": 376,
                    "y": 68.5
                },
                {
                    "x": 414,
                    "y": 66.5
                },
                {
                    "x": 439,
                    "y": 66.5
                },
                {
                    "x": 451,
                    "y": 66.5
                },
                {
                    "x": 455,
                    "y": 66.5
                },
                {
                    "x": 454,
                    "y": 66.5
                },
                {
                    "x": 444,
                    "y": 66.5
                },
                {
                    "x": 416,
                    "y": 67.5
                },
                {
                    "x": 359,
                    "y": 69.5
                },
                {
                    "x": 283,
                    "y": 72.5
                },
                {
                    "x": 188,
                    "y": 74.5
                },
                {
                    "x": 86,
                    "y": 76.5
                },
                {
                    "x": 35,
                    "y": 70.5
                },
                {
                    "x": 130,
                    "y": 66.5
                },
                {
                    "x": 225,
                    "y": 59.5
                },
                {
                    "x": 309,
                    "y": 57.5
                },
                {
                    "x": 377,
                    "y": 53.5
                },
                {
                    "x": 421,
                    "y": 51.5
                },
                {
                    "x": 438,
                    "y": 50.5
                },
                {
                    "x": 442,
                    "y": 49.5
                },
                {
                    "x": 440,
                    "y": 48.5
                },
                {
                    "x": 424,
                    "y": 48.5
                },
                {
                    "x": 386,
                    "y": 48.5
                },
                {
                    "x": 324,
                    "y": 48.5
                },
                {
                    "x": 251,
                    "y": 48.5
                },
                {
                    "x": 173,
                    "y": 48.5
                },
                {
                    "x": 97,
                    "y": 48.5
                },
                {
                    "x": 27,
                    "y": 48.5
                },
                {
                    "x": 40,
                    "y": 42.5
                },
                {
                    "x": 117,
                    "y": 38.5
                },
                {
                    "x": 193,
                    "y": 36.5
                },
                {
                    "x": 263,
                    "y": 34.5
                },
                {
                    "x": 323,
                    "y": 32.5
                },
                {
                    "x": 364,
                    "y": 32.5
                },
                {
                    "x": 383,
                    "y": 32.5
                },
                {
                    "x": 391,
                    "y": 32.5
                },
                {
                    "x": 387,
                    "y": 32.5
                },
                {
                    "x": 367,
                    "y": 32.5
                },
                {
                    "x": 325,
                    "y": 36.5
                },
                {
                    "x": 268,
                    "y": 38.5
                },
                {
                    "x": 200,
                    "y": 40.5
                },
                {
                    "x": 129,
                    "y": 42.5
                },
                {
                    "x": 61,
                    "y": 46.5
                },
                {
                    "x": 1,
                    "y": 52.5
                },
                {
                    "x": 64,
                    "y": 61.5
                },
                {
                    "x": 134,
                    "y": 61.5
                },
                {
                    "x": 202,
                    "y": 61.5
                },
                {
                    "x": 259,
                    "y": 59.5
                },
                {
                    "x": 306,
                    "y": 58.5
                },
                {
                    "x": 333,
                    "y": 58.5
                },
                {
                    "x": 343,
                    "y": 58.5
                },
                {
                    "x": 346,
                    "y": 58.5
                },
                {
                    "x": 339,
                    "y": 60.5
                },
                {
                    "x": 317,
                    "y": 67.5
                },
                {
                    "x": 279,
                    "y": 78.5
                },
                {
                    "x": 235,
                    "y": 93.5
                },
                {
                    "x": 191,
                    "y": 112.5
                },
                {
                    "x": 151,
                    "y": 135.5
                },
                {
                    "x": 117,
                    "y": 161.5
                },
                {
                    "x": 93,
                    "y": 187.5
                },
                {
                    "x": 79,
                    "y": 208.5
                },
                {
                    "x": 73,
                    "y": 226.5
                },
                {
                    "x": 70,
                    "y": 237.5
                },
                {
                    "x": 70,
                    "y": 245.5
                },
                {
                    "x": 70,
                    "y": 248.5
                },
                {
                    "x": 72,
                    "y": 249.5
                },
                {
                    "x": 78,
                    "y": 250.5
                },
                {
                    "x": 85,
                    "y": 249.5
                },
                {
                    "x": 93,
                    "y": 245.5
                },
                {
                    "x": 102,
                    "y": 240.5
                },
                {
                    "x": 110,
                    "y": 231.5
                },
                {
                    "x": 119,
                    "y": 218.5
                },
                {
                    "x": 127,
                    "y": 198.5
                },
                {
                    "x": 134,
                    "y": 173.5
                },
                {
                    "x": 136,
                    "y": 150.5
                },
                {
                    "x": 136,
                    "y": 131.5
                },
                {
                    "x": 135,
                    "y": 113.5
                },
                {
                    "x": 130,
                    "y": 101.5
                },
                {
                    "x": 124,
                    "y": 92.5
                },
                {
                    "x": 119,
                    "y": 87.5
                },
                {
                    "x": 114,
                    "y": 85.5
                },
                {
                    "x": 110,
                    "y": 85.5
                },
                {
                    "x": 106,
                    "y": 88.5
                },
                {
                    "x": 102,
                    "y": 98.5
                },
                {
                    "x": 98,
                    "y": 115.5
                },
                {
                    "x": 93,
                    "y": 144.5
                },
                {
                    "x": 92,
                    "y": 183.5
                },
                {
                    "x": 92,
                    "y": 223.5
                },
                {
                    "x": 92,
                    "y": 267.5
                },
                {
                    "x": 92,
                    "y": 310.5
                },
                {
                    "x": 92,
                    "y": 348.5
                },
                {
                    "x": 92,
                    "y": 381.5
                },
                {
                    "x": 92,
                    "y": 400.5
                },
                {
                    "x": 92,
                    "y": 412.5
                },
                {
                    "x": 92,
                    "y": 417.5
                },
                {
                    "x": 92,
                    "y": 419.5
                },
                {
                    "x": 92,
                    "y": 420.5
                },
                {
                    "x": 93,
                    "y": 420.5
                },
                {
                    "x": 94,
                    "y": 414.5
                },
                {
                    "x": 95,
                    "y": 402.5
                },
                {
                    "x": 95,
                    "y": 387.5
                },
                {
                    "x": 93,
                    "y": 366.5
                },
                {
                    "x": 90,
                    "y": 338.5
                },
                {
                    "x": 87,
                    "y": 309.5
                },
                {
                    "x": 87,
                    "y": 282.5
                },
                {
                    "x": 87,
                    "y": 258.5
                },
                {
                    "x": 87,
                    "y": 241.5
                },
                {
                    "x": 87,
                    "y": 230.5
                },
                {
                    "x": 88,
                    "y": 225.5
                },
                {
                    "x": 88,
                    "y": 223.5
                },
                {
                    "x": 89,
                    "y": 223.5
                },
                {
                    "x": 90,
                    "y": 223.5
                },
                {
                    "x": 91,
                    "y": 229.5
                },
                {
                    "x": 94,
                    "y": 243.5
                },
                {
                    "x": 96,
                    "y": 265.5
                },
                {
                    "x": 98,
                    "y": 301.5
                },
                {
                    "x": 100,
                    "y": 340.5
                },
                {
                    "x": 100,
                    "y": 378.5
                },
                {
                    "x": 100,
                    "y": 410.5
                },
                {
                    "x": 100,
                    "y": 435.5
                },
                {
                    "x": 101,
                    "y": 447.5
                },
                {
                    "x": 102,
                    "y": 452.5
                },
                {
                    "x": 103,
                    "y": 454.5
                },
                {
                    "x": 104,
                    "y": 452.5
                },
                {
                    "x": 109,
                    "y": 439.5
                },
                {
                    "x": 116,
                    "y": 416.5
                },
                {
                    "x": 127,
                    "y": 375.5
                },
                {
                    "x": 140,
                    "y": 327.5
                },
                {
                    "x": 159,
                    "y": 264.5
                },
                {
                    "x": 180,
                    "y": 204.5
                },
                {
                    "x": 204,
                    "y": 147.5
                },
                {
                    "x": 228,
                    "y": 99.5
                },
                {
                    "x": 250,
                    "y": 64.5
                },
                {
                    "x": 265,
                    "y": 44.5
                },
                {
                    "x": 272,
                    "y": 36.5
                },
                {
                    "x": 275,
                    "y": 33.5
                },
                {
                    "x": 276,
                    "y": 34.5
                },
                {
                    "x": 276,
                    "y": 44.5
                },
                {
                    "x": 273,
                    "y": 65.5
                },
                {
                    "x": 268,
                    "y": 107.5
                },
                {
                    "x": 260,
                    "y": 160.5
                },
                {
                    "x": 256,
                    "y": 220.5
                },
                {
                    "x": 250,
                    "y": 280.5
                },
                {
                    "x": 244,
                    "y": 338.5
                },
                {
                    "x": 240,
                    "y": 388.5
                },
                {
                    "x": 240,
                    "y": 423.5
                },
                {
                    "x": 240,
                    "y": 439.5
                },
                {
                    "x": 241,
                    "y": 446.5
                },
                {
                    "x": 243,
                    "y": 449.5
                },
                {
                    "x": 249,
                    "y": 447.5
                },
                {
                    "x": 259,
                    "y": 437.5
                },
                {
                    "x": 273,
                    "y": 416.5
                },
                {
                    "x": 292,
                    "y": 379.5
                },
                {
                    "x": 309,
                    "y": 335.5
                },
                {
                    "x": 325,
                    "y": 286.5
                },
                {
                    "x": 339,
                    "y": 234.5
                },
                {
                    "x": 348,
                    "y": 186.5
                },
                {
                    "x": 355,
                    "y": 149.5
                },
                {
                    "x": 360,
                    "y": 123.5
                },
                {
                    "x": 363,
                    "y": 112.5
                },
                {
                    "x": 365,
                    "y": 106.5
                },
                {
                    "x": 366,
                    "y": 105.5
                },
                {
                    "x": 366,
                    "y": 110.5
                },
                {
                    "x": 366,
                    "y": 126.5
                },
                {
                    "x": 366,
                    "y": 156.5
                },
                {
                    "x": 366,
                    "y": 199.5
                },
                {
                    "x": 366,
                    "y": 248.5
                },
                {
                    "x": 366,
                    "y": 299.5
                },
                {
                    "x": 366,
                    "y": 346.5
                },
                {
                    "x": 366,
                    "y": 381.5
                },
                {
                    "x": 368,
                    "y": 398.5
                },
                {
                    "x": 368,
                    "y": 405.5
                },
                {
                    "x": 369,
                    "y": 406.5
                },
                {
                    "x": 371,
                    "y": 404.5
                },
                {
                    "x": 375,
                    "y": 393.5
                },
                {
                    "x": 382,
                    "y": 371.5
                },
                {
                    "x": 389,
                    "y": 332.5
                },
                {
                    "x": 397,
                    "y": 287.5
                },
                {
                    "x": 402,
                    "y": 239.5
                },
                {
                    "x": 408,
                    "y": 192.5
                },
                {
                    "x": 413,
                    "y": 153.5
                },
                {
                    "x": 418,
                    "y": 121.5
                },
                {
                    "x": 422,
                    "y": 105.5
                },
                {
                    "x": 424,
                    "y": 97.5
                },
                {
                    "x": 425,
                    "y": 94.5
                },
                {
                    "x": 425,
                    "y": 96.5
                },
                {
                    "x": 423,
                    "y": 107.5
                },
                {
                    "x": 417,
                    "y": 128.5
                },
                {
                    "x": 412,
                    "y": 165.5
                },
                {
                    "x": 404,
                    "y": 210.5
                },
                {
                    "x": 399,
                    "y": 260.5
                },
                {
                    "x": 395,
                    "y": 306.5
                },
                {
                    "x": 393,
                    "y": 342.5
                },
                {
                    "x": 393,
                    "y": 367.5
                },
                {
                    "x": 393,
                    "y": 379.5
                },
                {
                    "x": 393,
                    "y": 383.5
                },
                {
                    "x": 393,
                    "y": 384.5
                },
                {
                    "x": 395,
                    "y": 382.5
                },
                {
                    "x": 400,
                    "y": 372.5
                },
                {
                    "x": 406,
                    "y": 354.5
                },
                {
                    "x": 416,
                    "y": 324.5
                },
                {
                    "x": 427,
                    "y": 286.5
                },
                {
                    "x": 438,
                    "y": 246.5
                },
                {
                    "x": 449,
                    "y": 205.5
                },
                {
                    "x": 458,
                    "y": 173.5
                },
                {
                    "x": 465,
                    "y": 148.5
                },
                {
                    "x": 470,
                    "y": 136.5
                },
                {
                    "x": 473,
                    "y": 128.5
                },
                {
                    "x": 474,
                    "y": 126.5
                },
                {
                    "x": 474,
                    "y": 130.5
                },
                {
                    "x": 474,
                    "y": 144.5
                },
                {
                    "x": 471,
                    "y": 168.5
                },
                {
                    "x": 469,
                    "y": 207.5
                },
                {
                    "x": 467,
                    "y": 251.5
                },
                {
                    "x": 467,
                    "y": 294.5
                },
                {
                    "x": 467,
                    "y": 335.5
                },
                {
                    "x": 467,
                    "y": 365.5
                },
                {
                    "x": 468,
                    "y": 384.5
                },
                {
                    "x": 469,
                    "y": 395.5
                },
                {
                    "x": 472,
                    "y": 401.5
                },
                {
                    "x": 474,
                    "y": 403.5
                },
                {
                    "x": 477,
                    "y": 402.5
                },
                {
                    "x": 482,
                    "y": 396.5
                },
                {
                    "x": 488,
                    "y": 383.5
                },
                {
                    "x": 494,
                    "y": 366.5
                },
                {
                    "x": 499,
                    "y": 339.5
                },
                {
                    "x": 499,
                    "y": 206.5
                },
                {
                    "x": 497,
                    "y": 199.5
                },
                {
                    "x": 495,
                    "y": 197.5
                },
                {
                    "x": 494,
                    "y": 197.5
                },
                {
                    "x": 492,
                    "y": 201.5
                },
                {
                    "x": 488,
                    "y": 212.5
                },
                {
                    "x": 481,
                    "y": 230.5
                },
                {
                    "x": 473,
                    "y": 257.5
                },
                {
                    "x": 461,
                    "y": 288.5
                },
                {
                    "x": 447,
                    "y": 319.5
                },
                {
                    "x": 434,
                    "y": 345.5
                },
                {
                    "x": 423,
                    "y": 363.5
                },
                {
                    "x": 413,
                    "y": 375.5
                },
                {
                    "x": 406,
                    "y": 383.5
                },
                {
                    "x": 400,
                    "y": 387.5
                },
                {
                    "x": 397,
                    "y": 388.5
                },
                {
                    "x": 394,
                    "y": 389.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ff5722",
            "strokeWidth": 12,
            "paths": [
                {
                    "x": 205,
                    "y": 340.5
                },
                {
                    "x": 199,
                    "y": 336.5
                },
                {
                    "x": 194,
                    "y": 334.5
                },
                {
                    "x": 189,
                    "y": 333.5
                },
                {
                    "x": 182,
                    "y": 333.5
                },
                {
                    "x": 175,
                    "y": 334.5
                },
                {
                    "x": 168,
                    "y": 338.5
                },
                {
                    "x": 160,
                    "y": 346.5
                },
                {
                    "x": 153,
                    "y": 355.5
                },
                {
                    "x": 147,
                    "y": 366.5
                },
                {
                    "x": 144,
                    "y": 377.5
                },
                {
                    "x": 144,
                    "y": 386.5
                },
                {
                    "x": 148,
                    "y": 397.5
                },
                {
                    "x": 162,
                    "y": 411.5
                },
                {
                    "x": 189,
                    "y": 428.5
                },
                {
                    "x": 228,
                    "y": 445.5
                },
                {
                    "x": 272,
                    "y": 458.5
                },
                {
                    "x": 312,
                    "y": 467.5
                },
                {
                    "x": 348,
                    "y": 470.5
                },
                {
                    "x": 376,
                    "y": 472.5
                },
                {
                    "x": 393,
                    "y": 472.5
                },
                {
                    "x": 401,
                    "y": 472.5
                },
                {
                    "x": 404,
                    "y": 471.5
                },
                {
                    "x": 404,
                    "y": 470.5
                },
                {
                    "x": 398,
                    "y": 467.5
                },
                {
                    "x": 383,
                    "y": 465.5
                },
                {
                    "x": 352,
                    "y": 462.5
                },
                {
                    "x": 314,
                    "y": 460.5
                },
                {
                    "x": 276,
                    "y": 460.5
                },
                {
                    "x": 243,
                    "y": 460.5
                },
                {
                    "x": 226,
                    "y": 460.5
                },
                {
                    "x": 217,
                    "y": 461.5
                },
                {
                    "x": 212,
                    "y": 462.5
                },
                {
                    "x": 210,
                    "y": 463.5
                },
                {
                    "x": 211,
                    "y": 463.5
                },
                {
                    "x": 215,
                    "y": 460.5
                },
                {
                    "x": 222,
                    "y": 457.5
                },
                {
                    "x": 228,
                    "y": 455.5
                },
                {
                    "x": 230,
                    "y": 453.5
                },
                {
                    "x": 231,
                    "y": 452.5
                },
                {
                    "x": 230,
                    "y": 450.5
                },
                {
                    "x": 227,
                    "y": 448.5
                },
                {
                    "x": 220,
                    "y": 446.5
                },
                {
                    "x": 212,
                    "y": 444.5
                },
                {
                    "x": 203,
                    "y": 441.5
                },
                {
                    "x": 196,
                    "y": 438.5
                },
                {
                    "x": 190,
                    "y": 434.5
                },
                {
                    "x": 187,
                    "y": 427.5
                },
                {
                    "x": 186,
                    "y": 417.5
                },
                {
                    "x": 186,
                    "y": 405.5
                },
                {
                    "x": 191,
                    "y": 394.5
                },
                {
                    "x": 198,
                    "y": 383.5
                },
                {
                    "x": 209,
                    "y": 373.5
                },
                {
                    "x": 217,
                    "y": 364.5
                },
                {
                    "x": 222,
                    "y": 358.5
                },
                {
                    "x": 224,
                    "y": 356.5
                },
                {
                    "x": 224,
                    "y": 354.5
                },
                {
                    "x": 218,
                    "y": 354.5
                },
                {
                    "x": 206,
                    "y": 356.5
                },
                {
                    "x": 190,
                    "y": 362.5
                },
                {
                    "x": 167,
                    "y": 373.5
                },
                {
                    "x": 141,
                    "y": 387.5
                },
                {
                    "x": 112,
                    "y": 403.5
                },
                {
                    "x": 85,
                    "y": 418.5
                },
                {
                    "x": 66,
                    "y": 429.5
                },
                {
                    "x": 56,
                    "y": 436.5
                },
                {
                    "x": 50,
                    "y": 441.5
                },
                {
                    "x": 48,
                    "y": 444.5
                },
                {
                    "x": 48,
                    "y": 447.5
                },
                {
                    "x": 55,
                    "y": 449.5
                },
                {
                    "x": 65,
                    "y": 453.5
                },
                {
                    "x": 78,
                    "y": 455.5
                },
                {
                    "x": 88,
                    "y": 457.5
                },
                {
                    "x": 94,
                    "y": 458.5
                },
                {
                    "x": 95,
                    "y": 458.5
                },
                {
                    "x": 94,
                    "y": 457.5
                },
                {
                    "x": 85,
                    "y": 450.5
                },
                {
                    "x": 74,
                    "y": 440.5
                },
                {
                    "x": 55,
                    "y": 419.5
                },
                {
                    "x": 35,
                    "y": 389.5
                },
                {
                    "x": 20,
                    "y": 360.5
                },
                {
                    "x": 10,
                    "y": 330.5
                },
                {
                    "x": 4,
                    "y": 303.5
                },
                {
                    "x": 4,
                    "y": 226.5
                },
                {
                    "x": 9,
                    "y": 217.5
                },
                {
                    "x": 15,
                    "y": 211.5
                },
                {
                    "x": 21,
                    "y": 208.5
                },
                {
                    "x": 25,
                    "y": 208.5
                },
                {
                    "x": 30,
                    "y": 210.5
                },
                {
                    "x": 35,
                    "y": 217.5
                },
                {
                    "x": 41,
                    "y": 231.5
                },
                {
                    "x": 49,
                    "y": 256.5
                },
                {
                    "x": 55,
                    "y": 283.5
                },
                {
                    "x": 60,
                    "y": 312.5
                },
                {
                    "x": 64,
                    "y": 333.5
                },
                {
                    "x": 66,
                    "y": 345.5
                },
                {
                    "x": 67,
                    "y": 352.5
                },
                {
                    "x": 68,
                    "y": 354.5
                },
                {
                    "x": 68,
                    "y": 355.5
                },
                {
                    "x": 68,
                    "y": 352.5
                },
                {
                    "x": 68,
                    "y": 343.5
                },
                {
                    "x": 68,
                    "y": 328.5
                },
                {
                    "x": 68,
                    "y": 306.5
                },
                {
                    "x": 68,
                    "y": 273.5
                },
                {
                    "x": 68,
                    "y": 238.5
                },
                {
                    "x": 68,
                    "y": 208.5
                },
                {
                    "x": 68,
                    "y": 181.5
                },
                {
                    "x": 68,
                    "y": 159.5
                },
                {
                    "x": 68,
                    "y": 145.5
                },
                {
                    "x": 68,
                    "y": 135.5
                },
                {
                    "x": 68,
                    "y": 132.5
                },
                {
                    "x": 68,
                    "y": 136.5
                },
                {
                    "x": 64,
                    "y": 149.5
                },
                {
                    "x": 59,
                    "y": 166.5
                },
                {
                    "x": 53,
                    "y": 193.5
                },
                {
                    "x": 46,
                    "y": 222.5
                },
                {
                    "x": 42,
                    "y": 243.5
                },
                {
                    "x": 41,
                    "y": 254.5
                },
                {
                    "x": 41,
                    "y": 257.5
                },
                {
                    "x": 42,
                    "y": 256.5
                },
                {
                    "x": 45,
                    "y": 247.5
                },
                {
                    "x": 55,
                    "y": 227.5
                },
                {
                    "x": 71,
                    "y": 193.5
                },
                {
                    "x": 90,
                    "y": 154.5
                },
                {
                    "x": 109,
                    "y": 114.5
                },
                {
                    "x": 127,
                    "y": 75.5
                },
                {
                    "x": 143,
                    "y": 41.5
                },
                {
                    "x": 154,
                    "y": 18.5
                },
                {
                    "x": 160,
                    "y": 5.5
                },
                {
                    "x": 163,
                    "y": 0.5
                },
                {
                    "x": 148,
                    "y": -0.5
                },
                {
                    "x": 130,
                    "y": 5.5
                },
                {
                    "x": 99,
                    "y": 17.5
                },
                {
                    "x": 65,
                    "y": 33.5
                },
                {
                    "x": 39,
                    "y": 46.5
                },
                {
                    "x": 23,
                    "y": 55.5
                },
                {
                    "x": 16,
                    "y": 61.5
                },
                {
                    "x": 14,
                    "y": 62.5
                },
                {
                    "x": 14,
                    "y": 63.5
                },
                {
                    "x": 17,
                    "y": 62.5
                },
                {
                    "x": 28,
                    "y": 58.5
                },
                {
                    "x": 48,
                    "y": 48.5
                },
                {
                    "x": 82,
                    "y": 34.5
                },
                {
                    "x": 121,
                    "y": 17.5
                },
                {
                    "x": 160,
                    "y": 1.5
                },
                {
                    "x": 93,
                    "y": -0.5
                },
                {
                    "x": 80,
                    "y": 3.5
                },
                {
                    "x": 74,
                    "y": 5.5
                },
                {
                    "x": 72,
                    "y": 5.5
                },
                {
                    "x": 74,
                    "y": 5.5
                },
                {
                    "x": 82,
                    "y": 3.5
                },
                {
                    "x": 94,
                    "y": 2.5
                },
                {
                    "x": 106,
                    "y": 2.5
                },
                {
                    "x": 118,
                    "y": 0.5
                },
                {
                    "x": 126,
                    "y": 0.5
                },
                {
                    "x": 131,
                    "y": 0.5
                },
                {
                    "x": 132,
                    "y": 0.5
                },
                {
                    "x": 129,
                    "y": 1.5
                },
                {
                    "x": 120,
                    "y": 3.5
                },
                {
                    "x": 105,
                    "y": 4.5
                },
                {
                    "x": 88,
                    "y": 5.5
                },
                {
                    "x": 71,
                    "y": 6.5
                },
                {
                    "x": 58,
                    "y": 7.5
                },
                {
                    "x": 48,
                    "y": 9.5
                },
                {
                    "x": 46,
                    "y": 9.5
                },
                {
                    "x": 51,
                    "y": 9.5
                },
                {
                    "x": 66,
                    "y": 9.5
                },
                {
                    "x": 94,
                    "y": 9.5
                },
                {
                    "x": 135,
                    "y": 11.5
                },
                {
                    "x": 184,
                    "y": 13.5
                },
                {
                    "x": 231,
                    "y": 15.5
                },
                {
                    "x": 272,
                    "y": 17.5
                },
                {
                    "x": 305,
                    "y": 18.5
                },
                {
                    "x": 320,
                    "y": 19.5
                },
                {
                    "x": 326,
                    "y": 20.5
                },
                {
                    "x": 326,
                    "y": 22.5
                },
                {
                    "x": 320,
                    "y": 24.5
                },
                {
                    "x": 301,
                    "y": 27.5
                },
                {
                    "x": 262,
                    "y": 31.5
                },
                {
                    "x": 213,
                    "y": 32.5
                },
                {
                    "x": 156,
                    "y": 36.5
                },
                {
                    "x": 104,
                    "y": 38.5
                },
                {
                    "x": 63,
                    "y": 40.5
                },
                {
                    "x": 43,
                    "y": 41.5
                },
                {
                    "x": 35,
                    "y": 42.5
                },
                {
                    "x": 34,
                    "y": 42.5
                },
                {
                    "x": 37,
                    "y": 42.5
                },
                {
                    "x": 57,
                    "y": 40.5
                },
                {
                    "x": 104,
                    "y": 36.5
                },
                {
                    "x": 167,
                    "y": 30.5
                },
                {
                    "x": 243,
                    "y": 23.5
                },
                {
                    "x": 319,
                    "y": 19.5
                },
                {
                    "x": 387,
                    "y": 15.5
                },
                {
                    "x": 440,
                    "y": 11.5
                },
                {
                    "x": 470,
                    "y": 10.5
                },
                {
                    "x": 482,
                    "y": 10.5
                },
                {
                    "x": 485,
                    "y": 10.5
                },
                {
                    "x": 484,
                    "y": 10.5
                },
                {
                    "x": 473,
                    "y": 10.5
                },
                {
                    "x": 448,
                    "y": 15.5
                },
                {
                    "x": 404,
                    "y": 20.5
                },
                {
                    "x": 351,
                    "y": 28.5
                },
                {
                    "x": 298,
                    "y": 36.5
                },
                {
                    "x": 258,
                    "y": 43.5
                },
                {
                    "x": 232,
                    "y": 48.5
                },
                {
                    "x": 221,
                    "y": 50.5
                },
                {
                    "x": 217,
                    "y": 51.5
                },
                {
                    "x": 221,
                    "y": 51.5
                },
                {
                    "x": 234,
                    "y": 48.5
                },
                {
                    "x": 261,
                    "y": 43.5
                },
                {
                    "x": 306,
                    "y": 38.5
                },
                {
                    "x": 356,
                    "y": 32.5
                },
                {
                    "x": 397,
                    "y": 28.5
                },
                {
                    "x": 428,
                    "y": 25.5
                },
                {
                    "x": 446,
                    "y": 23.5
                },
                {
                    "x": 455,
                    "y": 21.5
                },
                {
                    "x": 458,
                    "y": 21.5
                },
                {
                    "x": 453,
                    "y": 21.5
                },
                {
                    "x": 438,
                    "y": 23.5
                },
                {
                    "x": 405,
                    "y": 26.5
                },
                {
                    "x": 358,
                    "y": 30.5
                },
                {
                    "x": 303,
                    "y": 34.5
                },
                {
                    "x": 253,
                    "y": 39.5
                },
                {
                    "x": 214,
                    "y": 45.5
                },
                {
                    "x": 190,
                    "y": 51.5
                },
                {
                    "x": 180,
                    "y": 54.5
                },
                {
                    "x": 178,
                    "y": 57.5
                },
                {
                    "x": 182,
                    "y": 61.5
                },
                {
                    "x": 197,
                    "y": 65.5
                },
                {
                    "x": 234,
                    "y": 68.5
                },
                {
                    "x": 286,
                    "y": 72.5
                },
                {
                    "x": 348,
                    "y": 74.5
                },
                {
                    "x": 413,
                    "y": 74.5
                },
                {
                    "x": 475,
                    "y": 74.5
                },
                {
                    "x": 499,
                    "y": 105.5
                },
                {
                    "x": 495,
                    "y": 119.5
                },
                {
                    "x": 493,
                    "y": 130.5
                },
                {
                    "x": 492,
                    "y": 137.5
                },
                {
                    "x": 492,
                    "y": 139.5
                },
                {
                    "x": 493,
                    "y": 136.5
                },
                {
                    "x": 496,
                    "y": 129.5
                },
                {
                    "x": 497,
                    "y": 11.5
                },
                {
                    "x": 493,
                    "y": 34.5
                },
                {
                    "x": 490,
                    "y": 71.5
                },
                {
                    "x": 488,
                    "y": 114.5
                },
                {
                    "x": 488,
                    "y": 158.5
                },
                {
                    "x": 488,
                    "y": 193.5
                },
                {
                    "x": 488,
                    "y": 215.5
                },
                {
                    "x": 488,
                    "y": 225.5
                },
                {
                    "x": 488,
                    "y": 228.5
                },
                {
                    "x": 488,
                    "y": 226.5
                },
                {
                    "x": 490,
                    "y": 219.5
                },
                {
                    "x": 492,
                    "y": 210.5
                },
                {
                    "x": 494,
                    "y": 198.5
                },
                {
                    "x": 495,
                    "y": 184.5
                },
                {
                    "x": 496,
                    "y": 171.5
                },
                {
                    "x": 497,
                    "y": 159.5
                },
                {
                    "x": 498,
                    "y": 150.5
                },
                {
                    "x": 498,
                    "y": 144.5
                },
                {
                    "x": 498,
                    "y": 142.5
                },
                {
                    "x": 498,
                    "y": 141.5
                },
                {
                    "x": 496,
                    "y": 141.5
                },
                {
                    "x": 490,
                    "y": 145.5
                },
                {
                    "x": 483,
                    "y": 152.5
                },
                {
                    "x": 475,
                    "y": 163.5
                },
                {
                    "x": 466,
                    "y": 178.5
                },
                {
                    "x": 456,
                    "y": 201.5
                },
                {
                    "x": 444,
                    "y": 227.5
                },
                {
                    "x": 435,
                    "y": 255.5
                },
                {
                    "x": 427,
                    "y": 282.5
                },
                {
                    "x": 421,
                    "y": 306.5
                },
                {
                    "x": 417,
                    "y": 325.5
                },
                {
                    "x": 414,
                    "y": 338.5
                },
                {
                    "x": 413,
                    "y": 346.5
                },
                {
                    "x": 413,
                    "y": 352.5
                },
                {
                    "x": 413,
                    "y": 354.5
                },
                {
                    "x": 414,
                    "y": 356.5
                },
                {
                    "x": 417,
                    "y": 356.5
                },
                {
                    "x": 419,
                    "y": 357.5
                },
                {
                    "x": 420,
                    "y": 358.5
                },
                {
                    "x": 421,
                    "y": 358.5
                },
                {
                    "x": 421,
                    "y": 359.5
                },
                {
                    "x": 420,
                    "y": 363.5
                },
                {
                    "x": 416,
                    "y": 369.5
                },
                {
                    "x": 411,
                    "y": 377.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#4caf50",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 250,
                    "y": 332.5
                },
                {
                    "x": 247,
                    "y": 324.5
                },
                {
                    "x": 244,
                    "y": 319.5
                },
                {
                    "x": 243,
                    "y": 318.5
                },
                {
                    "x": 241,
                    "y": 317.5
                },
                {
                    "x": 240,
                    "y": 318.5
                },
                {
                    "x": 238,
                    "y": 322.5
                },
                {
                    "x": 236,
                    "y": 331.5
                },
                {
                    "x": 235,
                    "y": 343.5
                },
                {
                    "x": 235,
                    "y": 360.5
                },
                {
                    "x": 235,
                    "y": 379.5
                },
                {
                    "x": 235,
                    "y": 401.5
                },
                {
                    "x": 235,
                    "y": 420.5
                },
                {
                    "x": 235,
                    "y": 436.5
                },
                {
                    "x": 235,
                    "y": 447.5
                },
                {
                    "x": 235,
                    "y": 452.5
                },
                {
                    "x": 235,
                    "y": 455.5
                },
                {
                    "x": 236,
                    "y": 454.5
                },
                {
                    "x": 241,
                    "y": 450.5
                },
                {
                    "x": 248,
                    "y": 442.5
                },
                {
                    "x": 257,
                    "y": 433.5
                },
                {
                    "x": 265,
                    "y": 423.5
                },
                {
                    "x": 274,
                    "y": 413.5
                },
                {
                    "x": 280,
                    "y": 403.5
                },
                {
                    "x": 286,
                    "y": 393.5
                },
                {
                    "x": 289,
                    "y": 384.5
                },
                {
                    "x": 291,
                    "y": 374.5
                },
                {
                    "x": 291,
                    "y": 365.5
                },
                {
                    "x": 289,
                    "y": 355.5
                },
                {
                    "x": 282,
                    "y": 345.5
                },
                {
                    "x": 275,
                    "y": 337.5
                },
                {
                    "x": 266,
                    "y": 329.5
                },
                {
                    "x": 260,
                    "y": 323.5
                },
                {
                    "x": 255,
                    "y": 319.5
                },
                {
                    "x": 253,
                    "y": 317.5
                },
                {
                    "x": 251,
                    "y": 317.5
                },
                {
                    "x": 250,
                    "y": 316.5
                },
                {
                    "x": 249,
                    "y": 315.5
                },
                {
                    "x": 247,
                    "y": 314.5
                },
                {
                    "x": 246,
                    "y": 311.5
                },
                {
                    "x": 244,
                    "y": 306.5
                },
                {
                    "x": 242,
                    "y": 297.5
                },
                {
                    "x": 239,
                    "y": 285.5
                },
                {
                    "x": 237,
                    "y": 272.5
                },
                {
                    "x": 234,
                    "y": 263.5
                },
                {
                    "x": 230,
                    "y": 255.5
                },
                {
                    "x": 226,
                    "y": 251.5
                },
                {
                    "x": 223,
                    "y": 248.5
                },
                {
                    "x": 219,
                    "y": 247.5
                },
                {
                    "x": 216,
                    "y": 247.5
                },
                {
                    "x": 212,
                    "y": 250.5
                },
                {
                    "x": 208,
                    "y": 253.5
                },
                {
                    "x": 205,
                    "y": 258.5
                },
                {
                    "x": 204,
                    "y": 261.5
                },
                {
                    "x": 204,
                    "y": 264.5
                },
                {
                    "x": 205,
                    "y": 265.5
                },
                {
                    "x": 209,
                    "y": 265.5
                },
                {
                    "x": 217,
                    "y": 262.5
                },
                {
                    "x": 232,
                    "y": 253.5
                },
                {
                    "x": 251,
                    "y": 240.5
                },
                {
                    "x": 276,
                    "y": 224.5
                },
                {
                    "x": 301,
                    "y": 205.5
                },
                {
                    "x": 318,
                    "y": 191.5
                },
                {
                    "x": 330,
                    "y": 180.5
                },
                {
                    "x": 335,
                    "y": 175.5
                },
                {
                    "x": 337,
                    "y": 173.5
                },
                {
                    "x": 335,
                    "y": 171.5
                },
                {
                    "x": 324,
                    "y": 171.5
                },
                {
                    "x": 303,
                    "y": 175.5
                },
                {
                    "x": 268,
                    "y": 184.5
                },
                {
                    "x": 222,
                    "y": 193.5
                },
                {
                    "x": 174,
                    "y": 207.5
                },
                {
                    "x": 125,
                    "y": 221.5
                },
                {
                    "x": 85,
                    "y": 230.5
                },
                {
                    "x": 53,
                    "y": 236.5
                },
                {
                    "x": 37,
                    "y": 240.5
                },
                {
                    "x": 30,
                    "y": 241.5
                },
                {
                    "x": 28,
                    "y": 242.5
                },
                {
                    "x": 35,
                    "y": 242.5
                },
                {
                    "x": 52,
                    "y": 242.5
                },
                {
                    "x": 84,
                    "y": 242.5
                },
                {
                    "x": 128,
                    "y": 244.5
                },
                {
                    "x": 180,
                    "y": 247.5
                },
                {
                    "x": 236,
                    "y": 255.5
                },
                {
                    "x": 284,
                    "y": 267.5
                },
                {
                    "x": 320,
                    "y": 280.5
                },
                {
                    "x": 340,
                    "y": 288.5
                },
                {
                    "x": 348,
                    "y": 292.5
                },
                {
                    "x": 350,
                    "y": 296.5
                },
                {
                    "x": 346,
                    "y": 300.5
                },
                {
                    "x": 331,
                    "y": 303.5
                },
                {
                    "x": 303,
                    "y": 306.5
                },
                {
                    "x": 264,
                    "y": 310.5
                },
                {
                    "x": 222,
                    "y": 313.5
                },
                {
                    "x": 188,
                    "y": 319.5
                },
                {
                    "x": 169,
                    "y": 324.5
                },
                {
                    "x": 158,
                    "y": 328.5
                },
                {
                    "x": 153,
                    "y": 333.5
                },
                {
                    "x": 152,
                    "y": 339.5
                },
                {
                    "x": 155,
                    "y": 347.5
                },
                {
                    "x": 167,
                    "y": 357.5
                },
                {
                    "x": 189,
                    "y": 364.5
                },
                {
                    "x": 226,
                    "y": 369.5
                },
                {
                    "x": 270,
                    "y": 371.5
                },
                {
                    "x": 313,
                    "y": 371.5
                },
                {
                    "x": 347,
                    "y": 367.5
                },
                {
                    "x": 371,
                    "y": 361.5
                },
                {
                    "x": 383,
                    "y": 357.5
                },
                {
                    "x": 388,
                    "y": 353.5
                },
                {
                    "x": 389,
                    "y": 350.5
                },
                {
                    "x": 387,
                    "y": 347.5
                },
                {
                    "x": 377,
                    "y": 344.5
                },
                {
                    "x": 360,
                    "y": 341.5
                },
                {
                    "x": 330,
                    "y": 340.5
                },
                {
                    "x": 294,
                    "y": 340.5
                },
                {
                    "x": 262,
                    "y": 340.5
                },
                {
                    "x": 234,
                    "y": 340.5
                },
                {
                    "x": 218,
                    "y": 340.5
                },
                {
                    "x": 207,
                    "y": 339.5
                },
                {
                    "x": 201,
                    "y": 337.5
                },
                {
                    "x": 199,
                    "y": 332.5
                },
                {
                    "x": 199,
                    "y": 324.5
                },
                {
                    "x": 207,
                    "y": 311.5
                },
                {
                    "x": 226,
                    "y": 284.5
                },
                {
                    "x": 258,
                    "y": 250.5
                },
                {
                    "x": 297,
                    "y": 212.5
                },
                {
                    "x": 341,
                    "y": 174.5
                },
                {
                    "x": 388,
                    "y": 135.5
                },
                {
                    "x": 432,
                    "y": 101.5
                },
                {
                    "x": 468,
                    "y": 73.5
                },
                {
                    "x": 493,
                    "y": 55.5
                },
                {
                    "x": 485,
                    "y": 48.5
                },
                {
                    "x": 449,
                    "y": 60.5
                },
                {
                    "x": 395,
                    "y": 78.5
                },
                {
                    "x": 330,
                    "y": 99.5
                },
                {
                    "x": 259,
                    "y": 123.5
                },
                {
                    "x": 188,
                    "y": 147.5
                },
                {
                    "x": 131,
                    "y": 167.5
                },
                {
                    "x": 92,
                    "y": 182.5
                },
                {
                    "x": 75,
                    "y": 190.5
                },
                {
                    "x": 70,
                    "y": 194.5
                },
                {
                    "x": 72,
                    "y": 195.5
                },
                {
                    "x": 85,
                    "y": 195.5
                },
                {
                    "x": 113,
                    "y": 190.5
                },
                {
                    "x": 161,
                    "y": 178.5
                },
                {
                    "x": 226,
                    "y": 161.5
                },
                {
                    "x": 296,
                    "y": 140.5
                },
                {
                    "x": 364,
                    "y": 119.5
                },
                {
                    "x": 421,
                    "y": 98.5
                },
                {
                    "x": 463,
                    "y": 77.5
                },
                {
                    "x": 485,
                    "y": 62.5
                },
                {
                    "x": 491,
                    "y": 53.5
                },
                {
                    "x": 486,
                    "y": 46.5
                },
                {
                    "x": 474,
                    "y": 41.5
                },
                {
                    "x": 449,
                    "y": 37.5
                },
                {
                    "x": 403,
                    "y": 35.5
                },
                {
                    "x": 346,
                    "y": 35.5
                },
                {
                    "x": 291,
                    "y": 38.5
                },
                {
                    "x": 246,
                    "y": 45.5
                },
                {
                    "x": 219,
                    "y": 52.5
                },
                {
                    "x": 209,
                    "y": 55.5
                },
                {
                    "x": 207,
                    "y": 57.5
                },
                {
                    "x": 208,
                    "y": 60.5
                },
                {
                    "x": 217,
                    "y": 62.5
                },
                {
                    "x": 233,
                    "y": 63.5
                },
                {
                    "x": 264,
                    "y": 65.5
                },
                {
                    "x": 296,
                    "y": 65.5
                },
                {
                    "x": 329,
                    "y": 65.5
                },
                {
                    "x": 351,
                    "y": 65.5
                },
                {
                    "x": 362,
                    "y": 65.5
                },
                {
                    "x": 366,
                    "y": 65.5
                },
                {
                    "x": 365,
                    "y": 65.5
                },
                {
                    "x": 356,
                    "y": 65.5
                },
                {
                    "x": 337,
                    "y": 68.5
                },
                {
                    "x": 303,
                    "y": 71.5
                },
                {
                    "x": 264,
                    "y": 77.5
                },
                {
                    "x": 229,
                    "y": 85.5
                },
                {
                    "x": 202,
                    "y": 93.5
                },
                {
                    "x": 186,
                    "y": 101.5
                },
                {
                    "x": 178,
                    "y": 109.5
                },
                {
                    "x": 173,
                    "y": 117.5
                },
                {
                    "x": 173,
                    "y": 125.5
                },
                {
                    "x": 178,
                    "y": 133.5
                },
                {
                    "x": 186,
                    "y": 139.5
                },
                {
                    "x": 199,
                    "y": 144.5
                },
                {
                    "x": 211,
                    "y": 146.5
                },
                {
                    "x": 221,
                    "y": 147.5
                },
                {
                    "x": 228,
                    "y": 147.5
                },
                {
                    "x": 232,
                    "y": 147.5
                },
                {
                    "x": 234,
                    "y": 146.5
                },
                {
                    "x": 234,
                    "y": 144.5
                },
                {
                    "x": 232,
                    "y": 139.5
                },
                {
                    "x": 225,
                    "y": 132.5
                },
                {
                    "x": 215,
                    "y": 123.5
                },
                {
                    "x": 199,
                    "y": 113.5
                },
                {
                    "x": 176,
                    "y": 100.5
                },
                {
                    "x": 148,
                    "y": 88.5
                },
                {
                    "x": 117,
                    "y": 78.5
                },
                {
                    "x": 91,
                    "y": 74.5
                },
                {
                    "x": 72,
                    "y": 74.5
                },
                {
                    "x": 59,
                    "y": 75.5
                },
                {
                    "x": 49,
                    "y": 83.5
                },
                {
                    "x": 44,
                    "y": 95.5
                },
                {
                    "x": 44,
                    "y": 113.5
                },
                {
                    "x": 55,
                    "y": 144.5
                },
                {
                    "x": 75,
                    "y": 176.5
                },
                {
                    "x": 101,
                    "y": 202.5
                },
                {
                    "x": 128,
                    "y": 219.5
                },
                {
                    "x": 151,
                    "y": 228.5
                },
                {
                    "x": 168,
                    "y": 231.5
                },
                {
                    "x": 181,
                    "y": 230.5
                },
                {
                    "x": 190,
                    "y": 224.5
                },
                {
                    "x": 198,
                    "y": 215.5
                },
                {
                    "x": 205,
                    "y": 204.5
                },
                {
                    "x": 210,
                    "y": 192.5
                },
                {
                    "x": 214,
                    "y": 180.5
                },
                {
                    "x": 216,
                    "y": 169.5
                },
                {
                    "x": 216,
                    "y": 158.5
                },
                {
                    "x": 214,
                    "y": 148.5
                },
                {
                    "x": 208,
                    "y": 141.5
                },
                {
                    "x": 203,
                    "y": 136.5
                },
                {
                    "x": 199,
                    "y": 133.5
                },
                {
                    "x": 195,
                    "y": 133.5
                },
                {
                    "x": 190,
                    "y": 135.5
                },
                {
                    "x": 185,
                    "y": 143.5
                },
                {
                    "x": 180,
                    "y": 155.5
                },
                {
                    "x": 177,
                    "y": 172.5
                },
                {
                    "x": 174,
                    "y": 198.5
                },
                {
                    "x": 171,
                    "y": 224.5
                },
                {
                    "x": 168,
                    "y": 247.5
                },
                {
                    "x": 165,
                    "y": 260.5
                },
                {
                    "x": 164,
                    "y": 268.5
                },
                {
                    "x": 164,
                    "y": 271.5
                },
                {
                    "x": 164,
                    "y": 272.5
                },
                {
                    "x": 164,
                    "y": 266.5
                },
                {
                    "x": 165,
                    "y": 255.5
                },
                {
                    "x": 168,
                    "y": 239.5
                },
                {
                    "x": 175,
                    "y": 220.5
                },
                {
                    "x": 183,
                    "y": 197.5
                },
                {
                    "x": 192,
                    "y": 175.5
                },
                {
                    "x": 198,
                    "y": 153.5
                },
                {
                    "x": 202,
                    "y": 135.5
                },
                {
                    "x": 203,
                    "y": 117.5
                },
                {
                    "x": 203,
                    "y": 104.5
                },
                {
                    "x": 200,
                    "y": 96.5
                },
                {
                    "x": 195,
                    "y": 91.5
                },
                {
                    "x": 186,
                    "y": 89.5
                },
                {
                    "x": 176,
                    "y": 90.5
                },
                {
                    "x": 163,
                    "y": 98.5
                },
                {
                    "x": 149,
                    "y": 113.5
                },
                {
                    "x": 138,
                    "y": 139.5
                },
                {
                    "x": 131,
                    "y": 176.5
                },
                {
                    "x": 131,
                    "y": 220.5
                },
                {
                    "x": 143,
                    "y": 265.5
                },
                {
                    "x": 164,
                    "y": 308.5
                },
                {
                    "x": 187,
                    "y": 338.5
                },
                {
                    "x": 210,
                    "y": 358.5
                },
                {
                    "x": 231,
                    "y": 368.5
                },
                {
                    "x": 249,
                    "y": 372.5
                },
                {
                    "x": 270,
                    "y": 370.5
                },
                {
                    "x": 297,
                    "y": 360.5
                },
                {
                    "x": 330,
                    "y": 341.5
                },
                {
                    "x": 360,
                    "y": 317.5
                },
                {
                    "x": 384,
                    "y": 292.5
                },
                {
                    "x": 401,
                    "y": 267.5
                },
                {
                    "x": 408,
                    "y": 245.5
                },
                {
                    "x": 409,
                    "y": 225.5
                },
                {
                    "x": 404,
                    "y": 208.5
                },
                {
                    "x": 394,
                    "y": 196.5
                },
                {
                    "x": 380,
                    "y": 186.5
                },
                {
                    "x": 363,
                    "y": 179.5
                },
                {
                    "x": 342,
                    "y": 176.5
                },
                {
                    "x": 322,
                    "y": 178.5
                },
                {
                    "x": 304,
                    "y": 188.5
                },
                {
                    "x": 289,
                    "y": 205.5
                },
                {
                    "x": 279,
                    "y": 236.5
                },
                {
                    "x": 276,
                    "y": 269.5
                },
                {
                    "x": 278,
                    "y": 308.5
                },
                {
                    "x": 289,
                    "y": 344.5
                },
                {
                    "x": 306,
                    "y": 370.5
                },
                {
                    "x": 318,
                    "y": 383.5
                },
                {
                    "x": 330,
                    "y": 388.5
                },
                {
                    "x": 340,
                    "y": 388.5
                },
                {
                    "x": 353,
                    "y": 381.5
                },
                {
                    "x": 366,
                    "y": 368.5
                },
                {
                    "x": 377,
                    "y": 349.5
                },
                {
                    "x": 386,
                    "y": 326.5
                },
                {
                    "x": 391,
                    "y": 303.5
                },
                {
                    "x": 391,
                    "y": 283.5
                },
                {
                    "x": 389,
                    "y": 266.5
                },
                {
                    "x": 384,
                    "y": 253.5
                },
                {
                    "x": 374,
                    "y": 242.5
                },
                {
                    "x": 364,
                    "y": 234.5
                },
                {
                    "x": 353,
                    "y": 228.5
                },
                {
                    "x": 342,
                    "y": 226.5
                },
                {
                    "x": 330,
                    "y": 226.5
                },
                {
                    "x": 321,
                    "y": 231.5
                },
                {
                    "x": 313,
                    "y": 239.5
                },
                {
                    "x": 307,
                    "y": 252.5
                },
                {
                    "x": 305,
                    "y": 267.5
                },
                {
                    "x": 305,
                    "y": 281.5
                },
                {
                    "x": 311,
                    "y": 296.5
                },
                {
                    "x": 320,
                    "y": 306.5
                },
                {
                    "x": 330,
                    "y": 312.5
                },
                {
                    "x": 340,
                    "y": 314.5
                },
                {
                    "x": 350,
                    "y": 314.5
                },
                {
                    "x": 361,
                    "y": 311.5
                },
                {
                    "x": 370,
                    "y": 304.5
                },
                {
                    "x": 378,
                    "y": 296.5
                },
                {
                    "x": 383,
                    "y": 286.5
                },
                {
                    "x": 386,
                    "y": 276.5
                },
                {
                    "x": 388,
                    "y": 269.5
                },
                {
                    "x": 390,
                    "y": 265.5
                },
                {
                    "x": 390,
                    "y": 262.5
                },
                {
                    "x": 388,
                    "y": 263.5
                },
                {
                    "x": 382,
                    "y": 268.5
                },
                {
                    "x": 374,
                    "y": 277.5
                },
                {
                    "x": 364,
                    "y": 292.5
                },
                {
                    "x": 355,
                    "y": 312.5
                },
                {
                    "x": 349,
                    "y": 329.5
                },
                {
                    "x": 344,
                    "y": 340.5
                },
                {
                    "x": 342,
                    "y": 346.5
                },
                {
                    "x": 342,
                    "y": 348.5
                },
                {
                    "x": 342,
                    "y": 349.5
                },
                {
                    "x": 344,
                    "y": 349.5
                },
                {
                    "x": 350,
                    "y": 348.5
                },
                {
                    "x": 361,
                    "y": 347.5
                },
                {
                    "x": 372,
                    "y": 347.5
                },
                {
                    "x": 382,
                    "y": 347.5
                },
                {
                    "x": 390,
                    "y": 346.5
                },
                {
                    "x": 396,
                    "y": 346.5
                },
                {
                    "x": 399,
                    "y": 345.5
                },
                {
                    "x": 400,
                    "y": 344.5
                },
                {
                    "x": 396,
                    "y": 343.5
                },
                {
                    "x": 384,
                    "y": 343.5
                },
                {
                    "x": 364,
                    "y": 343.5
                },
                {
                    "x": 330,
                    "y": 345.5
                },
                {
                    "x": 292,
                    "y": 356.5
                },
                {
                    "x": 255,
                    "y": 375.5
                },
                {
                    "x": 225,
                    "y": 396.5
                },
                {
                    "x": 207,
                    "y": 421.5
                },
                {
                    "x": 197,
                    "y": 446.5
                },
                {
                    "x": 193,
                    "y": 467.5
                },
                {
                    "x": 193,
                    "y": 479.5
                },
                {
                    "x": 196,
                    "y": 485.5
                },
                {
                    "x": 201,
                    "y": 487.5
                },
                {
                    "x": 208,
                    "y": 484.5
                },
                {
                    "x": 218,
                    "y": 475.5
                },
                {
                    "x": 231,
                    "y": 461.5
                },
                {
                    "x": 248,
                    "y": 441.5
                },
                {
                    "x": 264,
                    "y": 416.5
                },
                {
                    "x": 277,
                    "y": 393.5
                },
                {
                    "x": 286,
                    "y": 370.5
                },
                {
                    "x": 289,
                    "y": 354.5
                },
                {
                    "x": 289,
                    "y": 342.5
                },
                {
                    "x": 284,
                    "y": 333.5
                },
                {
                    "x": 275,
                    "y": 327.5
                },
                {
                    "x": 262,
                    "y": 323.5
                },
                {
                    "x": 241,
                    "y": 321.5
                },
                {
                    "x": 216,
                    "y": 321.5
                },
                {
                    "x": 191,
                    "y": 329.5
                },
                {
                    "x": 172,
                    "y": 342.5
                },
                {
                    "x": 160,
                    "y": 361.5
                },
                {
                    "x": 156,
                    "y": 380.5
                },
                {
                    "x": 156,
                    "y": 396.5
                },
                {
                    "x": 160,
                    "y": 408.5
                },
                {
                    "x": 166,
                    "y": 416.5
                },
                {
                    "x": 176,
                    "y": 420.5
                },
                {
                    "x": 188,
                    "y": 421.5
                },
                {
                    "x": 201,
                    "y": 420.5
                },
                {
                    "x": 217,
                    "y": 414.5
                },
                {
                    "x": 233,
                    "y": 405.5
                },
                {
                    "x": 248,
                    "y": 393.5
                },
                {
                    "x": 260,
                    "y": 378.5
                },
                {
                    "x": 265,
                    "y": 366.5
                },
                {
                    "x": 268,
                    "y": 355.5
                },
                {
                    "x": 266,
                    "y": 345.5
                },
                {
                    "x": 260,
                    "y": 338.5
                },
                {
                    "x": 252,
                    "y": 334.5
                },
                {
                    "x": 238,
                    "y": 331.5
                },
                {
                    "x": 218,
                    "y": 331.5
                },
                {
                    "x": 189,
                    "y": 335.5
                },
                {
                    "x": 159,
                    "y": 345.5
                },
                {
                    "x": 132,
                    "y": 360.5
                },
                {
                    "x": 112,
                    "y": 379.5
                },
                {
                    "x": 100,
                    "y": 400.5
                },
                {
                    "x": 94,
                    "y": 415.5
                },
                {
                    "x": 94,
                    "y": 424.5
                },
                {
                    "x": 95,
                    "y": 430.5
                },
                {
                    "x": 98,
                    "y": 433.5
                },
                {
                    "x": 106,
                    "y": 433.5
                },
                {
                    "x": 118,
                    "y": 428.5
                },
                {
                    "x": 137,
                    "y": 416.5
                },
                {
                    "x": 162,
                    "y": 398.5
                },
                {
                    "x": 188,
                    "y": 374.5
                },
                {
                    "x": 209,
                    "y": 346.5
                },
                {
                    "x": 223,
                    "y": 322.5
                },
                {
                    "x": 232,
                    "y": 302.5
                },
                {
                    "x": 235,
                    "y": 288.5
                },
                {
                    "x": 235,
                    "y": 279.5
                },
                {
                    "x": 234,
                    "y": 273.5
                },
                {
                    "x": 231,
                    "y": 270.5
                },
                {
                    "x": 223,
                    "y": 269.5
                },
                {
                    "x": 211,
                    "y": 269.5
                },
                {
                    "x": 197,
                    "y": 274.5
                },
                {
                    "x": 182,
                    "y": 282.5
                },
                {
                    "x": 167,
                    "y": 294.5
                },
                {
                    "x": 157,
                    "y": 304.5
                },
                {
                    "x": 151,
                    "y": 314.5
                },
                {
                    "x": 148,
                    "y": 321.5
                },
                {
                    "x": 148,
                    "y": 325.5
                },
                {
                    "x": 149,
                    "y": 327.5
                },
                {
                    "x": 152,
                    "y": 328.5
                },
                {
                    "x": 159,
                    "y": 328.5
                },
                {
                    "x": 169,
                    "y": 328.5
                },
                {
                    "x": 181,
                    "y": 328.5
                },
                {
                    "x": 192,
                    "y": 328.5
                },
                {
                    "x": 200,
                    "y": 328.5
                },
                {
                    "x": 207,
                    "y": 330.5
                },
                {
                    "x": 212,
                    "y": 333.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#00bcd4",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 142,
                    "y": 255.5
                },
                {
                    "x": 135,
                    "y": 253.5
                },
                {
                    "x": 129,
                    "y": 253.5
                },
                {
                    "x": 123,
                    "y": 255.5
                },
                {
                    "x": 117,
                    "y": 260.5
                },
                {
                    "x": 111,
                    "y": 269.5
                },
                {
                    "x": 107,
                    "y": 279.5
                },
                {
                    "x": 102,
                    "y": 292.5
                },
                {
                    "x": 99,
                    "y": 305.5
                },
                {
                    "x": 96,
                    "y": 318.5
                },
                {
                    "x": 93,
                    "y": 332.5
                },
                {
                    "x": 91,
                    "y": 344.5
                },
                {
                    "x": 89,
                    "y": 355.5
                },
                {
                    "x": 89,
                    "y": 363.5
                },
                {
                    "x": 89,
                    "y": 369.5
                },
                {
                    "x": 89,
                    "y": 376.5
                },
                {
                    "x": 90,
                    "y": 383.5
                },
                {
                    "x": 92,
                    "y": 389.5
                },
                {
                    "x": 94,
                    "y": 392.5
                },
                {
                    "x": 95,
                    "y": 394.5
                },
                {
                    "x": 96,
                    "y": 395.5
                },
                {
                    "x": 96,
                    "y": 393.5
                },
                {
                    "x": 96,
                    "y": 387.5
                },
                {
                    "x": 94,
                    "y": 375.5
                },
                {
                    "x": 91,
                    "y": 358.5
                },
                {
                    "x": 86,
                    "y": 334.5
                },
                {
                    "x": 79,
                    "y": 309.5
                },
                {
                    "x": 69,
                    "y": 284.5
                },
                {
                    "x": 58,
                    "y": 258.5
                },
                {
                    "x": 45,
                    "y": 232.5
                },
                {
                    "x": 33,
                    "y": 211.5
                },
                {
                    "x": 23,
                    "y": 199.5
                },
                {
                    "x": 17,
                    "y": 192.5
                },
                {
                    "x": 15,
                    "y": 190.5
                },
                {
                    "x": 13,
                    "y": 190.5
                },
                {
                    "x": 13,
                    "y": 194.5
                },
                {
                    "x": 16,
                    "y": 207.5
                },
                {
                    "x": 24,
                    "y": 229.5
                },
                {
                    "x": 42,
                    "y": 266.5
                },
                {
                    "x": 69,
                    "y": 307.5
                },
                {
                    "x": 99,
                    "y": 347.5
                },
                {
                    "x": 126,
                    "y": 378.5
                },
                {
                    "x": 146,
                    "y": 395.5
                },
                {
                    "x": 159,
                    "y": 403.5
                },
                {
                    "x": 170,
                    "y": 404.5
                },
                {
                    "x": 179,
                    "y": 401.5
                },
                {
                    "x": 189,
                    "y": 394.5
                },
                {
                    "x": 199,
                    "y": 382.5
                },
                {
                    "x": 207,
                    "y": 364.5
                },
                {
                    "x": 213,
                    "y": 343.5
                },
                {
                    "x": 216,
                    "y": 320.5
                },
                {
                    "x": 216,
                    "y": 298.5
                },
                {
                    "x": 212,
                    "y": 274.5
                },
                {
                    "x": 205,
                    "y": 254.5
                },
                {
                    "x": 198,
                    "y": 241.5
                },
                {
                    "x": 191,
                    "y": 231.5
                },
                {
                    "x": 184,
                    "y": 225.5
                },
                {
                    "x": 178,
                    "y": 221.5
                },
                {
                    "x": 173,
                    "y": 218.5
                },
                {
                    "x": 169,
                    "y": 217.5
                },
                {
                    "x": 166,
                    "y": 217.5
                },
                {
                    "x": 163,
                    "y": 219.5
                },
                {
                    "x": 163,
                    "y": 222.5
                },
                {
                    "x": 164,
                    "y": 229.5
                },
                {
                    "x": 172,
                    "y": 237.5
                },
                {
                    "x": 184,
                    "y": 248.5
                },
                {
                    "x": 197,
                    "y": 257.5
                },
                {
                    "x": 215,
                    "y": 265.5
                },
                {
                    "x": 231,
                    "y": 269.5
                },
                {
                    "x": 244,
                    "y": 270.5
                },
                {
                    "x": 258,
                    "y": 268.5
                },
                {
                    "x": 272,
                    "y": 263.5
                },
                {
                    "x": 284,
                    "y": 253.5
                },
                {
                    "x": 293,
                    "y": 241.5
                },
                {
                    "x": 299,
                    "y": 229.5
                },
                {
                    "x": 301,
                    "y": 218.5
                },
                {
                    "x": 300,
                    "y": 209.5
                },
                {
                    "x": 295,
                    "y": 201.5
                },
                {
                    "x": 286,
                    "y": 197.5
                },
                {
                    "x": 273,
                    "y": 195.5
                },
                {
                    "x": 251,
                    "y": 195.5
                },
                {
                    "x": 221,
                    "y": 200.5
                },
                {
                    "x": 188,
                    "y": 214.5
                },
                {
                    "x": 160,
                    "y": 235.5
                },
                {
                    "x": 139,
                    "y": 263.5
                },
                {
                    "x": 127,
                    "y": 296.5
                },
                {
                    "x": 122,
                    "y": 330.5
                },
                {
                    "x": 122,
                    "y": 355.5
                },
                {
                    "x": 127,
                    "y": 369.5
                },
                {
                    "x": 133,
                    "y": 376.5
                },
                {
                    "x": 140,
                    "y": 378.5
                },
                {
                    "x": 150,
                    "y": 378.5
                },
                {
                    "x": 162,
                    "y": 375.5
                },
                {
                    "x": 177,
                    "y": 372.5
                },
                {
                    "x": 196,
                    "y": 368.5
                },
                {
                    "x": 214,
                    "y": 365.5
                },
                {
                    "x": 227,
                    "y": 364.5
                },
                {
                    "x": 236,
                    "y": 364.5
                },
                {
                    "x": 243,
                    "y": 364.5
                },
                {
                    "x": 246,
                    "y": 364.5
                },
                {
                    "x": 248,
                    "y": 365.5
                },
                {
                    "x": 248,
                    "y": 367.5
                },
                {
                    "x": 245,
                    "y": 371.5
                },
                {
                    "x": 239,
                    "y": 375.5
                },
                {
                    "x": 231,
                    "y": 378.5
                },
                {
                    "x": 221,
                    "y": 380.5
                },
                {
                    "x": 213,
                    "y": 382.5
                },
                {
                    "x": 207,
                    "y": 383.5
                },
                {
                    "x": 206,
                    "y": 384.5
                },
                {
                    "x": 206,
                    "y": 385.5
                },
                {
                    "x": 207,
                    "y": 387.5
                },
                {
                    "x": 215,
                    "y": 390.5
                },
                {
                    "x": 233,
                    "y": 397.5
                },
                {
                    "x": 266,
                    "y": 407.5
                },
                {
                    "x": 303,
                    "y": 418.5
                },
                {
                    "x": 341,
                    "y": 425.5
                },
                {
                    "x": 377,
                    "y": 428.5
                },
                {
                    "x": 405,
                    "y": 430.5
                },
                {
                    "x": 423,
                    "y": 428.5
                },
                {
                    "x": 432,
                    "y": 424.5
                },
                {
                    "x": 437,
                    "y": 419.5
                },
                {
                    "x": 437,
                    "y": 415.5
                },
                {
                    "x": 433,
                    "y": 409.5
                },
                {
                    "x": 422,
                    "y": 402.5
                },
                {
                    "x": 404,
                    "y": 394.5
                },
                {
                    "x": 379,
                    "y": 387.5
                },
                {
                    "x": 353,
                    "y": 382.5
                },
                {
                    "x": 330,
                    "y": 379.5
                },
                {
                    "x": 313,
                    "y": 379.5
                },
                {
                    "x": 302,
                    "y": 379.5
                },
                {
                    "x": 297,
                    "y": 381.5
                },
                {
                    "x": 294,
                    "y": 383.5
                },
                {
                    "x": 294,
                    "y": 386.5
                },
                {
                    "x": 296,
                    "y": 389.5
                },
                {
                    "x": 302,
                    "y": 392.5
                },
                {
                    "x": 311,
                    "y": 394.5
                },
                {
                    "x": 322,
                    "y": 397.5
                },
                {
                    "x": 334,
                    "y": 399.5
                },
                {
                    "x": 344,
                    "y": 402.5
                },
                {
                    "x": 351,
                    "y": 404.5
                },
                {
                    "x": 354,
                    "y": 405.5
                },
                {
                    "x": 356,
                    "y": 408.5
                },
                {
                    "x": 356,
                    "y": 411.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ffdbac",
            "strokeWidth": 4,
            "paths": [
                {
                    "x": 190,
                    "y": 434.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ffdbac",
            "strokeWidth": 8,
            "paths": [
                {
                    "x": 175,
                    "y": 205.5
                },
                {
                    "x": 167,
                    "y": 204.5
                },
                {
                    "x": 160,
                    "y": 205.5
                },
                {
                    "x": 155,
                    "y": 212.5
                },
                {
                    "x": 151,
                    "y": 222.5
                },
                {
                    "x": 149,
                    "y": 237.5
                },
                {
                    "x": 149,
                    "y": 259.5
                },
                {
                    "x": 153,
                    "y": 286.5
                },
                {
                    "x": 159,
                    "y": 315.5
                },
                {
                    "x": 165,
                    "y": 342.5
                },
                {
                    "x": 171,
                    "y": 361.5
                },
                {
                    "x": 174,
                    "y": 372.5
                },
                {
                    "x": 176,
                    "y": 376.5
                },
                {
                    "x": 177,
                    "y": 379.5
                },
                {
                    "x": 178,
                    "y": 379.5
                },
                {
                    "x": 179,
                    "y": 379.5
                },
                {
                    "x": 179,
                    "y": 378.5
                },
                {
                    "x": 180,
                    "y": 372.5
                },
                {
                    "x": 180,
                    "y": 363.5
                },
                {
                    "x": 180,
                    "y": 349.5
                },
                {
                    "x": 180,
                    "y": 329.5
                },
                {
                    "x": 180,
                    "y": 299.5
                },
                {
                    "x": 180,
                    "y": 264.5
                },
                {
                    "x": 180,
                    "y": 229.5
                },
                {
                    "x": 180,
                    "y": 199.5
                },
                {
                    "x": 180,
                    "y": 180.5
                },
                {
                    "x": 181,
                    "y": 169.5
                },
                {
                    "x": 182,
                    "y": 163.5
                },
                {
                    "x": 184,
                    "y": 162.5
                },
                {
                    "x": 187,
                    "y": 162.5
                },
                {
                    "x": 193,
                    "y": 169.5
                },
                {
                    "x": 201,
                    "y": 180.5
                },
                {
                    "x": 210,
                    "y": 198.5
                },
                {
                    "x": 220,
                    "y": 226.5
                },
                {
                    "x": 228,
                    "y": 256.5
                },
                {
                    "x": 232,
                    "y": 277.5
                },
                {
                    "x": 235,
                    "y": 289.5
                },
                {
                    "x": 237,
                    "y": 295.5
                },
                {
                    "x": 237,
                    "y": 297.5
                },
                {
                    "x": 240,
                    "y": 298.5
                },
                {
                    "x": 243,
                    "y": 298.5
                },
                {
                    "x": 248,
                    "y": 297.5
                },
                {
                    "x": 256,
                    "y": 294.5
                },
                {
                    "x": 266,
                    "y": 290.5
                },
                {
                    "x": 277,
                    "y": 284.5
                },
                {
                    "x": 288,
                    "y": 278.5
                },
                {
                    "x": 298,
                    "y": 274.5
                },
                {
                    "x": 304,
                    "y": 272.5
                },
                {
                    "x": 306,
                    "y": 272.5
                },
                {
                    "x": 305,
                    "y": 275.5
                },
                {
                    "x": 301,
                    "y": 279.5
                },
                {
                    "x": 297,
                    "y": 287.5
                },
                {
                    "x": 293,
                    "y": 296.5
                },
                {
                    "x": 291,
                    "y": 307.5
                },
                {
                    "x": 291,
                    "y": 319.5
                },
                {
                    "x": 292,
                    "y": 330.5
                },
                {
                    "x": 296,
                    "y": 340.5
                },
                {
                    "x": 299,
                    "y": 348.5
                },
                {
                    "x": 302,
                    "y": 354.5
                },
                {
                    "x": 303,
                    "y": 357.5
                },
                {
                    "x": 305,
                    "y": 359.5
                },
                {
                    "x": 306,
                    "y": 359.5
                },
                {
                    "x": 307,
                    "y": 360.5
                },
                {
                    "x": 308,
                    "y": 361.5
                },
                {
                    "x": 308,
                    "y": 362.5
                },
                {
                    "x": 308,
                    "y": 363.5
                },
                {
                    "x": 307,
                    "y": 363.5
                },
                {
                    "x": 305,
                    "y": 363.5
                },
                {
                    "x": 303,
                    "y": 363.5
                },
                {
                    "x": 300,
                    "y": 363.5
                },
                {
                    "x": 296,
                    "y": 363.5
                },
                {
                    "x": 289,
                    "y": 363.5
                },
                {
                    "x": 281,
                    "y": 363.5
                },
                {
                    "x": 272,
                    "y": 364.5
                },
                {
                    "x": 266,
                    "y": 366.5
                },
                {
                    "x": 263,
                    "y": 367.5
                },
                {
                    "x": 263,
                    "y": 369.5
                },
                {
                    "x": 265,
                    "y": 373.5
                },
                {
                    "x": 275,
                    "y": 376.5
                },
                {
                    "x": 289,
                    "y": 377.5
                },
                {
                    "x": 312,
                    "y": 379.5
                },
                {
                    "x": 344,
                    "y": 379.5
                },
                {
                    "x": 383,
                    "y": 376.5
                },
                {
                    "x": 423,
                    "y": 371.5
                },
                {
                    "x": 459,
                    "y": 365.5
                },
                {
                    "x": 488,
                    "y": 361.5
                },
                {
                    "x": 495,
                    "y": 358.5
                },
                {
                    "x": 471,
                    "y": 358.5
                },
                {
                    "x": 424,
                    "y": 359.5
                },
                {
                    "x": 367,
                    "y": 361.5
                },
                {
                    "x": 312,
                    "y": 367.5
                },
                {
                    "x": 266,
                    "y": 377.5
                },
                {
                    "x": 234,
                    "y": 387.5
                },
                {
                    "x": 219,
                    "y": 393.5
                },
                {
                    "x": 213,
                    "y": 396.5
                },
                {
                    "x": 213,
                    "y": 397.5
                },
                {
                    "x": 218,
                    "y": 397.5
                },
                {
                    "x": 241,
                    "y": 396.5
                },
                {
                    "x": 285,
                    "y": 393.5
                },
                {
                    "x": 340,
                    "y": 387.5
                },
                {
                    "x": 387,
                    "y": 381.5
                },
                {
                    "x": 418,
                    "y": 378.5
                },
                {
                    "x": 431,
                    "y": 374.5
                },
                {
                    "x": 436,
                    "y": 373.5
                },
                {
                    "x": 436,
                    "y": 371.5
                },
                {
                    "x": 431,
                    "y": 370.5
                },
                {
                    "x": 419,
                    "y": 370.5
                },
                {
                    "x": 403,
                    "y": 370.5
                },
                {
                    "x": 384,
                    "y": 370.5
                },
                {
                    "x": 369,
                    "y": 370.5
                },
                {
                    "x": 359,
                    "y": 370.5
                },
                {
                    "x": 353,
                    "y": 370.5
                },
                {
                    "x": 351,
                    "y": 368.5
                },
                {
                    "x": 351,
                    "y": 361.5
                },
                {
                    "x": 356,
                    "y": 350.5
                },
                {
                    "x": 366,
                    "y": 337.5
                },
                {
                    "x": 389,
                    "y": 306.5
                },
                {
                    "x": 412,
                    "y": 276.5
                },
                {
                    "x": 434,
                    "y": 243.5
                },
                {
                    "x": 449,
                    "y": 214.5
                },
                {
                    "x": 460,
                    "y": 189.5
                },
                {
                    "x": 467,
                    "y": 164.5
                },
                {
                    "x": 469,
                    "y": 144.5
                },
                {
                    "x": 469,
                    "y": 127.5
                },
                {
                    "x": 468,
                    "y": 110.5
                },
                {
                    "x": 464,
                    "y": 91.5
                },
                {
                    "x": 458,
                    "y": 72.5
                },
                {
                    "x": 453,
                    "y": 53.5
                },
                {
                    "x": 448,
                    "y": 36.5
                },
                {
                    "x": 446,
                    "y": 27.5
                },
                {
                    "x": 445,
                    "y": 22.5
                },
                {
                    "x": 445,
                    "y": 21.5
                },
                {
                    "x": 446,
                    "y": 21.5
                },
                {
                    "x": 449,
                    "y": 28.5
                },
                {
                    "x": 456,
                    "y": 44.5
                },
                {
                    "x": 467,
                    "y": 70.5
                },
                {
                    "x": 481,
                    "y": 103.5
                },
                {
                    "x": 494,
                    "y": 137.5
                },
                {
                    "x": 487,
                    "y": 144.5
                },
                {
                    "x": 463,
                    "y": 138.5
                },
                {
                    "x": 434,
                    "y": 134.5
                },
                {
                    "x": 401,
                    "y": 132.5
                },
                {
                    "x": 373,
                    "y": 132.5
                },
                {
                    "x": 351,
                    "y": 138.5
                },
                {
                    "x": 339,
                    "y": 144.5
                },
                {
                    "x": 333,
                    "y": 147.5
                },
                {
                    "x": 331,
                    "y": 147.5
                },
                {
                    "x": 332,
                    "y": 147.5
                },
                {
                    "x": 336,
                    "y": 144.5
                },
                {
                    "x": 344,
                    "y": 140.5
                },
                {
                    "x": 351,
                    "y": 135.5
                },
                {
                    "x": 357,
                    "y": 130.5
                },
                {
                    "x": 360,
                    "y": 125.5
                },
                {
                    "x": 361,
                    "y": 122.5
                },
                {
                    "x": 361,
                    "y": 121.5
                },
                {
                    "x": 361,
                    "y": 119.5
                },
                {
                    "x": 360,
                    "y": 119.5
                },
                {
                    "x": 352,
                    "y": 119.5
                },
                {
                    "x": 342,
                    "y": 119.5
                },
                {
                    "x": 318,
                    "y": 119.5
                },
                {
                    "x": 279,
                    "y": 116.5
                },
                {
                    "x": 237,
                    "y": 110.5
                },
                {
                    "x": 197,
                    "y": 105.5
                },
                {
                    "x": 169,
                    "y": 100.5
                },
                {
                    "x": 155,
                    "y": 97.5
                },
                {
                    "x": 148,
                    "y": 96.5
                },
                {
                    "x": 146,
                    "y": 96.5
                },
                {
                    "x": 148,
                    "y": 95.5
                },
                {
                    "x": 159,
                    "y": 95.5
                },
                {
                    "x": 177,
                    "y": 95.5
                },
                {
                    "x": 210,
                    "y": 93.5
                },
                {
                    "x": 248,
                    "y": 93.5
                },
                {
                    "x": 284,
                    "y": 90.5
                },
                {
                    "x": 311,
                    "y": 85.5
                },
                {
                    "x": 325,
                    "y": 81.5
                },
                {
                    "x": 332,
                    "y": 75.5
                },
                {
                    "x": 334,
                    "y": 71.5
                },
                {
                    "x": 332,
                    "y": 65.5
                },
                {
                    "x": 325,
                    "y": 61.5
                },
                {
                    "x": 312,
                    "y": 56.5
                },
                {
                    "x": 291,
                    "y": 52.5
                },
                {
                    "x": 255,
                    "y": 49.5
                },
                {
                    "x": 212,
                    "y": 49.5
                },
                {
                    "x": 168,
                    "y": 49.5
                },
                {
                    "x": 133,
                    "y": 49.5
                },
                {
                    "x": 110,
                    "y": 49.5
                },
                {
                    "x": 100,
                    "y": 50.5
                },
                {
                    "x": 95,
                    "y": 51.5
                },
                {
                    "x": 94,
                    "y": 52.5
                },
                {
                    "x": 97,
                    "y": 52.5
                },
                {
                    "x": 106,
                    "y": 52.5
                },
                {
                    "x": 121,
                    "y": 50.5
                },
                {
                    "x": 139,
                    "y": 48.5
                },
                {
                    "x": 153,
                    "y": 45.5
                },
                {
                    "x": 162,
                    "y": 42.5
                },
                {
                    "x": 167,
                    "y": 40.5
                },
                {
                    "x": 169,
                    "y": 39.5
                },
                {
                    "x": 170,
                    "y": 38.5
                },
                {
                    "x": 168,
                    "y": 38.5
                },
                {
                    "x": 160,
                    "y": 38.5
                },
                {
                    "x": 145,
                    "y": 38.5
                },
                {
                    "x": 125,
                    "y": 38.5
                },
                {
                    "x": 98,
                    "y": 38.5
                },
                {
                    "x": 73,
                    "y": 38.5
                },
                {
                    "x": 54,
                    "y": 38.5
                },
                {
                    "x": 41,
                    "y": 38.5
                },
                {
                    "x": 33,
                    "y": 38.5
                },
                {
                    "x": 30,
                    "y": 38.5
                },
                {
                    "x": 29,
                    "y": 38.5
                },
                {
                    "x": 29,
                    "y": 37.5
                },
                {
                    "x": 31,
                    "y": 37.5
                },
                {
                    "x": 36,
                    "y": 38.5
                },
                {
                    "x": 45,
                    "y": 42.5
                },
                {
                    "x": 53,
                    "y": 51.5
                },
                {
                    "x": 63,
                    "y": 62.5
                },
                {
                    "x": 74,
                    "y": 81.5
                },
                {
                    "x": 84,
                    "y": 106.5
                },
                {
                    "x": 88,
                    "y": 133.5
                },
                {
                    "x": 90,
                    "y": 160.5
                },
                {
                    "x": 88,
                    "y": 181.5
                },
                {
                    "x": 85,
                    "y": 194.5
                },
                {
                    "x": 81,
                    "y": 203.5
                },
                {
                    "x": 78,
                    "y": 208.5
                },
                {
                    "x": 76,
                    "y": 210.5
                },
                {
                    "x": 75,
                    "y": 211.5
                },
                {
                    "x": 76,
                    "y": 211.5
                },
                {
                    "x": 79,
                    "y": 209.5
                },
                {
                    "x": 84,
                    "y": 205.5
                },
                {
                    "x": 88,
                    "y": 202.5
                },
                {
                    "x": 92,
                    "y": 201.5
                },
                {
                    "x": 94,
                    "y": 201.5
                },
                {
                    "x": 96,
                    "y": 201.5
                },
                {
                    "x": 97,
                    "y": 203.5
                },
                {
                    "x": 97,
                    "y": 211.5
                },
                {
                    "x": 96,
                    "y": 223.5
                },
                {
                    "x": 90,
                    "y": 241.5
                },
                {
                    "x": 80,
                    "y": 267.5
                },
                {
                    "x": 71,
                    "y": 294.5
                },
                {
                    "x": 60,
                    "y": 325.5
                },
                {
                    "x": 49,
                    "y": 350.5
                },
                {
                    "x": 41,
                    "y": 364.5
                },
                {
                    "x": 34,
                    "y": 371.5
                },
                {
                    "x": 32,
                    "y": 375.5
                },
                {
                    "x": 30,
                    "y": 376.5
                },
                {
                    "x": 30,
                    "y": 375.5
                },
                {
                    "x": 33,
                    "y": 370.5
                },
                {
                    "x": 39,
                    "y": 361.5
                },
                {
                    "x": 47,
                    "y": 351.5
                },
                {
                    "x": 57,
                    "y": 338.5
                },
                {
                    "x": 67,
                    "y": 319.5
                },
                {
                    "x": 76,
                    "y": 297.5
                },
                {
                    "x": 83,
                    "y": 277.5
                },
                {
                    "x": 88,
                    "y": 260.5
                },
                {
                    "x": 92,
                    "y": 248.5
                },
                {
                    "x": 95,
                    "y": 241.5
                },
                {
                    "x": 98,
                    "y": 237.5
                },
                {
                    "x": 99,
                    "y": 236.5
                },
                {
                    "x": 100,
                    "y": 236.5
                },
                {
                    "x": 100,
                    "y": 238.5
                },
                {
                    "x": 100,
                    "y": 248.5
                },
                {
                    "x": 100,
                    "y": 262.5
                },
                {
                    "x": 100,
                    "y": 280.5
                },
                {
                    "x": 96,
                    "y": 307.5
                },
                {
                    "x": 88,
                    "y": 337.5
                },
                {
                    "x": 80,
                    "y": 364.5
                },
                {
                    "x": 73,
                    "y": 384.5
                },
                {
                    "x": 70,
                    "y": 394.5
                },
                {
                    "x": 68,
                    "y": 400.5
                },
                {
                    "x": 68,
                    "y": 402.5
                },
                {
                    "x": 68,
                    "y": 403.5
                },
                {
                    "x": 70,
                    "y": 403.5
                },
                {
                    "x": 78,
                    "y": 403.5
                },
                {
                    "x": 93,
                    "y": 401.5
                },
                {
                    "x": 119,
                    "y": 399.5
                },
                {
                    "x": 149,
                    "y": 398.5
                },
                {
                    "x": 179,
                    "y": 396.5
                },
                {
                    "x": 205,
                    "y": 395.5
                },
                {
                    "x": 221,
                    "y": 395.5
                },
                {
                    "x": 233,
                    "y": 395.5
                },
                {
                    "x": 240,
                    "y": 395.5
                },
                {
                    "x": 242,
                    "y": 395.5
                },
                {
                    "x": 234,
                    "y": 397.5
                },
                {
                    "x": 227,
                    "y": 400.5
                },
                {
                    "x": 221,
                    "y": 403.5
                },
                {
                    "x": 217,
                    "y": 406.5
                },
                {
                    "x": 216,
                    "y": 410.5
                },
                {
                    "x": 216,
                    "y": 415.5
                },
                {
                    "x": 217,
                    "y": 421.5
                },
                {
                    "x": 225,
                    "y": 427.5
                },
                {
                    "x": 236,
                    "y": 435.5
                },
                {
                    "x": 255,
                    "y": 445.5
                },
                {
                    "x": 279,
                    "y": 452.5
                },
                {
                    "x": 304,
                    "y": 458.5
                },
                {
                    "x": 327,
                    "y": 463.5
                },
                {
                    "x": 345,
                    "y": 464.5
                },
                {
                    "x": 359,
                    "y": 465.5
                },
                {
                    "x": 370,
                    "y": 465.5
                },
                {
                    "x": 375,
                    "y": 465.5
                },
                {
                    "x": 379,
                    "y": 464.5
                },
                {
                    "x": 379,
                    "y": 461.5
                },
                {
                    "x": 378,
                    "y": 458.5
                },
                {
                    "x": 372,
                    "y": 454.5
                },
                {
                    "x": 361,
                    "y": 450.5
                },
                {
                    "x": 343,
                    "y": 445.5
                },
                {
                    "x": 319,
                    "y": 439.5
                },
                {
                    "x": 291,
                    "y": 435.5
                },
                {
                    "x": 266,
                    "y": 434.5
                },
                {
                    "x": 246,
                    "y": 434.5
                },
                {
                    "x": 234,
                    "y": 434.5
                },
                {
                    "x": 226,
                    "y": 435.5
                },
                {
                    "x": 223,
                    "y": 436.5
                },
                {
                    "x": 223,
                    "y": 437.5
                },
                {
                    "x": 227,
                    "y": 438.5
                },
                {
                    "x": 238,
                    "y": 438.5
                },
                {
                    "x": 253,
                    "y": 438.5
                },
                {
                    "x": 271,
                    "y": 438.5
                },
                {
                    "x": 287,
                    "y": 438.5
                },
                {
                    "x": 303,
                    "y": 437.5
                },
                {
                    "x": 313,
                    "y": 435.5
                },
                {
                    "x": 319,
                    "y": 435.5
                },
                {
                    "x": 320,
                    "y": 435.5
                },
                {
                    "x": 319,
                    "y": 435.5
                },
                {
                    "x": 312,
                    "y": 437.5
                },
                {
                    "x": 301,
                    "y": 441.5
                },
                {
                    "x": 288,
                    "y": 445.5
                },
                {
                    "x": 272,
                    "y": 450.5
                },
                {
                    "x": 256,
                    "y": 455.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#cddc39",
            "strokeWidth": 12,
            "paths": [
                {
                    "x": 194,
                    "y": 211.5
                },
                {
                    "x": 192,
                    "y": 214.5
                },
                {
                    "x": 189,
                    "y": 220.5
                },
                {
                    "x": 185,
                    "y": 227.5
                },
                {
                    "x": 179,
                    "y": 237.5
                },
                {
                    "x": 175,
                    "y": 248.5
                },
                {
                    "x": 172,
                    "y": 262.5
                },
                {
                    "x": 172,
                    "y": 278.5
                },
                {
                    "x": 175,
                    "y": 299.5
                },
                {
                    "x": 190,
                    "y": 325.5
                },
                {
                    "x": 216,
                    "y": 350.5
                },
                {
                    "x": 248,
                    "y": 371.5
                },
                {
                    "x": 282,
                    "y": 385.5
                },
                {
                    "x": 311,
                    "y": 392.5
                },
                {
                    "x": 342,
                    "y": 395.5
                },
                {
                    "x": 368,
                    "y": 393.5
                },
                {
                    "x": 392,
                    "y": 387.5
                },
                {
                    "x": 410,
                    "y": 379.5
                },
                {
                    "x": 420,
                    "y": 370.5
                },
                {
                    "x": 424,
                    "y": 358.5
                },
                {
                    "x": 424,
                    "y": 344.5
                },
                {
                    "x": 418,
                    "y": 328.5
                },
                {
                    "x": 401,
                    "y": 308.5
                },
                {
                    "x": 376,
                    "y": 288.5
                },
                {
                    "x": 341,
                    "y": 268.5
                },
                {
                    "x": 299,
                    "y": 249.5
                },
                {
                    "x": 255,
                    "y": 229.5
                },
                {
                    "x": 216,
                    "y": 215.5
                },
                {
                    "x": 193,
                    "y": 204.5
                },
                {
                    "x": 184,
                    "y": 198.5
                },
                {
                    "x": 183,
                    "y": 195.5
                },
                {
                    "x": 187,
                    "y": 189.5
                },
                {
                    "x": 201,
                    "y": 183.5
                },
                {
                    "x": 231,
                    "y": 173.5
                },
                {
                    "x": 277,
                    "y": 160.5
                },
                {
                    "x": 325,
                    "y": 148.5
                },
                {
                    "x": 374,
                    "y": 136.5
                },
                {
                    "x": 412,
                    "y": 126.5
                },
                {
                    "x": 434,
                    "y": 116.5
                },
                {
                    "x": 444,
                    "y": 112.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#cddc39",
            "strokeWidth": 12,
            "paths": [
                {
                    "x": 324,
                    "y": 97.5
                },
                {
                    "x": 324,
                    "y": 96.5
                },
                {
                    "x": 322,
                    "y": 94.5
                },
                {
                    "x": 319,
                    "y": 93.5
                },
                {
                    "x": 313,
                    "y": 91.5
                },
                {
                    "x": 305,
                    "y": 91.5
                },
                {
                    "x": 296,
                    "y": 91.5
                },
                {
                    "x": 288,
                    "y": 91.5
                },
                {
                    "x": 280,
                    "y": 93.5
                },
                {
                    "x": 272,
                    "y": 97.5
                },
                {
                    "x": 265,
                    "y": 103.5
                },
                {
                    "x": 257,
                    "y": 111.5
                },
                {
                    "x": 250,
                    "y": 120.5
                },
                {
                    "x": 242,
                    "y": 130.5
                },
                {
                    "x": 236,
                    "y": 140.5
                },
                {
                    "x": 233,
                    "y": 148.5
                },
                {
                    "x": 230,
                    "y": 155.5
                },
                {
                    "x": 228,
                    "y": 161.5
                },
                {
                    "x": 227,
                    "y": 168.5
                },
                {
                    "x": 226,
                    "y": 175.5
                },
                {
                    "x": 226,
                    "y": 184.5
                },
                {
                    "x": 226,
                    "y": 195.5
                },
                {
                    "x": 228,
                    "y": 208.5
                },
                {
                    "x": 231,
                    "y": 223.5
                },
                {
                    "x": 233,
                    "y": 239.5
                },
                {
                    "x": 235,
                    "y": 251.5
                },
                {
                    "x": 236,
                    "y": 258.5
                },
                {
                    "x": 236,
                    "y": 260.5
                },
                {
                    "x": 236,
                    "y": 259.5
                },
                {
                    "x": 233,
                    "y": 254.5
                },
                {
                    "x": 226,
                    "y": 239.5
                },
                {
                    "x": 216,
                    "y": 219.5
                },
                {
                    "x": 205,
                    "y": 196.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#cddc39",
            "strokeWidth": 12,
            "paths": [
                {
                    "x": 125,
                    "y": 49.5
                },
                {
                    "x": 127,
                    "y": 49.5
                },
                {
                    "x": 129,
                    "y": 53.5
                },
                {
                    "x": 130,
                    "y": 59.5
                },
                {
                    "x": 130,
                    "y": 67.5
                },
                {
                    "x": 130,
                    "y": 79.5
                },
                {
                    "x": 132,
                    "y": 93.5
                },
                {
                    "x": 136,
                    "y": 110.5
                },
                {
                    "x": 145,
                    "y": 135.5
                },
                {
                    "x": 160,
                    "y": 159.5
                },
                {
                    "x": 178,
                    "y": 176.5
                },
                {
                    "x": 197,
                    "y": 183.5
                },
                {
                    "x": 220,
                    "y": 184.5
                },
                {
                    "x": 247,
                    "y": 179.5
                },
                {
                    "x": 275,
                    "y": 165.5
                },
                {
                    "x": 299,
                    "y": 151.5
                },
                {
                    "x": 313,
                    "y": 141.5
                },
                {
                    "x": 321,
                    "y": 133.5
                },
                {
                    "x": 324,
                    "y": 127.5
                },
                {
                    "x": 323,
                    "y": 124.5
                },
                {
                    "x": 316,
                    "y": 122.5
                },
                {
                    "x": 302,
                    "y": 121.5
                },
                {
                    "x": 275,
                    "y": 121.5
                },
                {
                    "x": 239,
                    "y": 123.5
                },
                {
                    "x": 202,
                    "y": 128.5
                },
                {
                    "x": 171,
                    "y": 133.5
                },
                {
                    "x": 148,
                    "y": 136.5
                },
                {
                    "x": 135,
                    "y": 137.5
                },
                {
                    "x": 126,
                    "y": 138.5
                },
                {
                    "x": 123,
                    "y": 138.5
                },
                {
                    "x": 122,
                    "y": 137.5
                },
                {
                    "x": 122,
                    "y": 133.5
                },
                {
                    "x": 128,
                    "y": 128.5
                },
                {
                    "x": 138,
                    "y": 121.5
                },
                {
                    "x": 149,
                    "y": 116.5
                },
                {
                    "x": 157,
                    "y": 113.5
                },
                {
                    "x": 163,
                    "y": 110.5
                },
                {
                    "x": 165,
                    "y": 109.5
                },
                {
                    "x": 159,
                    "y": 108.5
                },
                {
                    "x": 144,
                    "y": 106.5
                },
                {
                    "x": 112,
                    "y": 99.5
                },
                {
                    "x": 69,
                    "y": 88.5
                },
                {
                    "x": 18,
                    "y": 72.5
                },
                {
                    "x": 1,
                    "y": 181.5
                },
                {
                    "x": 5,
                    "y": 187.5
                },
                {
                    "x": 7,
                    "y": 189.5
                },
                {
                    "x": 12,
                    "y": 190.5
                },
                {
                    "x": 19,
                    "y": 191.5
                },
                {
                    "x": 28,
                    "y": 191.5
                },
                {
                    "x": 38,
                    "y": 191.5
                },
                {
                    "x": 48,
                    "y": 191.5
                },
                {
                    "x": 53,
                    "y": 191.5
                },
                {
                    "x": 55,
                    "y": 191.5
                },
                {
                    "x": 56,
                    "y": 191.5
                },
                {
                    "x": 56,
                    "y": 193.5
                },
                {
                    "x": 50,
                    "y": 201.5
                },
                {
                    "x": 43,
                    "y": 210.5
                },
                {
                    "x": 30,
                    "y": 223.5
                },
                {
                    "x": 14,
                    "y": 241.5
                },
                {
                    "x": 13,
                    "y": 273.5
                },
                {
                    "x": 46,
                    "y": 263.5
                },
                {
                    "x": 87,
                    "y": 250.5
                },
                {
                    "x": 131,
                    "y": 233.5
                },
                {
                    "x": 175,
                    "y": 214.5
                },
                {
                    "x": 212,
                    "y": 193.5
                },
                {
                    "x": 238,
                    "y": 173.5
                },
                {
                    "x": 250,
                    "y": 156.5
                },
                {
                    "x": 255,
                    "y": 145.5
                },
                {
                    "x": 253,
                    "y": 138.5
                },
                {
                    "x": 242,
                    "y": 133.5
                },
                {
                    "x": 223,
                    "y": 131.5
                },
                {
                    "x": 189,
                    "y": 133.5
                },
                {
                    "x": 151,
                    "y": 142.5
                },
                {
                    "x": 115,
                    "y": 157.5
                },
                {
                    "x": 88,
                    "y": 177.5
                },
                {
                    "x": 68,
                    "y": 203.5
                },
                {
                    "x": 56,
                    "y": 231.5
                },
                {
                    "x": 51,
                    "y": 257.5
                },
                {
                    "x": 52,
                    "y": 274.5
                },
                {
                    "x": 59,
                    "y": 287.5
                },
                {
                    "x": 70,
                    "y": 297.5
                },
                {
                    "x": 87,
                    "y": 302.5
                },
                {
                    "x": 112,
                    "y": 303.5
                },
                {
                    "x": 145,
                    "y": 303.5
                },
                {
                    "x": 183,
                    "y": 294.5
                },
                {
                    "x": 219,
                    "y": 281.5
                },
                {
                    "x": 245,
                    "y": 266.5
                },
                {
                    "x": 262,
                    "y": 252.5
                },
                {
                    "x": 270,
                    "y": 239.5
                },
                {
                    "x": 272,
                    "y": 227.5
                },
                {
                    "x": 268,
                    "y": 217.5
                },
                {
                    "x": 257,
                    "y": 210.5
                },
                {
                    "x": 238,
                    "y": 206.5
                },
                {
                    "x": 203,
                    "y": 206.5
                },
                {
                    "x": 161,
                    "y": 211.5
                },
                {
                    "x": 120,
                    "y": 224.5
                },
                {
                    "x": 88,
                    "y": 244.5
                },
                {
                    "x": 68,
                    "y": 269.5
                },
                {
                    "x": 56,
                    "y": 299.5
                },
                {
                    "x": 56,
                    "y": 327.5
                },
                {
                    "x": 62,
                    "y": 351.5
                },
                {
                    "x": 77,
                    "y": 369.5
                },
                {
                    "x": 93,
                    "y": 380.5
                },
                {
                    "x": 115,
                    "y": 386.5
                },
                {
                    "x": 143,
                    "y": 387.5
                },
                {
                    "x": 175,
                    "y": 387.5
                },
                {
                    "x": 209,
                    "y": 383.5
                },
                {
                    "x": 244,
                    "y": 376.5
                },
                {
                    "x": 268,
                    "y": 370.5
                },
                {
                    "x": 285,
                    "y": 365.5
                },
                {
                    "x": 293,
                    "y": 360.5
                },
                {
                    "x": 297,
                    "y": 356.5
                },
                {
                    "x": 297,
                    "y": 354.5
                },
                {
                    "x": 294,
                    "y": 353.5
                },
                {
                    "x": 279,
                    "y": 353.5
                },
                {
                    "x": 253,
                    "y": 353.5
                },
                {
                    "x": 212,
                    "y": 356.5
                },
                {
                    "x": 169,
                    "y": 365.5
                },
                {
                    "x": 135,
                    "y": 379.5
                },
                {
                    "x": 113,
                    "y": 397.5
                },
                {
                    "x": 101,
                    "y": 415.5
                },
                {
                    "x": 97,
                    "y": 437.5
                },
                {
                    "x": 100,
                    "y": 455.5
                },
                {
                    "x": 112,
                    "y": 470.5
                },
                {
                    "x": 127,
                    "y": 477.5
                },
                {
                    "x": 152,
                    "y": 483.5
                },
                {
                    "x": 182,
                    "y": 485.5
                },
                {
                    "x": 215,
                    "y": 484.5
                },
                {
                    "x": 252,
                    "y": 479.5
                },
                {
                    "x": 284,
                    "y": 472.5
                },
                {
                    "x": 309,
                    "y": 463.5
                },
                {
                    "x": 325,
                    "y": 454.5
                },
                {
                    "x": 335,
                    "y": 446.5
                },
                {
                    "x": 341,
                    "y": 438.5
                },
                {
                    "x": 344,
                    "y": 431.5
                },
                {
                    "x": 345,
                    "y": 426.5
                },
                {
                    "x": 345,
                    "y": 422.5
                },
                {
                    "x": 340,
                    "y": 419.5
                },
                {
                    "x": 333,
                    "y": 417.5
                },
                {
                    "x": 325,
                    "y": 415.5
                },
                {
                    "x": 316,
                    "y": 416.5
                },
                {
                    "x": 311,
                    "y": 418.5
                },
                {
                    "x": 306,
                    "y": 423.5
                },
                {
                    "x": 304,
                    "y": 429.5
                },
                {
                    "x": 305,
                    "y": 437.5
                },
                {
                    "x": 311,
                    "y": 445.5
                },
                {
                    "x": 321,
                    "y": 451.5
                },
                {
                    "x": 335,
                    "y": 456.5
                },
                {
                    "x": 350,
                    "y": 458.5
                },
                {
                    "x": 369,
                    "y": 458.5
                },
                {
                    "x": 393,
                    "y": 455.5
                },
                {
                    "x": 420,
                    "y": 447.5
                },
                {
                    "x": 446,
                    "y": 438.5
                },
                {
                    "x": 464,
                    "y": 426.5
                },
                {
                    "x": 474,
                    "y": 417.5
                },
                {
                    "x": 481,
                    "y": 407.5
                },
                {
                    "x": 484,
                    "y": 397.5
                },
                {
                    "x": 484,
                    "y": 388.5
                },
                {
                    "x": 478,
                    "y": 379.5
                },
                {
                    "x": 468,
                    "y": 370.5
                },
                {
                    "x": 449,
                    "y": 361.5
                },
                {
                    "x": 421,
                    "y": 351.5
                },
                {
                    "x": 397,
                    "y": 345.5
                },
                {
                    "x": 377,
                    "y": 343.5
                },
                {
                    "x": 366,
                    "y": 343.5
                },
                {
                    "x": 360,
                    "y": 344.5
                },
                {
                    "x": 358,
                    "y": 345.5
                },
                {
                    "x": 358,
                    "y": 347.5
                },
                {
                    "x": 360,
                    "y": 350.5
                },
                {
                    "x": 367,
                    "y": 352.5
                },
                {
                    "x": 377,
                    "y": 354.5
                },
                {
                    "x": 389,
                    "y": 354.5
                },
                {
                    "x": 401,
                    "y": 354.5
                },
                {
                    "x": 413,
                    "y": 350.5
                },
                {
                    "x": 423,
                    "y": 345.5
                },
                {
                    "x": 431,
                    "y": 337.5
                },
                {
                    "x": 435,
                    "y": 329.5
                },
                {
                    "x": 437,
                    "y": 321.5
                },
                {
                    "x": 436,
                    "y": 311.5
                },
                {
                    "x": 429,
                    "y": 298.5
                },
                {
                    "x": 414,
                    "y": 281.5
                },
                {
                    "x": 392,
                    "y": 263.5
                },
                {
                    "x": 357,
                    "y": 243.5
                },
                {
                    "x": 318,
                    "y": 227.5
                },
                {
                    "x": 280,
                    "y": 214.5
                },
                {
                    "x": 256,
                    "y": 208.5
                },
                {
                    "x": 243,
                    "y": 206.5
                },
                {
                    "x": 239,
                    "y": 206.5
                },
                {
                    "x": 237,
                    "y": 206.5
                },
                {
                    "x": 238,
                    "y": 206.5
                },
                {
                    "x": 244,
                    "y": 207.5
                },
                {
                    "x": 256,
                    "y": 209.5
                },
                {
                    "x": 273,
                    "y": 210.5
                },
                {
                    "x": 292,
                    "y": 210.5
                },
                {
                    "x": 310,
                    "y": 207.5
                },
                {
                    "x": 331,
                    "y": 199.5
                },
                {
                    "x": 347,
                    "y": 188.5
                },
                {
                    "x": 360,
                    "y": 175.5
                },
                {
                    "x": 371,
                    "y": 159.5
                },
                {
                    "x": 375,
                    "y": 144.5
                },
                {
                    "x": 375,
                    "y": 130.5
                },
                {
                    "x": 373,
                    "y": 115.5
                },
                {
                    "x": 362,
                    "y": 98.5
                },
                {
                    "x": 345,
                    "y": 84.5
                },
                {
                    "x": 324,
                    "y": 73.5
                },
                {
                    "x": 302,
                    "y": 68.5
                },
                {
                    "x": 282,
                    "y": 65.5
                },
                {
                    "x": 264,
                    "y": 66.5
                },
                {
                    "x": 251,
                    "y": 72.5
                },
                {
                    "x": 242,
                    "y": 82.5
                },
                {
                    "x": 237,
                    "y": 96.5
                },
                {
                    "x": 237,
                    "y": 111.5
                },
                {
                    "x": 240,
                    "y": 127.5
                },
                {
                    "x": 249,
                    "y": 140.5
                },
                {
                    "x": 258,
                    "y": 149.5
                },
                {
                    "x": 266,
                    "y": 154.5
                },
                {
                    "x": 276,
                    "y": 156.5
                },
                {
                    "x": 285,
                    "y": 156.5
                },
                {
                    "x": 296,
                    "y": 152.5
                },
                {
                    "x": 308,
                    "y": 145.5
                },
                {
                    "x": 318,
                    "y": 136.5
                },
                {
                    "x": 324,
                    "y": 126.5
                },
                {
                    "x": 328,
                    "y": 115.5
                },
                {
                    "x": 328,
                    "y": 104.5
                },
                {
                    "x": 324,
                    "y": 92.5
                },
                {
                    "x": 315,
                    "y": 84.5
                },
                {
                    "x": 301,
                    "y": 76.5
                },
                {
                    "x": 274,
                    "y": 70.5
                },
                {
                    "x": 235,
                    "y": 67.5
                },
                {
                    "x": 192,
                    "y": 67.5
                },
                {
                    "x": 150,
                    "y": 69.5
                },
                {
                    "x": 120,
                    "y": 79.5
                },
                {
                    "x": 101,
                    "y": 91.5
                },
                {
                    "x": 92,
                    "y": 103.5
                },
                {
                    "x": 90,
                    "y": 115.5
                },
                {
                    "x": 93,
                    "y": 129.5
                },
                {
                    "x": 105,
                    "y": 143.5
                },
                {
                    "x": 120,
                    "y": 155.5
                },
                {
                    "x": 137,
                    "y": 163.5
                },
                {
                    "x": 159,
                    "y": 168.5
                },
                {
                    "x": 184,
                    "y": 169.5
                },
                {
                    "x": 212,
                    "y": 167.5
                },
                {
                    "x": 241,
                    "y": 160.5
                },
                {
                    "x": 266,
                    "y": 154.5
                },
                {
                    "x": 283,
                    "y": 146.5
                },
                {
                    "x": 294,
                    "y": 136.5
                },
                {
                    "x": 299,
                    "y": 125.5
                },
                {
                    "x": 301,
                    "y": 114.5
                },
                {
                    "x": 300,
                    "y": 103.5
                },
                {
                    "x": 295,
                    "y": 93.5
                },
                {
                    "x": 283,
                    "y": 84.5
                },
                {
                    "x": 263,
                    "y": 75.5
                },
                {
                    "x": 233,
                    "y": 69.5
                },
                {
                    "x": 197,
                    "y": 65.5
                },
                {
                    "x": 165,
                    "y": 65.5
                },
                {
                    "x": 144,
                    "y": 67.5
                },
                {
                    "x": 131,
                    "y": 74.5
                },
                {
                    "x": 125,
                    "y": 83.5
                },
                {
                    "x": 123,
                    "y": 95.5
                },
                {
                    "x": 127,
                    "y": 109.5
                },
                {
                    "x": 140,
                    "y": 128.5
                },
                {
                    "x": 161,
                    "y": 147.5
                },
                {
                    "x": 185,
                    "y": 163.5
                },
                {
                    "x": 211,
                    "y": 173.5
                },
                {
                    "x": 242,
                    "y": 178.5
                },
                {
                    "x": 273,
                    "y": 181.5
                },
                {
                    "x": 307,
                    "y": 179.5
                },
                {
                    "x": 339,
                    "y": 170.5
                },
                {
                    "x": 365,
                    "y": 159.5
                },
                {
                    "x": 384,
                    "y": 147.5
                },
                {
                    "x": 393,
                    "y": 135.5
                },
                {
                    "x": 396,
                    "y": 123.5
                },
                {
                    "x": 396,
                    "y": 111.5
                },
                {
                    "x": 389,
                    "y": 100.5
                },
                {
                    "x": 374,
                    "y": 89.5
                },
                {
                    "x": 341,
                    "y": 76.5
                },
                {
                    "x": 298,
                    "y": 67.5
                },
                {
                    "x": 249,
                    "y": 63.5
                },
                {
                    "x": 205,
                    "y": 63.5
                },
                {
                    "x": 171,
                    "y": 67.5
                },
                {
                    "x": 154,
                    "y": 74.5
                },
                {
                    "x": 144,
                    "y": 83.5
                },
                {
                    "x": 139,
                    "y": 94.5
                },
                {
                    "x": 140,
                    "y": 106.5
                },
                {
                    "x": 147,
                    "y": 120.5
                },
                {
                    "x": 160,
                    "y": 136.5
                },
                {
                    "x": 177,
                    "y": 148.5
                },
                {
                    "x": 196,
                    "y": 158.5
                },
                {
                    "x": 214,
                    "y": 162.5
                },
                {
                    "x": 237,
                    "y": 165.5
                },
                {
                    "x": 260,
                    "y": 163.5
                },
                {
                    "x": 284,
                    "y": 159.5
                },
                {
                    "x": 304,
                    "y": 150.5
                },
                {
                    "x": 316,
                    "y": 142.5
                },
                {
                    "x": 322,
                    "y": 133.5
                },
                {
                    "x": 325,
                    "y": 124.5
                },
                {
                    "x": 325,
                    "y": 114.5
                },
                {
                    "x": 320,
                    "y": 106.5
                },
                {
                    "x": 310,
                    "y": 98.5
                },
                {
                    "x": 292,
                    "y": 93.5
                },
                {
                    "x": 261,
                    "y": 90.5
                },
                {
                    "x": 223,
                    "y": 90.5
                },
                {
                    "x": 185,
                    "y": 99.5
                },
                {
                    "x": 154,
                    "y": 115.5
                },
                {
                    "x": 128,
                    "y": 137.5
                },
                {
                    "x": 112,
                    "y": 162.5
                },
                {
                    "x": 102,
                    "y": 189.5
                },
                {
                    "x": 99,
                    "y": 215.5
                },
                {
                    "x": 100,
                    "y": 240.5
                },
                {
                    "x": 104,
                    "y": 259.5
                },
                {
                    "x": 108,
                    "y": 269.5
                },
                {
                    "x": 112,
                    "y": 273.5
                },
                {
                    "x": 116,
                    "y": 276.5
                },
                {
                    "x": 126,
                    "y": 277.5
                },
                {
                    "x": 138,
                    "y": 277.5
                },
                {
                    "x": 155,
                    "y": 275.5
                },
                {
                    "x": 173,
                    "y": 271.5
                },
                {
                    "x": 190,
                    "y": 266.5
                },
                {
                    "x": 201,
                    "y": 262.5
                },
                {
                    "x": 208,
                    "y": 259.5
                },
                {
                    "x": 210,
                    "y": 258.5
                },
                {
                    "x": 211,
                    "y": 258.5
                },
                {
                    "x": 208,
                    "y": 258.5
                },
                {
                    "x": 196,
                    "y": 259.5
                },
                {
                    "x": 175,
                    "y": 263.5
                },
                {
                    "x": 143,
                    "y": 270.5
                },
                {
                    "x": 113,
                    "y": 278.5
                },
                {
                    "x": 87,
                    "y": 289.5
                },
                {
                    "x": 73,
                    "y": 301.5
                },
                {
                    "x": 65,
                    "y": 314.5
                },
                {
                    "x": 62,
                    "y": 326.5
                },
                {
                    "x": 63,
                    "y": 338.5
                },
                {
                    "x": 69,
                    "y": 347.5
                },
                {
                    "x": 76,
                    "y": 352.5
                },
                {
                    "x": 88,
                    "y": 356.5
                },
                {
                    "x": 107,
                    "y": 357.5
                },
                {
                    "x": 137,
                    "y": 356.5
                },
                {
                    "x": 171,
                    "y": 351.5
                },
                {
                    "x": 203,
                    "y": 344.5
                },
                {
                    "x": 228,
                    "y": 337.5
                },
                {
                    "x": 246,
                    "y": 328.5
                },
                {
                    "x": 254,
                    "y": 323.5
                },
                {
                    "x": 258,
                    "y": 318.5
                },
                {
                    "x": 258,
                    "y": 314.5
                },
                {
                    "x": 257,
                    "y": 311.5
                },
                {
                    "x": 252,
                    "y": 309.5
                },
                {
                    "x": 247,
                    "y": 309.5
                },
                {
                    "x": 241,
                    "y": 309.5
                },
                {
                    "x": 235,
                    "y": 311.5
                },
                {
                    "x": 229,
                    "y": 314.5
                },
                {
                    "x": 225,
                    "y": 316.5
                },
                {
                    "x": 221,
                    "y": 319.5
                },
                {
                    "x": 219,
                    "y": 321.5
                },
                {
                    "x": 218,
                    "y": 323.5
                },
                {
                    "x": 217,
                    "y": 324.5
                },
                {
                    "x": 217,
                    "y": 325.5
                },
                {
                    "x": 217,
                    "y": 326.5
                },
                {
                    "x": 222,
                    "y": 329.5
                },
                {
                    "x": 232,
                    "y": 331.5
                },
                {
                    "x": 247,
                    "y": 332.5
                },
                {
                    "x": 267,
                    "y": 333.5
                },
                {
                    "x": 294,
                    "y": 333.5
                },
                {
                    "x": 322,
                    "y": 333.5
                },
                {
                    "x": 344,
                    "y": 333.5
                },
                {
                    "x": 359,
                    "y": 330.5
                },
                {
                    "x": 368,
                    "y": 328.5
                },
                {
                    "x": 370,
                    "y": 328.5
                },
                {
                    "x": 369,
                    "y": 328.5
                },
                {
                    "x": 362,
                    "y": 329.5
                },
                {
                    "x": 347,
                    "y": 334.5
                },
                {
                    "x": 322,
                    "y": 342.5
                },
                {
                    "x": 297,
                    "y": 350.5
                },
                {
                    "x": 272,
                    "y": 361.5
                },
                {
                    "x": 252,
                    "y": 374.5
                },
                {
                    "x": 240,
                    "y": 385.5
                },
                {
                    "x": 232,
                    "y": 396.5
                },
                {
                    "x": 228,
                    "y": 407.5
                },
                {
                    "x": 228,
                    "y": 421.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ff9800",
            "strokeWidth": 20,
            "paths": [
                {
                    "x": 126,
                    "y": 325.5
                },
                {
                    "x": 126,
                    "y": 326.5
                },
                {
                    "x": 126,
                    "y": 327.5
                },
                {
                    "x": 126,
                    "y": 328.5
                },
                {
                    "x": 126,
                    "y": 329.5
                },
                {
                    "x": 128,
                    "y": 329.5
                },
                {
                    "x": 129,
                    "y": 329.5
                },
                {
                    "x": 130,
                    "y": 329.5
                },
                {
                    "x": 132,
                    "y": 330.5
                },
                {
                    "x": 138,
                    "y": 331.5
                },
                {
                    "x": 147,
                    "y": 332.5
                },
                {
                    "x": 160,
                    "y": 333.5
                },
                {
                    "x": 183,
                    "y": 334.5
                },
                {
                    "x": 216,
                    "y": 336.5
                },
                {
                    "x": 254,
                    "y": 338.5
                },
                {
                    "x": 296,
                    "y": 339.5
                },
                {
                    "x": 337,
                    "y": 341.5
                },
                {
                    "x": 372,
                    "y": 341.5
                },
                {
                    "x": 405,
                    "y": 341.5
                },
                {
                    "x": 432,
                    "y": 341.5
                },
                {
                    "x": 450,
                    "y": 338.5
                },
                {
                    "x": 463,
                    "y": 336.5
                },
                {
                    "x": 471,
                    "y": 334.5
                },
                {
                    "x": 475,
                    "y": 332.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ff9800",
            "strokeWidth": 20,
            "paths": [
                {
                    "x": 262,
                    "y": 263.5
                },
                {
                    "x": 273,
                    "y": 263.5
                },
                {
                    "x": 286,
                    "y": 263.5
                },
                {
                    "x": 302,
                    "y": 263.5
                },
                {
                    "x": 324,
                    "y": 263.5
                },
                {
                    "x": 346,
                    "y": 263.5
                },
                {
                    "x": 365,
                    "y": 263.5
                },
                {
                    "x": 382,
                    "y": 263.5
                },
                {
                    "x": 396,
                    "y": 263.5
                },
                {
                    "x": 408,
                    "y": 263.5
                },
                {
                    "x": 416,
                    "y": 262.5
                },
                {
                    "x": 422,
                    "y": 261.5
                },
                {
                    "x": 424,
                    "y": 261.5
                },
                {
                    "x": 426,
                    "y": 260.5
                },
                {
                    "x": 426,
                    "y": 259.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ff9800",
            "strokeWidth": 20,
            "paths": [
                {
                    "x": 268,
                    "y": 175.5
                },
                {
                    "x": 282,
                    "y": 175.5
                },
                {
                    "x": 296,
                    "y": 175.5
                },
                {
                    "x": 312,
                    "y": 175.5
                },
                {
                    "x": 327,
                    "y": 175.5
                },
                {
                    "x": 341,
                    "y": 175.5
                },
                {
                    "x": 356,
                    "y": 175.5
                },
                {
                    "x": 369,
                    "y": 175.5
                },
                {
                    "x": 382,
                    "y": 175.5
                },
                {
                    "x": 394,
                    "y": 175.5
                },
                {
                    "x": 407,
                    "y": 175.5
                },
                {
                    "x": 419,
                    "y": 175.5
                },
                {
                    "x": 430,
                    "y": 174.5
                },
                {
                    "x": 438,
                    "y": 173.5
                },
                {
                    "x": 443,
                    "y": 172.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ff9800",
            "strokeWidth": 20,
            "paths": [
                {
                    "x": 178,
                    "y": 96.5
                },
                {
                    "x": 181,
                    "y": 96.5
                },
                {
                    "x": 185,
                    "y": 96.5
                },
                {
                    "x": 189,
                    "y": 96.5
                },
                {
                    "x": 194,
                    "y": 96.5
                },
                {
                    "x": 200,
                    "y": 96.5
                },
                {
                    "x": 206,
                    "y": 96.5
                },
                {
                    "x": 216,
                    "y": 96.5
                },
                {
                    "x": 226,
                    "y": 96.5
                },
                {
                    "x": 241,
                    "y": 96.5
                },
                {
                    "x": 261,
                    "y": 96.5
                },
                {
                    "x": 288,
                    "y": 96.5
                },
                {
                    "x": 324,
                    "y": 96.5
                },
                {
                    "x": 364,
                    "y": 96.5
                },
                {
                    "x": 405,
                    "y": 96.5
                },
                {
                    "x": 443,
                    "y": 96.5
                },
                {
                    "x": 470,
                    "y": 96.5
                },
                {
                    "x": 487,
                    "y": 96.5
                },
                {
                    "x": 495,
                    "y": 96.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ff9800",
            "strokeWidth": 20,
            "paths": [
                {
                    "x": 116,
                    "y": 96.5
                },
                {
                    "x": 117,
                    "y": 102.5
                },
                {
                    "x": 118,
                    "y": 107.5
                },
                {
                    "x": 118,
                    "y": 115.5
                },
                {
                    "x": 118,
                    "y": 123.5
                },
                {
                    "x": 116,
                    "y": 134.5
                },
                {
                    "x": 114,
                    "y": 146.5
                },
                {
                    "x": 113,
                    "y": 161.5
                },
                {
                    "x": 110,
                    "y": 182.5
                },
                {
                    "x": 110,
                    "y": 209.5
                },
                {
                    "x": 110,
                    "y": 239.5
                },
                {
                    "x": 110,
                    "y": 269.5
                },
                {
                    "x": 111,
                    "y": 291.5
                },
                {
                    "x": 112,
                    "y": 305.5
                },
                {
                    "x": 114,
                    "y": 312.5
                },
                {
                    "x": 115,
                    "y": 314.5
                },
                {
                    "x": 115,
                    "y": 315.5
                },
                {
                    "x": 115,
                    "y": 314.5
                },
                {
                    "x": 115,
                    "y": 310.5
                },
                {
                    "x": 115,
                    "y": 304.5
                },
                {
                    "x": 115,
                    "y": 297.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ff9800",
            "strokeWidth": 20,
            "paths": [
                {
                    "x": 144,
                    "y": 179.5
                },
                {
                    "x": 145,
                    "y": 182.5
                },
                {
                    "x": 146,
                    "y": 185.5
                },
                {
                    "x": 147,
                    "y": 188.5
                },
                {
                    "x": 148,
                    "y": 191.5
                },
                {
                    "x": 149,
                    "y": 194.5
                },
                {
                    "x": 150,
                    "y": 199.5
                },
                {
                    "x": 150,
                    "y": 203.5
                },
                {
                    "x": 151,
                    "y": 209.5
                },
                {
                    "x": 151,
                    "y": 215.5
                },
                {
                    "x": 151,
                    "y": 223.5
                },
                {
                    "x": 151,
                    "y": 233.5
                },
                {
                    "x": 151,
                    "y": 242.5
                },
                {
                    "x": 151,
                    "y": 251.5
                },
                {
                    "x": 151,
                    "y": 259.5
                },
                {
                    "x": 151,
                    "y": 265.5
                },
                {
                    "x": 151,
                    "y": 268.5
                },
                {
                    "x": 151,
                    "y": 269.5
                },
                {
                    "x": 152,
                    "y": 269.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ff9800",
            "strokeWidth": 20,
            "paths": [
                {
                    "x": 87,
                    "y": 381.5
                },
                {
                    "x": 87,
                    "y": 382.5
                },
                {
                    "x": 88,
                    "y": 384.5
                },
                {
                    "x": 88,
                    "y": 386.5
                },
                {
                    "x": 90,
                    "y": 390.5
                },
                {
                    "x": 92,
                    "y": 393.5
                },
                {
                    "x": 92,
                    "y": 396.5
                },
                {
                    "x": 93,
                    "y": 399.5
                },
                {
                    "x": 94,
                    "y": 401.5
                },
                {
                    "x": 95,
                    "y": 404.5
                },
                {
                    "x": 95,
                    "y": 407.5
                },
                {
                    "x": 97,
                    "y": 411.5
                },
                {
                    "x": 98,
                    "y": 414.5
                },
                {
                    "x": 99,
                    "y": 416.5
                },
                {
                    "x": 100,
                    "y": 417.5
                },
                {
                    "x": 101,
                    "y": 419.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ff9800",
            "strokeWidth": 20,
            "paths": [
                {
                    "x": 254,
                    "y": 403.5
                },
                {
                    "x": 254,
                    "y": 410.5
                },
                {
                    "x": 254,
                    "y": 416.5
                },
                {
                    "x": 254,
                    "y": 424.5
                },
                {
                    "x": 255,
                    "y": 431.5
                },
                {
                    "x": 257,
                    "y": 437.5
                },
                {
                    "x": 257,
                    "y": 440.5
                },
                {
                    "x": 258,
                    "y": 441.5
                },
                {
                    "x": 259,
                    "y": 442.5
                },
                {
                    "x": 259,
                    "y": 443.5
                },
                {
                    "x": 260,
                    "y": 443.5
                },
                {
                    "x": 263,
                    "y": 441.5
                },
                {
                    "x": 266,
                    "y": 439.5
                },
                {
                    "x": 270,
                    "y": 435.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ff9800",
            "strokeWidth": 20,
            "paths": [
                {
                    "x": 323,
                    "y": 412.5
                },
                {
                    "x": 324,
                    "y": 417.5
                },
                {
                    "x": 325,
                    "y": 420.5
                },
                {
                    "x": 326,
                    "y": 423.5
                },
                {
                    "x": 326,
                    "y": 426.5
                },
                {
                    "x": 328,
                    "y": 427.5
                },
                {
                    "x": 330,
                    "y": 428.5
                },
                {
                    "x": 332,
                    "y": 429.5
                },
                {
                    "x": 335,
                    "y": 429.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ff9800",
            "strokeWidth": 20,
            "paths": [
                {
                    "x": 384,
                    "y": 409.5
                },
                {
                    "x": 384,
                    "y": 411.5
                },
                {
                    "x": 385,
                    "y": 414.5
                },
                {
                    "x": 387,
                    "y": 419.5
                },
                {
                    "x": 389,
                    "y": 426.5
                },
                {
                    "x": 391,
                    "y": 433.5
                },
                {
                    "x": 392,
                    "y": 440.5
                },
                {
                    "x": 393,
                    "y": 447.5
                },
                {
                    "x": 394,
                    "y": 452.5
                },
                {
                    "x": 395,
                    "y": 456.5
                },
                {
                    "x": 396,
                    "y": 460.5
                },
                {
                    "x": 396,
                    "y": 461.5
                },
                {
                    "x": 397,
                    "y": 461.5
                },
                {
                    "x": 398,
                    "y": 461.5
                },
                {
                    "x": 400,
                    "y": 460.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ff9800",
            "strokeWidth": 20,
            "paths": [
                {
                    "x": 434,
                    "y": 411.5
                },
                {
                    "x": 434,
                    "y": 416.5
                },
                {
                    "x": 434,
                    "y": 423.5
                },
                {
                    "x": 434,
                    "y": 428.5
                },
                {
                    "x": 434,
                    "y": 432.5
                },
                {
                    "x": 434,
                    "y": 435.5
                },
                {
                    "x": 434,
                    "y": 438.5
                },
                {
                    "x": 434,
                    "y": 441.5
                },
                {
                    "x": 434,
                    "y": 443.5
                },
                {
                    "x": 434,
                    "y": 445.5
                },
                {
                    "x": 434,
                    "y": 448.5
                }
            ]
        }
    ],
    [
        {
            "drawMode": true,
            "strokeColor": "#617d8b",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 169,
                    "y": 481.5
                },
                {
                    "x": 169,
                    "y": 480.5
                },
                {
                    "x": 169,
                    "y": 479.5
                },
                {
                    "x": 169,
                    "y": 478.5
                },
                {
                    "x": 173,
                    "y": 475.5
                },
                {
                    "x": 181,
                    "y": 473.5
                },
                {
                    "x": 195,
                    "y": 471.5
                },
                {
                    "x": 217,
                    "y": 470.5
                },
                {
                    "x": 247,
                    "y": 470.5
                },
                {
                    "x": 282,
                    "y": 470.5
                },
                {
                    "x": 318,
                    "y": 468.5
                },
                {
                    "x": 354,
                    "y": 466.5
                },
                {
                    "x": 388,
                    "y": 463.5
                },
                {
                    "x": 416,
                    "y": 460.5
                },
                {
                    "x": 439,
                    "y": 458.5
                },
                {
                    "x": 456,
                    "y": 457.5
                },
                {
                    "x": 468,
                    "y": 456.5
                },
                {
                    "x": 477,
                    "y": 455.5
                },
                {
                    "x": 481,
                    "y": 455.5
                },
                {
                    "x": 483,
                    "y": 455.5
                },
                {
                    "x": 481,
                    "y": 455.5
                },
                {
                    "x": 472,
                    "y": 455.5
                },
                {
                    "x": 456,
                    "y": 456.5
                },
                {
                    "x": 425,
                    "y": 458.5
                },
                {
                    "x": 390,
                    "y": 458.5
                },
                {
                    "x": 344,
                    "y": 458.5
                },
                {
                    "x": 292,
                    "y": 458.5
                },
                {
                    "x": 236,
                    "y": 458.5
                },
                {
                    "x": 179,
                    "y": 458.5
                },
                {
                    "x": 127,
                    "y": 458.5
                },
                {
                    "x": 81,
                    "y": 458.5
                },
                {
                    "x": 46,
                    "y": 458.5
                },
                {
                    "x": 27,
                    "y": 458.5
                },
                {
                    "x": 16,
                    "y": 458.5
                },
                {
                    "x": 12,
                    "y": 458.5
                },
                {
                    "x": 13,
                    "y": 458.5
                },
                {
                    "x": 20,
                    "y": 458.5
                },
                {
                    "x": 35,
                    "y": 458.5
                },
                {
                    "x": 56,
                    "y": 455.5
                },
                {
                    "x": 86,
                    "y": 455.5
                },
                {
                    "x": 119,
                    "y": 452.5
                },
                {
                    "x": 158,
                    "y": 450.5
                },
                {
                    "x": 202,
                    "y": 448.5
                },
                {
                    "x": 245,
                    "y": 448.5
                },
                {
                    "x": 292,
                    "y": 444.5
                },
                {
                    "x": 336,
                    "y": 442.5
                },
                {
                    "x": 377,
                    "y": 439.5
                },
                {
                    "x": 414,
                    "y": 435.5
                },
                {
                    "x": 437,
                    "y": 432.5
                },
                {
                    "x": 450,
                    "y": 429.5
                },
                {
                    "x": 457,
                    "y": 427.5
                },
                {
                    "x": 459,
                    "y": 426.5
                },
                {
                    "x": 459,
                    "y": 425.5
                },
                {
                    "x": 457,
                    "y": 424.5
                },
                {
                    "x": 447,
                    "y": 424.5
                },
                {
                    "x": 433,
                    "y": 423.5
                },
                {
                    "x": 410,
                    "y": 422.5
                },
                {
                    "x": 374,
                    "y": 420.5
                },
                {
                    "x": 331,
                    "y": 418.5
                },
                {
                    "x": 279,
                    "y": 416.5
                },
                {
                    "x": 222,
                    "y": 414.5
                },
                {
                    "x": 164,
                    "y": 410.5
                },
                {
                    "x": 110,
                    "y": 408.5
                },
                {
                    "x": 63,
                    "y": 407.5
                },
                {
                    "x": 33,
                    "y": 407.5
                },
                {
                    "x": 17,
                    "y": 407.5
                },
                {
                    "x": 10,
                    "y": 407.5
                },
                {
                    "x": 9,
                    "y": 407.5
                },
                {
                    "x": 11,
                    "y": 407.5
                },
                {
                    "x": 20,
                    "y": 405.5
                },
                {
                    "x": 37,
                    "y": 402.5
                },
                {
                    "x": 71,
                    "y": 397.5
                },
                {
                    "x": 116,
                    "y": 392.5
                },
                {
                    "x": 166,
                    "y": 384.5
                },
                {
                    "x": 227,
                    "y": 374.5
                },
                {
                    "x": 297,
                    "y": 361.5
                },
                {
                    "x": 371,
                    "y": 348.5
                },
                {
                    "x": 441,
                    "y": 334.5
                },
                {
                    "x": 470,
                    "y": 288.5
                },
                {
                    "x": 416,
                    "y": 288.5
                },
                {
                    "x": 361,
                    "y": 288.5
                },
                {
                    "x": 305,
                    "y": 288.5
                },
                {
                    "x": 251,
                    "y": 288.5
                },
                {
                    "x": 199,
                    "y": 288.5
                },
                {
                    "x": 156,
                    "y": 288.5
                },
                {
                    "x": 126,
                    "y": 288.5
                },
                {
                    "x": 111,
                    "y": 288.5
                },
                {
                    "x": 106,
                    "y": 288.5
                },
                {
                    "x": 106,
                    "y": 287.5
                },
                {
                    "x": 111,
                    "y": 284.5
                },
                {
                    "x": 125,
                    "y": 279.5
                },
                {
                    "x": 152,
                    "y": 273.5
                },
                {
                    "x": 188,
                    "y": 268.5
                },
                {
                    "x": 238,
                    "y": 262.5
                },
                {
                    "x": 293,
                    "y": 258.5
                },
                {
                    "x": 351,
                    "y": 252.5
                },
                {
                    "x": 409,
                    "y": 246.5
                },
                {
                    "x": 461,
                    "y": 242.5
                },
                {
                    "x": 464,
                    "y": 236.5
                },
                {
                    "x": 415,
                    "y": 236.5
                },
                {
                    "x": 358,
                    "y": 232.5
                },
                {
                    "x": 298,
                    "y": 230.5
                },
                {
                    "x": 235,
                    "y": 228.5
                },
                {
                    "x": 178,
                    "y": 228.5
                },
                {
                    "x": 127,
                    "y": 226.5
                },
                {
                    "x": 89,
                    "y": 226.5
                },
                {
                    "x": 69,
                    "y": 226.5
                },
                {
                    "x": 61,
                    "y": 226.5
                },
                {
                    "x": 60,
                    "y": 226.5
                },
                {
                    "x": 63,
                    "y": 226.5
                },
                {
                    "x": 78,
                    "y": 226.5
                },
                {
                    "x": 104,
                    "y": 224.5
                },
                {
                    "x": 151,
                    "y": 219.5
                },
                {
                    "x": 214,
                    "y": 213.5
                },
                {
                    "x": 286,
                    "y": 206.5
                },
                {
                    "x": 365,
                    "y": 198.5
                },
                {
                    "x": 445,
                    "y": 187.5
                },
                {
                    "x": 490,
                    "y": 141.5
                },
                {
                    "x": 436,
                    "y": 139.5
                },
                {
                    "x": 384,
                    "y": 137.5
                },
                {
                    "x": 337,
                    "y": 133.5
                },
                {
                    "x": 306,
                    "y": 130.5
                },
                {
                    "x": 288,
                    "y": 127.5
                },
                {
                    "x": 281,
                    "y": 126.5
                },
                {
                    "x": 279,
                    "y": 125.5
                },
                {
                    "x": 280,
                    "y": 124.5
                },
                {
                    "x": 288,
                    "y": 121.5
                },
                {
                    "x": 301,
                    "y": 118.5
                },
                {
                    "x": 324,
                    "y": 114.5
                },
                {
                    "x": 356,
                    "y": 109.5
                },
                {
                    "x": 390,
                    "y": 104.5
                },
                {
                    "x": 418,
                    "y": 100.5
                },
                {
                    "x": 436,
                    "y": 98.5
                },
                {
                    "x": 446,
                    "y": 96.5
                },
                {
                    "x": 450,
                    "y": 96.5
                },
                {
                    "x": 451,
                    "y": 94.5
                },
                {
                    "x": 449,
                    "y": 93.5
                },
                {
                    "x": 442,
                    "y": 92.5
                },
                {
                    "x": 428,
                    "y": 92.5
                },
                {
                    "x": 403,
                    "y": 92.5
                },
                {
                    "x": 363,
                    "y": 92.5
                },
                {
                    "x": 314,
                    "y": 92.5
                },
                {
                    "x": 263,
                    "y": 92.5
                },
                {
                    "x": 208,
                    "y": 92.5
                },
                {
                    "x": 160,
                    "y": 92.5
                },
                {
                    "x": 119,
                    "y": 92.5
                },
                {
                    "x": 92,
                    "y": 92.5
                },
                {
                    "x": 77,
                    "y": 92.5
                },
                {
                    "x": 71,
                    "y": 92.5
                },
                {
                    "x": 70,
                    "y": 92.5
                },
                {
                    "x": 73,
                    "y": 90.5
                },
                {
                    "x": 86,
                    "y": 88.5
                },
                {
                    "x": 113,
                    "y": 85.5
                },
                {
                    "x": 160,
                    "y": 80.5
                },
                {
                    "x": 215,
                    "y": 76.5
                },
                {
                    "x": 275,
                    "y": 72.5
                },
                {
                    "x": 338,
                    "y": 70.5
                },
                {
                    "x": 400,
                    "y": 68.5
                },
                {
                    "x": 457,
                    "y": 66.5
                },
                {
                    "x": 488,
                    "y": 57.5
                },
                {
                    "x": 436,
                    "y": 53.5
                },
                {
                    "x": 376,
                    "y": 49.5
                },
                {
                    "x": 310,
                    "y": 45.5
                },
                {
                    "x": 239,
                    "y": 40.5
                },
                {
                    "x": 175,
                    "y": 40.5
                },
                {
                    "x": 120,
                    "y": 40.5
                },
                {
                    "x": 85,
                    "y": 40.5
                },
                {
                    "x": 69,
                    "y": 40.5
                },
                {
                    "x": 61,
                    "y": 40.5
                },
                {
                    "x": 59,
                    "y": 40.5
                },
                {
                    "x": 62,
                    "y": 40.5
                },
                {
                    "x": 74,
                    "y": 40.5
                },
                {
                    "x": 97,
                    "y": 38.5
                },
                {
                    "x": 139,
                    "y": 34.5
                },
                {
                    "x": 191,
                    "y": 30.5
                },
                {
                    "x": 248,
                    "y": 26.5
                },
                {
                    "x": 308,
                    "y": 24.5
                },
                {
                    "x": 368,
                    "y": 20.5
                },
                {
                    "x": 423,
                    "y": 18.5
                },
                {
                    "x": 463,
                    "y": 18.5
                },
                {
                    "x": 489,
                    "y": 17.5
                },
                {
                    "x": 499,
                    "y": 17.5
                },
                {
                    "x": 493,
                    "y": 17.5
                },
                {
                    "x": 475,
                    "y": 17.5
                },
                {
                    "x": 439,
                    "y": 17.5
                },
                {
                    "x": 391,
                    "y": 17.5
                },
                {
                    "x": 336,
                    "y": 18.5
                },
                {
                    "x": 282,
                    "y": 18.5
                },
                {
                    "x": 233,
                    "y": 20.5
                },
                {
                    "x": 192,
                    "y": 21.5
                },
                {
                    "x": 161,
                    "y": 23.5
                },
                {
                    "x": 143,
                    "y": 26.5
                },
                {
                    "x": 134,
                    "y": 28.5
                },
                {
                    "x": 131,
                    "y": 29.5
                },
                {
                    "x": 129,
                    "y": 31.5
                },
                {
                    "x": 129,
                    "y": 32.5
                },
                {
                    "x": 131,
                    "y": 33.5
                },
                {
                    "x": 134,
                    "y": 34.5
                },
                {
                    "x": 139,
                    "y": 34.5
                },
                {
                    "x": 146,
                    "y": 34.5
                },
                {
                    "x": 156,
                    "y": 34.5
                },
                {
                    "x": 166,
                    "y": 34.5
                },
                {
                    "x": 177,
                    "y": 34.5
                },
                {
                    "x": 189,
                    "y": 34.5
                },
                {
                    "x": 198,
                    "y": 34.5
                },
                {
                    "x": 206,
                    "y": 36.5
                },
                {
                    "x": 211,
                    "y": 38.5
                },
                {
                    "x": 213,
                    "y": 39.5
                },
                {
                    "x": 213,
                    "y": 41.5
                },
                {
                    "x": 206,
                    "y": 45.5
                },
                {
                    "x": 190,
                    "y": 50.5
                },
                {
                    "x": 161,
                    "y": 55.5
                },
                {
                    "x": 117,
                    "y": 58.5
                },
                {
                    "x": 68,
                    "y": 62.5
                },
                {
                    "x": 13,
                    "y": 66.5
                },
                {
                    "x": 16,
                    "y": 108.5
                },
                {
                    "x": 70,
                    "y": 108.5
                },
                {
                    "x": 124,
                    "y": 108.5
                },
                {
                    "x": 176,
                    "y": 107.5
                },
                {
                    "x": 220,
                    "y": 105.5
                },
                {
                    "x": 254,
                    "y": 100.5
                },
                {
                    "x": 275,
                    "y": 96.5
                },
                {
                    "x": 286,
                    "y": 92.5
                },
                {
                    "x": 291,
                    "y": 87.5
                },
                {
                    "x": 291,
                    "y": 83.5
                },
                {
                    "x": 287,
                    "y": 78.5
                },
                {
                    "x": 277,
                    "y": 72.5
                },
                {
                    "x": 263,
                    "y": 66.5
                },
                {
                    "x": 244,
                    "y": 62.5
                },
                {
                    "x": 223,
                    "y": 58.5
                },
                {
                    "x": 203,
                    "y": 57.5
                },
                {
                    "x": 187,
                    "y": 57.5
                },
                {
                    "x": 175,
                    "y": 57.5
                },
                {
                    "x": 168,
                    "y": 57.5
                },
                {
                    "x": 163,
                    "y": 59.5
                },
                {
                    "x": 161,
                    "y": 62.5
                },
                {
                    "x": 159,
                    "y": 65.5
                },
                {
                    "x": 159,
                    "y": 71.5
                },
                {
                    "x": 160,
                    "y": 78.5
                },
                {
                    "x": 162,
                    "y": 89.5
                },
                {
                    "x": 166,
                    "y": 101.5
                },
                {
                    "x": 169,
                    "y": 116.5
                },
                {
                    "x": 173,
                    "y": 135.5
                },
                {
                    "x": 176,
                    "y": 158.5
                },
                {
                    "x": 178,
                    "y": 183.5
                },
                {
                    "x": 179,
                    "y": 213.5
                },
                {
                    "x": 179,
                    "y": 246.5
                },
                {
                    "x": 181,
                    "y": 282.5
                },
                {
                    "x": 181,
                    "y": 315.5
                },
                {
                    "x": 182,
                    "y": 345.5
                },
                {
                    "x": 184,
                    "y": 373.5
                },
                {
                    "x": 187,
                    "y": 391.5
                },
                {
                    "x": 189,
                    "y": 402.5
                },
                {
                    "x": 190,
                    "y": 408.5
                },
                {
                    "x": 190,
                    "y": 413.5
                },
                {
                    "x": 190,
                    "y": 415.5
                },
                {
                    "x": 191,
                    "y": 415.5
                },
                {
                    "x": 192,
                    "y": 414.5
                },
                {
                    "x": 194,
                    "y": 404.5
                },
                {
                    "x": 196,
                    "y": 385.5
                },
                {
                    "x": 199,
                    "y": 357.5
                },
                {
                    "x": 205,
                    "y": 323.5
                },
                {
                    "x": 210,
                    "y": 286.5
                },
                {
                    "x": 213,
                    "y": 247.5
                },
                {
                    "x": 217,
                    "y": 208.5
                },
                {
                    "x": 219,
                    "y": 172.5
                },
                {
                    "x": 219,
                    "y": 140.5
                },
                {
                    "x": 219,
                    "y": 115.5
                },
                {
                    "x": 215,
                    "y": 94.5
                },
                {
                    "x": 212,
                    "y": 82.5
                },
                {
                    "x": 210,
                    "y": 76.5
                },
                {
                    "x": 209,
                    "y": 74.5
                },
                {
                    "x": 208,
                    "y": 74.5
                },
                {
                    "x": 207,
                    "y": 82.5
                },
                {
                    "x": 207,
                    "y": 96.5
                },
                {
                    "x": 207,
                    "y": 123.5
                },
                {
                    "x": 211,
                    "y": 164.5
                },
                {
                    "x": 217,
                    "y": 219.5
                },
                {
                    "x": 225,
                    "y": 277.5
                },
                {
                    "x": 233,
                    "y": 336.5
                },
                {
                    "x": 239,
                    "y": 385.5
                },
                {
                    "x": 242,
                    "y": 422.5
                },
                {
                    "x": 243,
                    "y": 439.5
                },
                {
                    "x": 244,
                    "y": 448.5
                },
                {
                    "x": 245,
                    "y": 452.5
                },
                {
                    "x": 246,
                    "y": 453.5
                },
                {
                    "x": 247,
                    "y": 452.5
                },
                {
                    "x": 251,
                    "y": 445.5
                },
                {
                    "x": 257,
                    "y": 429.5
                },
                {
                    "x": 267,
                    "y": 399.5
                },
                {
                    "x": 280,
                    "y": 363.5
                },
                {
                    "x": 295,
                    "y": 322.5
                },
                {
                    "x": 310,
                    "y": 278.5
                },
                {
                    "x": 325,
                    "y": 237.5
                },
                {
                    "x": 338,
                    "y": 196.5
                },
                {
                    "x": 347,
                    "y": 158.5
                },
                {
                    "x": 354,
                    "y": 129.5
                },
                {
                    "x": 356,
                    "y": 113.5
                },
                {
                    "x": 358,
                    "y": 105.5
                },
                {
                    "x": 359,
                    "y": 102.5
                },
                {
                    "x": 360,
                    "y": 106.5
                },
                {
                    "x": 362,
                    "y": 118.5
                },
                {
                    "x": 367,
                    "y": 142.5
                },
                {
                    "x": 376,
                    "y": 177.5
                },
                {
                    "x": 385,
                    "y": 219.5
                },
                {
                    "x": 393,
                    "y": 259.5
                },
                {
                    "x": 398,
                    "y": 296.5
                },
                {
                    "x": 401,
                    "y": 324.5
                },
                {
                    "x": 402,
                    "y": 344.5
                },
                {
                    "x": 403,
                    "y": 354.5
                },
                {
                    "x": 403,
                    "y": 358.5
                },
                {
                    "x": 404,
                    "y": 359.5
                },
                {
                    "x": 405,
                    "y": 358.5
                },
                {
                    "x": 407,
                    "y": 350.5
                },
                {
                    "x": 409,
                    "y": 337.5
                },
                {
                    "x": 411,
                    "y": 314.5
                },
                {
                    "x": 413,
                    "y": 281.5
                },
                {
                    "x": 415,
                    "y": 240.5
                },
                {
                    "x": 415,
                    "y": 199.5
                },
                {
                    "x": 415,
                    "y": 159.5
                },
                {
                    "x": 415,
                    "y": 126.5
                },
                {
                    "x": 415,
                    "y": 107.5
                },
                {
                    "x": 415,
                    "y": 96.5
                },
                {
                    "x": 416,
                    "y": 92.5
                },
                {
                    "x": 419,
                    "y": 98.5
                },
                {
                    "x": 425,
                    "y": 118.5
                },
                {
                    "x": 436,
                    "y": 156.5
                },
                {
                    "x": 446,
                    "y": 204.5
                },
                {
                    "x": 456,
                    "y": 257.5
                },
                {
                    "x": 461,
                    "y": 304.5
                },
                {
                    "x": 467,
                    "y": 346.5
                },
                {
                    "x": 470,
                    "y": 374.5
                },
                {
                    "x": 471,
                    "y": 389.5
                },
                {
                    "x": 472,
                    "y": 395.5
                },
                {
                    "x": 472,
                    "y": 397.5
                },
                {
                    "x": 473,
                    "y": 397.5
                },
                {
                    "x": 473,
                    "y": 395.5
                },
                {
                    "x": 474,
                    "y": 387.5
                },
                {
                    "x": 474,
                    "y": 372.5
                },
                {
                    "x": 473,
                    "y": 350.5
                },
                {
                    "x": 470,
                    "y": 319.5
                },
                {
                    "x": 463,
                    "y": 284.5
                },
                {
                    "x": 456,
                    "y": 250.5
                },
                {
                    "x": 451,
                    "y": 221.5
                },
                {
                    "x": 449,
                    "y": 203.5
                },
                {
                    "x": 448,
                    "y": 193.5
                },
                {
                    "x": 448,
                    "y": 189.5
                },
                {
                    "x": 448,
                    "y": 188.5
                },
                {
                    "x": 447,
                    "y": 188.5
                },
                {
                    "x": 447,
                    "y": 195.5
                },
                {
                    "x": 447,
                    "y": 206.5
                },
                {
                    "x": 447,
                    "y": 225.5
                },
                {
                    "x": 447,
                    "y": 255.5
                },
                {
                    "x": 447,
                    "y": 287.5
                },
                {
                    "x": 445,
                    "y": 318.5
                },
                {
                    "x": 442,
                    "y": 346.5
                },
                {
                    "x": 438,
                    "y": 365.5
                },
                {
                    "x": 435,
                    "y": 377.5
                },
                {
                    "x": 431,
                    "y": 387.5
                },
                {
                    "x": 427,
                    "y": 393.5
                },
                {
                    "x": 422,
                    "y": 396.5
                },
                {
                    "x": 419,
                    "y": 398.5
                },
                {
                    "x": 415,
                    "y": 400.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#617d8b",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 185,
                    "y": 344.5
                },
                {
                    "x": 181,
                    "y": 336.5
                },
                {
                    "x": 177,
                    "y": 326.5
                },
                {
                    "x": 171,
                    "y": 316.5
                },
                {
                    "x": 163,
                    "y": 306.5
                },
                {
                    "x": 155,
                    "y": 299.5
                },
                {
                    "x": 147,
                    "y": 292.5
                },
                {
                    "x": 138,
                    "y": 288.5
                },
                {
                    "x": 131,
                    "y": 286.5
                },
                {
                    "x": 124,
                    "y": 286.5
                },
                {
                    "x": 119,
                    "y": 286.5
                },
                {
                    "x": 114,
                    "y": 289.5
                },
                {
                    "x": 111,
                    "y": 295.5
                },
                {
                    "x": 109,
                    "y": 303.5
                },
                {
                    "x": 108,
                    "y": 312.5
                },
                {
                    "x": 108,
                    "y": 324.5
                },
                {
                    "x": 108,
                    "y": 337.5
                },
                {
                    "x": 108,
                    "y": 351.5
                },
                {
                    "x": 108,
                    "y": 364.5
                },
                {
                    "x": 108,
                    "y": 375.5
                },
                {
                    "x": 108,
                    "y": 383.5
                },
                {
                    "x": 110,
                    "y": 388.5
                },
                {
                    "x": 112,
                    "y": 392.5
                },
                {
                    "x": 114,
                    "y": 393.5
                },
                {
                    "x": 118,
                    "y": 394.5
                },
                {
                    "x": 122,
                    "y": 394.5
                },
                {
                    "x": 128,
                    "y": 391.5
                },
                {
                    "x": 134,
                    "y": 385.5
                },
                {
                    "x": 141,
                    "y": 375.5
                },
                {
                    "x": 147,
                    "y": 361.5
                },
                {
                    "x": 151,
                    "y": 340.5
                },
                {
                    "x": 152,
                    "y": 322.5
                },
                {
                    "x": 152,
                    "y": 306.5
                },
                {
                    "x": 152,
                    "y": 294.5
                },
                {
                    "x": 150,
                    "y": 284.5
                },
                {
                    "x": 147,
                    "y": 278.5
                },
                {
                    "x": 143,
                    "y": 275.5
                },
                {
                    "x": 139,
                    "y": 272.5
                },
                {
                    "x": 132,
                    "y": 271.5
                },
                {
                    "x": 123,
                    "y": 271.5
                },
                {
                    "x": 113,
                    "y": 271.5
                },
                {
                    "x": 103,
                    "y": 275.5
                },
                {
                    "x": 95,
                    "y": 281.5
                },
                {
                    "x": 89,
                    "y": 289.5
                },
                {
                    "x": 86,
                    "y": 299.5
                },
                {
                    "x": 86,
                    "y": 310.5
                },
                {
                    "x": 92,
                    "y": 320.5
                },
                {
                    "x": 99,
                    "y": 328.5
                },
                {
                    "x": 109,
                    "y": 335.5
                },
                {
                    "x": 120,
                    "y": 338.5
                },
                {
                    "x": 132,
                    "y": 339.5
                },
                {
                    "x": 143,
                    "y": 338.5
                },
                {
                    "x": 155,
                    "y": 333.5
                },
                {
                    "x": 167,
                    "y": 325.5
                },
                {
                    "x": 179,
                    "y": 313.5
                },
                {
                    "x": 187,
                    "y": 297.5
                },
                {
                    "x": 192,
                    "y": 278.5
                },
                {
                    "x": 192,
                    "y": 262.5
                },
                {
                    "x": 189,
                    "y": 243.5
                },
                {
                    "x": 181,
                    "y": 225.5
                },
                {
                    "x": 169,
                    "y": 211.5
                },
                {
                    "x": 154,
                    "y": 197.5
                },
                {
                    "x": 132,
                    "y": 183.5
                },
                {
                    "x": 109,
                    "y": 172.5
                },
                {
                    "x": 87,
                    "y": 165.5
                },
                {
                    "x": 71,
                    "y": 163.5
                },
                {
                    "x": 61,
                    "y": 163.5
                },
                {
                    "x": 55,
                    "y": 163.5
                },
                {
                    "x": 53,
                    "y": 168.5
                },
                {
                    "x": 54,
                    "y": 175.5
                },
                {
                    "x": 62,
                    "y": 185.5
                },
                {
                    "x": 76,
                    "y": 198.5
                },
                {
                    "x": 94,
                    "y": 207.5
                },
                {
                    "x": 113,
                    "y": 211.5
                },
                {
                    "x": 129,
                    "y": 211.5
                },
                {
                    "x": 146,
                    "y": 206.5
                },
                {
                    "x": 161,
                    "y": 196.5
                },
                {
                    "x": 172,
                    "y": 183.5
                },
                {
                    "x": 180,
                    "y": 172.5
                },
                {
                    "x": 186,
                    "y": 161.5
                },
                {
                    "x": 191,
                    "y": 151.5
                },
                {
                    "x": 193,
                    "y": 143.5
                },
                {
                    "x": 193,
                    "y": 137.5
                },
                {
                    "x": 191,
                    "y": 133.5
                },
                {
                    "x": 185,
                    "y": 130.5
                },
                {
                    "x": 174,
                    "y": 126.5
                },
                {
                    "x": 159,
                    "y": 125.5
                },
                {
                    "x": 139,
                    "y": 125.5
                },
                {
                    "x": 112,
                    "y": 125.5
                },
                {
                    "x": 84,
                    "y": 127.5
                },
                {
                    "x": 60,
                    "y": 132.5
                },
                {
                    "x": 41,
                    "y": 137.5
                },
                {
                    "x": 28,
                    "y": 142.5
                },
                {
                    "x": 21,
                    "y": 147.5
                },
                {
                    "x": 18,
                    "y": 151.5
                },
                {
                    "x": 18,
                    "y": 153.5
                },
                {
                    "x": 19,
                    "y": 154.5
                },
                {
                    "x": 25,
                    "y": 155.5
                },
                {
                    "x": 38,
                    "y": 156.5
                },
                {
                    "x": 56,
                    "y": 156.5
                },
                {
                    "x": 84,
                    "y": 156.5
                },
                {
                    "x": 111,
                    "y": 156.5
                },
                {
                    "x": 136,
                    "y": 156.5
                },
                {
                    "x": 157,
                    "y": 153.5
                },
                {
                    "x": 169,
                    "y": 151.5
                },
                {
                    "x": 174,
                    "y": 151.5
                },
                {
                    "x": 176,
                    "y": 151.5
                },
                {
                    "x": 174,
                    "y": 152.5
                },
                {
                    "x": 166,
                    "y": 157.5
                },
                {
                    "x": 151,
                    "y": 164.5
                },
                {
                    "x": 132,
                    "y": 175.5
                },
                {
                    "x": 115,
                    "y": 188.5
                },
                {
                    "x": 104,
                    "y": 200.5
                },
                {
                    "x": 97,
                    "y": 213.5
                },
                {
                    "x": 95,
                    "y": 229.5
                },
                {
                    "x": 96,
                    "y": 244.5
                },
                {
                    "x": 104,
                    "y": 259.5
                },
                {
                    "x": 116,
                    "y": 274.5
                },
                {
                    "x": 135,
                    "y": 287.5
                },
                {
                    "x": 156,
                    "y": 299.5
                },
                {
                    "x": 181,
                    "y": 309.5
                },
                {
                    "x": 206,
                    "y": 317.5
                },
                {
                    "x": 228,
                    "y": 324.5
                },
                {
                    "x": 245,
                    "y": 329.5
                },
                {
                    "x": 256,
                    "y": 335.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ffffff",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 182,
                    "y": 276.5
                },
                {
                    "x": 175,
                    "y": 267.5
                },
                {
                    "x": 163,
                    "y": 260.5
                },
                {
                    "x": 149,
                    "y": 254.5
                },
                {
                    "x": 133,
                    "y": 248.5
                },
                {
                    "x": 119,
                    "y": 246.5
                },
                {
                    "x": 106,
                    "y": 246.5
                },
                {
                    "x": 95,
                    "y": 247.5
                },
                {
                    "x": 85,
                    "y": 251.5
                },
                {
                    "x": 79,
                    "y": 255.5
                },
                {
                    "x": 76,
                    "y": 258.5
                },
                {
                    "x": 73,
                    "y": 262.5
                },
                {
                    "x": 73,
                    "y": 265.5
                },
                {
                    "x": 75,
                    "y": 268.5
                },
                {
                    "x": 82,
                    "y": 273.5
                },
                {
                    "x": 96,
                    "y": 281.5
                },
                {
                    "x": 120,
                    "y": 291.5
                },
                {
                    "x": 150,
                    "y": 303.5
                },
                {
                    "x": 184,
                    "y": 317.5
                },
                {
                    "x": 215,
                    "y": 331.5
                },
                {
                    "x": 241,
                    "y": 342.5
                },
                {
                    "x": 259,
                    "y": 352.5
                },
                {
                    "x": 270,
                    "y": 360.5
                },
                {
                    "x": 278,
                    "y": 368.5
                },
                {
                    "x": 282,
                    "y": 374.5
                },
                {
                    "x": 283,
                    "y": 379.5
                },
                {
                    "x": 281,
                    "y": 384.5
                },
                {
                    "x": 275,
                    "y": 388.5
                },
                {
                    "x": 267,
                    "y": 390.5
                },
                {
                    "x": 257,
                    "y": 391.5
                },
                {
                    "x": 247,
                    "y": 391.5
                },
                {
                    "x": 235,
                    "y": 391.5
                },
                {
                    "x": 224,
                    "y": 391.5
                },
                {
                    "x": 215,
                    "y": 391.5
                },
                {
                    "x": 208,
                    "y": 392.5
                },
                {
                    "x": 203,
                    "y": 393.5
                },
                {
                    "x": 201,
                    "y": 395.5
                },
                {
                    "x": 199,
                    "y": 399.5
                },
                {
                    "x": 200,
                    "y": 403.5
                },
                {
                    "x": 208,
                    "y": 408.5
                },
                {
                    "x": 220,
                    "y": 412.5
                },
                {
                    "x": 239,
                    "y": 414.5
                },
                {
                    "x": 263,
                    "y": 414.5
                },
                {
                    "x": 291,
                    "y": 414.5
                },
                {
                    "x": 317,
                    "y": 411.5
                },
                {
                    "x": 338,
                    "y": 406.5
                },
                {
                    "x": 357,
                    "y": 401.5
                },
                {
                    "x": 369,
                    "y": 396.5
                },
                {
                    "x": 376,
                    "y": 393.5
                },
                {
                    "x": 379,
                    "y": 393.5
                },
                {
                    "x": 379,
                    "y": 392.5
                },
                {
                    "x": 375,
                    "y": 392.5
                },
                {
                    "x": 361,
                    "y": 392.5
                },
                {
                    "x": 341,
                    "y": 392.5
                },
                {
                    "x": 316,
                    "y": 393.5
                },
                {
                    "x": 288,
                    "y": 396.5
                },
                {
                    "x": 265,
                    "y": 399.5
                },
                {
                    "x": 251,
                    "y": 400.5
                },
                {
                    "x": 243,
                    "y": 401.5
                },
                {
                    "x": 240,
                    "y": 400.5
                },
                {
                    "x": 239,
                    "y": 397.5
                },
                {
                    "x": 242,
                    "y": 388.5
                },
                {
                    "x": 252,
                    "y": 377.5
                },
                {
                    "x": 275,
                    "y": 355.5
                },
                {
                    "x": 308,
                    "y": 330.5
                },
                {
                    "x": 346,
                    "y": 303.5
                },
                {
                    "x": 386,
                    "y": 280.5
                },
                {
                    "x": 420,
                    "y": 262.5
                },
                {
                    "x": 444,
                    "y": 251.5
                },
                {
                    "x": 457,
                    "y": 244.5
                },
                {
                    "x": 462,
                    "y": 241.5
                },
                {
                    "x": 464,
                    "y": 240.5
                },
                {
                    "x": 464,
                    "y": 239.5
                },
                {
                    "x": 458,
                    "y": 239.5
                },
                {
                    "x": 449,
                    "y": 240.5
                },
                {
                    "x": 437,
                    "y": 244.5
                },
                {
                    "x": 423,
                    "y": 247.5
                },
                {
                    "x": 408,
                    "y": 250.5
                },
                {
                    "x": 392,
                    "y": 254.5
                },
                {
                    "x": 380,
                    "y": 257.5
                },
                {
                    "x": 370,
                    "y": 261.5
                },
                {
                    "x": 362,
                    "y": 264.5
                },
                {
                    "x": 356,
                    "y": 267.5
                },
                {
                    "x": 353,
                    "y": 270.5
                },
                {
                    "x": 352,
                    "y": 273.5
                },
                {
                    "x": 353,
                    "y": 278.5
                },
                {
                    "x": 358,
                    "y": 284.5
                },
                {
                    "x": 367,
                    "y": 292.5
                },
                {
                    "x": 377,
                    "y": 301.5
                },
                {
                    "x": 387,
                    "y": 310.5
                },
                {
                    "x": 397,
                    "y": 318.5
                }
            ]
        },
        {
            "drawMode": false,
            "strokeColor": "#000000",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 321,
                    "y": 145.5
                },
                {
                    "x": 321,
                    "y": 144.5
                },
                {
                    "x": 320,
                    "y": 141.5
                },
                {
                    "x": 320,
                    "y": 140.5
                },
                {
                    "x": 320,
                    "y": 139.5
                },
                {
                    "x": 320,
                    "y": 138.5
                },
                {
                    "x": 319,
                    "y": 138.5
                },
                {
                    "x": 317,
                    "y": 138.5
                },
                {
                    "x": 314,
                    "y": 138.5
                },
                {
                    "x": 310,
                    "y": 138.5
                },
                {
                    "x": 301,
                    "y": 140.5
                },
                {
                    "x": 292,
                    "y": 144.5
                },
                {
                    "x": 280,
                    "y": 147.5
                },
                {
                    "x": 266,
                    "y": 152.5
                },
                {
                    "x": 245,
                    "y": 156.5
                },
                {
                    "x": 225,
                    "y": 161.5
                },
                {
                    "x": 201,
                    "y": 167.5
                },
                {
                    "x": 180,
                    "y": 172.5
                },
                {
                    "x": 160,
                    "y": 174.5
                },
                {
                    "x": 142,
                    "y": 175.5
                },
                {
                    "x": 129,
                    "y": 177.5
                },
                {
                    "x": 120,
                    "y": 177.5
                },
                {
                    "x": 113,
                    "y": 177.5
                },
                {
                    "x": 110,
                    "y": 177.5
                },
                {
                    "x": 109,
                    "y": 176.5
                },
                {
                    "x": 109,
                    "y": 175.5
                },
                {
                    "x": 109,
                    "y": 174.5
                },
                {
                    "x": 111,
                    "y": 172.5
                }
            ]
        },
        {
            "drawMode": false,
            "strokeColor": "#000000",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 110,
                    "y": 103.5
                },
                {
                    "x": 111,
                    "y": 111.5
                },
                {
                    "x": 111,
                    "y": 121.5
                },
                {
                    "x": 111,
                    "y": 135.5
                },
                {
                    "x": 112,
                    "y": 151.5
                },
                {
                    "x": 117,
                    "y": 177.5
                },
                {
                    "x": 123,
                    "y": 207.5
                },
                {
                    "x": 132,
                    "y": 239.5
                },
                {
                    "x": 140,
                    "y": 272.5
                },
                {
                    "x": 150,
                    "y": 302.5
                },
                {
                    "x": 158,
                    "y": 327.5
                },
                {
                    "x": 164,
                    "y": 341.5
                },
                {
                    "x": 168,
                    "y": 351.5
                },
                {
                    "x": 172,
                    "y": 356.5
                },
                {
                    "x": 174,
                    "y": 358.5
                },
                {
                    "x": 175,
                    "y": 360.5
                }
            ]
        },
        {
            "drawMode": false,
            "strokeColor": "#000000",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 351,
                    "y": 279.5
                },
                {
                    "x": 359,
                    "y": 285.5
                },
                {
                    "x": 367,
                    "y": 293.5
                },
                {
                    "x": 377,
                    "y": 301.5
                },
                {
                    "x": 387,
                    "y": 307.5
                },
                {
                    "x": 398,
                    "y": 313.5
                },
                {
                    "x": 411,
                    "y": 317.5
                },
                {
                    "x": 423,
                    "y": 321.5
                },
                {
                    "x": 435,
                    "y": 324.5
                },
                {
                    "x": 447,
                    "y": 327.5
                },
                {
                    "x": 458,
                    "y": 329.5
                },
                {
                    "x": 468,
                    "y": 331.5
                },
                {
                    "x": 476,
                    "y": 334.5
                },
                {
                    "x": 482,
                    "y": 336.5
                },
                {
                    "x": 485,
                    "y": 339.5
                },
                {
                    "x": 487,
                    "y": 341.5
                },
                {
                    "x": 488,
                    "y": 344.5
                },
                {
                    "x": 488,
                    "y": 346.5
                }
            ]
        },
        {
            "drawMode": false,
            "strokeColor": "#000000",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 393,
                    "y": 380.5
                },
                {
                    "x": 382,
                    "y": 378.5
                },
                {
                    "x": 369,
                    "y": 376.5
                },
                {
                    "x": 353,
                    "y": 372.5
                },
                {
                    "x": 332,
                    "y": 368.5
                },
                {
                    "x": 308,
                    "y": 362.5
                },
                {
                    "x": 284,
                    "y": 358.5
                },
                {
                    "x": 262,
                    "y": 352.5
                },
                {
                    "x": 246,
                    "y": 348.5
                },
                {
                    "x": 233,
                    "y": 345.5
                },
                {
                    "x": 222,
                    "y": 342.5
                },
                {
                    "x": 212,
                    "y": 339.5
                },
                {
                    "x": 204,
                    "y": 337.5
                }
            ]
        },
        {
            "drawMode": false,
            "strokeColor": "#000000",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 180,
                    "y": 437.5
                },
                {
                    "x": 180,
                    "y": 439.5
                },
                {
                    "x": 179,
                    "y": 440.5
                },
                {
                    "x": 179,
                    "y": 441.5
                },
                {
                    "x": 179,
                    "y": 442.5
                },
                {
                    "x": 178,
                    "y": 443.5
                },
                {
                    "x": 177,
                    "y": 443.5
                },
                {
                    "x": 176,
                    "y": 443.5
                },
                {
                    "x": 174,
                    "y": 443.5
                },
                {
                    "x": 172,
                    "y": 443.5
                },
                {
                    "x": 169,
                    "y": 443.5
                },
                {
                    "x": 165,
                    "y": 443.5
                },
                {
                    "x": 161,
                    "y": 443.5
                },
                {
                    "x": 157,
                    "y": 443.5
                },
                {
                    "x": 153,
                    "y": 443.5
                },
                {
                    "x": 148,
                    "y": 442.5
                },
                {
                    "x": 144,
                    "y": 440.5
                },
                {
                    "x": 138,
                    "y": 438.5
                }
            ]
        },
        {
            "drawMode": false,
            "strokeColor": "#000000",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 77,
                    "y": 420.5
                },
                {
                    "x": 78,
                    "y": 419.5
                },
                {
                    "x": 81,
                    "y": 415.5
                },
                {
                    "x": 83,
                    "y": 411.5
                },
                {
                    "x": 87,
                    "y": 406.5
                },
                {
                    "x": 91,
                    "y": 400.5
                },
                {
                    "x": 95,
                    "y": 393.5
                },
                {
                    "x": 100,
                    "y": 384.5
                },
                {
                    "x": 103,
                    "y": 375.5
                },
                {
                    "x": 106,
                    "y": 364.5
                },
                {
                    "x": 111,
                    "y": 351.5
                },
                {
                    "x": 116,
                    "y": 334.5
                },
                {
                    "x": 120,
                    "y": 313.5
                },
                {
                    "x": 124,
                    "y": 291.5
                },
                {
                    "x": 128,
                    "y": 275.5
                },
                {
                    "x": 131,
                    "y": 262.5
                },
                {
                    "x": 133,
                    "y": 249.5
                },
                {
                    "x": 136,
                    "y": 235.5
                },
                {
                    "x": 137,
                    "y": 222.5
                },
                {
                    "x": 137,
                    "y": 210.5
                },
                {
                    "x": 134,
                    "y": 198.5
                },
                {
                    "x": 130,
                    "y": 188.5
                },
                {
                    "x": 126,
                    "y": 181.5
                },
                {
                    "x": 120,
                    "y": 175.5
                },
                {
                    "x": 112,
                    "y": 168.5
                },
                {
                    "x": 105,
                    "y": 163.5
                },
                {
                    "x": 99,
                    "y": 157.5
                },
                {
                    "x": 94,
                    "y": 152.5
                },
                {
                    "x": 90,
                    "y": 146.5
                },
                {
                    "x": 87,
                    "y": 141.5
                },
                {
                    "x": 85,
                    "y": 137.5
                },
                {
                    "x": 84,
                    "y": 131.5
                },
                {
                    "x": 84,
                    "y": 126.5
                },
                {
                    "x": 84,
                    "y": 120.5
                },
                {
                    "x": 86,
                    "y": 115.5
                },
                {
                    "x": 89,
                    "y": 112.5
                },
                {
                    "x": 93,
                    "y": 108.5
                },
                {
                    "x": 98,
                    "y": 106.5
                }
            ]
        },
        {
            "drawMode": false,
            "strokeColor": "#000000",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 236,
                    "y": 76.5
                },
                {
                    "x": 244,
                    "y": 75.5
                },
                {
                    "x": 254,
                    "y": 74.5
                },
                {
                    "x": 264,
                    "y": 74.5
                },
                {
                    "x": 277,
                    "y": 73.5
                },
                {
                    "x": 290,
                    "y": 73.5
                },
                {
                    "x": 302,
                    "y": 72.5
                },
                {
                    "x": 314,
                    "y": 71.5
                },
                {
                    "x": 324,
                    "y": 71.5
                },
                {
                    "x": 332,
                    "y": 71.5
                },
                {
                    "x": 339,
                    "y": 71.5
                },
                {
                    "x": 343,
                    "y": 71.5
                },
                {
                    "x": 348,
                    "y": 71.5
                },
                {
                    "x": 352,
                    "y": 73.5
                },
                {
                    "x": 358,
                    "y": 77.5
                },
                {
                    "x": 365,
                    "y": 81.5
                },
                {
                    "x": 374,
                    "y": 87.5
                },
                {
                    "x": 381,
                    "y": 93.5
                },
                {
                    "x": 389,
                    "y": 98.5
                },
                {
                    "x": 395,
                    "y": 103.5
                },
                {
                    "x": 401,
                    "y": 107.5
                },
                {
                    "x": 406,
                    "y": 110.5
                },
                {
                    "x": 408,
                    "y": 111.5
                },
                {
                    "x": 409,
                    "y": 111.5
                },
                {
                    "x": 410,
                    "y": 113.5
                },
                {
                    "x": 410,
                    "y": 114.5
                },
                {
                    "x": 410,
                    "y": 116.5
                },
                {
                    "x": 409,
                    "y": 118.5
                },
                {
                    "x": 408,
                    "y": 121.5
                },
                {
                    "x": 405,
                    "y": 127.5
                },
                {
                    "x": 402,
                    "y": 134.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ffeb3b",
            "strokeWidth": 8,
            "paths": [
                {
                    "x": 153,
                    "y": 383.5
                },
                {
                    "x": 152,
                    "y": 386.5
                },
                {
                    "x": 152,
                    "y": 388.5
                },
                {
                    "x": 151,
                    "y": 390.5
                },
                {
                    "x": 151,
                    "y": 392.5
                },
                {
                    "x": 151,
                    "y": 394.5
                },
                {
                    "x": 151,
                    "y": 395.5
                },
                {
                    "x": 151,
                    "y": 396.5
                },
                {
                    "x": 151,
                    "y": 397.5
                },
                {
                    "x": 153,
                    "y": 398.5
                },
                {
                    "x": 155,
                    "y": 400.5
                },
                {
                    "x": 159,
                    "y": 403.5
                },
                {
                    "x": 167,
                    "y": 409.5
                },
                {
                    "x": 173,
                    "y": 413.5
                },
                {
                    "x": 180,
                    "y": 418.5
                },
                {
                    "x": 186,
                    "y": 423.5
                },
                {
                    "x": 194,
                    "y": 427.5
                },
                {
                    "x": 200,
                    "y": 431.5
                },
                {
                    "x": 207,
                    "y": 434.5
                },
                {
                    "x": 215,
                    "y": 437.5
                },
                {
                    "x": 225,
                    "y": 441.5
                },
                {
                    "x": 236,
                    "y": 443.5
                },
                {
                    "x": 248,
                    "y": 445.5
                },
                {
                    "x": 260,
                    "y": 446.5
                },
                {
                    "x": 273,
                    "y": 446.5
                },
                {
                    "x": 285,
                    "y": 446.5
                },
                {
                    "x": 295,
                    "y": 443.5
                },
                {
                    "x": 302,
                    "y": 439.5
                },
                {
                    "x": 308,
                    "y": 432.5
                },
                {
                    "x": 313,
                    "y": 425.5
                },
                {
                    "x": 318,
                    "y": 417.5
                },
                {
                    "x": 321,
                    "y": 407.5
                },
                {
                    "x": 322,
                    "y": 399.5
                },
                {
                    "x": 323,
                    "y": 389.5
                },
                {
                    "x": 323,
                    "y": 380.5
                },
                {
                    "x": 322,
                    "y": 372.5
                },
                {
                    "x": 322,
                    "y": 366.5
                },
                {
                    "x": 322,
                    "y": 362.5
                },
                {
                    "x": 321,
                    "y": 358.5
                },
                {
                    "x": 320,
                    "y": 353.5
                },
                {
                    "x": 318,
                    "y": 348.5
                },
                {
                    "x": 316,
                    "y": 342.5
                },
                {
                    "x": 313,
                    "y": 335.5
                },
                {
                    "x": 309,
                    "y": 326.5
                },
                {
                    "x": 302,
                    "y": 315.5
                },
                {
                    "x": 294,
                    "y": 304.5
                },
                {
                    "x": 287,
                    "y": 293.5
                },
                {
                    "x": 279,
                    "y": 284.5
                },
                {
                    "x": 273,
                    "y": 278.5
                },
                {
                    "x": 267,
                    "y": 273.5
                },
                {
                    "x": 263,
                    "y": 270.5
                },
                {
                    "x": 258,
                    "y": 267.5
                },
                {
                    "x": 255,
                    "y": 266.5
                },
                {
                    "x": 252,
                    "y": 266.5
                },
                {
                    "x": 248,
                    "y": 265.5
                },
                {
                    "x": 243,
                    "y": 265.5
                },
                {
                    "x": 236,
                    "y": 265.5
                },
                {
                    "x": 228,
                    "y": 265.5
                },
                {
                    "x": 218,
                    "y": 266.5
                },
                {
                    "x": 209,
                    "y": 269.5
                },
                {
                    "x": 199,
                    "y": 273.5
                },
                {
                    "x": 189,
                    "y": 280.5
                },
                {
                    "x": 180,
                    "y": 288.5
                },
                {
                    "x": 172,
                    "y": 298.5
                },
                {
                    "x": 165,
                    "y": 312.5
                },
                {
                    "x": 159,
                    "y": 329.5
                },
                {
                    "x": 153,
                    "y": 346.5
                },
                {
                    "x": 151,
                    "y": 359.5
                },
                {
                    "x": 149,
                    "y": 369.5
                },
                {
                    "x": 148,
                    "y": 376.5
                },
                {
                    "x": 148,
                    "y": 379.5
                },
                {
                    "x": 148,
                    "y": 382.5
                },
                {
                    "x": 152,
                    "y": 385.5
                },
                {
                    "x": 163,
                    "y": 388.5
                },
                {
                    "x": 181,
                    "y": 392.5
                },
                {
                    "x": 206,
                    "y": 395.5
                },
                {
                    "x": 242,
                    "y": 396.5
                },
                {
                    "x": 278,
                    "y": 396.5
                },
                {
                    "x": 312,
                    "y": 391.5
                },
                {
                    "x": 345,
                    "y": 378.5
                },
                {
                    "x": 370,
                    "y": 362.5
                },
                {
                    "x": 389,
                    "y": 341.5
                },
                {
                    "x": 397,
                    "y": 321.5
                },
                {
                    "x": 397,
                    "y": 299.5
                },
                {
                    "x": 391,
                    "y": 277.5
                },
                {
                    "x": 377,
                    "y": 256.5
                },
                {
                    "x": 358,
                    "y": 235.5
                },
                {
                    "x": 333,
                    "y": 215.5
                },
                {
                    "x": 301,
                    "y": 197.5
                },
                {
                    "x": 265,
                    "y": 183.5
                },
                {
                    "x": 232,
                    "y": 172.5
                },
                {
                    "x": 205,
                    "y": 166.5
                },
                {
                    "x": 192,
                    "y": 165.5
                },
                {
                    "x": 187,
                    "y": 165.5
                },
                {
                    "x": 184,
                    "y": 165.5
                },
                {
                    "x": 184,
                    "y": 168.5
                },
                {
                    "x": 188,
                    "y": 171.5
                },
                {
                    "x": 198,
                    "y": 176.5
                },
                {
                    "x": 212,
                    "y": 180.5
                },
                {
                    "x": 233,
                    "y": 183.5
                },
                {
                    "x": 258,
                    "y": 184.5
                },
                {
                    "x": 288,
                    "y": 184.5
                },
                {
                    "x": 315,
                    "y": 179.5
                },
                {
                    "x": 341,
                    "y": 166.5
                },
                {
                    "x": 361,
                    "y": 149.5
                },
                {
                    "x": 373,
                    "y": 132.5
                },
                {
                    "x": 379,
                    "y": 120.5
                },
                {
                    "x": 381,
                    "y": 110.5
                },
                {
                    "x": 381,
                    "y": 103.5
                },
                {
                    "x": 376,
                    "y": 97.5
                },
                {
                    "x": 365,
                    "y": 93.5
                },
                {
                    "x": 348,
                    "y": 89.5
                },
                {
                    "x": 317,
                    "y": 88.5
                },
                {
                    "x": 279,
                    "y": 88.5
                },
                {
                    "x": 243,
                    "y": 90.5
                },
                {
                    "x": 216,
                    "y": 98.5
                },
                {
                    "x": 200,
                    "y": 107.5
                },
                {
                    "x": 192,
                    "y": 117.5
                },
                {
                    "x": 192,
                    "y": 127.5
                },
                {
                    "x": 199,
                    "y": 135.5
                },
                {
                    "x": 215,
                    "y": 143.5
                },
                {
                    "x": 249,
                    "y": 148.5
                },
                {
                    "x": 295,
                    "y": 148.5
                },
                {
                    "x": 348,
                    "y": 143.5
                },
                {
                    "x": 404,
                    "y": 131.5
                },
                {
                    "x": 456,
                    "y": 113.5
                },
                {
                    "x": 490,
                    "y": 2.5
                },
                {
                    "x": 445,
                    "y": 6.5
                },
                {
                    "x": 402,
                    "y": 18.5
                },
                {
                    "x": 367,
                    "y": 38.5
                },
                {
                    "x": 344,
                    "y": 59.5
                },
                {
                    "x": 334,
                    "y": 80.5
                },
                {
                    "x": 334,
                    "y": 97.5
                },
                {
                    "x": 346,
                    "y": 116.5
                },
                {
                    "x": 363,
                    "y": 129.5
                },
                {
                    "x": 387,
                    "y": 136.5
                },
                {
                    "x": 418,
                    "y": 140.5
                },
                {
                    "x": 457,
                    "y": 137.5
                },
                {
                    "x": 484,
                    "y": 55.5
                },
                {
                    "x": 426,
                    "y": 58.5
                },
                {
                    "x": 377,
                    "y": 62.5
                },
                {
                    "x": 345,
                    "y": 69.5
                },
                {
                    "x": 331,
                    "y": 73.5
                },
                {
                    "x": 325,
                    "y": 76.5
                },
                {
                    "x": 323,
                    "y": 78.5
                },
                {
                    "x": 325,
                    "y": 79.5
                },
                {
                    "x": 331,
                    "y": 80.5
                },
                {
                    "x": 345,
                    "y": 80.5
                },
                {
                    "x": 365,
                    "y": 80.5
                },
                {
                    "x": 391,
                    "y": 78.5
                },
                {
                    "x": 414,
                    "y": 75.5
                },
                {
                    "x": 430,
                    "y": 73.5
                },
                {
                    "x": 441,
                    "y": 70.5
                },
                {
                    "x": 446,
                    "y": 67.5
                },
                {
                    "x": 447,
                    "y": 65.5
                },
                {
                    "x": 446,
                    "y": 61.5
                },
                {
                    "x": 437,
                    "y": 57.5
                },
                {
                    "x": 420,
                    "y": 53.5
                },
                {
                    "x": 388,
                    "y": 48.5
                },
                {
                    "x": 341,
                    "y": 43.5
                },
                {
                    "x": 284,
                    "y": 39.5
                },
                {
                    "x": 221,
                    "y": 35.5
                },
                {
                    "x": 159,
                    "y": 35.5
                },
                {
                    "x": 112,
                    "y": 37.5
                },
                {
                    "x": 82,
                    "y": 46.5
                },
                {
                    "x": 71,
                    "y": 52.5
                },
                {
                    "x": 68,
                    "y": 60.5
                },
                {
                    "x": 71,
                    "y": 69.5
                },
                {
                    "x": 81,
                    "y": 77.5
                },
                {
                    "x": 97,
                    "y": 83.5
                },
                {
                    "x": 123,
                    "y": 88.5
                },
                {
                    "x": 156,
                    "y": 88.5
                },
                {
                    "x": 190,
                    "y": 85.5
                },
                {
                    "x": 222,
                    "y": 79.5
                },
                {
                    "x": 249,
                    "y": 69.5
                },
                {
                    "x": 270,
                    "y": 59.5
                },
                {
                    "x": 280,
                    "y": 50.5
                },
                {
                    "x": 286,
                    "y": 40.5
                },
                {
                    "x": 287,
                    "y": 29.5
                },
                {
                    "x": 283,
                    "y": 19.5
                },
                {
                    "x": 276,
                    "y": 12.5
                },
                {
                    "x": 262,
                    "y": 6.5
                },
                {
                    "x": 238,
                    "y": 1.5
                },
                {
                    "x": 161,
                    "y": -0.5
                },
                {
                    "x": 126,
                    "y": 6.5
                },
                {
                    "x": 105,
                    "y": 16.5
                },
                {
                    "x": 96,
                    "y": 26.5
                },
                {
                    "x": 93,
                    "y": 40.5
                },
                {
                    "x": 95,
                    "y": 53.5
                },
                {
                    "x": 102,
                    "y": 64.5
                },
                {
                    "x": 111,
                    "y": 73.5
                },
                {
                    "x": 121,
                    "y": 77.5
                },
                {
                    "x": 133,
                    "y": 79.5
                },
                {
                    "x": 146,
                    "y": 78.5
                },
                {
                    "x": 160,
                    "y": 74.5
                },
                {
                    "x": 172,
                    "y": 68.5
                },
                {
                    "x": 182,
                    "y": 60.5
                },
                {
                    "x": 189,
                    "y": 52.5
                },
                {
                    "x": 192,
                    "y": 42.5
                },
                {
                    "x": 192,
                    "y": 33.5
                },
                {
                    "x": 188,
                    "y": 24.5
                },
                {
                    "x": 180,
                    "y": 17.5
                },
                {
                    "x": 168,
                    "y": 11.5
                },
                {
                    "x": 148,
                    "y": 7.5
                },
                {
                    "x": 113,
                    "y": 5.5
                },
                {
                    "x": 67,
                    "y": 5.5
                },
                {
                    "x": 19,
                    "y": 10.5
                },
                {
                    "x": 9,
                    "y": 115.5
                },
                {
                    "x": 40,
                    "y": 111.5
                },
                {
                    "x": 72,
                    "y": 102.5
                },
                {
                    "x": 101,
                    "y": 89.5
                },
                {
                    "x": 123,
                    "y": 73.5
                },
                {
                    "x": 136,
                    "y": 56.5
                },
                {
                    "x": 140,
                    "y": 44.5
                },
                {
                    "x": 140,
                    "y": 33.5
                },
                {
                    "x": 137,
                    "y": 25.5
                },
                {
                    "x": 130,
                    "y": 21.5
                },
                {
                    "x": 116,
                    "y": 18.5
                },
                {
                    "x": 92,
                    "y": 18.5
                },
                {
                    "x": 53,
                    "y": 21.5
                },
                {
                    "x": 10,
                    "y": 32.5
                },
                {
                    "x": 2,
                    "y": 150.5
                },
                {
                    "x": 27,
                    "y": 143.5
                },
                {
                    "x": 49,
                    "y": 133.5
                },
                {
                    "x": 68,
                    "y": 122.5
                },
                {
                    "x": 80,
                    "y": 111.5
                },
                {
                    "x": 87,
                    "y": 102.5
                },
                {
                    "x": 90,
                    "y": 97.5
                },
                {
                    "x": 90,
                    "y": 95.5
                },
                {
                    "x": 89,
                    "y": 94.5
                },
                {
                    "x": 84,
                    "y": 94.5
                },
                {
                    "x": 72,
                    "y": 96.5
                },
                {
                    "x": 56,
                    "y": 102.5
                },
                {
                    "x": 37,
                    "y": 114.5
                },
                {
                    "x": 20,
                    "y": 128.5
                },
                {
                    "x": 9,
                    "y": 145.5
                },
                {
                    "x": 3,
                    "y": 159.5
                },
                {
                    "x": 0,
                    "y": 192.5
                },
                {
                    "x": 6,
                    "y": 199.5
                },
                {
                    "x": 11,
                    "y": 202.5
                },
                {
                    "x": 20,
                    "y": 204.5
                },
                {
                    "x": 32,
                    "y": 204.5
                },
                {
                    "x": 44,
                    "y": 203.5
                },
                {
                    "x": 56,
                    "y": 200.5
                },
                {
                    "x": 67,
                    "y": 194.5
                },
                {
                    "x": 75,
                    "y": 188.5
                },
                {
                    "x": 79,
                    "y": 182.5
                },
                {
                    "x": 79,
                    "y": 175.5
                },
                {
                    "x": 74,
                    "y": 166.5
                },
                {
                    "x": 65,
                    "y": 160.5
                },
                {
                    "x": 53,
                    "y": 157.5
                },
                {
                    "x": 35,
                    "y": 155.5
                },
                {
                    "x": 12,
                    "y": 156.5
                },
                {
                    "x": 22,
                    "y": 337.5
                },
                {
                    "x": 47,
                    "y": 348.5
                },
                {
                    "x": 74,
                    "y": 354.5
                },
                {
                    "x": 99,
                    "y": 354.5
                },
                {
                    "x": 120,
                    "y": 351.5
                },
                {
                    "x": 137,
                    "y": 344.5
                },
                {
                    "x": 148,
                    "y": 336.5
                },
                {
                    "x": 155,
                    "y": 328.5
                },
                {
                    "x": 157,
                    "y": 316.5
                },
                {
                    "x": 153,
                    "y": 302.5
                },
                {
                    "x": 141,
                    "y": 285.5
                },
                {
                    "x": 121,
                    "y": 270.5
                },
                {
                    "x": 93,
                    "y": 258.5
                },
                {
                    "x": 64,
                    "y": 252.5
                },
                {
                    "x": 36,
                    "y": 252.5
                },
                {
                    "x": 10,
                    "y": 257.5
                },
                {
                    "x": 4,
                    "y": 438.5
                },
                {
                    "x": 19,
                    "y": 448.5
                },
                {
                    "x": 31,
                    "y": 454.5
                },
                {
                    "x": 44,
                    "y": 455.5
                },
                {
                    "x": 59,
                    "y": 454.5
                },
                {
                    "x": 76,
                    "y": 447.5
                },
                {
                    "x": 88,
                    "y": 439.5
                },
                {
                    "x": 95,
                    "y": 429.5
                },
                {
                    "x": 100,
                    "y": 417.5
                },
                {
                    "x": 101,
                    "y": 405.5
                },
                {
                    "x": 101,
                    "y": 394.5
                },
                {
                    "x": 101,
                    "y": 388.5
                },
                {
                    "x": 101,
                    "y": 385.5
                },
                {
                    "x": 101,
                    "y": 383.5
                },
                {
                    "x": 98,
                    "y": 383.5
                },
                {
                    "x": 92,
                    "y": 387.5
                },
                {
                    "x": 82,
                    "y": 394.5
                },
                {
                    "x": 70,
                    "y": 407.5
                },
                {
                    "x": 60,
                    "y": 421.5
                },
                {
                    "x": 53,
                    "y": 438.5
                },
                {
                    "x": 49,
                    "y": 454.5
                },
                {
                    "x": 49,
                    "y": 468.5
                },
                {
                    "x": 57,
                    "y": 483.5
                },
                {
                    "x": 67,
                    "y": 493.5
                },
                {
                    "x": 81,
                    "y": 499.5
                },
                {
                    "x": 127,
                    "y": 497.5
                },
                {
                    "x": 160,
                    "y": 484.5
                },
                {
                    "x": 195,
                    "y": 466.5
                },
                {
                    "x": 225,
                    "y": 445.5
                },
                {
                    "x": 248,
                    "y": 422.5
                },
                {
                    "x": 266,
                    "y": 399.5
                },
                {
                    "x": 276,
                    "y": 378.5
                },
                {
                    "x": 280,
                    "y": 362.5
                },
                {
                    "x": 280,
                    "y": 352.5
                },
                {
                    "x": 280,
                    "y": 346.5
                },
                {
                    "x": 276,
                    "y": 343.5
                },
                {
                    "x": 271,
                    "y": 342.5
                },
                {
                    "x": 264,
                    "y": 344.5
                },
                {
                    "x": 258,
                    "y": 350.5
                },
                {
                    "x": 252,
                    "y": 361.5
                },
                {
                    "x": 250,
                    "y": 376.5
                },
                {
                    "x": 250,
                    "y": 393.5
                },
                {
                    "x": 256,
                    "y": 415.5
                },
                {
                    "x": 263,
                    "y": 430.5
                },
                {
                    "x": 273,
                    "y": 442.5
                },
                {
                    "x": 283,
                    "y": 448.5
                },
                {
                    "x": 292,
                    "y": 451.5
                },
                {
                    "x": 303,
                    "y": 451.5
                },
                {
                    "x": 314,
                    "y": 450.5
                },
                {
                    "x": 325,
                    "y": 443.5
                },
                {
                    "x": 332,
                    "y": 435.5
                },
                {
                    "x": 338,
                    "y": 424.5
                },
                {
                    "x": 340,
                    "y": 412.5
                },
                {
                    "x": 339,
                    "y": 399.5
                },
                {
                    "x": 333,
                    "y": 386.5
                },
                {
                    "x": 327,
                    "y": 376.5
                },
                {
                    "x": 319,
                    "y": 370.5
                },
                {
                    "x": 313,
                    "y": 367.5
                },
                {
                    "x": 308,
                    "y": 367.5
                },
                {
                    "x": 304,
                    "y": 369.5
                },
                {
                    "x": 301,
                    "y": 376.5
                },
                {
                    "x": 302,
                    "y": 387.5
                },
                {
                    "x": 307,
                    "y": 401.5
                },
                {
                    "x": 318,
                    "y": 417.5
                },
                {
                    "x": 335,
                    "y": 435.5
                },
                {
                    "x": 352,
                    "y": 448.5
                },
                {
                    "x": 369,
                    "y": 454.5
                },
                {
                    "x": 381,
                    "y": 456.5
                },
                {
                    "x": 393,
                    "y": 455.5
                },
                {
                    "x": 404,
                    "y": 450.5
                },
                {
                    "x": 413,
                    "y": 442.5
                },
                {
                    "x": 418,
                    "y": 430.5
                },
                {
                    "x": 421,
                    "y": 413.5
                },
                {
                    "x": 419,
                    "y": 387.5
                },
                {
                    "x": 411,
                    "y": 362.5
                },
                {
                    "x": 396,
                    "y": 336.5
                },
                {
                    "x": 379,
                    "y": 313.5
                },
                {
                    "x": 365,
                    "y": 298.5
                },
                {
                    "x": 356,
                    "y": 292.5
                },
                {
                    "x": 353,
                    "y": 289.5
                },
                {
                    "x": 351,
                    "y": 289.5
                },
                {
                    "x": 348,
                    "y": 290.5
                },
                {
                    "x": 345,
                    "y": 296.5
                },
                {
                    "x": 344,
                    "y": 305.5
                },
                {
                    "x": 344,
                    "y": 314.5
                },
                {
                    "x": 349,
                    "y": 322.5
                },
                {
                    "x": 354,
                    "y": 330.5
                },
                {
                    "x": 360,
                    "y": 334.5
                },
                {
                    "x": 368,
                    "y": 336.5
                },
                {
                    "x": 379,
                    "y": 337.5
                },
                {
                    "x": 394,
                    "y": 338.5
                },
                {
                    "x": 412,
                    "y": 337.5
                },
                {
                    "x": 440,
                    "y": 329.5
                },
                {
                    "x": 465,
                    "y": 317.5
                },
                {
                    "x": 485,
                    "y": 304.5
                },
                {
                    "x": 497,
                    "y": 291.5
                },
                {
                    "x": 495,
                    "y": 240.5
                },
                {
                    "x": 484,
                    "y": 235.5
                },
                {
                    "x": 470,
                    "y": 230.5
                },
                {
                    "x": 457,
                    "y": 228.5
                },
                {
                    "x": 442,
                    "y": 228.5
                },
                {
                    "x": 430,
                    "y": 228.5
                },
                {
                    "x": 422,
                    "y": 228.5
                },
                {
                    "x": 419,
                    "y": 228.5
                },
                {
                    "x": 419,
                    "y": 227.5
                },
                {
                    "x": 420,
                    "y": 223.5
                },
                {
                    "x": 424,
                    "y": 217.5
                },
                {
                    "x": 429,
                    "y": 210.5
                },
                {
                    "x": 434,
                    "y": 204.5
                },
                {
                    "x": 437,
                    "y": 198.5
                },
                {
                    "x": 439,
                    "y": 194.5
                },
                {
                    "x": 439,
                    "y": 192.5
                },
                {
                    "x": 437,
                    "y": 190.5
                },
                {
                    "x": 433,
                    "y": 189.5
                },
                {
                    "x": 425,
                    "y": 189.5
                },
                {
                    "x": 413,
                    "y": 193.5
                },
                {
                    "x": 401,
                    "y": 198.5
                },
                {
                    "x": 391,
                    "y": 205.5
                },
                {
                    "x": 385,
                    "y": 210.5
                },
                {
                    "x": 381,
                    "y": 214.5
                },
                {
                    "x": 380,
                    "y": 216.5
                },
                {
                    "x": 380,
                    "y": 217.5
                },
                {
                    "x": 383,
                    "y": 218.5
                },
                {
                    "x": 390,
                    "y": 218.5
                },
                {
                    "x": 401,
                    "y": 215.5
                },
                {
                    "x": 415,
                    "y": 209.5
                },
                {
                    "x": 433,
                    "y": 199.5
                },
                {
                    "x": 445,
                    "y": 190.5
                },
                {
                    "x": 452,
                    "y": 180.5
                },
                {
                    "x": 454,
                    "y": 170.5
                },
                {
                    "x": 452,
                    "y": 160.5
                },
                {
                    "x": 444,
                    "y": 152.5
                },
                {
                    "x": 433,
                    "y": 145.5
                },
                {
                    "x": 417,
                    "y": 140.5
                },
                {
                    "x": 394,
                    "y": 137.5
                },
                {
                    "x": 369,
                    "y": 138.5
                },
                {
                    "x": 344,
                    "y": 146.5
                },
                {
                    "x": 325,
                    "y": 159.5
                },
                {
                    "x": 315,
                    "y": 173.5
                },
                {
                    "x": 312,
                    "y": 186.5
                },
                {
                    "x": 315,
                    "y": 200.5
                },
                {
                    "x": 324,
                    "y": 211.5
                },
                {
                    "x": 337,
                    "y": 220.5
                },
                {
                    "x": 350,
                    "y": 224.5
                },
                {
                    "x": 363,
                    "y": 224.5
                },
                {
                    "x": 379,
                    "y": 219.5
                },
                {
                    "x": 395,
                    "y": 210.5
                },
                {
                    "x": 408,
                    "y": 197.5
                },
                {
                    "x": 415,
                    "y": 184.5
                },
                {
                    "x": 417,
                    "y": 171.5
                },
                {
                    "x": 416,
                    "y": 157.5
                },
                {
                    "x": 409,
                    "y": 145.5
                },
                {
                    "x": 397,
                    "y": 134.5
                },
                {
                    "x": 379,
                    "y": 125.5
                },
                {
                    "x": 352,
                    "y": 119.5
                },
                {
                    "x": 314,
                    "y": 117.5
                },
                {
                    "x": 272,
                    "y": 120.5
                },
                {
                    "x": 229,
                    "y": 131.5
                },
                {
                    "x": 192,
                    "y": 150.5
                },
                {
                    "x": 164,
                    "y": 170.5
                },
                {
                    "x": 147,
                    "y": 193.5
                },
                {
                    "x": 140,
                    "y": 210.5
                },
                {
                    "x": 138,
                    "y": 222.5
                },
                {
                    "x": 138,
                    "y": 230.5
                },
                {
                    "x": 139,
                    "y": 235.5
                },
                {
                    "x": 143,
                    "y": 237.5
                },
                {
                    "x": 150,
                    "y": 237.5
                },
                {
                    "x": 161,
                    "y": 234.5
                },
                {
                    "x": 175,
                    "y": 227.5
                },
                {
                    "x": 194,
                    "y": 217.5
                },
                {
                    "x": 211,
                    "y": 204.5
                },
                {
                    "x": 224,
                    "y": 189.5
                },
                {
                    "x": 232,
                    "y": 174.5
                },
                {
                    "x": 236,
                    "y": 162.5
                },
                {
                    "x": 236,
                    "y": 152.5
                },
                {
                    "x": 234,
                    "y": 145.5
                },
                {
                    "x": 228,
                    "y": 140.5
                },
                {
                    "x": 219,
                    "y": 137.5
                },
                {
                    "x": 202,
                    "y": 137.5
                },
                {
                    "x": 174,
                    "y": 139.5
                },
                {
                    "x": 142,
                    "y": 148.5
                },
                {
                    "x": 108,
                    "y": 160.5
                },
                {
                    "x": 84,
                    "y": 178.5
                },
                {
                    "x": 69,
                    "y": 195.5
                },
                {
                    "x": 63,
                    "y": 210.5
                },
                {
                    "x": 63,
                    "y": 225.5
                },
                {
                    "x": 69,
                    "y": 237.5
                },
                {
                    "x": 79,
                    "y": 247.5
                },
                {
                    "x": 93,
                    "y": 253.5
                },
                {
                    "x": 117,
                    "y": 258.5
                },
                {
                    "x": 144,
                    "y": 258.5
                },
                {
                    "x": 179,
                    "y": 252.5
                },
                {
                    "x": 213,
                    "y": 243.5
                },
                {
                    "x": 247,
                    "y": 231.5
                },
                {
                    "x": 276,
                    "y": 215.5
                },
                {
                    "x": 296,
                    "y": 198.5
                },
                {
                    "x": 304,
                    "y": 185.5
                },
                {
                    "x": 308,
                    "y": 173.5
                },
                {
                    "x": 305,
                    "y": 162.5
                },
                {
                    "x": 298,
                    "y": 153.5
                },
                {
                    "x": 284,
                    "y": 147.5
                },
                {
                    "x": 255,
                    "y": 142.5
                },
                {
                    "x": 219,
                    "y": 142.5
                },
                {
                    "x": 181,
                    "y": 145.5
                },
                {
                    "x": 149,
                    "y": 152.5
                },
                {
                    "x": 125,
                    "y": 162.5
                },
                {
                    "x": 113,
                    "y": 173.5
                },
                {
                    "x": 108,
                    "y": 185.5
                },
                {
                    "x": 108,
                    "y": 196.5
                },
                {
                    "x": 110,
                    "y": 210.5
                },
                {
                    "x": 119,
                    "y": 227.5
                },
                {
                    "x": 132,
                    "y": 244.5
                },
                {
                    "x": 145,
                    "y": 259.5
                },
                {
                    "x": 156,
                    "y": 271.5
                },
                {
                    "x": 164,
                    "y": 281.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#ff5722",
            "strokeWidth": 8,
            "paths": [
                {
                    "x": 218,
                    "y": 420.5
                },
                {
                    "x": 213,
                    "y": 420.5
                },
                {
                    "x": 203,
                    "y": 420.5
                },
                {
                    "x": 187,
                    "y": 420.5
                },
                {
                    "x": 161,
                    "y": 420.5
                },
                {
                    "x": 131,
                    "y": 422.5
                },
                {
                    "x": 97,
                    "y": 425.5
                },
                {
                    "x": 71,
                    "y": 430.5
                },
                {
                    "x": 53,
                    "y": 434.5
                },
                {
                    "x": 42,
                    "y": 437.5
                },
                {
                    "x": 36,
                    "y": 441.5
                },
                {
                    "x": 34,
                    "y": 445.5
                },
                {
                    "x": 36,
                    "y": 448.5
                },
                {
                    "x": 47,
                    "y": 454.5
                },
                {
                    "x": 68,
                    "y": 459.5
                },
                {
                    "x": 105,
                    "y": 466.5
                },
                {
                    "x": 155,
                    "y": 472.5
                },
                {
                    "x": 210,
                    "y": 476.5
                },
                {
                    "x": 267,
                    "y": 480.5
                },
                {
                    "x": 317,
                    "y": 482.5
                },
                {
                    "x": 352,
                    "y": 482.5
                },
                {
                    "x": 368,
                    "y": 482.5
                },
                {
                    "x": 377,
                    "y": 482.5
                },
                {
                    "x": 377,
                    "y": 479.5
                },
                {
                    "x": 371,
                    "y": 477.5
                },
                {
                    "x": 353,
                    "y": 472.5
                },
                {
                    "x": 317,
                    "y": 466.5
                },
                {
                    "x": 267,
                    "y": 461.5
                },
                {
                    "x": 206,
                    "y": 457.5
                },
                {
                    "x": 143,
                    "y": 450.5
                },
                {
                    "x": 86,
                    "y": 448.5
                },
                {
                    "x": 40,
                    "y": 448.5
                },
                {
                    "x": 10,
                    "y": 448.5
                },
                {
                    "x": 1,
                    "y": 448.5
                },
                {
                    "x": 24,
                    "y": 446.5
                },
                {
                    "x": 71,
                    "y": 442.5
                },
                {
                    "x": 129,
                    "y": 438.5
                },
                {
                    "x": 186,
                    "y": 434.5
                },
                {
                    "x": 246,
                    "y": 430.5
                },
                {
                    "x": 301,
                    "y": 424.5
                },
                {
                    "x": 346,
                    "y": 420.5
                },
                {
                    "x": 374,
                    "y": 417.5
                },
                {
                    "x": 386,
                    "y": 415.5
                },
                {
                    "x": 390,
                    "y": 414.5
                },
                {
                    "x": 389,
                    "y": 414.5
                },
                {
                    "x": 380,
                    "y": 414.5
                },
                {
                    "x": 358,
                    "y": 414.5
                },
                {
                    "x": 314,
                    "y": 414.5
                },
                {
                    "x": 257,
                    "y": 410.5
                },
                {
                    "x": 189,
                    "y": 407.5
                },
                {
                    "x": 124,
                    "y": 403.5
                },
                {
                    "x": 66,
                    "y": 401.5
                },
                {
                    "x": 28,
                    "y": 401.5
                },
                {
                    "x": 12,
                    "y": 401.5
                },
                {
                    "x": 6,
                    "y": 401.5
                },
                {
                    "x": 7,
                    "y": 402.5
                },
                {
                    "x": 22,
                    "y": 403.5
                },
                {
                    "x": 68,
                    "y": 403.5
                },
                {
                    "x": 141,
                    "y": 403.5
                },
                {
                    "x": 233,
                    "y": 398.5
                },
                {
                    "x": 331,
                    "y": 389.5
                },
                {
                    "x": 429,
                    "y": 380.5
                },
                {
                    "x": 426,
                    "y": 339.5
                },
                {
                    "x": 331,
                    "y": 339.5
                },
                {
                    "x": 242,
                    "y": 340.5
                },
                {
                    "x": 161,
                    "y": 342.5
                },
                {
                    "x": 95,
                    "y": 346.5
                },
                {
                    "x": 49,
                    "y": 350.5
                },
                {
                    "x": 31,
                    "y": 352.5
                },
                {
                    "x": 24,
                    "y": 354.5
                },
                {
                    "x": 24,
                    "y": 355.5
                },
                {
                    "x": 31,
                    "y": 355.5
                },
                {
                    "x": 58,
                    "y": 352.5
                },
                {
                    "x": 114,
                    "y": 346.5
                },
                {
                    "x": 185,
                    "y": 337.5
                },
                {
                    "x": 267,
                    "y": 324.5
                },
                {
                    "x": 355,
                    "y": 311.5
                },
                {
                    "x": 432,
                    "y": 298.5
                },
                {
                    "x": 497,
                    "y": 286.5
                },
                {
                    "x": 473,
                    "y": 265.5
                },
                {
                    "x": 386,
                    "y": 265.5
                },
                {
                    "x": 289,
                    "y": 266.5
                },
                {
                    "x": 194,
                    "y": 271.5
                },
                {
                    "x": 110,
                    "y": 275.5
                },
                {
                    "x": 44,
                    "y": 279.5
                },
                {
                    "x": 0,
                    "y": 283.5
                },
                {
                    "x": 29,
                    "y": 270.5
                },
                {
                    "x": 87,
                    "y": 258.5
                },
                {
                    "x": 162,
                    "y": 243.5
                },
                {
                    "x": 240,
                    "y": 226.5
                },
                {
                    "x": 320,
                    "y": 209.5
                },
                {
                    "x": 390,
                    "y": 192.5
                },
                {
                    "x": 447,
                    "y": 178.5
                },
                {
                    "x": 484,
                    "y": 167.5
                },
                {
                    "x": 496,
                    "y": 153.5
                },
                {
                    "x": 480,
                    "y": 153.5
                },
                {
                    "x": 429,
                    "y": 153.5
                },
                {
                    "x": 372,
                    "y": 153.5
                },
                {
                    "x": 312,
                    "y": 153.5
                },
                {
                    "x": 258,
                    "y": 153.5
                },
                {
                    "x": 215,
                    "y": 153.5
                },
                {
                    "x": 187,
                    "y": 154.5
                },
                {
                    "x": 174,
                    "y": 155.5
                },
                {
                    "x": 170,
                    "y": 155.5
                },
                {
                    "x": 177,
                    "y": 155.5
                },
                {
                    "x": 193,
                    "y": 150.5
                },
                {
                    "x": 223,
                    "y": 142.5
                },
                {
                    "x": 261,
                    "y": 131.5
                },
                {
                    "x": 304,
                    "y": 118.5
                },
                {
                    "x": 348,
                    "y": 104.5
                },
                {
                    "x": 386,
                    "y": 92.5
                },
                {
                    "x": 417,
                    "y": 80.5
                },
                {
                    "x": 437,
                    "y": 71.5
                },
                {
                    "x": 446,
                    "y": 67.5
                },
                {
                    "x": 450,
                    "y": 64.5
                },
                {
                    "x": 450,
                    "y": 62.5
                },
                {
                    "x": 445,
                    "y": 59.5
                },
                {
                    "x": 428,
                    "y": 57.5
                },
                {
                    "x": 393,
                    "y": 57.5
                },
                {
                    "x": 344,
                    "y": 57.5
                },
                {
                    "x": 281,
                    "y": 55.5
                },
                {
                    "x": 216,
                    "y": 55.5
                },
                {
                    "x": 154,
                    "y": 55.5
                },
                {
                    "x": 103,
                    "y": 55.5
                },
                {
                    "x": 68,
                    "y": 55.5
                },
                {
                    "x": 50,
                    "y": 55.5
                },
                {
                    "x": 43,
                    "y": 56.5
                },
                {
                    "x": 46,
                    "y": 57.5
                },
                {
                    "x": 59,
                    "y": 58.5
                },
                {
                    "x": 85,
                    "y": 58.5
                },
                {
                    "x": 132,
                    "y": 57.5
                },
                {
                    "x": 189,
                    "y": 53.5
                },
                {
                    "x": 258,
                    "y": 49.5
                },
                {
                    "x": 326,
                    "y": 45.5
                },
                {
                    "x": 391,
                    "y": 43.5
                },
                {
                    "x": 443,
                    "y": 41.5
                },
                {
                    "x": 475,
                    "y": 41.5
                },
                {
                    "x": 487,
                    "y": 40.5
                },
                {
                    "x": 490,
                    "y": 40.5
                },
                {
                    "x": 485,
                    "y": 40.5
                },
                {
                    "x": 467,
                    "y": 40.5
                },
                {
                    "x": 424,
                    "y": 40.5
                },
                {
                    "x": 362,
                    "y": 40.5
                },
                {
                    "x": 272,
                    "y": 41.5
                },
                {
                    "x": 175,
                    "y": 46.5
                },
                {
                    "x": 77,
                    "y": 52.5
                },
                {
                    "x": 68,
                    "y": 83.5
                },
                {
                    "x": 144,
                    "y": 81.5
                },
                {
                    "x": 217,
                    "y": 81.5
                },
                {
                    "x": 282,
                    "y": 79.5
                },
                {
                    "x": 331,
                    "y": 77.5
                },
                {
                    "x": 361,
                    "y": 77.5
                },
                {
                    "x": 373,
                    "y": 77.5
                },
                {
                    "x": 375,
                    "y": 77.5
                },
                {
                    "x": 373,
                    "y": 77.5
                },
                {
                    "x": 360,
                    "y": 80.5
                },
                {
                    "x": 329,
                    "y": 85.5
                },
                {
                    "x": 268,
                    "y": 91.5
                },
                {
                    "x": 192,
                    "y": 97.5
                },
                {
                    "x": 107,
                    "y": 108.5
                },
                {
                    "x": 25,
                    "y": 119.5
                },
                {
                    "x": 31,
                    "y": 168.5
                },
                {
                    "x": 91,
                    "y": 168.5
                },
                {
                    "x": 150,
                    "y": 168.5
                },
                {
                    "x": 201,
                    "y": 168.5
                },
                {
                    "x": 239,
                    "y": 168.5
                },
                {
                    "x": 262,
                    "y": 169.5
                },
                {
                    "x": 273,
                    "y": 169.5
                },
                {
                    "x": 275,
                    "y": 171.5
                },
                {
                    "x": 274,
                    "y": 172.5
                },
                {
                    "x": 266,
                    "y": 175.5
                },
                {
                    "x": 244,
                    "y": 180.5
                },
                {
                    "x": 196,
                    "y": 190.5
                },
                {
                    "x": 130,
                    "y": 200.5
                },
                {
                    "x": 53,
                    "y": 213.5
                },
                {
                    "x": 90,
                    "y": 325.5
                },
                {
                    "x": 185,
                    "y": 316.5
                },
                {
                    "x": 278,
                    "y": 305.5
                },
                {
                    "x": 366,
                    "y": 296.5
                },
                {
                    "x": 442,
                    "y": 288.5
                },
                {
                    "x": 497,
                    "y": 284.5
                },
                {
                    "x": 453,
                    "y": 298.5
                },
                {
                    "x": 386,
                    "y": 312.5
                },
                {
                    "x": 313,
                    "y": 329.5
                },
                {
                    "x": 243,
                    "y": 351.5
                },
                {
                    "x": 180,
                    "y": 376.5
                },
                {
                    "x": 132,
                    "y": 400.5
                },
                {
                    "x": 100,
                    "y": 421.5
                },
                {
                    "x": 82,
                    "y": 436.5
                },
                {
                    "x": 76,
                    "y": 444.5
                },
                {
                    "x": 76,
                    "y": 450.5
                },
                {
                    "x": 81,
                    "y": 454.5
                },
                {
                    "x": 96,
                    "y": 457.5
                },
                {
                    "x": 122,
                    "y": 460.5
                },
                {
                    "x": 155,
                    "y": 462.5
                },
                {
                    "x": 186,
                    "y": 464.5
                },
                {
                    "x": 214,
                    "y": 467.5
                },
                {
                    "x": 235,
                    "y": 471.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 65,
                    "y": 58.5
                },
                {
                    "x": 65,
                    "y": 57.5
                },
                {
                    "x": 65,
                    "y": 62.5
                },
                {
                    "x": 68,
                    "y": 73.5
                },
                {
                    "x": 71,
                    "y": 89.5
                },
                {
                    "x": 76,
                    "y": 112.5
                },
                {
                    "x": 79,
                    "y": 143.5
                },
                {
                    "x": 81,
                    "y": 179.5
                },
                {
                    "x": 81,
                    "y": 220.5
                },
                {
                    "x": 81,
                    "y": 260.5
                },
                {
                    "x": 81,
                    "y": 260.5
                },
                {
                    "x": 78,
                    "y": 299.5
                },
                {
                    "x": 73,
                    "y": 328.5
                },
                {
                    "x": 69,
                    "y": 349.5
                },
                {
                    "x": 67,
                    "y": 363.5
                },
                {
                    "x": 65,
                    "y": 372.5
                },
                {
                    "x": 65,
                    "y": 380.5
                },
                {
                    "x": 65,
                    "y": 387.5
                },
                {
                    "x": 65,
                    "y": 393.5
                },
                {
                    "x": 66,
                    "y": 398.5
                },
                {
                    "x": 67,
                    "y": 403.5
                },
                {
                    "x": 71,
                    "y": 406.5
                },
                {
                    "x": 76,
                    "y": 409.5
                },
                {
                    "x": 80,
                    "y": 411.5
                },
                {
                    "x": 85,
                    "y": 412.5
                },
                {
                    "x": 87,
                    "y": 412.5
                },
                {
                    "x": 87,
                    "y": 412.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 45,
                    "y": 141.5
                },
                {
                    "x": 45,
                    "y": 141.5
                },
                {
                    "x": 45,
                    "y": 143.5
                },
                {
                    "x": 46,
                    "y": 146.5
                },
                {
                    "x": 47,
                    "y": 152.5
                },
                {
                    "x": 48,
                    "y": 159.5
                },
                {
                    "x": 50,
                    "y": 168.5
                },
                {
                    "x": 52,
                    "y": 178.5
                },
                {
                    "x": 53,
                    "y": 187.5
                },
                {
                    "x": 54,
                    "y": 195.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 143,
                    "y": 35.5
                },
                {
                    "x": 143,
                    "y": 48.5
                },
                {
                    "x": 143,
                    "y": 70.5
                },
                {
                    "x": 143,
                    "y": 108.5
                },
                {
                    "x": 143,
                    "y": 157.5
                },
                {
                    "x": 143,
                    "y": 211.5
                },
                {
                    "x": 143,
                    "y": 268.5
                },
                {
                    "x": 143,
                    "y": 322.5
                },
                {
                    "x": 143,
                    "y": 368.5
                },
                {
                    "x": 143,
                    "y": 398.5
                },
                {
                    "x": 143,
                    "y": 413.5
                },
                {
                    "x": 143,
                    "y": 418.5
                },
                {
                    "x": 143,
                    "y": 419.5
                },
                {
                    "x": 143,
                    "y": 415.5
                },
                {
                    "x": 143,
                    "y": 408.5
                },
                {
                    "x": 142,
                    "y": 399.5
                },
                {
                    "x": 140,
                    "y": 386.5
                },
                {
                    "x": 137,
                    "y": 369.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 152,
                    "y": 147.5
                },
                {
                    "x": 153,
                    "y": 167.5
                },
                {
                    "x": 155,
                    "y": 195.5
                },
                {
                    "x": 156,
                    "y": 223.5
                },
                {
                    "x": 159,
                    "y": 248.5
                },
                {
                    "x": 162,
                    "y": 272.5
                },
                {
                    "x": 165,
                    "y": 292.5
                },
                {
                    "x": 168,
                    "y": 306.5
                },
                {
                    "x": 174,
                    "y": 317.5
                },
                {
                    "x": 179,
                    "y": 324.5
                },
                {
                    "x": 184,
                    "y": 328.5
                },
                {
                    "x": 190,
                    "y": 331.5
                },
                {
                    "x": 196,
                    "y": 331.5
                },
                {
                    "x": 204,
                    "y": 329.5
                },
                {
                    "x": 211,
                    "y": 322.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 214,
                    "y": 135.5
                },
                {
                    "x": 212,
                    "y": 168.5
                },
                {
                    "x": 212,
                    "y": 206.5
                },
                {
                    "x": 212,
                    "y": 246.5
                },
                {
                    "x": 211,
                    "y": 287.5
                },
                {
                    "x": 209,
                    "y": 326.5
                },
                {
                    "x": 209,
                    "y": 361.5
                },
                {
                    "x": 207,
                    "y": 389.5
                },
                {
                    "x": 207,
                    "y": 406.5
                },
                {
                    "x": 207,
                    "y": 416.5
                },
                {
                    "x": 207,
                    "y": 420.5
                },
                {
                    "x": 207,
                    "y": 421.5
                },
                {
                    "x": 207,
                    "y": 419.5
                },
                {
                    "x": 208,
                    "y": 410.5
                },
                {
                    "x": 210,
                    "y": 395.5
                },
                {
                    "x": 213,
                    "y": 369.5
                },
                {
                    "x": 217,
                    "y": 335.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 243,
                    "y": 154.5
                },
                {
                    "x": 243,
                    "y": 190.5
                },
                {
                    "x": 244,
                    "y": 231.5
                },
                {
                    "x": 244,
                    "y": 274.5
                },
                {
                    "x": 244,
                    "y": 318.5
                },
                {
                    "x": 244,
                    "y": 353.5
                },
                {
                    "x": 244,
                    "y": 377.5
                },
                {
                    "x": 244,
                    "y": 391.5
                },
                {
                    "x": 244,
                    "y": 395.5
                },
                {
                    "x": 245,
                    "y": 395.5
                },
                {
                    "x": 246,
                    "y": 394.5
                },
                {
                    "x": 249,
                    "y": 384.5
                },
                {
                    "x": 253,
                    "y": 370.5
                },
                {
                    "x": 258,
                    "y": 353.5
                },
                {
                    "x": 265,
                    "y": 333.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 284,
                    "y": 230.5
                },
                {
                    "x": 284,
                    "y": 279.5
                },
                {
                    "x": 282,
                    "y": 334.5
                },
                {
                    "x": 282,
                    "y": 382.5
                },
                {
                    "x": 280,
                    "y": 426.5
                },
                {
                    "x": 277,
                    "y": 460.5
                },
                {
                    "x": 274,
                    "y": 480.5
                },
                {
                    "x": 273,
                    "y": 491.5
                },
                {
                    "x": 273,
                    "y": 494.5
                },
                {
                    "x": 275,
                    "y": 489.5
                },
                {
                    "x": 280,
                    "y": 473.5
                },
                {
                    "x": 286,
                    "y": 449.5
                },
                {
                    "x": 294,
                    "y": 417.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 361,
                    "y": 250.5
                },
                {
                    "x": 361,
                    "y": 293.5
                },
                {
                    "x": 361,
                    "y": 334.5
                },
                {
                    "x": 361,
                    "y": 369.5
                },
                {
                    "x": 361,
                    "y": 397.5
                },
                {
                    "x": 361,
                    "y": 416.5
                },
                {
                    "x": 361,
                    "y": 426.5
                },
                {
                    "x": 361,
                    "y": 430.5
                },
                {
                    "x": 361,
                    "y": 432.5
                },
                {
                    "x": 364,
                    "y": 428.5
                },
                {
                    "x": 370,
                    "y": 417.5
                },
                {
                    "x": 376,
                    "y": 401.5
                },
                {
                    "x": 386,
                    "y": 376.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 414,
                    "y": 142.5
                },
                {
                    "x": 414,
                    "y": 177.5
                },
                {
                    "x": 414,
                    "y": 218.5
                },
                {
                    "x": 414,
                    "y": 258.5
                },
                {
                    "x": 412,
                    "y": 297.5
                },
                {
                    "x": 409,
                    "y": 330.5
                },
                {
                    "x": 407,
                    "y": 356.5
                },
                {
                    "x": 406,
                    "y": 369.5
                },
                {
                    "x": 405,
                    "y": 377.5
                },
                {
                    "x": 405,
                    "y": 380.5
                },
                {
                    "x": 408,
                    "y": 375.5
                },
                {
                    "x": 412,
                    "y": 365.5
                },
                {
                    "x": 418,
                    "y": 351.5
                },
                {
                    "x": 427,
                    "y": 331.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 462,
                    "y": 181.5
                },
                {
                    "x": 463,
                    "y": 214.5
                },
                {
                    "x": 463,
                    "y": 249.5
                },
                {
                    "x": 463,
                    "y": 282.5
                },
                {
                    "x": 463,
                    "y": 309.5
                },
                {
                    "x": 462,
                    "y": 330.5
                },
                {
                    "x": 459,
                    "y": 343.5
                },
                {
                    "x": 457,
                    "y": 350.5
                },
                {
                    "x": 457,
                    "y": 352.5
                },
                {
                    "x": 457,
                    "y": 353.5
                },
                {
                    "x": 458,
                    "y": 347.5
                },
                {
                    "x": 461,
                    "y": 339.5
                },
                {
                    "x": 466,
                    "y": 328.5
                },
                {
                    "x": 470,
                    "y": 316.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 461,
                    "y": 176.5
                },
                {
                    "x": 460,
                    "y": 198.5
                },
                {
                    "x": 458,
                    "y": 226.5
                },
                {
                    "x": 455,
                    "y": 252.5
                },
                {
                    "x": 453,
                    "y": 275.5
                },
                {
                    "x": 449,
                    "y": 289.5
                },
                {
                    "x": 445,
                    "y": 296.5
                },
                {
                    "x": 440,
                    "y": 299.5
                },
                {
                    "x": 435,
                    "y": 299.5
                },
                {
                    "x": 429,
                    "y": 293.5
                },
                {
                    "x": 421,
                    "y": 281.5
                },
                {
                    "x": 413,
                    "y": 265.5
                },
                {
                    "x": 404,
                    "y": 240.5
                },
                {
                    "x": 394,
                    "y": 212.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 200,
                    "y": 45.5
                },
                {
                    "x": 203,
                    "y": 48.5
                },
                {
                    "x": 213,
                    "y": 53.5
                },
                {
                    "x": 226,
                    "y": 57.5
                },
                {
                    "x": 248,
                    "y": 63.5
                },
                {
                    "x": 274,
                    "y": 66.5
                },
                {
                    "x": 302,
                    "y": 68.5
                },
                {
                    "x": 332,
                    "y": 68.5
                },
                {
                    "x": 362,
                    "y": 67.5
                },
                {
                    "x": 390,
                    "y": 64.5
                },
                {
                    "x": 417,
                    "y": 59.5
                },
                {
                    "x": 437,
                    "y": 57.5
                },
                {
                    "x": 451,
                    "y": 54.5
                },
                {
                    "x": 457,
                    "y": 53.5
                },
                {
                    "x": 460,
                    "y": 53.5
                },
                {
                    "x": 459,
                    "y": 53.5
                },
                {
                    "x": 453,
                    "y": 53.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 113,
                    "y": 84.5
                },
                {
                    "x": 133,
                    "y": 84.5
                },
                {
                    "x": 167,
                    "y": 80.5
                },
                {
                    "x": 209,
                    "y": 77.5
                },
                {
                    "x": 256,
                    "y": 71.5
                },
                {
                    "x": 303,
                    "y": 67.5
                },
                {
                    "x": 344,
                    "y": 64.5
                },
                {
                    "x": 378,
                    "y": 60.5
                },
                {
                    "x": 400,
                    "y": 59.5
                },
                {
                    "x": 412,
                    "y": 59.5
                },
                {
                    "x": 416,
                    "y": 59.5
                },
                {
                    "x": 417,
                    "y": 59.5
                },
                {
                    "x": 414,
                    "y": 61.5
                },
                {
                    "x": 405,
                    "y": 64.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 127,
                    "y": 123.5
                },
                {
                    "x": 142,
                    "y": 124.5
                },
                {
                    "x": 166,
                    "y": 124.5
                },
                {
                    "x": 204,
                    "y": 124.5
                },
                {
                    "x": 250,
                    "y": 124.5
                },
                {
                    "x": 299,
                    "y": 124.5
                },
                {
                    "x": 345,
                    "y": 124.5
                },
                {
                    "x": 385,
                    "y": 124.5
                },
                {
                    "x": 415,
                    "y": 124.5
                },
                {
                    "x": 432,
                    "y": 124.5
                },
                {
                    "x": 440,
                    "y": 124.5
                },
                {
                    "x": 442,
                    "y": 124.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 115,
                    "y": 157.5
                },
                {
                    "x": 126,
                    "y": 157.5
                },
                {
                    "x": 144,
                    "y": 157.5
                },
                {
                    "x": 179,
                    "y": 157.5
                },
                {
                    "x": 225,
                    "y": 157.5
                },
                {
                    "x": 277,
                    "y": 157.5
                },
                {
                    "x": 331,
                    "y": 156.5
                },
                {
                    "x": 385,
                    "y": 154.5
                },
                {
                    "x": 435,
                    "y": 153.5
                },
                {
                    "x": 490,
                    "y": 151.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 86,
                    "y": 196.5
                },
                {
                    "x": 89,
                    "y": 198.5
                },
                {
                    "x": 99,
                    "y": 202.5
                },
                {
                    "x": 121,
                    "y": 206.5
                },
                {
                    "x": 156,
                    "y": 207.5
                },
                {
                    "x": 203,
                    "y": 209.5
                },
                {
                    "x": 252,
                    "y": 209.5
                },
                {
                    "x": 300,
                    "y": 209.5
                },
                {
                    "x": 344,
                    "y": 209.5
                },
                {
                    "x": 379,
                    "y": 209.5
                },
                {
                    "x": 398,
                    "y": 209.5
                },
                {
                    "x": 408,
                    "y": 209.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 215,
                    "y": 255.5
                },
                {
                    "x": 213,
                    "y": 257.5
                },
                {
                    "x": 213,
                    "y": 258.5
                },
                {
                    "x": 216,
                    "y": 259.5
                },
                {
                    "x": 225,
                    "y": 259.5
                },
                {
                    "x": 238,
                    "y": 259.5
                },
                {
                    "x": 259,
                    "y": 259.5
                },
                {
                    "x": 286,
                    "y": 259.5
                },
                {
                    "x": 318,
                    "y": 259.5
                },
                {
                    "x": 348,
                    "y": 259.5
                },
                {
                    "x": 371,
                    "y": 260.5
                },
                {
                    "x": 386,
                    "y": 261.5
                },
                {
                    "x": 395,
                    "y": 264.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 120,
                    "y": 308.5
                },
                {
                    "x": 115,
                    "y": 308.5
                },
                {
                    "x": 114,
                    "y": 309.5
                },
                {
                    "x": 114,
                    "y": 310.5
                },
                {
                    "x": 122,
                    "y": 311.5
                },
                {
                    "x": 136,
                    "y": 311.5
                },
                {
                    "x": 160,
                    "y": 311.5
                },
                {
                    "x": 196,
                    "y": 311.5
                },
                {
                    "x": 236,
                    "y": 311.5
                },
                {
                    "x": 277,
                    "y": 311.5
                },
                {
                    "x": 312,
                    "y": 311.5
                },
                {
                    "x": 340,
                    "y": 312.5
                },
                {
                    "x": 357,
                    "y": 312.5
                },
                {
                    "x": 366,
                    "y": 313.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 122,
                    "y": 359.5
                },
                {
                    "x": 124,
                    "y": 360.5
                },
                {
                    "x": 134,
                    "y": 363.5
                },
                {
                    "x": 149,
                    "y": 365.5
                },
                {
                    "x": 169,
                    "y": 368.5
                },
                {
                    "x": 195,
                    "y": 372.5
                },
                {
                    "x": 225,
                    "y": 379.5
                },
                {
                    "x": 252,
                    "y": 385.5
                },
                {
                    "x": 276,
                    "y": 391.5
                },
                {
                    "x": 292,
                    "y": 395.5
                },
                {
                    "x": 303,
                    "y": 398.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 103,
                    "y": 423.5
                },
                {
                    "x": 101,
                    "y": 424.5
                },
                {
                    "x": 108,
                    "y": 424.5
                },
                {
                    "x": 122,
                    "y": 424.5
                },
                {
                    "x": 146,
                    "y": 424.5
                },
                {
                    "x": 188,
                    "y": 422.5
                },
                {
                    "x": 237,
                    "y": 420.5
                },
                {
                    "x": 292,
                    "y": 416.5
                },
                {
                    "x": 349,
                    "y": 414.5
                },
                {
                    "x": 400,
                    "y": 414.5
                },
                {
                    "x": 447,
                    "y": 412.5
                },
                {
                    "x": 483,
                    "y": 410.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#f44336",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 186,
                    "y": 435.5
                },
                {
                    "x": 182,
                    "y": 436.5
                },
                {
                    "x": 180,
                    "y": 437.5
                },
                {
                    "x": 181,
                    "y": 437.5
                },
                {
                    "x": 186,
                    "y": 437.5
                },
                {
                    "x": 198,
                    "y": 437.5
                },
                {
                    "x": 216,
                    "y": 437.5
                },
                {
                    "x": 246,
                    "y": 437.5
                },
                {
                    "x": 281,
                    "y": 437.5
                },
                {
                    "x": 317,
                    "y": 439.5
                },
                {
                    "x": 347,
                    "y": 439.5
                },
                {
                    "x": 371,
                    "y": 439.5
                },
                {
                    "x": 385,
                    "y": 440.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#2196f3",
            "strokeWidth": 8,
            "paths": [
                {
                    "x": 361,
                    "y": 412.5
                },
                {
                    "x": 378,
                    "y": 415.5
                },
                {
                    "x": 388,
                    "y": 416.5
                },
                {
                    "x": 391,
                    "y": 416.5
                },
                {
                    "x": 392,
                    "y": 417.5
                },
                {
                    "x": 392,
                    "y": 418.5
                },
                {
                    "x": 387,
                    "y": 421.5
                },
                {
                    "x": 376,
                    "y": 426.5
                },
                {
                    "x": 358,
                    "y": 431.5
                },
                {
                    "x": 333,
                    "y": 438.5
                },
                {
                    "x": 306,
                    "y": 446.5
                },
                {
                    "x": 283,
                    "y": 455.5
                },
                {
                    "x": 264,
                    "y": 462.5
                },
                {
                    "x": 249,
                    "y": 467.5
                },
                {
                    "x": 240,
                    "y": 471.5
                },
                {
                    "x": 234,
                    "y": 473.5
                },
                {
                    "x": 232,
                    "y": 473.5
                },
                {
                    "x": 237,
                    "y": 469.5
                },
                {
                    "x": 245,
                    "y": 466.5
                },
                {
                    "x": 256,
                    "y": 462.5
                },
                {
                    "x": 268,
                    "y": 457.5
                },
                {
                    "x": 279,
                    "y": 453.5
                },
                {
                    "x": 289,
                    "y": 450.5
                },
                {
                    "x": 295,
                    "y": 448.5
                },
                {
                    "x": 297,
                    "y": 447.5
                },
                {
                    "x": 298,
                    "y": 447.5
                },
                {
                    "x": 294,
                    "y": 447.5
                },
                {
                    "x": 284,
                    "y": 447.5
                },
                {
                    "x": 272,
                    "y": 448.5
                },
                {
                    "x": 257,
                    "y": 451.5
                },
                {
                    "x": 243,
                    "y": 456.5
                },
                {
                    "x": 231,
                    "y": 462.5
                },
                {
                    "x": 224,
                    "y": 467.5
                },
                {
                    "x": 221,
                    "y": 470.5
                },
                {
                    "x": 221,
                    "y": 473.5
                },
                {
                    "x": 225,
                    "y": 477.5
                },
                {
                    "x": 236,
                    "y": 479.5
                },
                {
                    "x": 252,
                    "y": 479.5
                },
                {
                    "x": 280,
                    "y": 477.5
                },
                {
                    "x": 318,
                    "y": 466.5
                },
                {
                    "x": 357,
                    "y": 451.5
                },
                {
                    "x": 394,
                    "y": 431.5
                },
                {
                    "x": 425,
                    "y": 406.5
                },
                {
                    "x": 445,
                    "y": 380.5
                },
                {
                    "x": 456,
                    "y": 355.5
                },
                {
                    "x": 461,
                    "y": 331.5
                },
                {
                    "x": 461,
                    "y": 312.5
                },
                {
                    "x": 458,
                    "y": 298.5
                },
                {
                    "x": 453,
                    "y": 287.5
                },
                {
                    "x": 443,
                    "y": 277.5
                },
                {
                    "x": 428,
                    "y": 271.5
                },
                {
                    "x": 414,
                    "y": 270.5
                },
                {
                    "x": 398,
                    "y": 273.5
                },
                {
                    "x": 382,
                    "y": 282.5
                },
                {
                    "x": 370,
                    "y": 295.5
                },
                {
                    "x": 359,
                    "y": 315.5
                },
                {
                    "x": 353,
                    "y": 340.5
                },
                {
                    "x": 352,
                    "y": 362.5
                },
                {
                    "x": 352,
                    "y": 377.5
                },
                {
                    "x": 355,
                    "y": 385.5
                },
                {
                    "x": 358,
                    "y": 390.5
                },
                {
                    "x": 360,
                    "y": 391.5
                },
                {
                    "x": 363,
                    "y": 391.5
                },
                {
                    "x": 365,
                    "y": 390.5
                },
                {
                    "x": 369,
                    "y": 384.5
                },
                {
                    "x": 375,
                    "y": 375.5
                },
                {
                    "x": 379,
                    "y": 366.5
                },
                {
                    "x": 382,
                    "y": 355.5
                },
                {
                    "x": 386,
                    "y": 344.5
                },
                {
                    "x": 388,
                    "y": 335.5
                },
                {
                    "x": 390,
                    "y": 328.5
                },
                {
                    "x": 391,
                    "y": 325.5
                },
                {
                    "x": 391,
                    "y": 324.5
                },
                {
                    "x": 389,
                    "y": 323.5
                },
                {
                    "x": 385,
                    "y": 323.5
                },
                {
                    "x": 378,
                    "y": 327.5
                },
                {
                    "x": 368,
                    "y": 335.5
                },
                {
                    "x": 359,
                    "y": 347.5
                },
                {
                    "x": 351,
                    "y": 362.5
                },
                {
                    "x": 346,
                    "y": 376.5
                },
                {
                    "x": 344,
                    "y": 388.5
                },
                {
                    "x": 343,
                    "y": 396.5
                },
                {
                    "x": 343,
                    "y": 401.5
                },
                {
                    "x": 344,
                    "y": 402.5
                },
                {
                    "x": 348,
                    "y": 402.5
                },
                {
                    "x": 357,
                    "y": 401.5
                },
                {
                    "x": 369,
                    "y": 395.5
                },
                {
                    "x": 386,
                    "y": 386.5
                },
                {
                    "x": 405,
                    "y": 375.5
                },
                {
                    "x": 422,
                    "y": 360.5
                },
                {
                    "x": 435,
                    "y": 348.5
                },
                {
                    "x": 445,
                    "y": 333.5
                },
                {
                    "x": 450,
                    "y": 322.5
                },
                {
                    "x": 453,
                    "y": 315.5
                },
                {
                    "x": 453,
                    "y": 311.5
                },
                {
                    "x": 450,
                    "y": 308.5
                },
                {
                    "x": 440,
                    "y": 308.5
                },
                {
                    "x": 427,
                    "y": 310.5
                },
                {
                    "x": 409,
                    "y": 319.5
                },
                {
                    "x": 389,
                    "y": 334.5
                },
                {
                    "x": 371,
                    "y": 356.5
                },
                {
                    "x": 360,
                    "y": 380.5
                },
                {
                    "x": 356,
                    "y": 403.5
                },
                {
                    "x": 357,
                    "y": 419.5
                },
                {
                    "x": 363,
                    "y": 428.5
                },
                {
                    "x": 369,
                    "y": 434.5
                },
                {
                    "x": 378,
                    "y": 434.5
                },
                {
                    "x": 389,
                    "y": 429.5
                },
                {
                    "x": 405,
                    "y": 417.5
                },
                {
                    "x": 428,
                    "y": 398.5
                },
                {
                    "x": 451,
                    "y": 374.5
                },
                {
                    "x": 473,
                    "y": 349.5
                },
                {
                    "x": 490,
                    "y": 322.5
                },
                {
                    "x": 490,
                    "y": 213.5
                },
                {
                    "x": 474,
                    "y": 204.5
                },
                {
                    "x": 447,
                    "y": 197.5
                },
                {
                    "x": 408,
                    "y": 194.5
                },
                {
                    "x": 369,
                    "y": 196.5
                },
                {
                    "x": 336,
                    "y": 208.5
                },
                {
                    "x": 316,
                    "y": 224.5
                },
                {
                    "x": 307,
                    "y": 242.5
                },
                {
                    "x": 307,
                    "y": 258.5
                },
                {
                    "x": 314,
                    "y": 276.5
                },
                {
                    "x": 326,
                    "y": 286.5
                },
                {
                    "x": 345,
                    "y": 291.5
                },
                {
                    "x": 368,
                    "y": 289.5
                },
                {
                    "x": 401,
                    "y": 279.5
                },
                {
                    "x": 437,
                    "y": 262.5
                },
                {
                    "x": 472,
                    "y": 240.5
                },
                {
                    "x": 483,
                    "y": 12.5
                },
                {
                    "x": 447,
                    "y": 12.5
                },
                {
                    "x": 410,
                    "y": 18.5
                },
                {
                    "x": 377,
                    "y": 32.5
                },
                {
                    "x": 352,
                    "y": 50.5
                },
                {
                    "x": 334,
                    "y": 73.5
                },
                {
                    "x": 324,
                    "y": 91.5
                },
                {
                    "x": 321,
                    "y": 103.5
                },
                {
                    "x": 321,
                    "y": 109.5
                },
                {
                    "x": 321,
                    "y": 111.5
                },
                {
                    "x": 325,
                    "y": 111.5
                },
                {
                    "x": 333,
                    "y": 108.5
                },
                {
                    "x": 346,
                    "y": 99.5
                },
                {
                    "x": 366,
                    "y": 84.5
                },
                {
                    "x": 384,
                    "y": 68.5
                },
                {
                    "x": 399,
                    "y": 48.5
                },
                {
                    "x": 411,
                    "y": 27.5
                },
                {
                    "x": 416,
                    "y": 10.5
                },
                {
                    "x": 342,
                    "y": 13.5
                },
                {
                    "x": 332,
                    "y": 34.5
                },
                {
                    "x": 326,
                    "y": 53.5
                },
                {
                    "x": 326,
                    "y": 68.5
                },
                {
                    "x": 328,
                    "y": 79.5
                },
                {
                    "x": 334,
                    "y": 86.5
                },
                {
                    "x": 342,
                    "y": 90.5
                },
                {
                    "x": 352,
                    "y": 90.5
                },
                {
                    "x": 364,
                    "y": 88.5
                },
                {
                    "x": 380,
                    "y": 82.5
                },
                {
                    "x": 398,
                    "y": 72.5
                },
                {
                    "x": 415,
                    "y": 61.5
                },
                {
                    "x": 425,
                    "y": 52.5
                },
                {
                    "x": 431,
                    "y": 43.5
                },
                {
                    "x": 434,
                    "y": 34.5
                },
                {
                    "x": 434,
                    "y": 26.5
                },
                {
                    "x": 429,
                    "y": 18.5
                },
                {
                    "x": 419,
                    "y": 12.5
                },
                {
                    "x": 404,
                    "y": 8.5
                },
                {
                    "x": 378,
                    "y": 5.5
                },
                {
                    "x": 343,
                    "y": 5.5
                },
                {
                    "x": 306,
                    "y": 8.5
                },
                {
                    "x": 277,
                    "y": 14.5
                },
                {
                    "x": 256,
                    "y": 24.5
                },
                {
                    "x": 247,
                    "y": 35.5
                },
                {
                    "x": 243,
                    "y": 47.5
                },
                {
                    "x": 245,
                    "y": 62.5
                },
                {
                    "x": 256,
                    "y": 79.5
                },
                {
                    "x": 276,
                    "y": 96.5
                },
                {
                    "x": 300,
                    "y": 108.5
                },
                {
                    "x": 327,
                    "y": 115.5
                },
                {
                    "x": 355,
                    "y": 116.5
                },
                {
                    "x": 375,
                    "y": 114.5
                },
                {
                    "x": 394,
                    "y": 109.5
                },
                {
                    "x": 407,
                    "y": 102.5
                },
                {
                    "x": 416,
                    "y": 94.5
                },
                {
                    "x": 420,
                    "y": 86.5
                },
                {
                    "x": 420,
                    "y": 76.5
                },
                {
                    "x": 416,
                    "y": 65.5
                },
                {
                    "x": 402,
                    "y": 51.5
                },
                {
                    "x": 373,
                    "y": 36.5
                },
                {
                    "x": 334,
                    "y": 21.5
                },
                {
                    "x": 289,
                    "y": 12.5
                },
                {
                    "x": 236,
                    "y": 8.5
                },
                {
                    "x": 185,
                    "y": 8.5
                },
                {
                    "x": 143,
                    "y": 12.5
                },
                {
                    "x": 113,
                    "y": 22.5
                },
                {
                    "x": 96,
                    "y": 35.5
                },
                {
                    "x": 89,
                    "y": 46.5
                },
                {
                    "x": 89,
                    "y": 58.5
                },
                {
                    "x": 90,
                    "y": 68.5
                },
                {
                    "x": 96,
                    "y": 75.5
                },
                {
                    "x": 102,
                    "y": 80.5
                },
                {
                    "x": 109,
                    "y": 83.5
                },
                {
                    "x": 116,
                    "y": 84.5
                },
                {
                    "x": 123,
                    "y": 83.5
                },
                {
                    "x": 131,
                    "y": 80.5
                },
                {
                    "x": 138,
                    "y": 75.5
                },
                {
                    "x": 145,
                    "y": 68.5
                },
                {
                    "x": 148,
                    "y": 61.5
                },
                {
                    "x": 148,
                    "y": 53.5
                },
                {
                    "x": 146,
                    "y": 44.5
                },
                {
                    "x": 141,
                    "y": 36.5
                },
                {
                    "x": 134,
                    "y": 30.5
                },
                {
                    "x": 124,
                    "y": 25.5
                },
                {
                    "x": 110,
                    "y": 20.5
                },
                {
                    "x": 92,
                    "y": 17.5
                },
                {
                    "x": 70,
                    "y": 17.5
                },
                {
                    "x": 53,
                    "y": 19.5
                },
                {
                    "x": 38,
                    "y": 25.5
                },
                {
                    "x": 29,
                    "y": 33.5
                },
                {
                    "x": 24,
                    "y": 41.5
                },
                {
                    "x": 22,
                    "y": 50.5
                },
                {
                    "x": 22,
                    "y": 58.5
                },
                {
                    "x": 24,
                    "y": 64.5
                },
                {
                    "x": 27,
                    "y": 67.5
                },
                {
                    "x": 32,
                    "y": 69.5
                },
                {
                    "x": 37,
                    "y": 70.5
                },
                {
                    "x": 44,
                    "y": 70.5
                },
                {
                    "x": 51,
                    "y": 70.5
                },
                {
                    "x": 57,
                    "y": 68.5
                },
                {
                    "x": 63,
                    "y": 65.5
                },
                {
                    "x": 65,
                    "y": 63.5
                },
                {
                    "x": 67,
                    "y": 59.5
                },
                {
                    "x": 67,
                    "y": 56.5
                },
                {
                    "x": 66,
                    "y": 52.5
                },
                {
                    "x": 62,
                    "y": 49.5
                },
                {
                    "x": 55,
                    "y": 44.5
                },
                {
                    "x": 44,
                    "y": 41.5
                },
                {
                    "x": 27,
                    "y": 40.5
                },
                {
                    "x": 0,
                    "y": 40.5
                },
                {
                    "x": 4,
                    "y": 103.5
                },
                {
                    "x": 43,
                    "y": 97.5
                },
                {
                    "x": 81,
                    "y": 88.5
                },
                {
                    "x": 111,
                    "y": 78.5
                },
                {
                    "x": 132,
                    "y": 66.5
                },
                {
                    "x": 143,
                    "y": 54.5
                },
                {
                    "x": 149,
                    "y": 43.5
                },
                {
                    "x": 149,
                    "y": 32.5
                },
                {
                    "x": 144,
                    "y": 21.5
                },
                {
                    "x": 134,
                    "y": 13.5
                },
                {
                    "x": 118,
                    "y": 7.5
                },
                {
                    "x": 92,
                    "y": 2.5
                },
                {
                    "x": 59,
                    "y": 2.5
                },
                {
                    "x": 25,
                    "y": 8.5
                },
                {
                    "x": 4,
                    "y": 177.5
                },
                {
                    "x": 14,
                    "y": 171.5
                },
                {
                    "x": 23,
                    "y": 164.5
                },
                {
                    "x": 28,
                    "y": 158.5
                },
                {
                    "x": 30,
                    "y": 152.5
                },
                {
                    "x": 30,
                    "y": 146.5
                },
                {
                    "x": 28,
                    "y": 141.5
                },
                {
                    "x": 21,
                    "y": 137.5
                },
                {
                    "x": 10,
                    "y": 135.5
                },
                {
                    "x": 21,
                    "y": 282.5
                },
                {
                    "x": 63,
                    "y": 263.5
                },
                {
                    "x": 95,
                    "y": 241.5
                },
                {
                    "x": 117,
                    "y": 218.5
                },
                {
                    "x": 127,
                    "y": 200.5
                },
                {
                    "x": 129,
                    "y": 186.5
                },
                {
                    "x": 126,
                    "y": 174.5
                },
                {
                    "x": 118,
                    "y": 165.5
                },
                {
                    "x": 103,
                    "y": 159.5
                },
                {
                    "x": 77,
                    "y": 155.5
                },
                {
                    "x": 47,
                    "y": 155.5
                },
                {
                    "x": 14,
                    "y": 164.5
                },
                {
                    "x": 5,
                    "y": 312.5
                },
                {
                    "x": 22,
                    "y": 299.5
                },
                {
                    "x": 38,
                    "y": 281.5
                },
                {
                    "x": 50,
                    "y": 260.5
                },
                {
                    "x": 57,
                    "y": 240.5
                },
                {
                    "x": 60,
                    "y": 225.5
                },
                {
                    "x": 60,
                    "y": 216.5
                },
                {
                    "x": 55,
                    "y": 207.5
                },
                {
                    "x": 47,
                    "y": 202.5
                },
                {
                    "x": 35,
                    "y": 197.5
                },
                {
                    "x": 16,
                    "y": 195.5
                },
                {
                    "x": 11,
                    "y": 368.5
                },
                {
                    "x": 34,
                    "y": 369.5
                },
                {
                    "x": 54,
                    "y": 367.5
                },
                {
                    "x": 76,
                    "y": 362.5
                },
                {
                    "x": 96,
                    "y": 353.5
                },
                {
                    "x": 113,
                    "y": 342.5
                },
                {
                    "x": 121,
                    "y": 332.5
                },
                {
                    "x": 126,
                    "y": 322.5
                },
                {
                    "x": 127,
                    "y": 311.5
                },
                {
                    "x": 123,
                    "y": 299.5
                },
                {
                    "x": 112,
                    "y": 285.5
                },
                {
                    "x": 93,
                    "y": 272.5
                },
                {
                    "x": 70,
                    "y": 263.5
                },
                {
                    "x": 41,
                    "y": 258.5
                },
                {
                    "x": 12,
                    "y": 258.5
                },
                {
                    "x": 21,
                    "y": 428.5
                },
                {
                    "x": 50,
                    "y": 425.5
                },
                {
                    "x": 80,
                    "y": 417.5
                },
                {
                    "x": 109,
                    "y": 409.5
                },
                {
                    "x": 135,
                    "y": 398.5
                },
                {
                    "x": 149,
                    "y": 387.5
                },
                {
                    "x": 157,
                    "y": 377.5
                },
                {
                    "x": 160,
                    "y": 367.5
                },
                {
                    "x": 160,
                    "y": 353.5
                },
                {
                    "x": 156,
                    "y": 341.5
                },
                {
                    "x": 145,
                    "y": 331.5
                },
                {
                    "x": 129,
                    "y": 322.5
                },
                {
                    "x": 107,
                    "y": 316.5
                },
                {
                    "x": 79,
                    "y": 313.5
                },
                {
                    "x": 47,
                    "y": 317.5
                },
                {
                    "x": 17,
                    "y": 327.5
                },
                {
                    "x": 0,
                    "y": 459.5
                },
                {
                    "x": 17,
                    "y": 465.5
                },
                {
                    "x": 36,
                    "y": 465.5
                },
                {
                    "x": 57,
                    "y": 461.5
                },
                {
                    "x": 85,
                    "y": 453.5
                },
                {
                    "x": 110,
                    "y": 442.5
                },
                {
                    "x": 132,
                    "y": 428.5
                },
                {
                    "x": 147,
                    "y": 415.5
                },
                {
                    "x": 157,
                    "y": 400.5
                },
                {
                    "x": 162,
                    "y": 384.5
                },
                {
                    "x": 164,
                    "y": 369.5
                },
                {
                    "x": 159,
                    "y": 355.5
                },
                {
                    "x": 148,
                    "y": 345.5
                },
                {
                    "x": 130,
                    "y": 337.5
                },
                {
                    "x": 104,
                    "y": 332.5
                },
                {
                    "x": 71,
                    "y": 332.5
                },
                {
                    "x": 38,
                    "y": 335.5
                },
                {
                    "x": 10,
                    "y": 345.5
                },
                {
                    "x": 14,
                    "y": 438.5
                },
                {
                    "x": 38,
                    "y": 438.5
                },
                {
                    "x": 75,
                    "y": 435.5
                },
                {
                    "x": 114,
                    "y": 430.5
                },
                {
                    "x": 154,
                    "y": 421.5
                },
                {
                    "x": 190,
                    "y": 408.5
                },
                {
                    "x": 219,
                    "y": 395.5
                },
                {
                    "x": 235,
                    "y": 382.5
                },
                {
                    "x": 244,
                    "y": 372.5
                },
                {
                    "x": 249,
                    "y": 362.5
                },
                {
                    "x": 249,
                    "y": 354.5
                },
                {
                    "x": 242,
                    "y": 347.5
                },
                {
                    "x": 231,
                    "y": 341.5
                },
                {
                    "x": 212,
                    "y": 337.5
                },
                {
                    "x": 185,
                    "y": 337.5
                },
                {
                    "x": 154,
                    "y": 339.5
                },
                {
                    "x": 126,
                    "y": 347.5
                },
                {
                    "x": 108,
                    "y": 357.5
                },
                {
                    "x": 97,
                    "y": 370.5
                },
                {
                    "x": 92,
                    "y": 384.5
                },
                {
                    "x": 94,
                    "y": 397.5
                },
                {
                    "x": 104,
                    "y": 412.5
                },
                {
                    "x": 119,
                    "y": 428.5
                },
                {
                    "x": 143,
                    "y": 440.5
                },
                {
                    "x": 170,
                    "y": 447.5
                },
                {
                    "x": 198,
                    "y": 450.5
                },
                {
                    "x": 228,
                    "y": 450.5
                },
                {
                    "x": 259,
                    "y": 446.5
                },
                {
                    "x": 287,
                    "y": 438.5
                },
                {
                    "x": 307,
                    "y": 427.5
                },
                {
                    "x": 319,
                    "y": 418.5
                },
                {
                    "x": 326,
                    "y": 409.5
                },
                {
                    "x": 328,
                    "y": 400.5
                },
                {
                    "x": 326,
                    "y": 389.5
                },
                {
                    "x": 318,
                    "y": 378.5
                },
                {
                    "x": 302,
                    "y": 365.5
                },
                {
                    "x": 273,
                    "y": 351.5
                },
                {
                    "x": 241,
                    "y": 343.5
                },
                {
                    "x": 210,
                    "y": 339.5
                },
                {
                    "x": 182,
                    "y": 340.5
                },
                {
                    "x": 163,
                    "y": 347.5
                },
                {
                    "x": 151,
                    "y": 355.5
                },
                {
                    "x": 146,
                    "y": 366.5
                },
                {
                    "x": 146,
                    "y": 378.5
                },
                {
                    "x": 152,
                    "y": 391.5
                },
                {
                    "x": 163,
                    "y": 403.5
                },
                {
                    "x": 177,
                    "y": 415.5
                },
                {
                    "x": 198,
                    "y": 423.5
                },
                {
                    "x": 219,
                    "y": 429.5
                },
                {
                    "x": 242,
                    "y": 432.5
                },
                {
                    "x": 267,
                    "y": 434.5
                },
                {
                    "x": 297,
                    "y": 434.5
                },
                {
                    "x": 326,
                    "y": 431.5
                },
                {
                    "x": 347,
                    "y": 426.5
                },
                {
                    "x": 363,
                    "y": 418.5
                },
                {
                    "x": 372,
                    "y": 409.5
                },
                {
                    "x": 376,
                    "y": 399.5
                },
                {
                    "x": 376,
                    "y": 389.5
                },
                {
                    "x": 374,
                    "y": 377.5
                },
                {
                    "x": 364,
                    "y": 363.5
                },
                {
                    "x": 344,
                    "y": 346.5
                },
                {
                    "x": 315,
                    "y": 331.5
                },
                {
                    "x": 280,
                    "y": 320.5
                },
                {
                    "x": 237,
                    "y": 313.5
                },
                {
                    "x": 196,
                    "y": 313.5
                },
                {
                    "x": 160,
                    "y": 317.5
                },
                {
                    "x": 135,
                    "y": 327.5
                },
                {
                    "x": 117,
                    "y": 341.5
                },
                {
                    "x": 109,
                    "y": 359.5
                },
                {
                    "x": 109,
                    "y": 375.5
                },
                {
                    "x": 116,
                    "y": 393.5
                },
                {
                    "x": 129,
                    "y": 410.5
                },
                {
                    "x": 146,
                    "y": 423.5
                },
                {
                    "x": 166,
                    "y": 431.5
                },
                {
                    "x": 187,
                    "y": 436.5
                },
                {
                    "x": 210,
                    "y": 437.5
                },
                {
                    "x": 233,
                    "y": 435.5
                },
                {
                    "x": 260,
                    "y": 427.5
                },
                {
                    "x": 281,
                    "y": 417.5
                },
                {
                    "x": 295,
                    "y": 405.5
                },
                {
                    "x": 303,
                    "y": 394.5
                },
                {
                    "x": 308,
                    "y": 383.5
                },
                {
                    "x": 309,
                    "y": 372.5
                },
                {
                    "x": 306,
                    "y": 360.5
                },
                {
                    "x": 298,
                    "y": 348.5
                },
                {
                    "x": 285,
                    "y": 336.5
                },
                {
                    "x": 261,
                    "y": 325.5
                },
                {
                    "x": 232,
                    "y": 317.5
                },
                {
                    "x": 201,
                    "y": 313.5
                },
                {
                    "x": 167,
                    "y": 312.5
                },
                {
                    "x": 140,
                    "y": 312.5
                },
                {
                    "x": 123,
                    "y": 312.5
                },
                {
                    "x": 112,
                    "y": 314.5
                },
                {
                    "x": 108,
                    "y": 315.5
                },
                {
                    "x": 106,
                    "y": 316.5
                },
                {
                    "x": 109,
                    "y": 314.5
                },
                {
                    "x": 118,
                    "y": 308.5
                },
                {
                    "x": 131,
                    "y": 300.5
                },
                {
                    "x": 149,
                    "y": 290.5
                },
                {
                    "x": 168,
                    "y": 277.5
                },
                {
                    "x": 190,
                    "y": 261.5
                },
                {
                    "x": 210,
                    "y": 244.5
                },
                {
                    "x": 228,
                    "y": 230.5
                },
                {
                    "x": 240,
                    "y": 219.5
                },
                {
                    "x": 247,
                    "y": 211.5
                },
                {
                    "x": 251,
                    "y": 207.5
                },
                {
                    "x": 251,
                    "y": 206.5
                },
                {
                    "x": 248,
                    "y": 206.5
                },
                {
                    "x": 238,
                    "y": 209.5
                },
                {
                    "x": 222,
                    "y": 217.5
                },
                {
                    "x": 198,
                    "y": 229.5
                },
                {
                    "x": 174,
                    "y": 244.5
                },
                {
                    "x": 156,
                    "y": 260.5
                },
                {
                    "x": 146,
                    "y": 274.5
                },
                {
                    "x": 142,
                    "y": 286.5
                },
                {
                    "x": 140,
                    "y": 296.5
                },
                {
                    "x": 140,
                    "y": 303.5
                },
                {
                    "x": 141,
                    "y": 306.5
                },
                {
                    "x": 143,
                    "y": 308.5
                },
                {
                    "x": 149,
                    "y": 308.5
                },
                {
                    "x": 158,
                    "y": 304.5
                },
                {
                    "x": 171,
                    "y": 297.5
                },
                {
                    "x": 189,
                    "y": 288.5
                },
                {
                    "x": 209,
                    "y": 274.5
                },
                {
                    "x": 223,
                    "y": 262.5
                },
                {
                    "x": 234,
                    "y": 250.5
                },
                {
                    "x": 241,
                    "y": 239.5
                },
                {
                    "x": 244,
                    "y": 229.5
                },
                {
                    "x": 244,
                    "y": 220.5
                },
                {
                    "x": 240,
                    "y": 211.5
                },
                {
                    "x": 231,
                    "y": 205.5
                },
                {
                    "x": 217,
                    "y": 200.5
                },
                {
                    "x": 196,
                    "y": 196.5
                },
                {
                    "x": 174,
                    "y": 196.5
                },
                {
                    "x": 153,
                    "y": 199.5
                },
                {
                    "x": 137,
                    "y": 208.5
                },
                {
                    "x": 128,
                    "y": 218.5
                },
                {
                    "x": 125,
                    "y": 231.5
                },
                {
                    "x": 126,
                    "y": 244.5
                },
                {
                    "x": 136,
                    "y": 259.5
                },
                {
                    "x": 157,
                    "y": 278.5
                },
                {
                    "x": 184,
                    "y": 295.5
                },
                {
                    "x": 214,
                    "y": 307.5
                },
                {
                    "x": 249,
                    "y": 314.5
                },
                {
                    "x": 287,
                    "y": 316.5
                },
                {
                    "x": 328,
                    "y": 316.5
                },
                {
                    "x": 365,
                    "y": 309.5
                },
                {
                    "x": 398,
                    "y": 301.5
                },
                {
                    "x": 423,
                    "y": 290.5
                },
                {
                    "x": 440,
                    "y": 277.5
                },
                {
                    "x": 450,
                    "y": 265.5
                },
                {
                    "x": 453,
                    "y": 253.5
                },
                {
                    "x": 452,
                    "y": 238.5
                },
                {
                    "x": 439,
                    "y": 217.5
                },
                {
                    "x": 415,
                    "y": 191.5
                },
                {
                    "x": 375,
                    "y": 164.5
                },
                {
                    "x": 325,
                    "y": 142.5
                },
                {
                    "x": 271,
                    "y": 130.5
                },
                {
                    "x": 214,
                    "y": 126.5
                },
                {
                    "x": 164,
                    "y": 129.5
                },
                {
                    "x": 96,
                    "y": 159.5
                },
                {
                    "x": 83,
                    "y": 180.5
                },
                {
                    "x": 78,
                    "y": 207.5
                },
                {
                    "x": 83,
                    "y": 233.5
                },
                {
                    "x": 98,
                    "y": 260.5
                },
                {
                    "x": 119,
                    "y": 279.5
                },
                {
                    "x": 140,
                    "y": 291.5
                },
                {
                    "x": 162,
                    "y": 298.5
                },
                {
                    "x": 185,
                    "y": 301.5
                },
                {
                    "x": 210,
                    "y": 301.5
                },
                {
                    "x": 236,
                    "y": 296.5
                },
                {
                    "x": 264,
                    "y": 286.5
                },
                {
                    "x": 288,
                    "y": 274.5
                },
                {
                    "x": 305,
                    "y": 259.5
                },
                {
                    "x": 315,
                    "y": 245.5
                },
                {
                    "x": 320,
                    "y": 231.5
                },
                {
                    "x": 320,
                    "y": 216.5
                },
                {
                    "x": 316,
                    "y": 197.5
                },
                {
                    "x": 302,
                    "y": 175.5
                },
                {
                    "x": 279,
                    "y": 156.5
                },
                {
                    "x": 245,
                    "y": 139.5
                },
                {
                    "x": 202,
                    "y": 130.5
                },
                {
                    "x": 156,
                    "y": 126.5
                },
                {
                    "x": 111,
                    "y": 129.5
                },
                {
                    "x": 71,
                    "y": 138.5
                },
                {
                    "x": 42,
                    "y": 156.5
                },
                {
                    "x": 24,
                    "y": 178.5
                },
                {
                    "x": 16,
                    "y": 203.5
                },
                {
                    "x": 17,
                    "y": 228.5
                },
                {
                    "x": 28,
                    "y": 254.5
                },
                {
                    "x": 48,
                    "y": 276.5
                },
                {
                    "x": 72,
                    "y": 293.5
                },
                {
                    "x": 97,
                    "y": 300.5
                },
                {
                    "x": 131,
                    "y": 304.5
                },
                {
                    "x": 164,
                    "y": 301.5
                },
                {
                    "x": 204,
                    "y": 292.5
                },
                {
                    "x": 245,
                    "y": 279.5
                },
                {
                    "x": 282,
                    "y": 261.5
                },
                {
                    "x": 309,
                    "y": 242.5
                },
                {
                    "x": 328,
                    "y": 224.5
                },
                {
                    "x": 339,
                    "y": 205.5
                },
                {
                    "x": 344,
                    "y": 189.5
                },
                {
                    "x": 344,
                    "y": 174.5
                },
                {
                    "x": 337,
                    "y": 159.5
                },
                {
                    "x": 317,
                    "y": 144.5
                },
                {
                    "x": 284,
                    "y": 132.5
                },
                {
                    "x": 244,
                    "y": 126.5
                },
                {
                    "x": 200,
                    "y": 127.5
                },
                {
                    "x": 158,
                    "y": 137.5
                },
                {
                    "x": 123,
                    "y": 155.5
                },
                {
                    "x": 105,
                    "y": 175.5
                },
                {
                    "x": 99,
                    "y": 197.5
                },
                {
                    "x": 102,
                    "y": 223.5
                },
                {
                    "x": 119,
                    "y": 250.5
                },
                {
                    "x": 140,
                    "y": 271.5
                },
                {
                    "x": 164,
                    "y": 285.5
                },
                {
                    "x": 186,
                    "y": 291.5
                },
                {
                    "x": 205,
                    "y": 291.5
                },
                {
                    "x": 226,
                    "y": 288.5
                },
                {
                    "x": 253,
                    "y": 280.5
                },
                {
                    "x": 281,
                    "y": 268.5
                },
                {
                    "x": 308,
                    "y": 253.5
                },
                {
                    "x": 330,
                    "y": 238.5
                },
                {
                    "x": 346,
                    "y": 220.5
                },
                {
                    "x": 356,
                    "y": 201.5
                },
                {
                    "x": 360,
                    "y": 188.5
                },
                {
                    "x": 358,
                    "y": 173.5
                },
                {
                    "x": 351,
                    "y": 160.5
                },
                {
                    "x": 336,
                    "y": 146.5
                },
                {
                    "x": 310,
                    "y": 135.5
                },
                {
                    "x": 278,
                    "y": 127.5
                },
                {
                    "x": 242,
                    "y": 123.5
                },
                {
                    "x": 207,
                    "y": 123.5
                },
                {
                    "x": 180,
                    "y": 127.5
                },
                {
                    "x": 168,
                    "y": 132.5
                },
                {
                    "x": 160,
                    "y": 140.5
                },
                {
                    "x": 157,
                    "y": 148.5
                },
                {
                    "x": 158,
                    "y": 158.5
                },
                {
                    "x": 164,
                    "y": 166.5
                },
                {
                    "x": 171,
                    "y": 173.5
                },
                {
                    "x": 180,
                    "y": 177.5
                },
                {
                    "x": 189,
                    "y": 179.5
                },
                {
                    "x": 200,
                    "y": 179.5
                },
                {
                    "x": 212,
                    "y": 177.5
                },
                {
                    "x": 226,
                    "y": 172.5
                },
                {
                    "x": 239,
                    "y": 165.5
                },
                {
                    "x": 249,
                    "y": 157.5
                },
                {
                    "x": 257,
                    "y": 149.5
                },
                {
                    "x": 261,
                    "y": 140.5
                },
                {
                    "x": 262,
                    "y": 131.5
                },
                {
                    "x": 260,
                    "y": 120.5
                },
                {
                    "x": 251,
                    "y": 111.5
                },
                {
                    "x": 238,
                    "y": 106.5
                },
                {
                    "x": 218,
                    "y": 104.5
                },
                {
                    "x": 192,
                    "y": 104.5
                },
                {
                    "x": 169,
                    "y": 109.5
                },
                {
                    "x": 148,
                    "y": 119.5
                },
                {
                    "x": 137,
                    "y": 131.5
                },
                {
                    "x": 132,
                    "y": 144.5
                },
                {
                    "x": 134,
                    "y": 159.5
                },
                {
                    "x": 145,
                    "y": 175.5
                },
                {
                    "x": 157,
                    "y": 186.5
                },
                {
                    "x": 172,
                    "y": 194.5
                },
                {
                    "x": 186,
                    "y": 196.5
                },
                {
                    "x": 201,
                    "y": 194.5
                },
                {
                    "x": 221,
                    "y": 187.5
                },
                {
                    "x": 242,
                    "y": 176.5
                },
                {
                    "x": 263,
                    "y": 164.5
                },
                {
                    "x": 280,
                    "y": 151.5
                },
                {
                    "x": 292,
                    "y": 139.5
                },
                {
                    "x": 300,
                    "y": 127.5
                },
                {
                    "x": 305,
                    "y": 118.5
                },
                {
                    "x": 305,
                    "y": 110.5
                },
                {
                    "x": 300,
                    "y": 105.5
                },
                {
                    "x": 287,
                    "y": 101.5
                },
                {
                    "x": 269,
                    "y": 100.5
                },
                {
                    "x": 238,
                    "y": 101.5
                },
                {
                    "x": 205,
                    "y": 104.5
                },
                {
                    "x": 175,
                    "y": 110.5
                },
                {
                    "x": 153,
                    "y": 118.5
                },
                {
                    "x": 140,
                    "y": 125.5
                },
                {
                    "x": 134,
                    "y": 129.5
                },
                {
                    "x": 131,
                    "y": 132.5
                },
                {
                    "x": 130,
                    "y": 132.5
                },
                {
                    "x": 131,
                    "y": 132.5
                },
                {
                    "x": 137,
                    "y": 130.5
                },
                {
                    "x": 150,
                    "y": 124.5
                },
                {
                    "x": 166,
                    "y": 117.5
                },
                {
                    "x": 184,
                    "y": 107.5
                },
                {
                    "x": 201,
                    "y": 96.5
                },
                {
                    "x": 211,
                    "y": 86.5
                },
                {
                    "x": 218,
                    "y": 74.5
                },
                {
                    "x": 221,
                    "y": 66.5
                },
                {
                    "x": 221,
                    "y": 63.5
                },
                {
                    "x": 219,
                    "y": 61.5
                },
                {
                    "x": 212,
                    "y": 61.5
                },
                {
                    "x": 203,
                    "y": 66.5
                },
                {
                    "x": 195,
                    "y": 73.5
                },
                {
                    "x": 187,
                    "y": 85.5
                },
                {
                    "x": 182,
                    "y": 100.5
                },
                {
                    "x": 182,
                    "y": 117.5
                },
                {
                    "x": 186,
                    "y": 133.5
                },
                {
                    "x": 193,
                    "y": 146.5
                },
                {
                    "x": 202,
                    "y": 154.5
                },
                {
                    "x": 210,
                    "y": 158.5
                },
                {
                    "x": 218,
                    "y": 158.5
                },
                {
                    "x": 228,
                    "y": 155.5
                },
                {
                    "x": 239,
                    "y": 147.5
                },
                {
                    "x": 249,
                    "y": 138.5
                },
                {
                    "x": 259,
                    "y": 127.5
                },
                {
                    "x": 267,
                    "y": 113.5
                },
                {
                    "x": 275,
                    "y": 98.5
                },
                {
                    "x": 280,
                    "y": 85.5
                },
                {
                    "x": 285,
                    "y": 73.5
                },
                {
                    "x": 287,
                    "y": 64.5
                },
                {
                    "x": 288,
                    "y": 58.5
                },
                {
                    "x": 288,
                    "y": 56.5
                },
                {
                    "x": 287,
                    "y": 55.5
                },
                {
                    "x": 282,
                    "y": 55.5
                },
                {
                    "x": 271,
                    "y": 59.5
                },
                {
                    "x": 256,
                    "y": 67.5
                },
                {
                    "x": 237,
                    "y": 80.5
                },
                {
                    "x": 224,
                    "y": 93.5
                },
                {
                    "x": 217,
                    "y": 106.5
                },
                {
                    "x": 214,
                    "y": 118.5
                },
                {
                    "x": 214,
                    "y": 129.5
                },
                {
                    "x": 218,
                    "y": 137.5
                },
                {
                    "x": 223,
                    "y": 143.5
                },
                {
                    "x": 232,
                    "y": 147.5
                },
                {
                    "x": 244,
                    "y": 150.5
                },
                {
                    "x": 262,
                    "y": 149.5
                },
                {
                    "x": 289,
                    "y": 143.5
                },
                {
                    "x": 322,
                    "y": 131.5
                },
                {
                    "x": 361,
                    "y": 116.5
                },
                {
                    "x": 397,
                    "y": 100.5
                },
                {
                    "x": 425,
                    "y": 81.5
                },
                {
                    "x": 442,
                    "y": 65.5
                },
                {
                    "x": 452,
                    "y": 49.5
                },
                {
                    "x": 455,
                    "y": 38.5
                },
                {
                    "x": 455,
                    "y": 30.5
                },
                {
                    "x": 450,
                    "y": 25.5
                },
                {
                    "x": 440,
                    "y": 22.5
                },
                {
                    "x": 428,
                    "y": 22.5
                },
                {
                    "x": 412,
                    "y": 29.5
                },
                {
                    "x": 395,
                    "y": 42.5
                },
                {
                    "x": 380,
                    "y": 62.5
                },
                {
                    "x": 370,
                    "y": 89.5
                },
                {
                    "x": 367,
                    "y": 120.5
                },
                {
                    "x": 371,
                    "y": 154.5
                },
                {
                    "x": 381,
                    "y": 187.5
                },
                {
                    "x": 395,
                    "y": 216.5
                },
                {
                    "x": 407,
                    "y": 237.5
                },
                {
                    "x": 416,
                    "y": 249.5
                },
                {
                    "x": 425,
                    "y": 256.5
                },
                {
                    "x": 432,
                    "y": 259.5
                },
                {
                    "x": 439,
                    "y": 260.5
                },
                {
                    "x": 446,
                    "y": 259.5
                },
                {
                    "x": 455,
                    "y": 255.5
                },
                {
                    "x": 461,
                    "y": 249.5
                },
                {
                    "x": 466,
                    "y": 240.5
                },
                {
                    "x": 469,
                    "y": 232.5
                },
                {
                    "x": 471,
                    "y": 221.5
                },
                {
                    "x": 469,
                    "y": 208.5
                },
                {
                    "x": 462,
                    "y": 193.5
                },
                {
                    "x": 450,
                    "y": 179.5
                },
                {
                    "x": 434,
                    "y": 168.5
                },
                {
                    "x": 412,
                    "y": 162.5
                },
                {
                    "x": 384,
                    "y": 161.5
                },
                {
                    "x": 355,
                    "y": 164.5
                },
                {
                    "x": 327,
                    "y": 176.5
                },
                {
                    "x": 305,
                    "y": 194.5
                },
                {
                    "x": 288,
                    "y": 218.5
                },
                {
                    "x": 281,
                    "y": 243.5
                },
                {
                    "x": 281,
                    "y": 265.5
                },
                {
                    "x": 284,
                    "y": 286.5
                },
                {
                    "x": 292,
                    "y": 301.5
                },
                {
                    "x": 299,
                    "y": 310.5
                },
                {
                    "x": 306,
                    "y": 314.5
                },
                {
                    "x": 315,
                    "y": 315.5
                },
                {
                    "x": 327,
                    "y": 313.5
                },
                {
                    "x": 341,
                    "y": 305.5
                },
                {
                    "x": 360,
                    "y": 294.5
                },
                {
                    "x": 379,
                    "y": 281.5
                },
                {
                    "x": 394,
                    "y": 267.5
                },
                {
                    "x": 407,
                    "y": 250.5
                },
                {
                    "x": 414,
                    "y": 233.5
                },
                {
                    "x": 416,
                    "y": 217.5
                },
                {
                    "x": 416,
                    "y": 204.5
                },
                {
                    "x": 410,
                    "y": 194.5
                },
                {
                    "x": 402,
                    "y": 189.5
                },
                {
                    "x": 391,
                    "y": 187.5
                },
                {
                    "x": 376,
                    "y": 189.5
                },
                {
                    "x": 354,
                    "y": 201.5
                },
                {
                    "x": 332,
                    "y": 221.5
                },
                {
                    "x": 313,
                    "y": 248.5
                },
                {
                    "x": 303,
                    "y": 281.5
                },
                {
                    "x": 299,
                    "y": 312.5
                },
                {
                    "x": 302,
                    "y": 343.5
                },
                {
                    "x": 309,
                    "y": 368.5
                },
                {
                    "x": 317,
                    "y": 386.5
                },
                {
                    "x": 324,
                    "y": 395.5
                },
                {
                    "x": 329,
                    "y": 400.5
                },
                {
                    "x": 335,
                    "y": 401.5
                },
                {
                    "x": 340,
                    "y": 400.5
                },
                {
                    "x": 346,
                    "y": 395.5
                },
                {
                    "x": 353,
                    "y": 388.5
                },
                {
                    "x": 357,
                    "y": 378.5
                },
                {
                    "x": 360,
                    "y": 367.5
                },
                {
                    "x": 362,
                    "y": 355.5
                },
                {
                    "x": 360,
                    "y": 340.5
                },
                {
                    "x": 354,
                    "y": 323.5
                },
                {
                    "x": 343,
                    "y": 306.5
                },
                {
                    "x": 328,
                    "y": 295.5
                },
                {
                    "x": 313,
                    "y": 287.5
                },
                {
                    "x": 297,
                    "y": 285.5
                },
                {
                    "x": 281,
                    "y": 287.5
                },
                {
                    "x": 265,
                    "y": 298.5
                },
                {
                    "x": 253,
                    "y": 313.5
                },
                {
                    "x": 246,
                    "y": 332.5
                },
                {
                    "x": 243,
                    "y": 356.5
                },
                {
                    "x": 243,
                    "y": 375.5
                },
                {
                    "x": 248,
                    "y": 394.5
                },
                {
                    "x": 255,
                    "y": 407.5
                },
                {
                    "x": 263,
                    "y": 415.5
                },
                {
                    "x": 273,
                    "y": 419.5
                },
                {
                    "x": 284,
                    "y": 420.5
                },
                {
                    "x": 297,
                    "y": 419.5
                },
                {
                    "x": 313,
                    "y": 414.5
                },
                {
                    "x": 328,
                    "y": 406.5
                },
                {
                    "x": 342,
                    "y": 398.5
                },
                {
                    "x": 350,
                    "y": 390.5
                },
                {
                    "x": 354,
                    "y": 383.5
                },
                {
                    "x": 356,
                    "y": 377.5
                },
                {
                    "x": 356,
                    "y": 375.5
                },
                {
                    "x": 353,
                    "y": 373.5
                },
                {
                    "x": 348,
                    "y": 373.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#795548",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 133,
                    "y": 185.5
                },
                {
                    "x": 133,
                    "y": 193.5
                },
                {
                    "x": 133,
                    "y": 205.5
                },
                {
                    "x": 134,
                    "y": 222.5
                },
                {
                    "x": 136,
                    "y": 247.5
                },
                {
                    "x": 137,
                    "y": 278.5
                },
                {
                    "x": 139,
                    "y": 313.5
                },
                {
                    "x": 141,
                    "y": 349.5
                },
                {
                    "x": 142,
                    "y": 382.5
                },
                {
                    "x": 142,
                    "y": 402.5
                },
                {
                    "x": 142,
                    "y": 411.5
                },
                {
                    "x": 142,
                    "y": 413.5
                },
                {
                    "x": 142,
                    "y": 411.5
                },
                {
                    "x": 143,
                    "y": 404.5
                },
                {
                    "x": 146,
                    "y": 393.5
                },
                {
                    "x": 152,
                    "y": 379.5
                },
                {
                    "x": 162,
                    "y": 360.5
                },
                {
                    "x": 176,
                    "y": 341.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#795548",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 244,
                    "y": 192.5
                },
                {
                    "x": 238,
                    "y": 208.5
                },
                {
                    "x": 232,
                    "y": 232.5
                },
                {
                    "x": 227,
                    "y": 264.5
                },
                {
                    "x": 222,
                    "y": 300.5
                },
                {
                    "x": 220,
                    "y": 339.5
                },
                {
                    "x": 218,
                    "y": 380.5
                },
                {
                    "x": 218,
                    "y": 418.5
                },
                {
                    "x": 219,
                    "y": 454.5
                },
                {
                    "x": 222,
                    "y": 482.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#795548",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 296,
                    "y": 291.5
                },
                {
                    "x": 296,
                    "y": 293.5
                },
                {
                    "x": 297,
                    "y": 300.5
                },
                {
                    "x": 299,
                    "y": 312.5
                },
                {
                    "x": 303,
                    "y": 329.5
                },
                {
                    "x": 307,
                    "y": 353.5
                },
                {
                    "x": 314,
                    "y": 377.5
                },
                {
                    "x": 319,
                    "y": 396.5
                },
                {
                    "x": 323,
                    "y": 407.5
                },
                {
                    "x": 326,
                    "y": 415.5
                },
                {
                    "x": 330,
                    "y": 418.5
                },
                {
                    "x": 335,
                    "y": 420.5
                },
                {
                    "x": 339,
                    "y": 420.5
                },
                {
                    "x": 345,
                    "y": 418.5
                },
                {
                    "x": 352,
                    "y": 413.5
                },
                {
                    "x": 360,
                    "y": 405.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#795548",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 379,
                    "y": 381.5
                },
                {
                    "x": 379,
                    "y": 395.5
                },
                {
                    "x": 379,
                    "y": 414.5
                },
                {
                    "x": 379,
                    "y": 438.5
                },
                {
                    "x": 379,
                    "y": 463.5
                },
                {
                    "x": 383,
                    "y": 484.5
                },
                {
                    "x": 387,
                    "y": 498.5
                },
                {
                    "x": 392,
                    "y": 494.5
                },
                {
                    "x": 393,
                    "y": 479.5
                },
                {
                    "x": 393,
                    "y": 459.5
                },
                {
                    "x": 394,
                    "y": 434.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#795548",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 418,
                    "y": 241.5
                },
                {
                    "x": 426,
                    "y": 268.5
                },
                {
                    "x": 436,
                    "y": 296.5
                },
                {
                    "x": 443,
                    "y": 321.5
                },
                {
                    "x": 448,
                    "y": 335.5
                },
                {
                    "x": 450,
                    "y": 340.5
                },
                {
                    "x": 450,
                    "y": 343.5
                },
                {
                    "x": 449,
                    "y": 343.5
                },
                {
                    "x": 445,
                    "y": 340.5
                },
                {
                    "x": 435,
                    "y": 332.5
                },
                {
                    "x": 421,
                    "y": 323.5
                },
                {
                    "x": 403,
                    "y": 306.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#795548",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 120,
                    "y": 182.5
                },
                {
                    "x": 153,
                    "y": 186.5
                },
                {
                    "x": 195,
                    "y": 189.5
                },
                {
                    "x": 241,
                    "y": 191.5
                },
                {
                    "x": 290,
                    "y": 191.5
                },
                {
                    "x": 339,
                    "y": 191.5
                },
                {
                    "x": 385,
                    "y": 191.5
                },
                {
                    "x": 420,
                    "y": 191.5
                },
                {
                    "x": 446,
                    "y": 189.5
                },
                {
                    "x": 458,
                    "y": 186.5
                },
                {
                    "x": 465,
                    "y": 184.5
                },
                {
                    "x": 468,
                    "y": 183.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#795548",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 163,
                    "y": 193.5
                },
                {
                    "x": 173,
                    "y": 191.5
                },
                {
                    "x": 188,
                    "y": 189.5
                },
                {
                    "x": 211,
                    "y": 186.5
                },
                {
                    "x": 242,
                    "y": 183.5
                },
                {
                    "x": 281,
                    "y": 179.5
                },
                {
                    "x": 319,
                    "y": 178.5
                },
                {
                    "x": 355,
                    "y": 176.5
                },
                {
                    "x": 386,
                    "y": 174.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#795548",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 179,
                    "y": 162.5
                },
                {
                    "x": 178,
                    "y": 162.5
                },
                {
                    "x": 180,
                    "y": 160.5
                },
                {
                    "x": 189,
                    "y": 157.5
                },
                {
                    "x": 204,
                    "y": 153.5
                },
                {
                    "x": 233,
                    "y": 147.5
                },
                {
                    "x": 271,
                    "y": 138.5
                },
                {
                    "x": 308,
                    "y": 129.5
                },
                {
                    "x": 346,
                    "y": 118.5
                },
                {
                    "x": 377,
                    "y": 108.5
                },
                {
                    "x": 396,
                    "y": 101.5
                },
                {
                    "x": 406,
                    "y": 97.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#795548",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 221,
                    "y": 106.5
                },
                {
                    "x": 224,
                    "y": 106.5
                },
                {
                    "x": 235,
                    "y": 106.5
                },
                {
                    "x": 254,
                    "y": 103.5
                },
                {
                    "x": 287,
                    "y": 100.5
                },
                {
                    "x": 326,
                    "y": 98.5
                },
                {
                    "x": 367,
                    "y": 94.5
                },
                {
                    "x": 409,
                    "y": 91.5
                },
                {
                    "x": 442,
                    "y": 87.5
                },
                {
                    "x": 466,
                    "y": 84.5
                },
                {
                    "x": 478,
                    "y": 82.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#795548",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 203,
                    "y": 81.5
                },
                {
                    "x": 196,
                    "y": 81.5
                },
                {
                    "x": 193,
                    "y": 81.5
                },
                {
                    "x": 197,
                    "y": 81.5
                },
                {
                    "x": 207,
                    "y": 80.5
                },
                {
                    "x": 228,
                    "y": 77.5
                },
                {
                    "x": 264,
                    "y": 74.5
                },
                {
                    "x": 312,
                    "y": 68.5
                },
                {
                    "x": 359,
                    "y": 62.5
                },
                {
                    "x": 398,
                    "y": 57.5
                },
                {
                    "x": 430,
                    "y": 52.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#795548",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 206,
                    "y": 44.5
                },
                {
                    "x": 199,
                    "y": 44.5
                },
                {
                    "x": 197,
                    "y": 44.5
                },
                {
                    "x": 199,
                    "y": 44.5
                },
                {
                    "x": 208,
                    "y": 42.5
                },
                {
                    "x": 223,
                    "y": 40.5
                },
                {
                    "x": 243,
                    "y": 37.5
                },
                {
                    "x": 266,
                    "y": 34.5
                },
                {
                    "x": 289,
                    "y": 31.5
                },
                {
                    "x": 307,
                    "y": 30.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#795548",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 179,
                    "y": 28.5
                },
                {
                    "x": 177,
                    "y": 28.5
                },
                {
                    "x": 181,
                    "y": 28.5
                },
                {
                    "x": 193,
                    "y": 28.5
                },
                {
                    "x": 213,
                    "y": 28.5
                },
                {
                    "x": 243,
                    "y": 28.5
                },
                {
                    "x": 276,
                    "y": 28.5
                },
                {
                    "x": 308,
                    "y": 28.5
                },
                {
                    "x": 333,
                    "y": 28.5
                },
                {
                    "x": 350,
                    "y": 28.5
                },
                {
                    "x": 361,
                    "y": 28.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#795548",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 135,
                    "y": 34.5
                },
                {
                    "x": 129,
                    "y": 34.5
                },
                {
                    "x": 127,
                    "y": 34.5
                },
                {
                    "x": 126,
                    "y": 35.5
                },
                {
                    "x": 126,
                    "y": 36.5
                },
                {
                    "x": 126,
                    "y": 39.5
                },
                {
                    "x": 126,
                    "y": 44.5
                },
                {
                    "x": 128,
                    "y": 54.5
                },
                {
                    "x": 130,
                    "y": 65.5
                },
                {
                    "x": 132,
                    "y": 78.5
                },
                {
                    "x": 133,
                    "y": 95.5
                },
                {
                    "x": 133,
                    "y": 114.5
                },
                {
                    "x": 133,
                    "y": 136.5
                },
                {
                    "x": 132,
                    "y": 151.5
                },
                {
                    "x": 130,
                    "y": 161.5
                },
                {
                    "x": 128,
                    "y": 166.5
                },
                {
                    "x": 127,
                    "y": 169.5
                },
                {
                    "x": 126,
                    "y": 171.5
                },
                {
                    "x": 125,
                    "y": 171.5
                },
                {
                    "x": 124,
                    "y": 170.5
                },
                {
                    "x": 124,
                    "y": 166.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#795548",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 108,
                    "y": 69.5
                },
                {
                    "x": 99,
                    "y": 79.5
                },
                {
                    "x": 89,
                    "y": 94.5
                },
                {
                    "x": 78,
                    "y": 113.5
                },
                {
                    "x": 66,
                    "y": 132.5
                },
                {
                    "x": 55,
                    "y": 148.5
                },
                {
                    "x": 48,
                    "y": 157.5
                },
                {
                    "x": 43,
                    "y": 162.5
                },
                {
                    "x": 41,
                    "y": 163.5
                },
                {
                    "x": 41,
                    "y": 162.5
                },
                {
                    "x": 45,
                    "y": 157.5
                },
                {
                    "x": 51,
                    "y": 147.5
                },
                {
                    "x": 61,
                    "y": 133.5
                },
                {
                    "x": 74,
                    "y": 117.5
                },
                {
                    "x": 86,
                    "y": 102.5
                },
                {
                    "x": 95,
                    "y": 90.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#795548",
            "strokeWidth": 24,
            "paths": [
                {
                    "x": 99,
                    "y": 80.5
                },
                {
                    "x": 90,
                    "y": 93.5
                },
                {
                    "x": 75,
                    "y": 117.5
                },
                {
                    "x": 60,
                    "y": 149.5
                },
                {
                    "x": 45,
                    "y": 182.5
                },
                {
                    "x": 33,
                    "y": 218.5
                },
                {
                    "x": 23,
                    "y": 251.5
                },
                {
                    "x": 16,
                    "y": 278.5
                },
                {
                    "x": 12,
                    "y": 296.5
                },
                {
                    "x": 11,
                    "y": 307.5
                },
                {
                    "x": 11,
                    "y": 312.5
                },
                {
                    "x": 11,
                    "y": 315.5
                },
                {
                    "x": 12,
                    "y": 317.5
                },
                {
                    "x": 18,
                    "y": 318.5
                },
                {
                    "x": 30,
                    "y": 318.5
                },
                {
                    "x": 45,
                    "y": 315.5
                },
                {
                    "x": 67,
                    "y": 309.5
                },
                {
                    "x": 94,
                    "y": 300.5
                },
                {
                    "x": 128,
                    "y": 285.5
                },
                {
                    "x": 159,
                    "y": 270.5
                },
                {
                    "x": 189,
                    "y": 251.5
                },
                {
                    "x": 227,
                    "y": 215.5
                },
                {
                    "x": 235,
                    "y": 205.5
                },
                {
                    "x": 239,
                    "y": 201.5
                },
                {
                    "x": 240,
                    "y": 199.5
                },
                {
                    "x": 239,
                    "y": 199.5
                },
                {
                    "x": 231,
                    "y": 202.5
                },
                {
                    "x": 218,
                    "y": 210.5
                },
                {
                    "x": 196,
                    "y": 228.5
                },
                {
                    "x": 169,
                    "y": 254.5
                },
                {
                    "x": 144,
                    "y": 287.5
                },
                {
                    "x": 123,
                    "y": 325.5
                },
                {
                    "x": 107,
                    "y": 361.5
                },
                {
                    "x": 97,
                    "y": 391.5
                },
                {
                    "x": 93,
                    "y": 410.5
                },
                {
                    "x": 93,
                    "y": 418.5
                },
                {
                    "x": 93,
                    "y": 421.5
                },
                {
                    "x": 95,
                    "y": 421.5
                },
                {
                    "x": 103,
                    "y": 417.5
                },
                {
                    "x": 120,
                    "y": 408.5
                },
                {
                    "x": 147,
                    "y": 391.5
                },
                {
                    "x": 181,
                    "y": 371.5
                },
                {
                    "x": 216,
                    "y": 349.5
                },
                {
                    "x": 249,
                    "y": 326.5
                },
                {
                    "x": 279,
                    "y": 304.5
                },
                {
                    "x": 300,
                    "y": 285.5
                },
                {
                    "x": 309,
                    "y": 275.5
                },
                {
                    "x": 312,
                    "y": 271.5
                },
                {
                    "x": 312,
                    "y": 268.5
                },
                {
                    "x": 307,
                    "y": 268.5
                },
                {
                    "x": 289,
                    "y": 271.5
                },
                {
                    "x": 254,
                    "y": 282.5
                },
                {
                    "x": 207,
                    "y": 297.5
                },
                {
                    "x": 161,
                    "y": 315.5
                },
                {
                    "x": 119,
                    "y": 334.5
                },
                {
                    "x": 92,
                    "y": 351.5
                },
                {
                    "x": 79,
                    "y": 363.5
                },
                {
                    "x": 74,
                    "y": 373.5
                },
                {
                    "x": 74,
                    "y": 381.5
                },
                {
                    "x": 81,
                    "y": 388.5
                },
                {
                    "x": 97,
                    "y": 391.5
                },
                {
                    "x": 132,
                    "y": 391.5
                },
                {
                    "x": 184,
                    "y": 388.5
                },
                {
                    "x": 253,
                    "y": 376.5
                },
                {
                    "x": 323,
                    "y": 359.5
                },
                {
                    "x": 391,
                    "y": 338.5
                },
                {
                    "x": 446,
                    "y": 317.5
                },
                {
                    "x": 485,
                    "y": 299.5
                },
                {
                    "x": 483,
                    "y": 272.5
                },
                {
                    "x": 435,
                    "y": 272.5
                },
                {
                    "x": 366,
                    "y": 278.5
                },
                {
                    "x": 289,
                    "y": 290.5
                },
                {
                    "x": 216,
                    "y": 312.5
                },
                {
                    "x": 150,
                    "y": 340.5
                },
                {
                    "x": 100,
                    "y": 366.5
                },
                {
                    "x": 62,
                    "y": 390.5
                },
                {
                    "x": 45,
                    "y": 405.5
                },
                {
                    "x": 37,
                    "y": 412.5
                },
                {
                    "x": 37,
                    "y": 416.5
                },
                {
                    "x": 42,
                    "y": 419.5
                },
                {
                    "x": 55,
                    "y": 420.5
                },
                {
                    "x": 81,
                    "y": 420.5
                },
                {
                    "x": 126,
                    "y": 418.5
                },
                {
                    "x": 175,
                    "y": 412.5
                },
                {
                    "x": 228,
                    "y": 404.5
                },
                {
                    "x": 276,
                    "y": 397.5
                },
                {
                    "x": 311,
                    "y": 388.5
                },
                {
                    "x": 333,
                    "y": 382.5
                },
                {
                    "x": 342,
                    "y": 378.5
                },
                {
                    "x": 345,
                    "y": 375.5
                },
                {
                    "x": 342,
                    "y": 374.5
                },
                {
                    "x": 333,
                    "y": 374.5
                },
                {
                    "x": 301,
                    "y": 374.5
                },
                {
                    "x": 244,
                    "y": 374.5
                },
                {
                    "x": 174,
                    "y": 374.5
                },
                {
                    "x": 100,
                    "y": 377.5
                },
                {
                    "x": 35,
                    "y": 383.5
                },
                {
                    "x": 41,
                    "y": 421.5
                },
                {
                    "x": 112,
                    "y": 411.5
                },
                {
                    "x": 190,
                    "y": 396.5
                },
                {
                    "x": 265,
                    "y": 376.5
                },
                {
                    "x": 333,
                    "y": 353.5
                },
                {
                    "x": 391,
                    "y": 326.5
                },
                {
                    "x": 437,
                    "y": 296.5
                },
                {
                    "x": 465,
                    "y": 271.5
                },
                {
                    "x": 479,
                    "y": 252.5
                },
                {
                    "x": 481,
                    "y": 237.5
                },
                {
                    "x": 474,
                    "y": 221.5
                },
                {
                    "x": 450,
                    "y": 207.5
                },
                {
                    "x": 407,
                    "y": 194.5
                },
                {
                    "x": 349,
                    "y": 186.5
                },
                {
                    "x": 281,
                    "y": 182.5
                },
                {
                    "x": 210,
                    "y": 182.5
                },
                {
                    "x": 150,
                    "y": 189.5
                },
                {
                    "x": 103,
                    "y": 204.5
                },
                {
                    "x": 72,
                    "y": 218.5
                },
                {
                    "x": 57,
                    "y": 230.5
                },
                {
                    "x": 51,
                    "y": 239.5
                },
                {
                    "x": 50,
                    "y": 245.5
                },
                {
                    "x": 52,
                    "y": 250.5
                },
                {
                    "x": 60,
                    "y": 253.5
                },
                {
                    "x": 75,
                    "y": 254.5
                },
                {
                    "x": 101,
                    "y": 254.5
                },
                {
                    "x": 141,
                    "y": 249.5
                },
                {
                    "x": 190,
                    "y": 243.5
                },
                {
                    "x": 244,
                    "y": 233.5
                },
                {
                    "x": 298,
                    "y": 217.5
                },
                {
                    "x": 347,
                    "y": 199.5
                },
                {
                    "x": 384,
                    "y": 181.5
                },
                {
                    "x": 403,
                    "y": 167.5
                },
                {
                    "x": 410,
                    "y": 160.5
                },
                {
                    "x": 410,
                    "y": 153.5
                },
                {
                    "x": 403,
                    "y": 148.5
                },
                {
                    "x": 386,
                    "y": 144.5
                },
                {
                    "x": 344,
                    "y": 141.5
                },
                {
                    "x": 280,
                    "y": 141.5
                },
                {
                    "x": 201,
                    "y": 142.5
                },
                {
                    "x": 119,
                    "y": 148.5
                },
                {
                    "x": 45,
                    "y": 157.5
                },
                {
                    "x": 19,
                    "y": 180.5
                },
                {
                    "x": 88,
                    "y": 167.5
                },
                {
                    "x": 166,
                    "y": 150.5
                },
                {
                    "x": 244,
                    "y": 131.5
                },
                {
                    "x": 317,
                    "y": 109.5
                },
                {
                    "x": 377,
                    "y": 88.5
                },
                {
                    "x": 421,
                    "y": 71.5
                },
                {
                    "x": 444,
                    "y": 59.5
                },
                {
                    "x": 452,
                    "y": 52.5
                },
                {
                    "x": 452,
                    "y": 48.5
                },
                {
                    "x": 442,
                    "y": 45.5
                },
                {
                    "x": 415,
                    "y": 42.5
                },
                {
                    "x": 359,
                    "y": 42.5
                },
                {
                    "x": 280,
                    "y": 42.5
                },
                {
                    "x": 191,
                    "y": 42.5
                },
                {
                    "x": 107,
                    "y": 43.5
                },
                {
                    "x": 37,
                    "y": 47.5
                },
                {
                    "x": 5,
                    "y": 48.5
                },
                {
                    "x": 55,
                    "y": 42.5
                },
                {
                    "x": 110,
                    "y": 34.5
                },
                {
                    "x": 166,
                    "y": 26.5
                },
                {
                    "x": 216,
                    "y": 18.5
                },
                {
                    "x": 253,
                    "y": 13.5
                },
                {
                    "x": 273,
                    "y": 10.5
                },
                {
                    "x": 281,
                    "y": 10.5
                },
                {
                    "x": 283,
                    "y": 10.5
                },
                {
                    "x": 282,
                    "y": 10.5
                },
                {
                    "x": 273,
                    "y": 11.5
                },
                {
                    "x": 248,
                    "y": 14.5
                },
                {
                    "x": 201,
                    "y": 16.5
                },
                {
                    "x": 141,
                    "y": 18.5
                },
                {
                    "x": 69,
                    "y": 18.5
                },
                {
                    "x": 11,
                    "y": 29.5
                },
                {
                    "x": 95,
                    "y": 29.5
                },
                {
                    "x": 179,
                    "y": 28.5
                },
                {
                    "x": 258,
                    "y": 26.5
                },
                {
                    "x": 323,
                    "y": 22.5
                },
                {
                    "x": 370,
                    "y": 18.5
                },
                {
                    "x": 396,
                    "y": 15.5
                },
                {
                    "x": 404,
                    "y": 13.5
                },
                {
                    "x": 406,
                    "y": 12.5
                },
                {
                    "x": 403,
                    "y": 12.5
                },
                {
                    "x": 390,
                    "y": 12.5
                },
                {
                    "x": 362,
                    "y": 12.5
                },
                {
                    "x": 313,
                    "y": 12.5
                },
                {
                    "x": 254,
                    "y": 12.5
                },
                {
                    "x": 183,
                    "y": 12.5
                },
                {
                    "x": 113,
                    "y": 12.5
                },
                {
                    "x": 48,
                    "y": 15.5
                },
                {
                    "x": 65,
                    "y": 33.5
                },
                {
                    "x": 149,
                    "y": 27.5
                },
                {
                    "x": 239,
                    "y": 18.5
                },
                {
                    "x": 327,
                    "y": 9.5
                },
                {
                    "x": 409,
                    "y": 1.5
                },
                {
                    "x": 143,
                    "y": 5.5
                },
                {
                    "x": 74,
                    "y": 13.5
                },
                {
                    "x": 21,
                    "y": 23.5
                },
                {
                    "x": 8,
                    "y": 41.5
                },
                {
                    "x": 57,
                    "y": 41.5
                },
                {
                    "x": 125,
                    "y": 41.5
                },
                {
                    "x": 201,
                    "y": 38.5
                },
                {
                    "x": 282,
                    "y": 33.5
                },
                {
                    "x": 362,
                    "y": 27.5
                },
                {
                    "x": 435,
                    "y": 23.5
                },
                {
                    "x": 493,
                    "y": 19.5
                },
                {
                    "x": 476,
                    "y": 19.5
                },
                {
                    "x": 416,
                    "y": 23.5
                },
                {
                    "x": 345,
                    "y": 27.5
                },
                {
                    "x": 271,
                    "y": 31.5
                },
                {
                    "x": 200,
                    "y": 35.5
                },
                {
                    "x": 137,
                    "y": 42.5
                },
                {
                    "x": 87,
                    "y": 49.5
                },
                {
                    "x": 52,
                    "y": 56.5
                },
                {
                    "x": 36,
                    "y": 60.5
                },
                {
                    "x": 31,
                    "y": 62.5
                },
                {
                    "x": 35,
                    "y": 62.5
                },
                {
                    "x": 59,
                    "y": 62.5
                },
                {
                    "x": 105,
                    "y": 62.5
                },
                {
                    "x": 171,
                    "y": 59.5
                },
                {
                    "x": 250,
                    "y": 55.5
                },
                {
                    "x": 331,
                    "y": 51.5
                },
                {
                    "x": 413,
                    "y": 46.5
                },
                {
                    "x": 486,
                    "y": 42.5
                },
                {
                    "x": 477,
                    "y": 31.5
                },
                {
                    "x": 383,
                    "y": 33.5
                },
                {
                    "x": 277,
                    "y": 37.5
                },
                {
                    "x": 169,
                    "y": 44.5
                },
                {
                    "x": 65,
                    "y": 57.5
                },
                {
                    "x": 76,
                    "y": 89.5
                },
                {
                    "x": 163,
                    "y": 78.5
                },
                {
                    "x": 254,
                    "y": 67.5
                },
                {
                    "x": 336,
                    "y": 59.5
                },
                {
                    "x": 404,
                    "y": 50.5
                },
                {
                    "x": 454,
                    "y": 44.5
                },
                {
                    "x": 478,
                    "y": 40.5
                },
                {
                    "x": 485,
                    "y": 38.5
                },
                {
                    "x": 477,
                    "y": 38.5
                },
                {
                    "x": 451,
                    "y": 38.5
                },
                {
                    "x": 396,
                    "y": 40.5
                },
                {
                    "x": 325,
                    "y": 47.5
                },
                {
                    "x": 246,
                    "y": 55.5
                },
                {
                    "x": 169,
                    "y": 64.5
                },
                {
                    "x": 105,
                    "y": 74.5
                },
                {
                    "x": 55,
                    "y": 84.5
                },
                {
                    "x": 22,
                    "y": 92.5
                },
                {
                    "x": 8,
                    "y": 97.5
                },
                {
                    "x": 4,
                    "y": 100.5
                },
                {
                    "x": 12,
                    "y": 100.5
                },
                {
                    "x": 36,
                    "y": 100.5
                },
                {
                    "x": 83,
                    "y": 98.5
                },
                {
                    "x": 138,
                    "y": 94.5
                },
                {
                    "x": 198,
                    "y": 90.5
                },
                {
                    "x": 259,
                    "y": 84.5
                },
                {
                    "x": 317,
                    "y": 78.5
                },
                {
                    "x": 364,
                    "y": 70.5
                },
                {
                    "x": 396,
                    "y": 65.5
                },
                {
                    "x": 409,
                    "y": 63.5
                },
                {
                    "x": 413,
                    "y": 62.5
                },
                {
                    "x": 414,
                    "y": 62.5
                },
                {
                    "x": 410,
                    "y": 62.5
                },
                {
                    "x": 391,
                    "y": 62.5
                },
                {
                    "x": 350,
                    "y": 66.5
                },
                {
                    "x": 292,
                    "y": 70.5
                },
                {
                    "x": 224,
                    "y": 74.5
                },
                {
                    "x": 150,
                    "y": 80.5
                },
                {
                    "x": 79,
                    "y": 87.5
                },
                {
                    "x": 18,
                    "y": 97.5
                },
                {
                    "x": 8,
                    "y": 120.5
                },
                {
                    "x": 68,
                    "y": 118.5
                },
                {
                    "x": 135,
                    "y": 116.5
                },
                {
                    "x": 201,
                    "y": 114.5
                },
                {
                    "x": 263,
                    "y": 110.5
                },
                {
                    "x": 316,
                    "y": 104.5
                },
                {
                    "x": 355,
                    "y": 101.5
                },
                {
                    "x": 375,
                    "y": 98.5
                },
                {
                    "x": 384,
                    "y": 97.5
                },
                {
                    "x": 385,
                    "y": 97.5
                },
                {
                    "x": 382,
                    "y": 97.5
                },
                {
                    "x": 365,
                    "y": 97.5
                },
                {
                    "x": 329,
                    "y": 99.5
                },
                {
                    "x": 275,
                    "y": 101.5
                },
                {
                    "x": 207,
                    "y": 105.5
                },
                {
                    "x": 133,
                    "y": 111.5
                },
                {
                    "x": 59,
                    "y": 118.5
                },
                {
                    "x": 42,
                    "y": 152.5
                },
                {
                    "x": 129,
                    "y": 147.5
                },
                {
                    "x": 224,
                    "y": 140.5
                },
                {
                    "x": 322,
                    "y": 131.5
                },
                {
                    "x": 412,
                    "y": 122.5
                },
                {
                    "x": 483,
                    "y": 116.5
                },
                {
                    "x": 449,
                    "y": 112.5
                },
                {
                    "x": 383,
                    "y": 119.5
                },
                {
                    "x": 320,
                    "y": 125.5
                },
                {
                    "x": 264,
                    "y": 135.5
                },
                {
                    "x": 219,
                    "y": 144.5
                },
                {
                    "x": 192,
                    "y": 152.5
                },
                {
                    "x": 179,
                    "y": 155.5
                },
                {
                    "x": 175,
                    "y": 157.5
                },
                {
                    "x": 175,
                    "y": 158.5
                },
                {
                    "x": 178,
                    "y": 158.5
                },
                {
                    "x": 193,
                    "y": 158.5
                },
                {
                    "x": 221,
                    "y": 157.5
                },
                {
                    "x": 263,
                    "y": 154.5
                },
                {
                    "x": 307,
                    "y": 150.5
                },
                {
                    "x": 352,
                    "y": 144.5
                },
                {
                    "x": 386,
                    "y": 139.5
                },
                {
                    "x": 406,
                    "y": 137.5
                },
                {
                    "x": 416,
                    "y": 134.5
                },
                {
                    "x": 419,
                    "y": 134.5
                },
                {
                    "x": 413,
                    "y": 134.5
                },
                {
                    "x": 395,
                    "y": 134.5
                },
                {
                    "x": 352,
                    "y": 134.5
                },
                {
                    "x": 289,
                    "y": 137.5
                },
                {
                    "x": 213,
                    "y": 141.5
                },
                {
                    "x": 134,
                    "y": 145.5
                },
                {
                    "x": 63,
                    "y": 152.5
                },
                {
                    "x": 10,
                    "y": 159.5
                },
                {
                    "x": 24,
                    "y": 182.5
                },
                {
                    "x": 89,
                    "y": 182.5
                },
                {
                    "x": 167,
                    "y": 181.5
                },
                {
                    "x": 254,
                    "y": 174.5
                },
                {
                    "x": 342,
                    "y": 165.5
                },
                {
                    "x": 418,
                    "y": 159.5
                },
                {
                    "x": 482,
                    "y": 151.5
                },
                {
                    "x": 450,
                    "y": 140.5
                },
                {
                    "x": 374,
                    "y": 140.5
                },
                {
                    "x": 282,
                    "y": 142.5
                },
                {
                    "x": 185,
                    "y": 148.5
                },
                {
                    "x": 95,
                    "y": 155.5
                },
                {
                    "x": 23,
                    "y": 165.5
                },
                {
                    "x": 35,
                    "y": 196.5
                },
                {
                    "x": 102,
                    "y": 196.5
                },
                {
                    "x": 181,
                    "y": 196.5
                },
                {
                    "x": 259,
                    "y": 193.5
                },
                {
                    "x": 333,
                    "y": 186.5
                },
                {
                    "x": 399,
                    "y": 180.5
                },
                {
                    "x": 452,
                    "y": 172.5
                },
                {
                    "x": 483,
                    "y": 167.5
                },
                {
                    "x": 494,
                    "y": 165.5
                },
                {
                    "x": 498,
                    "y": 164.5
                },
                {
                    "x": 495,
                    "y": 163.5
                },
                {
                    "x": 479,
                    "y": 162.5
                },
                {
                    "x": 438,
                    "y": 162.5
                },
                {
                    "x": 373,
                    "y": 162.5
                },
                {
                    "x": 284,
                    "y": 165.5
                },
                {
                    "x": 184,
                    "y": 170.5
                },
                {
                    "x": 80,
                    "y": 176.5
                },
                {
                    "x": 24,
                    "y": 222.5
                },
                {
                    "x": 109,
                    "y": 216.5
                },
                {
                    "x": 193,
                    "y": 209.5
                },
                {
                    "x": 273,
                    "y": 201.5
                },
                {
                    "x": 339,
                    "y": 193.5
                },
                {
                    "x": 386,
                    "y": 185.5
                },
                {
                    "x": 410,
                    "y": 181.5
                },
                {
                    "x": 418,
                    "y": 178.5
                },
                {
                    "x": 419,
                    "y": 177.5
                },
                {
                    "x": 413,
                    "y": 177.5
                },
                {
                    "x": 389,
                    "y": 177.5
                },
                {
                    "x": 337,
                    "y": 177.5
                },
                {
                    "x": 264,
                    "y": 178.5
                },
                {
                    "x": 182,
                    "y": 185.5
                },
                {
                    "x": 103,
                    "y": 191.5
                },
                {
                    "x": 37,
                    "y": 201.5
                },
                {
                    "x": 2,
                    "y": 239.5
                },
                {
                    "x": 59,
                    "y": 238.5
                },
                {
                    "x": 127,
                    "y": 236.5
                },
                {
                    "x": 198,
                    "y": 232.5
                },
                {
                    "x": 266,
                    "y": 228.5
                },
                {
                    "x": 329,
                    "y": 222.5
                },
                {
                    "x": 377,
                    "y": 216.5
                },
                {
                    "x": 402,
                    "y": 213.5
                },
                {
                    "x": 412,
                    "y": 212.5
                },
                {
                    "x": 414,
                    "y": 211.5
                },
                {
                    "x": 410,
                    "y": 211.5
                },
                {
                    "x": 392,
                    "y": 211.5
                },
                {
                    "x": 351,
                    "y": 211.5
                },
                {
                    "x": 286,
                    "y": 212.5
                },
                {
                    "x": 204,
                    "y": 219.5
                },
                {
                    "x": 122,
                    "y": 227.5
                },
                {
                    "x": 48,
                    "y": 238.5
                },
                {
                    "x": 29,
                    "y": 285.5
                },
                {
                    "x": 102,
                    "y": 282.5
                },
                {
                    "x": 179,
                    "y": 275.5
                },
                {
                    "x": 258,
                    "y": 271.5
                },
                {
                    "x": 326,
                    "y": 267.5
                },
                {
                    "x": 378,
                    "y": 261.5
                },
                {
                    "x": 409,
                    "y": 258.5
                },
                {
                    "x": 420,
                    "y": 257.5
                },
                {
                    "x": 423,
                    "y": 257.5
                },
                {
                    "x": 422,
                    "y": 256.5
                },
                {
                    "x": 410,
                    "y": 256.5
                },
                {
                    "x": 382,
                    "y": 256.5
                },
                {
                    "x": 328,
                    "y": 257.5
                },
                {
                    "x": 262,
                    "y": 261.5
                },
                {
                    "x": 191,
                    "y": 265.5
                },
                {
                    "x": 131,
                    "y": 271.5
                },
                {
                    "x": 86,
                    "y": 279.5
                },
                {
                    "x": 57,
                    "y": 285.5
                },
                {
                    "x": 43,
                    "y": 289.5
                },
                {
                    "x": 39,
                    "y": 290.5
                },
                {
                    "x": 37,
                    "y": 291.5
                },
                {
                    "x": 39,
                    "y": 291.5
                },
                {
                    "x": 51,
                    "y": 291.5
                },
                {
                    "x": 75,
                    "y": 291.5
                },
                {
                    "x": 116,
                    "y": 289.5
                },
                {
                    "x": 163,
                    "y": 285.5
                },
                {
                    "x": 213,
                    "y": 281.5
                },
                {
                    "x": 257,
                    "y": 278.5
                },
                {
                    "x": 296,
                    "y": 274.5
                },
                {
                    "x": 324,
                    "y": 273.5
                },
                {
                    "x": 336,
                    "y": 273.5
                },
                {
                    "x": 339,
                    "y": 273.5
                },
                {
                    "x": 333,
                    "y": 273.5
                },
                {
                    "x": 317,
                    "y": 273.5
                },
                {
                    "x": 276,
                    "y": 273.5
                },
                {
                    "x": 214,
                    "y": 275.5
                },
                {
                    "x": 140,
                    "y": 278.5
                },
                {
                    "x": 67,
                    "y": 282.5
                },
                {
                    "x": 18,
                    "y": 299.5
                },
                {
                    "x": 86,
                    "y": 295.5
                },
                {
                    "x": 151,
                    "y": 291.5
                },
                {
                    "x": 212,
                    "y": 287.5
                },
                {
                    "x": 256,
                    "y": 283.5
                },
                {
                    "x": 279,
                    "y": 281.5
                },
                {
                    "x": 288,
                    "y": 278.5
                },
                {
                    "x": 291,
                    "y": 278.5
                },
                {
                    "x": 289,
                    "y": 278.5
                },
                {
                    "x": 280,
                    "y": 278.5
                },
                {
                    "x": 258,
                    "y": 279.5
                },
                {
                    "x": 213,
                    "y": 282.5
                },
                {
                    "x": 161,
                    "y": 288.5
                },
                {
                    "x": 111,
                    "y": 296.5
                },
                {
                    "x": 68,
                    "y": 307.5
                },
                {
                    "x": 35,
                    "y": 318.5
                },
                {
                    "x": 17,
                    "y": 326.5
                },
                {
                    "x": 8,
                    "y": 331.5
                },
                {
                    "x": 5,
                    "y": 335.5
                },
                {
                    "x": 5,
                    "y": 337.5
                },
                {
                    "x": 8,
                    "y": 338.5
                },
                {
                    "x": 20,
                    "y": 339.5
                },
                {
                    "x": 40,
                    "y": 339.5
                },
                {
                    "x": 73,
                    "y": 339.5
                },
                {
                    "x": 113,
                    "y": 339.5
                },
                {
                    "x": 157,
                    "y": 339.5
                },
                {
                    "x": 201,
                    "y": 336.5
                },
                {
                    "x": 237,
                    "y": 333.5
                },
                {
                    "x": 266,
                    "y": 330.5
                },
                {
                    "x": 281,
                    "y": 327.5
                },
                {
                    "x": 288,
                    "y": 327.5
                },
                {
                    "x": 290,
                    "y": 326.5
                },
                {
                    "x": 286,
                    "y": 326.5
                },
                {
                    "x": 271,
                    "y": 326.5
                },
                {
                    "x": 242,
                    "y": 328.5
                },
                {
                    "x": 196,
                    "y": 332.5
                },
                {
                    "x": 143,
                    "y": 336.5
                },
                {
                    "x": 88,
                    "y": 342.5
                },
                {
                    "x": 43,
                    "y": 351.5
                },
                {
                    "x": 11,
                    "y": 358.5
                },
                {
                    "x": 6,
                    "y": 368.5
                },
                {
                    "x": 50,
                    "y": 365.5
                },
                {
                    "x": 113,
                    "y": 361.5
                },
                {
                    "x": 189,
                    "y": 355.5
                },
                {
                    "x": 271,
                    "y": 346.5
                },
                {
                    "x": 353,
                    "y": 337.5
                },
                {
                    "x": 427,
                    "y": 329.5
                },
                {
                    "x": 483,
                    "y": 321.5
                },
                {
                    "x": 457,
                    "y": 313.5
                },
                {
                    "x": 397,
                    "y": 316.5
                },
                {
                    "x": 323,
                    "y": 323.5
                },
                {
                    "x": 252,
                    "y": 331.5
                },
                {
                    "x": 190,
                    "y": 343.5
                },
                {
                    "x": 139,
                    "y": 357.5
                },
                {
                    "x": 106,
                    "y": 368.5
                },
                {
                    "x": 94,
                    "y": 372.5
                },
                {
                    "x": 89,
                    "y": 375.5
                },
                {
                    "x": 90,
                    "y": 376.5
                },
                {
                    "x": 98,
                    "y": 376.5
                },
                {
                    "x": 120,
                    "y": 376.5
                },
                {
                    "x": 159,
                    "y": 374.5
                },
                {
                    "x": 208,
                    "y": 370.5
                },
                {
                    "x": 261,
                    "y": 364.5
                },
                {
                    "x": 308,
                    "y": 360.5
                },
                {
                    "x": 347,
                    "y": 355.5
                },
                {
                    "x": 368,
                    "y": 352.5
                },
                {
                    "x": 377,
                    "y": 350.5
                },
                {
                    "x": 380,
                    "y": 350.5
                },
                {
                    "x": 372,
                    "y": 350.5
                },
                {
                    "x": 349,
                    "y": 350.5
                },
                {
                    "x": 303,
                    "y": 352.5
                },
                {
                    "x": 245,
                    "y": 356.5
                },
                {
                    "x": 190,
                    "y": 364.5
                },
                {
                    "x": 139,
                    "y": 376.5
                },
                {
                    "x": 100,
                    "y": 391.5
                },
                {
                    "x": 74,
                    "y": 402.5
                },
                {
                    "x": 63,
                    "y": 410.5
                },
                {
                    "x": 58,
                    "y": 416.5
                },
                {
                    "x": 60,
                    "y": 421.5
                },
                {
                    "x": 72,
                    "y": 424.5
                },
                {
                    "x": 95,
                    "y": 427.5
                },
                {
                    "x": 136,
                    "y": 428.5
                },
                {
                    "x": 185,
                    "y": 428.5
                },
                {
                    "x": 233,
                    "y": 428.5
                },
                {
                    "x": 278,
                    "y": 426.5
                },
                {
                    "x": 311,
                    "y": 424.5
                },
                {
                    "x": 325,
                    "y": 423.5
                },
                {
                    "x": 332,
                    "y": 423.5
                },
                {
                    "x": 333,
                    "y": 423.5
                },
                {
                    "x": 331,
                    "y": 423.5
                },
                {
                    "x": 321,
                    "y": 423.5
                },
                {
                    "x": 297,
                    "y": 423.5
                },
                {
                    "x": 259,
                    "y": 423.5
                },
                {
                    "x": 215,
                    "y": 424.5
                },
                {
                    "x": 177,
                    "y": 425.5
                },
                {
                    "x": 146,
                    "y": 429.5
                },
                {
                    "x": 130,
                    "y": 431.5
                },
                {
                    "x": 123,
                    "y": 433.5
                },
                {
                    "x": 121,
                    "y": 434.5
                },
                {
                    "x": 123,
                    "y": 434.5
                },
                {
                    "x": 134,
                    "y": 431.5
                },
                {
                    "x": 155,
                    "y": 428.5
                },
                {
                    "x": 184,
                    "y": 423.5
                },
                {
                    "x": 213,
                    "y": 419.5
                },
                {
                    "x": 236,
                    "y": 414.5
                },
                {
                    "x": 255,
                    "y": 410.5
                },
                {
                    "x": 265,
                    "y": 407.5
                },
                {
                    "x": 269,
                    "y": 406.5
                },
                {
                    "x": 270,
                    "y": 406.5
                },
                {
                    "x": 269,
                    "y": 406.5
                },
                {
                    "x": 262,
                    "y": 406.5
                },
                {
                    "x": 247,
                    "y": 406.5
                },
                {
                    "x": 227,
                    "y": 406.5
                },
                {
                    "x": 202,
                    "y": 408.5
                },
                {
                    "x": 181,
                    "y": 411.5
                },
                {
                    "x": 168,
                    "y": 413.5
                },
                {
                    "x": 161,
                    "y": 414.5
                },
                {
                    "x": 158,
                    "y": 415.5
                },
                {
                    "x": 156,
                    "y": 415.5
                },
                {
                    "x": 159,
                    "y": 415.5
                },
                {
                    "x": 162,
                    "y": 415.5
                },
                {
                    "x": 164,
                    "y": 415.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#009688",
            "strokeWidth": 16,
            "paths": [
                {
                    "x": 154,
                    "y": 162.5
                },
                {
                    "x": 151,
                    "y": 164.5
                },
                {
                    "x": 149,
                    "y": 169.5
                },
                {
                    "x": 144,
                    "y": 188.5
                },
                {
                    "x": 142,
                    "y": 201.5
                },
                {
                    "x": 140,
                    "y": 215.5
                },
                {
                    "x": 139,
                    "y": 228.5
                },
                {
                    "x": 139,
                    "y": 239.5
                },
                {
                    "x": 139,
                    "y": 245.5
                },
                {
                    "x": 141,
                    "y": 250.5
                },
                {
                    "x": 145,
                    "y": 254.5
                },
                {
                    "x": 151,
                    "y": 256.5
                },
                {
                    "x": 159,
                    "y": 257.5
                },
                {
                    "x": 169,
                    "y": 257.5
                },
                {
                    "x": 178,
                    "y": 256.5
                },
                {
                    "x": 187,
                    "y": 252.5
                },
                {
                    "x": 193,
                    "y": 248.5
                },
                {
                    "x": 196,
                    "y": 245.5
                },
                {
                    "x": 199,
                    "y": 241.5
                },
                {
                    "x": 200,
                    "y": 239.5
                },
                {
                    "x": 200,
                    "y": 237.5
                },
                {
                    "x": 198,
                    "y": 236.5
                },
                {
                    "x": 196,
                    "y": 234.5
                },
                {
                    "x": 195,
                    "y": 233.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#009688",
            "strokeWidth": 16,
            "paths": [
                {
                    "x": 227,
                    "y": 145.5
                },
                {
                    "x": 227,
                    "y": 148.5
                },
                {
                    "x": 227,
                    "y": 152.5
                },
                {
                    "x": 228,
                    "y": 159.5
                },
                {
                    "x": 229,
                    "y": 170.5
                },
                {
                    "x": 231,
                    "y": 183.5
                },
                {
                    "x": 234,
                    "y": 202.5
                },
                {
                    "x": 238,
                    "y": 228.5
                },
                {
                    "x": 243,
                    "y": 257.5
                },
                {
                    "x": 250,
                    "y": 286.5
                },
                {
                    "x": 254,
                    "y": 310.5
                },
                {
                    "x": 257,
                    "y": 322.5
                },
                {
                    "x": 259,
                    "y": 328.5
                },
                {
                    "x": 260,
                    "y": 330.5
                },
                {
                    "x": 260,
                    "y": 331.5
                }
            ]
        },
        {
            "drawMode": true,
            "strokeColor": "#009688",
            "strokeWidth": 16,
            "paths": [
                {
                    "x": 157,
                    "y": 356.5
                },
                {
                    "x": 157,
                    "y": 362.5
                },
                {
                    "x": 157,
                    "y": 370.5
                },
                {
                    "x": 157,
                    "y": 380.5
                },
                {
                    "x": 159,
                    "y": 389.5
                },
                {
                    "x": 161,
                    "y": 396.5
                },
                {
                    "x": 167,
                    "y": 404.5
                },
                {
                    "x": 174,
                    "y": 409.5
                },
                {
                    "x": 181,
                    "y": 412.5
                },
                {
                    "x": 191,
                    "y": 414.5
                },
                {
                    "x": 203,
                    "y": 415.5
                },
                {
                    "x": 217,
                    "y": 415.5
                },
                {
                    "x": 231,
                    "y": 413.5
                },
                {
                    "x": 243,
                    "y": 410.5
                },
                {
                    "x": 255,
                    "y": 405.5
                },
                {
                    "x": 266,
                    "y": 400.5
                },
                {
                    "x": 275,
                    "y": 394.5
                },
                {
                    "x": 281,
                    "y": 388.5
                },
                {
                    "x": 286,
                    "y": 382.5
                },
                {
                    "x": 289,
                    "y": 377.5
                },
                {
                    "x": 290,
                    "y": 373.5
                },
                {
                    "x": 290,
                    "y": 369.5
                },
                {
                    "x": 290,
                    "y": 366.5
                },
                {
                    "x": 290,
                    "y": 363.5
                },
                {
                    "x": 290,
                    "y": 362.5
                },
                {
                    "x": 289,
                    "y": 362.5
                },
                {
                    "x": 288,
                    "y": 363.5
                },
                {
                    "x": 285,
                    "y": 366.5
                },
                {
                    "x": 283,
                    "y": 368.5
                },
                {
                    "x": 278,
                    "y": 371.5
                },
                {
                    "x": 273,
                    "y": 374.5
                },
                {
                    "x": 264,
                    "y": 377.5
                },
                {
                    "x": 255,
                    "y": 379.5
                },
                {
                    "x": 242,
                    "y": 381.5
                },
                {
                    "x": 229,
                    "y": 382.5
                },
                {
                    "x": 216,
                    "y": 382.5
                },
                {
                    "x": 204,
                    "y": 382.5
                },
                {
                    "x": 192,
                    "y": 382.5
                },
                {
                    "x": 181,
                    "y": 382.5
                },
                {
                    "x": 172,
                    "y": 382.5
                },
                {
                    "x": 165,
                    "y": 382.5
                },
                {
                    "x": 161,
                    "y": 382.5
                },
                {
                    "x": 158,
                    "y": 382.5
                },
                {
                    "x": 157,
                    "y": 380.5
                },
                {
                    "x": 156,
                    "y": 377.5
                },
                {
                    "x": 156,
                    "y": 374.5
                },
                {
                    "x": 157,
                    "y": 369.5
                },
                {
                    "x": 160,
                    "y": 363.5
                },
                {
                    "x": 165,
                    "y": 356.5
                },
                {
                    "x": 170,
                    "y": 350.5
                },
                {
                    "x": 175,
                    "y": 345.5
                },
                {
                    "x": 178,
                    "y": 342.5
                },
                {
                    "x": 181,
                    "y": 341.5
                },
                {
                    "x": 182,
                    "y": 341.5
                },
                {
                    "x": 183,
                    "y": 341.5
                },
                {
                    "x": 185,
                    "y": 342.5
                },
                {
                    "x": 186,
                    "y": 346.5
                },
                {
                    "x": 189,
                    "y": 350.5
                },
                {
                    "x": 193,
                    "y": 355.5
                },
                {
                    "x": 199,
                    "y": 360.5
                },
                {
                    "x": 205,
                    "y": 364.5
                },
                {
                    "x": 212,
                    "y": 367.5
                },
                {
                    "x": 218,
                    "y": 368.5
                },
                {
                    "x": 223,
                    "y": 369.5
                },
                {
                    "x": 226,
                    "y": 369.5
                },
                {
                    "x": 229,
                    "y": 369.5
                },
                {
                    "x": 230,
                    "y": 369.5
                },
                {
                    "x": 231,
                    "y": 369.5
                },
                {
                    "x": 232,
                    "y": 369.5
                },
                {
                    "x": 232,
                    "y": 368.5
                },
                {
                    "x": 232,
                    "y": 367.5
                },
                {
                    "x": 231,
                    "y": 365.5
                },
                {
                    "x": 229,
                    "y": 363.5
                },
                {
                    "x": 226,
                    "y": 359.5
                },
                {
                    "x": 222,
                    "y": 355.5
                },
                {
                    "x": 216,
                    "y": 351.5
                },
                {
                    "x": 207,
                    "y": 348.5
                },
                {
                    "x": 196,
                    "y": 345.5
                },
                {
                    "x": 184,
                    "y": 343.5
                },
                {
                    "x": 172,
                    "y": 343.5
                },
                {
                    "x": 161,
                    "y": 343.5
                },
                {
                    "x": 153,
                    "y": 346.5
                },
                {
                    "x": 147,
                    "y": 350.5
                },
                {
                    "x": 144,
                    "y": 355.5
                },
                {
                    "x": 144,
                    "y": 359.5
                },
                {
                    "x": 148,
                    "y": 364.5
                },
                {
                    "x": 157,
                    "y": 367.5
                },
                {
                    "x": 169,
                    "y": 369.5
                },
                {
                    "x": 185,
                    "y": 370.5
                },
                {
                    "x": 205,
                    "y": 370.5
                },
                {
                    "x": 226,
                    "y": 366.5
                },
                {
                    "x": 245,
                    "y": 360.5
                },
                {
                    "x": 262,
                    "y": 355.5
                },
                {
                    "x": 274,
                    "y": 350.5
                },
                {
                    "x": 283,
                    "y": 345.5
                },
                {
                    "x": 288,
                    "y": 343.5
                },
                {
                    "x": 289,
                    "y": 342.5
                },
                {
                    "x": 290,
                    "y": 342.5
                },
                {
                    "x": 288,
                    "y": 345.5
                },
                {
                    "x": 285,
                    "y": 349.5
                },
                {
                    "x": 281,
                    "y": 354.5
                },
                {
                    "x": 277,
                    "y": 357.5
                },
                {
                    "x": 275,
                    "y": 360.5
                },
                {
                    "x": 273,
                    "y": 361.5
                },
                {
                    "x": 273,
                    "y": 362.5
                },
                {
                    "x": 274,
                    "y": 362.5
                },
                {
                    "x": 279,
                    "y": 362.5
                },
                {
                    "x": 287,
                    "y": 361.5
                },
                {
                    "x": 298,
                    "y": 359.5
                },
                {
                    "x": 309,
                    "y": 358.5
                },
                {
                    "x": 317,
                    "y": 358.5
                },
                {
                    "x": 322,
                    "y": 358.5
                },
                {
                    "x": 325,
                    "y": 358.5
                },
                {
                    "x": 323,
                    "y": 358.5
                },
                {
                    "x": 321,
                    "y": 359.5
                },
                {
                    "x": 321,
                    "y": 360.5
                },
                {
                    "x": 321,
                    "y": 361.5
                },
                {
                    "x": 321,
                    "y": 363.5
                },
                {
                    "x": 325,
                    "y": 367.5
                },
                {
                    "x": 331,
                    "y": 371.5
                },
                {
                    "x": 336,
                    "y": 377.5
                },
                {
                    "x": 341,
                    "y": 383.5
                },
                {
                    "x": 344,
                    "y": 390.5
                },
                {
                    "x": 345,
                    "y": 397.5
                },
                {
                    "x": 345,
                    "y": 404.5
                }
            ]
        },
        {
            "drawMode": false,
            "strokeColor": "#000000",
            "strokeWidth": 16,
            "paths": [
                {
                    "x": 211,
                    "y": 467.5
                },
                {
                    "x": 213,
                    "y": 467.5
                },
                {
                    "x": 216,
                    "y": 465.5
                },
                {
                    "x": 221,
                    "y": 462.5
                },
                {
                    "x": 228,
                    "y": 457.5
                },
                {
                    "x": 238,
                    "y": 451.5
                },
                {
                    "x": 253,
                    "y": 439.5
                },
                {
                    "x": 273,
                    "y": 422.5
                },
                {
                    "x": 296,
                    "y": 400.5
                },
                {
                    "x": 322,
                    "y": 376.5
                },
                {
                    "x": 349,
                    "y": 348.5
                },
                {
                    "x": 373,
                    "y": 322.5
                },
                {
                    "x": 396,
                    "y": 296.5
                },
                {
                    "x": 414,
                    "y": 276.5
                },
                {
                    "x": 429,
                    "y": 258.5
                },
                {
                    "x": 440,
                    "y": 246.5
                }
            ]
        },
        {
            "drawMode": false,
            "strokeColor": "#000000",
            "strokeWidth": 16,
            "paths": [
                {
                    "x": 160,
                    "y": 424.5
                },
                {
                    "x": 164,
                    "y": 422.5
                },
                {
                    "x": 172,
                    "y": 418.5
                },
                {
                    "x": 181,
                    "y": 413.5
                },
                {
                    "x": 195,
                    "y": 404.5
                },
                {
                    "x": 213,
                    "y": 393.5
                },
                {
                    "x": 238,
                    "y": 376.5
                },
                {
                    "x": 265,
                    "y": 356.5
                },
                {
                    "x": 296,
                    "y": 332.5
                },
                {
                    "x": 327,
                    "y": 307.5
                },
                {
                    "x": 357,
                    "y": 282.5
                },
                {
                    "x": 391,
                    "y": 254.5
                },
                {
                    "x": 419,
                    "y": 231.5
                },
                {
                    "x": 444,
                    "y": 213.5
                },
                {
                    "x": 463,
                    "y": 200.5
                },
                {
                    "x": 475,
                    "y": 190.5
                }
            ]
        },
        {
            "drawMode": false,
            "strokeColor": "#000000",
            "strokeWidth": 16,
            "paths": [
                {
                    "x": 193,
                    "y": 278.5
                },
                {
                    "x": 192,
                    "y": 278.5
                },
                {
                    "x": 192,
                    "y": 277.5
                },
                {
                    "x": 194,
                    "y": 275.5
                },
                {
                    "x": 199,
                    "y": 271.5
                },
                {
                    "x": 206,
                    "y": 265.5
                },
                {
                    "x": 217,
                    "y": 255.5
                },
                {
                    "x": 235,
                    "y": 241.5
                },
                {
                    "x": 256,
                    "y": 220.5
                },
                {
                    "x": 280,
                    "y": 194.5
                },
                {
                    "x": 309,
                    "y": 165.5
                },
                {
                    "x": 337,
                    "y": 132.5
                },
                {
                    "x": 367,
                    "y": 98.5
                },
                {
                    "x": 394,
                    "y": 67.5
                },
                {
                    "x": 418,
                    "y": 41.5
                },
                {
                    "x": 437,
                    "y": 21.5
                },
                {
                    "x": 450,
                    "y": 8.5
                },
                {
                    "x": 456,
                    "y": 1.5
                }
            ]
        },
        {
            "drawMode": false,
            "strokeColor": "#000000",
            "strokeWidth": 16,
            "paths": [
                {
                    "x": 212,
                    "y": 187.5
                },
                {
                    "x": 214,
                    "y": 184.5
                },
                {
                    "x": 220,
                    "y": 179.5
                },
                {
                    "x": 229,
                    "y": 173.5
                },
                {
                    "x": 242,
                    "y": 165.5
                },
                {
                    "x": 261,
                    "y": 151.5
                },
                {
                    "x": 289,
                    "y": 132.5
                },
                {
                    "x": 321,
                    "y": 111.5
                },
                {
                    "x": 354,
                    "y": 89.5
                },
                {
                    "x": 384,
                    "y": 68.5
                },
                {
                    "x": 409,
                    "y": 50.5
                },
                {
                    "x": 428,
                    "y": 36.5
                },
                {
                    "x": 438,
                    "y": 29.5
                },
                {
                    "x": 444,
                    "y": 23.5
                }
            ]
        },
        {
            "drawMode": false,
            "strokeColor": "#000000",
            "strokeWidth": 16,
            "paths": [
                {
                    "x": 182,
                    "y": 129.5
                },
                {
                    "x": 181,
                    "y": 127.5
                },
                {
                    "x": 181,
                    "y": 125.5
                },
                {
                    "x": 181,
                    "y": 122.5
                },
                {
                    "x": 181,
                    "y": 118.5
                },
                {
                    "x": 184,
                    "y": 112.5
                },
                {
                    "x": 189,
                    "y": 105.5
                },
                {
                    "x": 197,
                    "y": 95.5
                },
                {
                    "x": 209,
                    "y": 83.5
                },
                {
                    "x": 225,
                    "y": 65.5
                },
                {
                    "x": 243,
                    "y": 47.5
                },
                {
                    "x": 261,
                    "y": 32.5
                },
                {
                    "x": 275,
                    "y": 21.5
                },
                {
                    "x": 285,
                    "y": 13.5
                },
                {
                    "x": 292,
                    "y": 8.5
                },
                {
                    "x": 293,
                    "y": 6.5
                },
                {
                    "x": 284,
                    "y": 10.5
                },
                {
                    "x": 268,
                    "y": 17.5
                },
                {
                    "x": 236,
                    "y": 33.5
                },
                {
                    "x": 199,
                    "y": 51.5
                },
                {
                    "x": 159,
                    "y": 74.5
                },
                {
                    "x": 119,
                    "y": 101.5
                },
                {
                    "x": 78,
                    "y": 131.5
                },
                {
                    "x": 42,
                    "y": 157.5
                },
                {
                    "x": 14,
                    "y": 182.5
                },
                {
                    "x": 1,
                    "y": 218.5
                },
                {
                    "x": 17,
                    "y": 218.5
                },
                {
                    "x": 41,
                    "y": 215.5
                }
            ]
        }
    ]
];
const botWordData = [
    "A cheeky Nandos",
    "A firm handshake",
    "A lingering gaze",
    "A loving pat on the head",
    "A man hiding in a pram",
    "A very disappointing Christmas",
    "A very unfortunate birthmark",
    "A wheelbarrow race",
    "Afro broccoli",
    "Amoeba disco",
    "The last chicken in the shop",
    "An awkward hug",
    "Angry BBQ",
    "Illegal selfie",
    "Surprise baby",
    "Awkward family photo",
    "Back acne",
    "Bad babysitter",
    "Badly drawn on eyebrows",
    "Badly trained dentist",
    "Banana superhero",
    "Getting beaten up by a jellyfish",
    "Beer goggles",
    "Bingo wings",
    "Pirate Booty",
    "Brain freeze",
    "A baby with a beard",
    "Bronies",
    "Brushing your tongue",
    "Builder’s bum",
    "Bum chin",
    "Bum dial",
    "Hamburgers for hands",
    "Underwater breakdancing",
    "Trying to eat your toe",
    "Cannibal banquet",
    "A tower of cats",
    "Centaur lollipop man",
    "Cheese nightmare",
    "Chicken Cottage",
    "Choking on donuts",
    "CIA waterboarding",
    "Clown police line up",
    "Cow tipping",
    "Cowboy wrestling",
    "Crap parkour",
    "Mystery meat",
    "Crying on the inside",
    "Crying over spilt milk",
    "Dad dancing",
    "Dark matter",
    "Depressed toilet",
    "Swinging a cat",
    "A rubbish horse",
    "Dreaming of bacon",
    "Drooling in your sleep",
    "Drowning in someone's eyes",
    "Drunk fly",
    "The human spider",
    "Eating spiderwebs",
    "Disco otter",
    "Very small oven gloves",
    "Exploding cuckoo clock",
    "Extinction of the dinosaurs",
    "Eye-wateringly strong pickles",
    "Eyeball golf",
    "Fainting goats",
    "Rude pasta",
    "Freestyle kazoo solo",
    "A big fat nothing",
    "Giraffe limbo contest",
    "Filling the hole in your tummy",
    "Gorilla panic",
    "Haunted oven",
    "Heavy breathing",
    "Holiday in Hell",
    "Horse burger",
    "Horseplay",
    "Covered in bling",
    "Jazz hands",
    "Karate chopping a frog",
    "Living in a skip",
    "Lost in a ball pit",
    "Lost in Ikea",
    "Man loves bear"
]


export default {
    images,
    fonts,
    WSErrorCodes,
    isBot,
    botDrawingData,
    botWordData,
};